import {
  AiOutlineLeft,
  AiOutlineRight,
  AiOutlineDoubleLeft,
  AiOutlineDoubleRight,
  AiOutlineMail,
  AiOutlineCloseCircle,
} from "react-icons/ai";
import Cookies from "js-cookie";
import { Tooltip } from "react-tooltip";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../../../components/Footer";
import React, { useState, useEffect } from "react";
import { FaSpinner } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from "../../../components/Toast/toastErrorBD";
import ExcelUFCDs from "../../../components/Excel/excelUFCDs/excelUFCDs";
import ModalEnvioContrato from "../../../components/Modal/ModalEnvioContrato";
import NavbarAdmin1 from "../../../components/Navbars/NavbarAdmin1/NavbarAdmin1";
import AlterarFormato from "../../../components/FormChecks/formatDates/foramatDates";
import CheckTypeFinanceiro from "../../../components/CheckTypeAdmin/CheckTypeFinanceiro";
import toastSuccess from "../../../components/Toast/toastSuccess";
import toastError from "../../../components/Toast/toastError";
import DetermineCourseStatus from "../../../components/FormChecks/determinarCourseStatus/determineCourseStatus";
import { TbReportAnalytics } from "react-icons/tb";
import { FaRegListAlt } from "react-icons/fa";
import { PiUserListLight } from "react-icons/pi";
import ExcelAvaliacaoImpacto from "../../../components/Excel/Formularios/formAvaliacaoImpacto";
function Candidaturas() {
  const params = useParams();
  const [data, setData] = useState(null);
  const [admin, setAdmin] = useState(null);
  const [cursoSelecionado, setCursoSelecionado] = useState(0);
  const [totalPages, setTotalPages] = useState();
  const [trainingEntityList, setTrainingEntityList] = useState([]);
  const [operationsList, setOperationsList] = useState();
  const [coordinatorsList, setCoordinatorsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [error, setError] = useState(null);
  const [modalIsOpen2, setModalIsOpen2] = useState(null);
  const [savedSearchTerm, setSavedSearchTerm] = useState(
    Cookies.get("searchTerm")
  );
  const [searchTerm, setSearchTerm] = useState(
    savedSearchTerm ? savedSearchTerm : ""
  );
  const [savedTrainingEntity, setSavedTrainingEntity] = useState(
    Cookies.get("trainingEntity")
  );
  const [trainingEntity, setTrainingEntity] = useState(
    savedTrainingEntity ? savedTrainingEntity : ""
  );
  const [savedOperation, setSavedOperation] = useState(
    Cookies.get("operation")
  );
  const [operation, setOperation] = useState(
    savedOperation ? savedOperation : ""
  );
  const [savedCoordinator, setSavedCoordinator] = useState(
    Cookies.get("coordinator")
  );
  const [coordinator, setCoordinator] = useState(
    savedCoordinator ? savedCoordinator : ""
  );
  const [savedStatusOfCourses, setSavedStatusOfCourses] = useState(
    Cookies.get("statusOfCourses")
  );
  const [statusOfCourses, setStatusOfCourses] = useState(
    savedStatusOfCourses ? savedStatusOfCourses : ""
  );
  const [savedItemsPerPage, setSavedItemsPerPage] = useState(
    Cookies.get("itemsPerPage")
  );
  const [savedCurrentPage, setSavedCurrentPage] = useState(
    Cookies.get("currentPage")
  );
  const [savedContractUpdated, setSavedContractUpdated] = useState(
    Cookies.get("contractUpdated")
  );
  const [contractUpdated, setContractUpdated] = useState(
    savedContractUpdated ? savedContractUpdated : "false"
  );
  const [currentPage, setCurrentPage] = useState(
    savedCurrentPage ? parseInt(savedCurrentPage) : 1
  );
  const navigate = useNavigate();
  localStorage.setItem("contractUpdated", "false");
  const [itemsPerPage, setItemsPag] = useState(
    savedItemsPerPage ? savedItemsPerPage : 5
  );

  const id = localStorage.getItem("id");

  useEffect(() => {
    getAdmin()
  }, []);
  useEffect(() => {
    Cookies.set("searchTerm", searchTerm);
    Cookies.set("trainingEntity", trainingEntity);
    Cookies.set("coordinator", coordinator);
    Cookies.set("operation", operation);
    Cookies.set("statusOfCourses", statusOfCourses);
    Cookies.set("itemsPerPage", itemsPerPage);
    Cookies.set("currentPage", currentPage);
    Cookies.set("contractUpdated", contractUpdated);
    handleSearch();
  }, [currentPage, itemsPerPage, totalPages, trainingEntity, operation, coordinator, statusOfCourses, searchTerm, contractUpdated, cursoSelecionado, modalIsOpen2,]);
  async function getAdmin() {
    try {
      // Fetch the admin details
      const adminResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/admins/${params.id_candidatura}`,
        {
          headers: {
            authorization: localStorage.getItem('admin1'),
          },
        }
      );
      // Check if the admin response is OK
      if (!adminResponse.ok) {
        throw new Error(`HTTP error! status: ${adminResponse.status}`);
      }
      const adminResult = await adminResponse.json();
      // Set admin details and training entity list
      setAdmin(adminResult);
      setTrainingEntityList(adminResult.entFormadora);
      // Fetch all operations without pagination
      const operationsResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/Operacao`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: localStorage.getItem("admin1"),
          },
          method: "GET",
        }
      );
      // Check if the operations response is OK
      if (!operationsResponse.ok) {
        throw new Error(`HTTP error! status: ${operationsResponse.status}`);
      }
      const operationsResult = await operationsResponse.json();
      // Ensure both `entFormadora` and `operationsResult` are valid
      if (!adminResult.entFormadora || !Array.isArray(adminResult.entFormadora)) {
        throw new Error("entFormadora is missing or invalid in admin result.");
      }
      if (!Array.isArray(operationsResult)) {
        throw new Error("Operations result is not an array.");
      }
      // Encontrando operações que contêm as entFormadora presentes na lista adminResult.entFormadora
      const matchingOperations = operationsResult.filter((operation) =>
        operation.entFormadora.some((entFormadoraId) =>
          adminResult.entFormadora.some(
            (entFormadora) => entFormadora._id.toString() === entFormadoraId._id.toString()
          )
        )
      );
      // Update state with unique operations
      setOperationsList(matchingOperations);

      // Fetch courses with pagination
      const percursosResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/percurso/getAllPercursosByEntFormadora/${id}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: localStorage.getItem("admin1"),
          },
          method: "GET",
        }
      );
      // Check if the percursos response is OK
      if (!percursosResponse.ok) {
        throw new Error(`HTTP error! status: ${percursosResponse.status}`);
      }
      const percursosResult = await percursosResponse.json();

      // Extrair coordenadores dos percursos e remover duplicatas
      const uniqueCoordinators = new Map();
      percursosResult.data.forEach((percurso) => {
        if (percurso.Coordenador && percurso.Coordenador.length > 0) {
          percurso.Coordenador.forEach((coordenador) => {
            uniqueCoordinators.set(coordenador._id, {_id:coordenador._id, fullName: coordenador.fullName});
          });
        }
      });
      // Update state with unique coordinators
      setCoordinatorsList(Array.from(uniqueCoordinators.values()));
      setLoading2(false);
    } catch (error) {
      setLoading2(false);
      console.error("Error fetching data: ", error);
      toast("Erro ao obter os dados, por favor tente mais tarde.");
      setError(error);
    }
  }
  
  // Get all Percursos based on the filters, searchTerm and pagination
  async function getCourses(
    currentPage,
    itemsPerPage,
    searchTerm,
    statusOfCourses,
    trainingEntity,
    coordinator,
    operation
  ) {
    try {
      const query = new URLSearchParams({
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        searchTerm: searchTerm,
        statusOfCourses: statusOfCourses,
        trainingEntity: trainingEntity,
        coordinator: coordinator,
        operation: operation,
      });

      // Fetch courses with pagination
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/percurso/getAllPercursosByEntFormadora/${id}?${query}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: localStorage.getItem("admin1"),
          },
          method: "GET",
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      const totalItems = result.totalItems;
      const totalPages = Math.ceil(totalItems / result.itemsPerPage);

      // Save the data that comes from the API and define the total of pages and the total of items
      if (currentPage > totalPages) {
        setCurrentPage(totalPages > 0 ? totalPages : 1);
      } else {
        setTotalPages(totalPages);
      }
      setData(result.data);
      setLoading(false)
    } catch (error) {
      setLoading(false);
      toast("Erro ao obter os dados, por favor tente mais tarde.");
    }
  }


  const handleSearch = async () => {
    await getCourses(
      currentPage,
      itemsPerPage,
      searchTerm,
      statusOfCourses,
      trainingEntity,
      coordinator,
      operation
    );
  };
  function envioMailAvaImpacto(id) {
    fetch(`${process.env.REACT_APP_API_URL}/percurso/envioMailAvaImp/${id}`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'authorization': localStorage.getItem('admin1')
      },
    }).then((result) => {
      result.json().then((resp) => {
        if (resp.length > 0) toastSuccess('Email enviado aos formandos aprovados.')
        else toastError("Não existem formandos aprovados.")
      })
    })
  }

  // Increase the current page
  function increasePagination() {
    setCurrentPage((prevCurrentPage) => {
      if (prevCurrentPage < totalPages) {
        return prevCurrentPage + 1;
      }
      return prevCurrentPage;
    });
  }

  // Decrease the current page
  function decreasePagination() {
    setCurrentPage((prevCurrentPage) => {
      if (prevCurrentPage > 1) {
        return prevCurrentPage - 1;
      }
      return prevCurrentPage;
    });
  }

  async function excelUfcds() {
    try {
      setLoading(true);
      await ExcelUFCDs("admin1", admin);
    } finally {
      setLoading(false);
    }
  }

  const handleClick = (event, item) => {
    // Check if the clicked element is a <td>
    if (event.target.tagName === "TD") {
      // Check if the <td> has a 'clickable' class
      if (!event.target.classList.contains("item")) {
        // Navigate to the desired location
        // Replace 'destination' with your actual navigation logic
      } else navigate("/MenuAdmin/" + id + "/cursos/" + item._id);
    }
  };

  // When data is not fetched yet show loading
  if (loading)
    return (
      <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
        <div className="loading"></div>
      </div>
    );
  if (error)
    return toast("Erro ao obter os dados, por favor tente mais tarde.");

  // Go to the last page
  function goToLastPage() {
    if (currentPage !== totalPages) {
      setCurrentPage(totalPages);
    }
  }

  // Go to the first page
  function goToFirstPage() {
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  }

  function addSixMonthsToDate(dateString) {
    const date = new Date(dateString);
    date.setMonth(date.getMonth() + 6);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  }
  return (
    <>
      <main className="min-h-screen">
        <div className="w-full">
          <NavbarAdmin1 currentPage={"gerirCursos"} />
        </div>
        <Link to={"/MenuAdmin/" + id} className="back-button-div-noNavbar">
          <img
            className="back-button-img"
            src={process.env.PUBLIC_URL + "/img/back_arrow.svg"}
            alt="Back"
          />
        </Link>
        <div className="flex-grow mt-8">
          <h1 className="title">Lista de cursos/percursos</h1>
          <label className="search-input-label">Pesquisar</label>
          <div className="search-div mt-2 mb-2">
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                <svg
                  className="w-4 h-4 text-gray-500"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                defaultValue={searchTerm}
                type="text"
                className="search-input"
                placeholder="ex: Nome, Local ..."
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
              />
            </div>
          </div>
          <label className="search-input-label">Filtrar por:</label>
          <div className="flex flex-col justify-center mb-2 sm:flex-row">
            <div className="flex flex-col sm:mr-5">
              <label className="text-text-second-color" htmlFor="templateCheck">
                Entidades Formadoras{" "}
              </label>
              {trainingEntityList.length > 0 && (
                <select
                  onWheel={(e) => e.target.blur()}
                  defaultValue={trainingEntity}
                  className="mx-auto w-52 search-selector"
                  name="sel"
                  id="seletorEnt"
                  onChange={(e) => {
                    setTrainingEntity(e.target.value);
                  }}
                >
                  <option value="">Todas</option>
                  {trainingEntityList.map((item, index) => (
                    <option value={item._id} key={item.name}>
                      {item.name}
                    </option>
                  ))}
                </select>
              )}
            </div>
            <div className="mr-5 flex flex-col">
              <label className="text-text-second-color" htmlFor="templateCheck">
                Operações{" "}
              </label>
              {operationsList && operationsList.length > 0 && (
                <select
                  defaultValue={operation}
                  onWheel={(e) => e.target.blur()}
                  className="mx-auto w-52 search-selector"
                  name="sel"
                  id="seletorOp"
                  onChange={(e) => {
                    setOperation(e.target.value);
                  }}
                >
                  <option value="">Todas</option>
                  {operationsList.map((item, index) => (
                    <option value={item._id} key={index}>
                      {item.codigoOperacao}
                    </option>
                  ))}
                </select>
              )}
            </div>
            <div className="flex flex-col sm:mr-5">
      <label className="text-text-second-color" htmlFor="seletorCoord">
        Coordenadores{" "}
      </label>
      {coordinatorsList && coordinatorsList.length > 0 &&<select
        onWheel={(e) => e.target.blur()}
        defaultValue={coordinator}
        className="mx-auto w-52 search-selector"
        name="sel"
        id="seletorCoord"
        onChange={(e) => {
          setCoordinator(e.target.value);
        }}
      >
        {loading2 ? (
          <option value="">A carregar...</option>
        ) : (
          <option value="">Todos</option>)}
          {coordinatorsList
            .slice()
            .sort((a, b) => a.fullName.localeCompare(b.fullName))
            .map((item, index) => (
              <option value={item._id} key={index}>
                {item.fullName}
              </option>
            ))
          }
      </select>}
    {loading2 && (
        <FaSpinner className="animate-spin" />
    )}
    </div>
            <div className="mr-5 flex flex-col">
              <label className="text-text-second-color" htmlFor="templateCheck">
                Estado do Percurso{" "}
              </label>
              <select
                defaultValue={statusOfCourses}
                onWheel={(e) => e.target.blur()}
                className="mx-auto w-52 search-selector"
                name="sel"
                id="seletorOp"
                onChange={(e) => {
                  setStatusOfCourses(e.target.value);
                }}
              >
                <option value="">Todos</option>
                <option value={"A decorrer"}>A decorrer</option>
                <option value={"Terminado"}>Terminado</option>
                <option value={"Planeado"}>Planeado</option>
              </select>
            </div>
          </div>

          {data && data.length > 0 ? (
            <>
              <div className="flex justify-center items-center mb-3">
                <label className="text-text-main-color">
                  Dados por página:{" "}
                </label>
                <select
                  onWheel={(e) => e.target.blur()}
                  className="selector w-14 ml-1"
                  defaultValue={itemsPerPage}
                  onChange={(e) => {
                    setItemsPag(parseInt(e.target.value));
                  }}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                </select>
              </div>
              <div className="flex items-center justify-center mx-auto mb-1 max-w-2xs xs:max-w-xs sm:max-w-lg md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-[90%] rounded-2xl;">
                <table className="my-table w-full">
                  <thead className="bg-main-color">
                    <tr className="text-white h-14">
                      <th className="p-2 min-w-[80px] border border-white">
                        Operação
                      </th>
                      <th className="p-2 min-w-[80px] border border-white">
                        Entidade Formadora
                      </th>
                      <th className="p-2 min-w-[150px] border border-white">
                        Nome
                      </th>
                      <th className="p-2 min-w-[80px] border border-white">
                        Código Interno
                      </th>
                      <th className="p-2 min-w-[80px] border border-white">
                        Código SIGO
                      </th>
                      <th className="p-2 min-w-[80px] border border-white">
                        Forma de Organização
                      </th>
                      <th className="p-2 min-w-[80px] border border-white">
                        Modalidades
                      </th>
                      <th className="p-2 min-w-[80px] border border-white">
                        Local
                      </th>
                      <th className="p-2 min-w-[80px] border border-white">
                        Data de Inicio
                      </th>
                      <th className="p-2 min-w-[80px] border border-white">
                        Data de Fim
                      </th>
                      <th className="p-2 min-w-[80px] border border-white">
                        Avaliação Impacto
                      </th>
                      <th className="p-2 min-w-[80px] border border-white">
                        Duração
                      </th>
                      <th className="p-2 min-w-[80px] border border-white">
                        Nível
                      </th>
                      <th className="p-2 min-w-[100px] border border-white">
                        Estado
                      </th>
                      <th className="p-2 min-w-[100px] border border-white">
                        Fecho Admin
                      </th>
                      <th className="p-2 min-w-[150px] border border-white">
                        Coordenador Pedagógico
                      </th>
                    </tr>
                  </thead>
                  {data && data.length > 0 ? (
                    <tbody className="text-text-main-color">
                      {data
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((item, index) => (
                          <tr
                            key={item._id}
                            className="row-click"
                            onClick={(e) => handleClick(e, item)}
                          >
                            <td className="border border-white item">
                              {item.operacao[0] &&
                                item.operacao[0].codigoOperacao}
                            </td>
                            <td className="border border-white item">
                              {item.entFormadora[0] ? (
                                <>{item.entFormadora[0].name} </>
                              ) : (
                                "N/A"
                              )}
                            </td>
                            <td className="border border-white item">
                              {item.name ? <>{item.name} </> : "N/A"}
                            </td>
                            <td className="border border-white item">
                              {item.codeInterno ? (
                                <>{item.codeInterno} </>
                              ) : (
                                "N/A"
                              )}
                            </td>
                            <td className="border border-white item">
                              {item.codeSIGO ? <>{item.codeSIGO} </> : "N/A"}
                            </td>
                            <td className="border border-white item">
                              {item.type ? <> {item.type} </> : "N/A"}
                            </td>
                            <td className="border border-white item">
                              {item.modalidade ? <>{item.modalidade}</> : "N/A"}
                            </td>
                            <td className="border border-white item">
                              {item.locality ? <>{item.locality}</> : "N/A"}
                            </td>
                            <td className="border border-white item">
                              {item.dateBegin ? (
                                <>{AlterarFormato(item.dateBegin)} </>
                              ) : (
                                "N/A"
                              )}
                            </td>
                            <td className="border border-white item">
                              {item.dateEnd ? (
                                <>{AlterarFormato(item.dateEnd)} </>
                              ) : (
                                "N/A"
                              )}
                            </td>
                            <td className="border border-white item">
                              {new Date() <
                                new Date(addSixMonthsToDate(item.dateEnd)) ? (
                                <>
                                  {AlterarFormato(
                                    addSixMonthsToDate(item.dateEnd)
                                  )}{" "}
                                </>
                              ) : (
                                <div className="flex flex-col gap-2">
                                  {" "}
                                  {AlterarFormato(
                                    addSixMonthsToDate(item.dateEnd)
                                  )}
                                  <div>
                                  <button
                                    className="button-geral tooltip"
                                    data-tip="Avaliação de Impacto"
                                    onClick={() => {
                                      setModalIsOpen2(true);
                                      setCursoSelecionado(item)
                                    }
                                    }
                                  >
                                      <PiUserListLight />
                                  </button>
                                  </div>
                                </div>
                              )}
                            </td>
                            <td className="border border-white item">
                              {item.duration ? <>{item.duration} </> : "N/A"}
                            </td>
                            <td className="border border-white item">
                              {item.nivel ? <>{item.nivel} </> : "N/A"}
                            </td>
                            <td className="border border-white item">
                              {DetermineCourseStatus(
                                item.dateBegin,
                                item.dateEnd
                              )}
                            </td>
                            <td className="border border-white item">
                              {item.fechoAdmin === true ? "Fechado" : "Aberto"}
                            </td>
                            <td className="border border-white item">
                              {item.Coordenador[0] ? (
                                <>
                                  {item.Coordenador[0].fullName}
                                  <div className="button-container justify-center">
                                    {item.dateEnd ? (
                                      <div className="">
                                        {item.fechoCoordenador === true ? (
                                          <div className="">
                                            {item.evaluation ||
                                              item.evaluation === 0 ? (
                                              <span
                                                className="evaluation cursor-default"
                                                data-tooltip-id="my-tooltip"
                                                data-tooltip-content={
                                                  "Avaliação"
                                                }
                                              >
                                                {item.evaluation}%
                                              </span>
                                            ) : (
                                              <button className="button-geral">
                                                <Link
                                                  to={
                                                    "/menuAdmin/" +
                                                    params.id_candidatura +
                                                    "/cursos/avaliacao/" +
                                                    item._id
                                                  }
                                                >
                                                  Avaliar
                                                </Link>
                                              </button>
                                            )}
                                          </div>
                                        ) : null}
                                      </div>
                                    ) : null}
                                    {item.Coordenador[0].tipo === false ? (
                                      <ModalEnvioContrato
                                        key={item._id}
                                        tipo={item.modalidade}
                                        data={item}
                                        token={"admin1"}
                                      />
                                    ) : null}
                                  </div>
                                </>
                              ) : (
                                "N/A"
                              )}
                            </td>
                          </tr>
                        ))}
                      <div className="modal2" style={{ display: modalIsOpen2 ? "block" : "none" }}>
                        <div className="fixed inset-0 w-screen h-screen m-0 bg-opacity-0 font-Lato">
                          <div className="flex items-center justify-center h-screen">
                            <div className="relative z-50 p-2 rounded-lg shadow-lg  xl:w-128 bg-secundary-color shadow-gray-600 min-w-[40%] min-h-[60%]">
                              <div >
                                <div className="flex flex-col justify-center my-2">
                                  <h2 className="subtitle">{cursoSelecionado.name}</h2>
                                  <div className="flex flex-col min-h-[35em] gap-10 my-auto justify-center items-center">
                                    <div>
                                      <h3 className="third-title">Formulário de avaliação</h3>
                                      <div className="flex gap-10 justify-center">
                                        <button onClick={() => envioMailAvaImpacto(cursoSelecionado._id)} className={"tooltip"} data-tip={"Enviar mail de preenchimento do Formulário de Impacto"}>
                                          <AiOutlineMail className={"p-2 text-main-color hover:text-text-second-color m-auto"} size={120} />
                                        </button>
                                      </div>
                                    </div>
                                    <button onClick={() =>(cursoSelecionado.avaliacaoImpactoFormandosResults && cursoSelecionado.avaliacaoImpactoFormandosResults.length > 0) && ExcelAvaliacaoImpacto(cursoSelecionado, "admin1")} className={!(cursoSelecionado.avaliacaoImpactoFormandosResults && cursoSelecionado.avaliacaoImpactoFormandosResults.length > 0) ? "cursor-not-allowed tooltip" : "tooltip"} data-tip={"Download do Relatório do Formulário"}>
                                      <h3 className="third-title">Download do Relatório do Formulário</h3>
                                      <TbReportAnalytics className={!(cursoSelecionado.avaliacaoImpactoFormandosResults && cursoSelecionado.avaliacaoImpactoFormandosResults.length > 0) ? "p-2 text-slate-300 m-auto" : "p-2 text-main-color hover:text-text-second-color m-auto"} size={120} />
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <button className="absolute top-1.5 right-1.5" onClick={(e) => setModalIsOpen2(false)}>
                                <AiOutlineCloseCircle size={24} className="hover:text-text-error" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </tbody>
                  ) : (
                    <tbody />
                  )}
                </table>
              </div>
              <Tooltip id="my-tooltip" />
              <div className="flex items-center justify-center mt-4 mb-8">
                <button
                  className={
                    currentPage > 1
                      ? "button-geral mr-1"
                      : "button-disabled mr-1"
                  }
                  onClick={() => goToFirstPage()}
                >
                  <AiOutlineDoubleLeft />
                </button>
                <button
                  className={
                    currentPage > 1
                      ? "button-geral mr-1"
                      : "button-disabled mr-1"
                  }
                  onClick={() => decreasePagination()}
                >
                  <AiOutlineLeft />
                </button>
                <p className="text-main-color">
                  Página {currentPage} - {totalPages}
                </p>
                <button
                  className={
                    currentPage < totalPages
                      ? "button-geral ml-1"
                      : "button-disabled ml-1"
                  }
                  onClick={() => increasePagination()}
                >
                  <AiOutlineRight />
                </button>
                <button
                  className={
                    currentPage < totalPages
                      ? "button-geral ml-1"
                      : "button-disabled ml-1"
                  }
                  onClick={() => goToLastPage()}
                >
                  <AiOutlineDoubleRight />
                </button>
              </div>
            </>
          ) : (
            <p className="third-title">Não existem dados</p>
          )}
          {localStorage.getItem("TipoAdmin") !== "TORVC" && (
            <button
              className="button-geral mt-6 mb-10"
              onClick={() => excelUfcds("admin1", admin)}
            >
              <h3 className="text-white group-hover:text-white uppercase">
                Download Informações das Disciplinas de Todos os Percursos
              </h3>
            </button>
          )}
        </div>
      </main>
      <Footer />
    </>
  );
}

export default Candidaturas;
