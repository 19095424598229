import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';
import axios from 'axios';
import toastError from '../../Toast/toastError';

const excelGenerator = async (userData, autorization) => {
    try {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');
        const videoConferencias = [];
        userData.eventos.forEach(element => {
            videoConferencias.push(element)
        });
        //Arredondar Horas
        function roundToNearestHour(date) {
            date.setMinutes(date.getMinutes() + 30);
            date.setMinutes(0, 0, 0);

            return date;
        }


        //vc é video conferencia
        const saveVcs = []
        if (videoConferencias.length > 0) {
            function hasPOrVC(inputString) {
                const regex = /P|VC/;
                return regex.test(inputString);
            }
            function hasExtra(inputString) {
                const regex = /extra/;
                return regex.test(inputString);
            }
            let index = -1
            let extra = false
            const videoConferenciasSorted = sortArrayByDate(videoConferencias)
            videoConferenciasSorted.forEach(vc => {
                let hours
                let realHour
                let min
                let hoursEnd
                let realHourEnd
                let minEnd
                let outHours
                if (hasPOrVC(vc.title) === true && !hasExtra(vc.title)) {
                    index++
                } else if (hasExtra(vc.title)) {
                    extra = true
                }
                else extra = true
                if (userData.percurso[0].horario === "Laboral") {
                    if ((new Date(vc.start)).getHours() < 18) {
                        hours = roundToNearestHour(new Date(vc.start)).getHours() - 8
                        min = new Date(vc.start).getMinutes()
                        realHour = `${new Date(vc.start).getHours()}:${min === 0 ? "00" : min}`
                    }
                    else if ((new Date(vc.start)).getHours() >= 18) {
                        hours = 10
                        min = new Date(vc.start).getMinutes()
                        realHour = `${new Date(vc.start).getHours()}:${min === 0 ? "00" : min}`
                    }
                    if ((new Date(vc.end)).getHours() < 18) {
                        hoursEnd = roundToNearestHour(new Date(vc.end)).getHours() - 9
                        minEnd = new Date(vc.end).getMinutes()
                        realHourEnd = `${new Date(vc.end).getHours()}:${minEnd === 0 ? "00" : minEnd}`
                    }
                    else if ((new Date(vc.end)).getHours() >= 18) {
                        hoursEnd = 10
                        outHours = true
                        minEnd = new Date(vc.end).getMinutes()
                        realHourEnd = `${new Date(vc.end).getHours()}:${minEnd === 0 ? "00" : minEnd}`
                    }
                }
                else {
                    if ((new Date(vc.start)).getHours() <= 15) {
                        hours = 1
                        min = new Date(vc.start).getMinutes()
                        realHour = `${new Date(vc.start).getHours()}:${min === 0 ? "00" : min}`
                    }
                    else {
                        hours = (roundToNearestHour(new Date(vc.start)).getHours() - 13)
                        min = new Date(vc.start).getMinutes()
                        realHour = `${new Date(vc.start).getHours()}:${min === 0 ? "00" : min}`
                    }

                    if ((new Date(vc.start)).getHours() <= 15) {
                        hoursEnd = 2
                        outHours = true
                        minEnd = new Date(vc.end).getMinutes()
                        realHourEnd = `${new Date(vc.end).getHours()}:${minEnd === 0 ? "00" : minEnd}`
                    }
                    else {
                        hoursEnd = ((roundToNearestHour(new Date(vc.end)).getHours() === 0 ? 24 : roundToNearestHour(new Date(vc.end)).getHours()) - 14)
                        minEnd = new Date(vc.end).getMinutes()
                        realHourEnd = `${new Date(vc.end).getHours()}:${minEnd === 0 ? "00" : minEnd}`
                    }
                }
                saveVcs.push({
                    title: vc.title,
                    index: index,
                    extra: extra,
                    tipo: vc.tipo,
                    sessao: vc.sessao,
                    ufcd: vc.ufcds[0].codeUfcd + vc.ufcds[0].name,
                    hourStart: hours,
                    realHour,
                    min: min,
                    hoursEnd: hoursEnd,
                    realHourEnd,
                    minEnd: minEnd,
                    outHours: outHours,
                    dayStart: (new Date(vc.start)).getDate(),
                    year: (new Date(vc.start)).getFullYear(),
                    monthStart: (new Date(vc.start)).getMonth() + 1,
                    monthEnd: (new Date(vc.end)).getMonth() + 1,
                    dayEnd: (new Date(vc.end)).getDate(),
                    start: vc.start,
                    end: vc.end,
                    color: vc.ufcds[0].color
                })

            })
        }
        else toastError("Não contem Aulas.")
        function getColumnLetterFromIndex(index) {
            const columnLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            let columnName = '';

            while (index > 0) {
                const remainder = (index - 1) % 26;
                columnName = columnLetters[remainder] + columnName;
                index = Math.floor((index - 1) / 26);
            }

            return columnName;
        }
        //Caluca o total de meses entre a data do inicio e do fim
        function DateDifference(datea, dateb) {
            const year1 = new Date(datea).getFullYear()
            const month1 = new Date(datea).getMonth() + 1
            const year2 = new Date(dateb).getFullYear()
            const month2 = new Date(dateb).getMonth() + 1

            const yearDiff = year2 - year1;
            const monthDiff = month2 - month1;

            const totalMonthsDiff = yearDiff * 12 + monthDiff;

            return totalMonthsDiff;
        }
        //Data de Inicio e Data final
        const inicio = new Date(userData.dateBegin)
        const mesInicio = inicio.getMonth()
        const anoInicio = inicio.getFullYear()

        const fim = new Date(userData.dateEnd)
        const mesFim = fim.getMonth()
        const anoFim = fim.getFullYear()

        const convertDateFormat2 = (originalDate) => {
            // Split the date into an array using the dash as a separator
            const dateArray = originalDate.split('-');
            // Rearrange the array elements to the desired format
            const formattedDate = `${dateArray[2]}` + `/${dateArray[1]}` + `/${dateArray[0]}`;
            return formattedDate;
        };


        const difMeses = DateDifference(inicio, fim)
        function sortArrayByDate(array) {
            // Função de comparação para ordenar os objetos por data
            const compareByDate = (a, b) => new Date(a.start) - new Date(b.start);

            // Cria uma cópia do array original antes de ordenar
            const sortedArray = [...array];

            // Ordena o array usando a função de comparação
            sortedArray.sort(compareByDate);

            return sortedArray;
        }
        /*   // Modulos e SubModulos
          const saveModulos = []
          const modulos = userData.modulos
        
          const sortedModulos = sortArrayByDate(modulos);
          sortedModulos.forEach(modulo => {
              let totalDias = (new Date(modulo.end) - new Date(modulo.start)) / (1000 * 3600 * 24)
              let hours = 0
              if (userData.percurso[0].horario === "Laboral") {
                  if (modulo.endHour) {
                      if ((new Date(modulo.endHour)).getHours() <= 12) {
                          hours = parseInt(modulo.endHour) - 7
                      }
                      else if((new Date(modulo.dateEnd)).getHours() >= 18){
                          hours = parseInt((new Date(modulo.dateEnd)).getHours()) - 9
                      }
                      else {
                          hours = parseInt(modulo.endHour) - 8
                      }
                  }
                  saveModulos.push({
                      title: modulo.title,
                      endHours: hours,
                      dayStart: (new Date(modulo.start)).getDate(),
                      monthStart: (new Date(modulo.start)).getMonth() + 1,
                      yearStart: (new Date(modulo.start)).getFullYear(),
                      monthEnd: (new Date(modulo.end)).getMonth() + 1,
                      dayEnd: (new Date(modulo.end)).getDate(),
                      yearEnd: (new Date(modulo.end)).getFullYear(),
                      duration: totalDias,
                      color: generateRandomColor()
                  })
              }
              else {
                  if (modulo.endHour) {
                      if ((new Date(modulo.endHour)).getHours() <= 15) {
                          hours = 1
                      }
                      else {
                          hours = parseInt(modulo.endHour) - 7
                      }
                  }
                  saveModulos.push({
                      title: modulo.title,
                      endHours: hours,
                      dayStart: (new Date(modulo.start)).getDate(),
                      monthStart: (new Date(modulo.start)).getMonth() + 1,
                      yearStart: (new Date(modulo.start)).getFullYear(),
                      monthEnd: (new Date(modulo.end)).getMonth() + 1,
                      dayEnd: (new Date(modulo.end)).getDate(),
                      yearEnd: (new Date(modulo.end)).getFullYear(),
                      duration: totalDias,
                      color: generateRandomColor()
                  })
              }
          });
          // Testes
          const saveTestes = []
          const testes = userData.testes
          const sortedTestes = sortArrayByDate(testes);
          sortedTestes.forEach(teste => {
              let totalDias = (new Date(teste.dateEnd) - new Date(teste.dateBegin)) / (1000 * 3600 * 24)
              let hours = 0
              if (userData.percurso[0].horario === "Laboral") {
                  if (teste.dateEnd) {
                      if ((new Date(teste.dateEnd)).getHours() <= 12) {
                          hours = parseInt((new Date(teste.dateEnd)).getHours()) - 8
                      }
                      else if((new Date(teste.dateEnd)).getHours() > 18){
                          hours = 0
                      }
                      else {
                          hours = parseInt((new Date(teste.dateEnd)).getHours()) - 9
                      }
                  }
                  saveTestes.push({
                      endHours: hours,
                      dayStart: (new Date(teste.dateBegin)).getDate(),
                      monthStart: (new Date(teste.dateBegin)).getMonth() + 1,
                      yearStart: (new Date(teste.dateBegin)).getFullYear(),
                      monthEnd: (new Date(teste.dateEnd)).getMonth() + 1,
                      yearEnd: (new Date(teste.dateEnd)).getFullYear(),
                      dayEnd: (new Date(teste.dateEnd)).getDate(),
                      monthEnd: (new Date(teste.dateEnd)).getMonth() + 1,
                      duration: totalDias,
                      color: "#FFFF00"
                  })
              }
              else {
                  if (teste.dateEnd) {
                      if ((new Date(teste.dateEnd)).getHours() <= 15) {
                          hours = 0
                      }
                      else {
                          hours = parseInt((new Date(teste.dateEnd)).getHours()) - 11
                      }
                  }
                  saveTestes.push({
                      endHours: hours,
                      dayStart: (new Date(teste.dateBegin)).getDate(),
                      monthStart: (new Date(teste.dateBegin)).getMonth() + 1,
                      yearStart: (new Date(teste.dateBegin)).getFullYear(),
                      monthEnd: (new Date(teste.dateEnd)).getMonth() + 1,
                      yearEnd: (new Date(teste.dateEnd)).getFullYear(),
                      dayEnd: (new Date(teste.dateEnd)).getDate(),
                      monthEnd: (new Date(teste.dateEnd)).getMonth() + 1,
                      duration: totalDias,
                      color: "#FFFF00"
                  })
              }
          }); */


        function getMonthName(monthNumber) {
            const date = new Date();
            date.setMonth(monthNumber - 1);

            return date.toLocaleString('pt-Pt', { month: 'long' });
        }

        const bold = { bold: true };
        const bold2 = { bold: true, size: 10 };
        const title = { bold: true, name: "DejaVu Sans", size: 14 };
        const borderThin = {
            top: { style: 'thin' },    // Top border
            bottom: { style: 'thin' }, // Bottom border
            left: { style: 'thin' },   // Left border
            right: { style: 'thin' },  // Right border
        };
        //Preenche as celulas do excel
        function applyFillToCell(worksheet, cells) {
            const color = cells[0];
            cells.border = borderThin;
            cells.slice(1).forEach(cell => {
                worksheet.getCell(cell).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: color },
                };
            });

            for (let col = 3; col <= 39; col++) {
                for (let row = 16; row <= 25; row++) {
                    const cell = worksheet.getCell(row, col);
                    cell.border = borderThin
                    // Set the fill color of the cell
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FF' + "#B2B2B2".substring(1) }
                    };
                }
            }

            for (let col = 1; col <= 39; col++) {
                const cell = worksheet.getCell(14, col);
                cell.border = borderThin
                cell.font = bold
            }
        }


        // Variaveis de estilização
        const alignment = {
            horizontal: 'center',
            vertical: 'middle',
            wrapText: true,
        };
        const imageUrlEntFormador = userData.percurso[0].entFormadora[0].logotipo;
        const imageBufferEFor = await axios.get(imageUrlEntFormador, { responseType: 'arraybuffer' });
        // Verificação de ano biSexto
        function isLeapYear(year) {
            if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
                return true; // É bissexto
            } else {
                return false; // Não é bissexto
            }
        }
        //dias da semana
        const daysOfWeek = ["Sab", "Dom", "Seg", "Ter", "Qua", "Qui", "Sex"];

        function getFirstDayOfMonth(year, month) {
            // Create a new Date object for the 1st day of the specified month and year
            let day = 0
            const firstDay = new Date(year, month - 1, 1);
            switch (firstDay.getDay()) {
                case 0:
                    day = 2;
                    break;
                case 1:
                    day = 3;
                    break;
                case 2:
                    day = 4;
                    break;
                case 3:
                    day = 5;
                    break;
                case 4:
                    day = 6;
                    break;
                case 5:
                    day = 7;
                    break;
                case 6:
                    day = 1;
                    break;
                default:
                    day = 0;
                    break;
            }
            // Get the day of the week (0: Sunday, 1: Monday, ..., 6: Saturday)
            return day
        }
        //Cor aleatoria
        function generateRandomColor() {
            const a = Math.floor(Math.random() * 256); // Random alpha value between 0 and 255
            const r = Math.floor(Math.random() * 256);
            let g = Math.floor(Math.random() * 256);
            const b = Math.floor(Math.random() * 256);

            // Ensure a significant difference between green and red values
            const threshold = 40; // Adjust this threshold as needed
            if (Math.abs(r - g) < threshold) {
                const direction = r < g ? 1 : -1;
                g = Math.min(255, Math.max(0, g + direction * threshold));
            }

            let argbColor = `#${(a << 24 | r << 16 | g << 8 | b).toString(16).padStart(8, '0')}`;
            argbColor = argbColor.replace("-", "");
            return argbColor;
        }
        //Define altura de linhas
        function setMultipleRowHeight(worksheet, startRow, endRow, height) {
            for (let row = startRow; row <= endRow; row++) {
                worksheet.getRow(row).height = height;
            }
        }
        // Tratar das cores
        // Create a function to load and add the image
        async function loadImageAndAddToWorksheet() {
            // Create a new Image object
            const img = new Image();

            // Create a promise to resolve when the image has loaded
            return new Promise((resolve, reject) => {
                img.onload = function () {
                    const originalWidth = img.width;
                    const originalHeight = img.height;

                    // Calculate the scaling factor based on the desired width (e.g., 150)

                    const scalingFactor = originalWidth / originalHeight;

                    // Calculate the scaled height
                    const scaledHeight = 50;
                    const desiredWidth = scalingFactor * 50;
                    const imageIdEFor = workbook.addImage({
                        buffer: imageBufferEFor.data,
                        extension: 'png',
                    });

                    worksheet.addImage(imageIdEFor, {
                        tl: { col: 0.5, row: 0.4 },
                        ext: { width: desiredWidth, height: scaledHeight },
                    });

                    // Resolve the promise
                    resolve();
                };

                // Set the source of the image to your image file
                img.src = imageUrlEntFormador;
            });
        }

        // Call the function to load and add the image
        await loadImageAndAddToWorksheet();

        if (userData.percurso[0].entFinanciadora.length > 0) {
            if (userData.percurso[0].entFinanciadora[0].logotipo1) {
                const imageUrlEntFinanciadora = userData.percurso[0].entFinanciadora[0].logotipo1;
                const imageBuffer = await axios.get(imageUrlEntFinanciadora, { responseType: 'arraybuffer' });

                async function loadImage2AndAddToWorksheet() {
                    // Create a new Image object
                    const img = new Image();

                    // Create a promise to resolve when the image has loaded
                    return new Promise((resolve, reject) => {
                        img.onload = function () {
                            const originalWidth = img.width;
                            const originalHeight = img.height;


                            const scalingFactor = originalWidth / originalHeight;

                            // Calculate the scaled height
                            const scaledHeight = 50;
                            const desiredWidth = scalingFactor * 50;


                            const imageId2 = workbook.addImage({
                                buffer: imageBuffer.data,
                                extension: 'png',
                            });
                            worksheet.addImage(imageId2, {
                                tl: { col: 32, row: 0.4 },
                                ext: { width: desiredWidth, height: scaledHeight },
                            });

                            // Resolve the promise
                            resolve();
                        };

                        // Set the source of the image to your image file
                        img.src = imageUrlEntFinanciadora;
                    });
                }
                await loadImage2AndAddToWorksheet();
            }
            if (userData.percurso[0].entFinanciadora[0].logotipo2) {
                const imageUrlEntFinanciadora = userData.percurso[0].entFinanciadora[0].logotipo2;
                const imageBuffer = await axios.get(imageUrlEntFinanciadora, { responseType: 'arraybuffer' });

                async function loadImage2AndAddToWorksheet() {
                    // Create a new Image object
                    const img = new Image();

                    // Create a promise to resolve when the image has loaded
                    return new Promise((resolve, reject) => {
                        img.onload = function () {
                            const originalWidth = img.width;
                            const originalHeight = img.height;


                            const scalingFactor = originalWidth / originalHeight;

                            // Calculate the scaled height
                            const scaledHeight = 50;
                            const desiredWidth = scalingFactor * 50;


                            const imageId2 = workbook.addImage({
                                buffer: imageBuffer.data,
                                extension: 'png',
                            });
                            let lengthFinal = 0
                            if (userData.eventos.length > userData.formadores.length) {
                                lengthFinal = userData.eventos.length
                            }
                            else lengthFinal = userData.formadores.length

                            worksheet.addImage(imageId2, {
                                tl: { col: 26, row: ((difMeses * 13) + 14 + 15 + lengthFinal) },
                                ext: { width: desiredWidth, height: scaledHeight },
                            });

                            // Resolve the promise
                            resolve();
                        };

                        // Set the source of the image to your image file
                        img.src = imageUrlEntFinanciadora;
                    });
                }
                await loadImage2AndAddToWorksheet();
            }

        }
        // Celulas de texto - Cabeçalho
        const A1 = worksheet.getCell('A1');
        const A2 = worksheet.getCell('A2');
        const A3 = worksheet.getCell('A3');
        const A4 = worksheet.getCell('A4');
        const A5 = worksheet.getCell('A5');
        const A6 = worksheet.getCell('A6');
        const A7 = worksheet.getCell('A7');
        const A8 = worksheet.getCell('A8');
        const A10 = worksheet.getCell('A10');
        const A11 = worksheet.getCell('A11');


        const D2 = worksheet.getCell('D2');
        const D3 = worksheet.getCell('D3');
        const D4 = worksheet.getCell('D4');
        const D5 = worksheet.getCell('D5');
        const D6 = worksheet.getCell('D6');
        const D11 = worksheet.getCell('D11');

        const E7 = worksheet.getCell('E7');
        const E8 = worksheet.getCell('E8');
        const E10 = worksheet.getCell('E10');

        const L7 = worksheet.getCell('L7');
        const L8 = worksheet.getCell('L8');
        const L10 = worksheet.getCell('L10');

        const V3 = worksheet.getCell('V3');
        const V4 = worksheet.getCell('V4');

        const S7 = worksheet.getCell('S7');
        const S8 = worksheet.getCell('S8');
        const S10 = worksheet.getCell('S10');

        const Z7 = worksheet.getCell('Z7');
        const Z8 = worksheet.getCell('Z8');
        const Z10 = worksheet.getCell('Z10');


        const AD3 = worksheet.getCell('AD3');
        const AD4 = worksheet.getCell('AD4');

        const AG7 = worksheet.getCell('AG7');
        const AG8 = worksheet.getCell('AG8');
        const AG10 = worksheet.getCell('AG10');

        // Valor das Celulas
        A1.value = 'Cronograma';
        A2.value = 'Entidade Formadora:'; D2.value = userData.percurso[0].entFormadora[0].name
        A3.value = 'Tipologia de Operação:'; D3.value = userData.percurso[0].operacao[0].nomeTipologia; V3.value = "Modalidade de Formação:"; AD3.value = userData.percurso[0].modalidade
        A4.value = 'Operação:'; D4.value = userData.percurso[0].operacao[0].codigoOperacao; V4.value = "Código e Área de Formação:"; AD4.value = userData.percurso[0].areaCodigoFormacao
        A5.value = 'Curso/Percurso:'; D5.value = userData.percurso[0].name;
        A6.value = 'Curso/Unidade:'; D6.value = userData.codeUfcd + " - " + userData.name;
        A7.value = 'Código Administrativo (em SIGO):'; E7.value = 'Código Interno:'; L7.value = 'Nível:'; S7.value = 'Carga Horária:'; Z7.value = 'N.º Curso (em SIIFSE):'; AG7.value = 'N.º Ação (em SIIFSE):';
        A8.value = userData.percurso[0].codeSIGO; E8.value = userData.percurso[0].codeInterno; L8.value = userData.percurso[0].nivel; S8.value = userData.cargaHoraria; Z8.value = userData.percurso[0].cursoN; AG8.value = userData.percurso[0].acaoN;
        A10.value = "Data de Início:"; E10.value = convertDateFormat2(userData.dateBegin); L10.value = "Data de Fim:"; S10.value = convertDateFormat2(userData.dateEnd); Z10.value = 'Horário:'; AG10.value = userData.percurso[0].horario;

        A11.value = 'Local de Formação:'; D11.value = userData.percurso[0].locality

        // Centrar
        A1.alignment = alignment
        A2.alignment = alignment
        A3.alignment = alignment
        A4.alignment = alignment
        A5.alignment = alignment
        A6.alignment = alignment
        A7.alignment = alignment
        A8.alignment = alignment
        A10.alignment = alignment
        A11.alignment = alignment

        E7.alignment = alignment
        E8.alignment = alignment
        E10.alignment = alignment

        L7.alignment = alignment
        L8.alignment = alignment
        L10.alignment = alignment

        V3.alignment = alignment
        V4.alignment = alignment

        S7.alignment = alignment
        S8.alignment = alignment
        S10.alignment = alignment

        Z7.alignment = alignment
        Z8.alignment = alignment
        Z10.alignment = alignment

        AG7.alignment = alignment
        AG8.alignment = alignment
        AG10.alignment = alignment

        // Negrito
        A1.font = title
        A2.font = bold
        A3.font = bold
        A4.font = bold
        A5.font = bold
        A6.font = bold
        A7.font = bold
        A10.font = bold
        A11.font = bold

        E7.font = bold

        L7.font = bold
        L10.font = bold

        V3.font = bold
        V4.font = bold

        S7.font = bold

        Z7.font = bold
        Z10.font = bold

        AG7.font = bold

        // Collumn Width
        worksheet.getColumn('A').width = 12
        worksheet.getColumn('B').width = 8
        for (let columnNumber = 3; columnNumber <= 39; columnNumber++) {
            worksheet.getColumn(columnNumber).width = 6.5;
        }
        // Row height
        worksheet.getRow('1').height = 50

        // Estutura - Cabeçalho 
        worksheet.mergeCells('A1:AM1');
        worksheet.mergeCells('A2:C2'); worksheet.mergeCells('D2:AM2');
        worksheet.mergeCells('A3:C3'); worksheet.mergeCells('D3:U3'); worksheet.mergeCells('V3:AC3'); worksheet.mergeCells('AD3:AM3');
        worksheet.mergeCells('A4:C4'); worksheet.mergeCells('D4:U4'); worksheet.mergeCells('V4:AC4'); worksheet.mergeCells('AD4:AM4');
        worksheet.mergeCells('A5:C5'); worksheet.mergeCells('D5:AM5');
        worksheet.mergeCells('A6:C6'); worksheet.mergeCells('D6:AM6');
        worksheet.mergeCells('A7:D7'); worksheet.mergeCells('E7:K7'); worksheet.mergeCells('L7:R7'); worksheet.mergeCells('S7:Y7'); worksheet.mergeCells('Z7:AF7'); worksheet.mergeCells('AG7:AM7');
        worksheet.mergeCells('A8:D9'); worksheet.mergeCells('E8:K9'); worksheet.mergeCells('L8:R9'); worksheet.mergeCells('S8:Y9'); worksheet.mergeCells('Z8:AF9'); worksheet.mergeCells('AG8:AM9');
        worksheet.mergeCells('A10:D10'); worksheet.mergeCells('E10:K10'); worksheet.mergeCells('L10:R10'); worksheet.mergeCells('S10:Y10'); worksheet.mergeCells('Z10:AF10'); worksheet.mergeCells('AG10:AM10');
        worksheet.mergeCells('A11:C11'); worksheet.mergeCells('D11:AM11');

        // Estilização - Cabeçalho
        const startCell = worksheet.getCell('A1');
        const endCell = worksheet.getCell('AD6');

        worksheet.eachRow({ from: startCell.row, to: endCell.row }, (row) => {
            row.eachCell((cell) => {
                cell.border = borderThin
            });
        });
        const cells = ['efefbd', 'A2', 'A3', 'A4', 'A5', 'A6', 'A7', 'A10', 'A11', 'E7', 'L7', 'S7', 'Z7', 'AG7', 'V3', 'V4', 'L10', 'Z10', 'A13', 'B13', 'C13', 'D13', 'E13', 'F13', 'G13', 'H13', 'I13', 'J13', 'K13', 'L13', 'M13', 'N13', 'O13', 'P13', 'Q13', 'R13', 'S13', 'T13', 'U13', 'V13', 'W13', 'X13', 'Y13', 'Z13', 'AA13', 'AB13', 'AC13', 'AD13', 'AE13', 'AF13', 'AG13', 'AH13', 'AI13', 'AJ13', 'AK13', 'AL13', 'AM13'];

        const cells2 = ['b2b2b2', 'A14', 'B14', 'C14', 'D14', 'E14', 'F14', 'G14', 'H14', 'I14', 'J14', 'K14', 'L14', 'M14', 'N14', 'O14', 'P14', 'Q14', 'R14', 'S14', 'T14', 'U14', 'V14', 'W14', 'X14', 'Y14', 'Z14', 'AA14', 'AB14', 'AC14', 'AD14', 'AE14', 'AF14', 'AG14', 'AH14', 'AI14', 'AJ14', 'AK14', 'AL14', 'AM14'];
        //Palete de Cores
        applyFillToCell(worksheet, cells);
        applyFillToCell(worksheet, cells2);

        let j = 14
        let ind = 0
        let cont = 0
        let mes = 0
        for (let i = 0; i <= difMeses; i++) {
            ind++
            // Posição correta
            // variaveis
            const Aj = worksheet.getCell("A" + (j + 2));
            const Bj1 = worksheet.getCell('B' + (j + 2));
            const Bj2 = worksheet.getCell('B' + (j + 3));
            const Bj3 = worksheet.getCell('B' + (j + 4));
            const Bj4 = worksheet.getCell('B' + (j + 5));
            const Bj5 = worksheet.getCell('B' + (j + 6));
            const Bj6 = worksheet.getCell('B' + (j + 7));
            const Bj7 = worksheet.getCell('B' + (j + 8));
            const Bj8 = worksheet.getCell('B' + (j + 9));
            const Bj9 = worksheet.getCell('B' + (j + 10));
            const Bj10 = worksheet.getCell('B' + (j + 11));

            // valores das variaveis

            Bj1.value = userData.percurso[0].horario === "Laboral" ? "08h/09h" : "08h/15h"
            Bj2.value = userData.percurso[0].horario === "Laboral" ? "09h/10h" : "15h/16h"
            Bj3.value = userData.percurso[0].horario === "Laboral" ? "10h/11h" : "16h/17h"
            Bj4.value = userData.percurso[0].horario === "Laboral" ? "11h/12h" : "17h/18h"
            Bj5.value = userData.percurso[0].horario === "Laboral" ? "12h/13h" : "18h/19h"
            Bj6.value = userData.percurso[0].horario === "Laboral" ? "13h/14h" : "19h/20h"
            Bj7.value = userData.percurso[0].horario === "Laboral" ? "14h/15h" : "20h/21h"
            Bj8.value = userData.percurso[0].horario === "Laboral" ? "15h/16h" : "21h/22h"
            Bj9.value = userData.percurso[0].horario === "Laboral" ? "16h/17h" : "22h/23h"
            Bj10.value = userData.percurso[0].horario === "Laboral" ? "17h/18h" : "23h/24h"

            // Border das variáveis

            Bj1.border = borderThin
            Bj2.border = borderThin
            Bj3.border = borderThin
            Bj4.border = borderThin
            Bj5.border = borderThin
            Bj6.border = borderThin
            Bj7.border = borderThin
            Bj8.border = borderThin
            Bj9.border = borderThin
            Bj10.border = borderThin
            Aj.border = borderThin

            worksheet.mergeCells(`A${j + 2}:A${j + 11}`);

            //Cria o array com dias da semana
            function fillDaysOfWeek(worksheet) {
                // Generate an array with the days of the week 
                for (let i = 0; i <= 36; i++) {
                    let cell
                    let nRow = 67 + i;
                    if (nRow > 90) {
                        let row = String.fromCharCode(nRow - 26);
                        cell = worksheet.getCell(`A${row}${j}`);
                    } else {
                        let row = String.fromCharCode(nRow);
                        cell = worksheet.getCell(`${row}${j}`);
                    }
                    const dayOfWeekIndex = i % 7;
                    cell.value = daysOfWeek[dayOfWeekIndex];
                }
            }
            fillDaysOfWeek(worksheet)


            //Preenche dias do mes
            function fillDaysOfMonth(worksheet, diasSemana, j, ano, mes) {
                let control = 0;
                switch (diasSemana) {
                    case daysOfWeek[0]:
                        control = 0;
                        break;
                    case daysOfWeek[1]:
                        control = 1;
                        break;
                    case daysOfWeek[2]:
                        control = 2;
                        break;
                    case daysOfWeek[3]:
                        control = 3;
                        break;
                    case daysOfWeek[4]:
                        control = 4;
                        break;
                    case daysOfWeek[5]:
                        control = 5;
                        break;
                    case daysOfWeek[6]:
                        control = 6;
                        break;
                    default:
                        control = 0;
                        break;
                }
                if (mes === 1 || mes === 3 || mes === 5 || mes === 7 || mes === 8 || mes === 10 || mes === 12) {
                    const daysOfMonth = Array.from({ length: 31 }, (_, i) => i + 1);
                    for (let i = 0; i < daysOfMonth.length; i++) {
                        let cell
                        let nRow = 67 + i + control;
                        if (nRow > 90) {
                            let row = String.fromCharCode(nRow - 26);
                            cell = worksheet.getCell(`A${row}${j + 1}`);
                        } else {
                            let row = String.fromCharCode(nRow);
                            cell = worksheet.getCell(`${row}${j + 1}`);
                        }

                        cell.value = daysOfMonth[i];
                    }
                }
                else if (mes === 2 && isLeapYear(ano)) {
                    const daysOfMonth = Array.from({ length: 29 }, (_, i) => i + 1);
                    for (let i = 0; i < daysOfMonth.length; i++) {
                        let cell
                        let nRow = 67 + i + control;
                        if (nRow > 90) {
                            let row = String.fromCharCode(nRow - 26);
                            cell = worksheet.getCell(`A${row}${j + 1}`);
                        } else {
                            let row = String.fromCharCode(nRow);
                            cell = worksheet.getCell(`${row}${j + 1}`);
                        }

                        cell.value = daysOfMonth[i];
                    }
                }
                else if (mes === 2 && !isLeapYear(ano)) {
                    const daysOfMonth = Array.from({ length: 28 }, (_, i) => i + 1);
                    for (let i = 0; i < daysOfMonth.length; i++) {
                        let cell
                        let nRow = 67 + i + control;
                        if (nRow > 90) {
                            let row = String.fromCharCode(nRow - 26);
                            cell = worksheet.getCell(`A${row}${j + 1}`);
                        } else {
                            let row = String.fromCharCode(nRow);
                            cell = worksheet.getCell(`${row}${j + 1}`);
                        }

                        cell.value = daysOfMonth[i];
                    }
                }
                else {
                    const daysOfMonth = Array.from({ length: 30 }, (_, i) => i + 1);
                    for (let i = 0; i < daysOfMonth.length; i++) {
                        let cell
                        let nRow = 67 + i + control;
                        if (nRow > 90) {
                            let row = String.fromCharCode(nRow - 26);
                            cell = worksheet.getCell(`A${row}${j + 1}`);
                        } else {
                            let row = String.fromCharCode(nRow);
                            cell = worksheet.getCell(`${row}${j + 1}`);
                        }

                        cell.value = daysOfMonth[i];
                    }
                }
                for (let col = 1; col <= 39; col++) {
                    const cell = worksheet.getCell(j, col);
                    cell.border = borderThin
                    // Set the fill color of the cell
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FF' + "#efecbd".substring(1) }
                    };
                    cell.font = bold
                }

                for (let col = 1; col <= 39; col++) {
                    const cell = worksheet.getCell(j - 1, col);
                    cell.border = borderThin
                    // Set the fill color of the cell
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FF' + "#efecbd".substring(1) }
                    };
                }

                for (let col = 1; col <= 39; col++) {
                    const cell = worksheet.getCell(j + 1, col);
                    // Set the fill color of the cell
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FF' + "#B2B2B2".substring(1) }
                    }
                }
                for (let col = 3; col <= 39; col++) {
                    for (let row = j + 2; row <= j + 11; row++) {
                        const cell = worksheet.getCell(row, col);

                        // Set the fill color of the cell
                        cell.border = borderThin
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'FF' + "#B2B2B2".substring(1) }
                        };
                    }
                }
                setMultipleRowHeight(worksheet, j, j + 12, 12);

                /*  saveModulos.forEach(modulo => {
 
                     const diff = modulo.monthStart - (mesInicio + 1)
                     let yearDiff = DateDifference2(modulo.yearStart, modulo.yearEnd)
                     for (let start = modulo.monthStart; start <= ((modulo.monthEnd) + (12 * (yearDiff))); start++) {
                         let year = anoInicio + yearDiff
                         const days = DaysInMonth(year, start)
                         const day1Mes = getFirstDayOfMonth(year, start)
                         const startCol = (modulo.dayStart + 1 + (day1Mes))
                         if (modulo.monthStart === ((modulo.monthEnd) + (12 * (yearDiff)))) {
                             if (start === modulo.monthStart) {
                                 for (let col = startCol; col <= (day1Mes + modulo.dayEnd + 1); col++) {
                                     for (let row = (diff * 13) + 14 + 2; row <= (diff * 13) + 14 + 11; row++) {
                                         const cell = worksheet.getCell(row, col);
                                         // Set the fill color of the cell
                                         cell.fill = {
                                             type: 'pattern',
                                             pattern: 'solid',
                                             fgColor: { argb: modulo.color.substring(1) }
                                         };
                                     }
                                 }
                             }
                         }
                         else {
                             if (start === modulo.monthStart) {
                                 for (let col = startCol; col <= (days + day1Mes + 1); col++) {
                                     for (let row = (diff * 13) + 14 + 2; row <= (diff * 13) + 14 + 11; row++) {
                                         const cell = worksheet.getCell(row, col);
                                         // Set the fill color of the cell
                                         cell.fill = {
                                             type: 'pattern',
                                             pattern: 'solid',
                                             fgColor: { argb: modulo.color.substring(1) }
                                         };
                                     }
                                 }
                             }
                             else if (start === ((modulo.monthEnd) + (12 * (yearDiff)))) {
                                 for (let col = (day1Mes + 2); col <= (modulo.dayEnd + day1Mes + 1); col++) {
                                     for (let row = 14 + ((diff + (((modulo.monthEnd) + (12 * (yearDiff))) - modulo.monthStart)) * 13) + 2; row <= ((diff + (((modulo.monthEnd) + (12 * (yearDiff))) - modulo.monthStart)) * 13) + 14 + 11; row++) {
                                         const cell = worksheet.getCell(row, col);
                                         // Set the fill color of the cell
                                         cell.fill = {
                                             type: 'pattern',
                                             pattern: 'solid',
                                             fgColor: { argb: modulo.color.substring(1) }
                                         };
                                     }
                                 }
                             }
                             else {
                                 for (let l = modulo.monthStart + 1; l <= ((modulo.monthEnd) + (12 * (yearDiff))); l++) {
 
                                     if (l != ((modulo.monthEnd) + (12 * (yearDiff)))) {
                                         for (let col = (day1Mes + 2); col <= (days + day1Mes + 1); col++) {
                                             for (let row = ((diff + (((modulo.monthEnd) + (12 * (yearDiff))) - start)) * 13) + 14 + 2; row <= ((diff + (((modulo.monthEnd) + (12 * (yearDiff))) - start)) * 13) + 14 + 11; row++) {
                                                 const cell = worksheet.getCell(row, col);
                                                 // Set the fill color of the cell
                                                 cell.fill = {
                                                     type: 'pattern',
                                                     pattern: 'solid',
                                                     fgColor: { argb: modulo.color.substring(1) }
                                                 };
                                             }
                                         }
                                     }
                                 }
                             }
                         }
                     }
                 });
  */
            }
            //Obtem o ultimo dia do mes
            function getEndingDay(month, year) {
                // Validate month and year inputs
                if (month < 1 || month > 12) {
                    return 'Invalid month';
                }

                // Create a new Date object with the provided month and year
                const date = new Date(year, month - 1, 1);

                // Get the day of the week for the first day of the month (0-6, where 0 represents Sunday)
                const endingDay = date.getDay();

                // Create an array of days of the week
                const daysOfWeek = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];
                // Return the starting day of the month
                return daysOfWeek[endingDay];
            }
            if (i != 0 && (i + mesInicio) % 12 === 0) {
                cont++
            }
            if ((i + 1) + mesInicio > 12) {

                mes = (i + 1) + mesInicio - (12 * cont)
            }
            else mes = (i + 1) + mesInicio
            const endingDay = getEndingDay(mes, (anoInicio + cont));
            fillDaysOfMonth(worksheet, endingDay, j, anoInicio + cont, mes);
            Aj.value = (getMonthName(mes) + "\n" + (anoInicio + cont))
            Aj.alignment = alignment
            j = j + 13
        }
        /*  let indexSaveTestes = 0
         saveTestes.forEach(teste => {
             indexSaveTestes++
             let yearDiff = DateDifference2(anoInicio, teste.yearEnd)
             let monthDiff = DateDifference2(teste.yearStart, teste.yearEnd)
             const diff = ((teste.monthEnd) + (12 * (yearDiff))) - (mesInicio + 1)
             for (let start = teste.monthStart; start <= ((teste.monthEnd) + (12 * (yearDiff))); start++) {
                 let year = anoInicio + yearDiff
                 const day1Mes = getFirstDayOfMonth(year, start)
                 const startCol = (teste.dayStart + 1 + (day1Mes))
                 const endCol = (teste.dayEnd + 1 + (day1Mes))
                 const startRow = (teste.endHours)
                 if (teste.monthStart === ((teste.monthEnd) + (12 * (monthDiff)))) {
                     if (start === teste.monthStart) {
                         for (let row = (diff * 13) + 14 + 2 + startRow; row <= (diff * 13) + 14 + 11; row++) {
                             const cell = worksheet.getCell(row, startCol);
                             // Set the fill color of the cell
                             cell.fill = {
                                 type: 'pattern',
                                 pattern: 'solid',
                                 fgColor: { argb: teste.color.substring(1) }
                             };
                             cell.value = `AS${indexSaveTestes}`
                         }
                         for (let row = (diff * 13) + 14 + 2; row <= (diff * 13) + 14 + 11; row++) {
                             const cell = worksheet.getCell(row, (startCol + 1));
                             
                             // Set the fill color of the cell
                             cell.fill = {
                                 type: 'pattern',
                                 pattern: 'solid',
                                 fgColor: { argb: teste.color.substring(1) }
                             };
                             cell.value = `AS${indexSaveTestes}`
                         }
                         for (let row = (diff * 13) + 14 + 2; row <= (diff * 13) + 14 + 1 + startRow; row++) {
                             const cell = worksheet.getCell(row, endCol);
                             
                             // Set the fill color of the cell
                             cell.fill = {
                                 type: 'pattern',
                                 pattern: 'solid',
                                 fgColor: { argb: teste.color.substring(1) }
                             };
                             cell.value = `AS${indexSaveTestes}`
                         }
                     }
                 }
                 else {
                     if (start === teste.monthStart) {
                         for (let row = (diff * 13) + 14 + 2 + startRow; row <= (diff * 13) + 14 + 11; row++) {
                             const cell = worksheet.getCell(row, startCol);
                             // Set the fill color of the cell
                             cell.fill = {
                                 type: 'pattern',
                                 pattern: 'solid',
                                 fgColor: { argb: teste.color.substring(1) }
                             };
                             cell.value = `AS${indexSaveTestes}`
                         }
                         if (startCol > endCol) {
                             for (let row = (diff * 13) + 14 + 2; row <= (diff * 13) + 14 + 11; row++) {
                                 const cell = worksheet.getCell(row, (startCol + 1));
                                 // Set the fill color of the cell
                                 cell.fill = {
                                     type: 'pattern',
                                     pattern: 'solid',
                                     fgColor: { argb: teste.color.substring(1) }
                                 };
                                 cell.value = `AS${indexSaveTestes}`
                             }
                         }
                     }
                     else if (start === ((teste.monthEnd) + (12 * (monthDiff)))) {
                         if (startCol < endCol) {
                             for (let row = (diff * 13) + 14 + 2; row <= (diff * 13) + 14 + 11; row++) {
                                 const cell = worksheet.getCell(row, (startCol + 1));
                                 // Set the fill color of the cell
                                 cell.fill = {
                                     type: 'pattern',
                                     pattern: 'solid',
                                     fgColor: { argb: teste.color.substring(1) }
                                 };
                                 cell.value = `AS${indexSaveTestes}`
                             }
                         }
                         for (let row = (diff * 13) + 14 + 2; row <= (diff * 13) + 14 + 1 + startRow; row++) {
                             const cell = worksheet.getCell(row, endCol);
                            
                             // Set the fill color of the cell
                             cell.fill = {
                                 type: 'pattern',
                                 pattern: 'solid',
                                 fgColor: { argb: teste.color.substring(1) }
                             };
                             cell.value = `AS${indexSaveTestes}`
                         }
                     }
                 }
             }
         }); */


        let index = 0
        let index2 = 0

        saveVcs.forEach(vc => {
            const diff = DateDifference(inicio, vc.end)
            let year = vc.year
            const day1Mes = getFirstDayOfMonth(year, vc.monthStart)
            const startCol = (vc.dayStart + 1 + (day1Mes))
            const cell = worksheet.getCell((diff * 13) + 15 + vc.hourStart, startCol);
            if (cell.value) {
                console.log(cell.value)
                let tipoLetra = ""
                let tipoLegenda = ""
                if (vc.tipo === "Presencial") {
                    tipoLetra = "P"
                    tipoLegenda = "Aula Presencial"
                }
                else {
                    tipoLetra = "VC"
                    tipoLegenda = "Videoconferência"
                }
                if (vc.extra === true) {
                    cell.value = `${cell.value}, ${tipoLetra} - Extra ${vc.outHours ? vc.realHour + " - " + vc.realHourEnd : ""}`
                }
                else cell.value = `${cell.value}, ${tipoLetra}${vc.sessao} ${vc.outHours ? vc.realHour + " - " + vc.realHourEnd : ""}`
            }
            else {
                worksheet.mergeCells(`${getColumnLetterFromIndex(startCol)}${(diff * 13) + 15 + vc.hourStart}:${getColumnLetterFromIndex(startCol)}${(diff * 13) + 15 + vc.hoursEnd}`);
                let tipoLetra = ""
                let tipoLegenda = ""
                if (vc.tipo === "Presencial") {
                    tipoLetra = "P"
                    tipoLegenda = "Aula Presencial"
                }
                else {
                    tipoLetra = "VC"
                    tipoLegenda = "Videoconferência"
                }
                if (vc.extra === true) {

                    cell.value = `${tipoLetra} - Extra ${vc.outHours ? vc.realHour + " - " + vc.realHourEnd : ""}`
                }
                else cell.value = `${tipoLetra}${vc.sessao} ${vc.outHours ? vc.realHour + " - " + vc.realHourEnd : ""}`
            }
            // Set the fill color of the cell
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: "#ff91d2ff".substring(1) }
            };
            cell.font = {
                size: 6
            };
            cell.alignment = {
                horizontal: 'center',
                vertical: 'middle',
                wrapText: true,
            };

            index2++
        });
        //Legenda 

        //Módulos
        /*  saveModulos.forEach(element => {
             const diff = difMeses
             // Modulos
             let legenda = worksheet.getCell('B' + ((diff * 13) + 13 + 15 + index));
             legenda.fill = {
                 type: 'pattern',
                 pattern: 'solid',
                 fgColor: { argb: element.color.substring(1) }
             };
             legenda.border = borderThin
             legenda.value = "Mod" + (index + 1)
 
             let legenda1 = worksheet.getCell('C' + ((diff * 13) + 13 + 15 + index));
             worksheet.mergeCells(`C${(diff * 13) + 13 + 15 + index}: L${(diff * 13) + 13 + 15 + index}`);
             legenda1.border = borderThin
             legenda1.value = `Módulo ${index + 1} - ` + element.title
 
             let legenda3 = worksheet.getCell('Z' + ((diff * 13) + 13 + 15 + index))
             legenda3.fill = {
                 type: 'pattern',
                 pattern: 'solid',
                 fgColor: { argb: element.color.substring(1) }
             };
             legenda3.border = borderThin
             legenda3.value = "Mod" + (index + 1)
 
             
 
             index = index + 1
         });
         let indexTestes = 0
         saveTestes.forEach(teste => {
             const diff = difMeses
             // Testes
             let legenda = worksheet.getCell('B' + ((diff * 13) + 13 + 15 + indexTestes + saveModulos.length));
             legenda.fill = {
                 type: 'pattern',
                 pattern: 'solid',
                 fgColor: { argb: teste.color.substring(1) }
             };
             legenda.border = borderThin
             legenda.value = "AS" + (indexTestes + 1)
 
             let legenda1 = worksheet.getCell('C' + ((diff * 13) + 13 + 15 + indexTestes + saveModulos.length));
             worksheet.mergeCells(`C${(diff * 13) + 13 + 15 + indexTestes + saveModulos.length}: L${(diff * 13) + 13 + 15 + indexTestes + saveModulos.length}`);
             legenda1.border = borderThin
             legenda1.value = `Ação Sumativa ${indexTestes + 1} `
 
 
             indexTestes++
         }); */

        let index1 = 0
        saveVcs.forEach(vc => {
            const diff = difMeses
            let tipoLetra = ""
            let tipoLegenda = ""
            if (vc.tipo === "Presencial") {
                tipoLetra = "P"
                tipoLegenda = "Aula Presencial"
            }
            else {
                tipoLetra = "VC"
                tipoLegenda = "Videoconferência"
            }
            let legendaVC1 = worksheet.getCell('O' + ((diff * 13) + 15 + 13 + index1));
            worksheet.mergeCells(`O${(diff * 13) + 15 + 13 + index1}: X${(diff * 13) + 15 + 13 + index1}`);
            legendaVC1.value = `${vc.title}`
            legendaVC1.border = borderThin
            const legendaVC = worksheet.getCell('N' + ((diff * 13) + 15 + 13 + index1));
            legendaVC.value = `${tipoLetra}${vc.sessao}`
            legendaVC.border = borderThin
            legendaVC.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: "#ff91d2ff".substring(1) }
            };
            index1++
        });
        let legendaFormador = worksheet.getCell('AA' + ((difMeses * 13) + 13 + 15));
        worksheet.mergeCells(`AA${(difMeses * 13) + 13 + 15}: AJ${(difMeses * 13) + 13 + 15}`);
        legendaFormador.border = borderThin
        legendaFormador.font = bold
        legendaFormador.alignment = alignment
        legendaFormador.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: "#efecbd".substring(1) }
        };
        legendaFormador.value = "Formadores:"

        let index3 = 0
        userData.formadores.forEach(element => {
            let legendaFormadorName = worksheet.getCell('AA' + ((difMeses * 13) + 13 + 16 + index3));
            worksheet.mergeCells(`AA${(difMeses * 13) + 13 + 16 + index3}: AJ${(difMeses * 13) + 13 + 16 + index3}`);
            legendaFormadorName.border = borderThin
            legendaFormadorName.value = element.fullName
            index3++
        });


        // VC0

        // Generate the Excel file
        const buffer = await workbook.xlsx.writeBuffer();

        // Convert the buffer to a Blob
        const blob = new Blob([buffer], { type: 'application/octet-stream' });
        const formData = new FormData();
        formData.append('file', blob, `cronogramaDisciplina${userData.name}.xlsx`); // Explicitly set the filename with .xlsx extension
        // Save the Blob to the user's computer
        if (!autorization) FileSaver.saveAs(blob, `Cronograma.xlsx`);
        fetch(`${process.env.REACT_APP_API_URL}/cronograma/Disciplina/${userData._id}`, {
            method: "POST",
            credentials: "same-origin",
            body: formData
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    // Handle the response from the server
                },
                (error) => {
                    // Handle any errors
                }
            );

    } catch (error) {
        if (error.message === "Cannot merge already merged cells") {
            // Display a custom notification to the user
            toastError("Existem Aulas em Conflito.");
        } else {
            // Handle other errors or log them
            console.error(error);
        }
    }
};


export default excelGenerator;