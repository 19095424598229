import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import Footer from "../../../../components/Footer";
import { BsFillPencilFill } from "react-icons/bs";
import toastSuccess from "../../../../components/Toast/toastSuccess";
import excelGeneratorClone from "../../../../components/Excel/cronogramaGeralClone";
import NavbarAdmin2 from "../../../../components/Navbars/NavbarAdmin2/NavbarAdmin2";
import { AiFillCopy, AiOutlineCalendar } from "react-icons/ai";
import { BiCalendarEdit } from "react-icons/bi";
import { BsCalendar3 } from "react-icons/bs";
import { LuFileCog, LuFiles, LuFileText, LuBookOpen, LuBook } from "react-icons/lu";
import { Books, Student, ChalkboardTeacher } from "@phosphor-icons/react";
import copyText from "../../../../components/FormChecks/copyText/copyText";
import ExcelUtilizadoresExternos from "../../../../components/Excel/excelUtilizadoresExternos/excelUtilizadoresExternos";
import AlterarFormato from "../../../../components/FormChecks/formatDates/foramatDates";
import options from "../../../../components/FormChecks/areaCodigoFormacao/areaCodigoFormacao";
import toastError from "../../../../components/Toast/toastError";
import ExcelFormandosSimples from "../../../../components/Excel/excelFormandos/excelFormandosSimple";
import AlterarFormatoDataTempo from "../../../../components/FormChecks/formatDates/formatDateTime";
function Candidaturas() {
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [fechoCoordenador, setfechoCoordenador] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [coordenadorList, setCoordenadorList] = useState(0)
    const [operacoesList, setOperacoesList] = useState(0)
    const [categoriasList, setCategoriasList] = useState(0)
    const [entFormadorasList, setEntFormadorasList] = useState(0)
    const [entFinanciadorasList, setEntFinanciadorasList] = useState(0)
    const [moodlesList, setMoodlesList] = useState(0)
    const [coordenador, setCoordenador] = useState([])
    const [alterarGeral, setAlterarGeral] = useState(false)
    const [alterar, setAlterar] = useState(false)
    const [alterar2, setAlterar2] = useState(false)
    const [alterar3, setAlterar3] = useState(false)
    const [alterarDataInicio, setAlterarDataInicio] = useState(false)
    const [alterarDataFinal, setAlterarDataFinal] = useState(false)
    const [alterarDataFinalCandidaturas, setAlterarDataFinalCandidaturas] = useState(false)
    const [dateBegin, setDateBegin] = useState("")
    const [dateEnd, setDateEnd] = useState("")
    const [dateEndCandidacies, setDateEndCandidacies] = useState("")
    const [name, setName] = useState(null);
    const params = useParams();

    useEffect(() => {
        getPercurso()
        if (coordenadorList === 0) getCoordenadores()
        if (operacoesList === 0) getOperacoes()
        if (entFormadorasList === 0) getEntFormadoras()
        if (entFinanciadorasList === 0) getEntFinanciadoras()
        if (moodlesList === 0) getMoodles()
        if (categoriasList === 0 && data) getCategorias()
    }, [loading, alterar]);

    //Download do ficheiro
    function downloadFile(url) {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('target', '_blank'); // Opens the link in a new tab/window
        link.setAttribute('download', ''); // Optional, sets the download attribute to trigger download in some browsers
        link.click();
    }


    //Separa nome e aplido
    function nomeApelido(fullname) {
        let names = fullname.split(" ");
        if (names.length === 1) {
            setName(names[0])
        } else {
            let firstName = names[0];
            let lastName = names.pop();
            setName(firstName + " " + lastName)
        }
    }

    //Obtem percurso
    function getPercurso() {
        fetch(`${process.env.REACT_APP_API_URL}/percurso/${params.id_curso}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "authorization": localStorage.getItem('admin2')
            },
            method: 'GET',
        })
            .then(res => res.json())
            .then((result) => {
                let cont = 0
                result.ufcds.forEach(ufcds => {
                    if (ufcds.fechoCoordenador) {
                        cont++
                    }
                });
                if (cont === result.ufcds.length) {
                    setfechoCoordenador(true)
                }
                setData(result)
                setDateBegin(result.dateBegin)
                setDateEnd(result.dateEnd)
                if (result.Coordenador[0]) nomeApelido(result.Coordenador[0].fullName)
                setLoading(false)
            })
    }

    //Obtem Categorias
    function getCategorias() {
        fetch(`${process.env.REACT_APP_API_URL}/Categoria`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "authorization": localStorage.getItem('admin2')
            },
            method: 'GET',
        })
            .then(res => res.json())
            .then((result) => {
                if (result && Array.isArray(result)) {
                    const filteredCategorias = result.filter(
                        (item) => item.entFormadora?.[0] === data.entFormadora?.[0]?._id
                    );
                    setCategoriasList(filteredCategorias);
                } else {
                    console.error("Unexpected data format:", result);
                }
            })
    }

    function alteracoesGeral() {
        console.log()
        fetch(`${process.env.REACT_APP_API_URL}/percurso/${params.id_curso}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem('admin2')
            },
            body: JSON.stringify({
                name: document.getElementById("name").value ? document.getElementById("name").value : data.name,
                operacao: document.getElementById("operacao").value ? document.getElementById("operacao").value : data.operacao[0]._id,
                codeSIGO: document.getElementById("SIGO").value ? document.getElementById("SIGO").value : data.codeSIGO,
                areaCodigoFormacao: document.getElementById("AreaCodigoFormacao").value ? document.getElementById("AreaCodigoFormacao").value : data.areaCodigoFormacao,
                modalidade: document.getElementById("modalidade").value ? document.getElementById("modalidade").value : data.modalidade,
                codeInterno: document.getElementById("codeInterno").value ? document.getElementById("codeInterno").value : data.codeInterno,
                type: document.getElementById("type") && document.getElementById("type").value ? document.getElementById("type").value : data.type,
                entFormadora: document.getElementById("entFormadora").value ? document.getElementById("entFormadora").value : data.entFormadora[0]._id,
                entFinanciadora: document.getElementById("entFinanciadora")?.value && document.getElementById("entFinanciadora").value !== "-1"
                    ? document.getElementById("entFinanciadora").value
                    : document.getElementById("entFinanciadora").value !== "-1" ? data.entFinanciadora[0]?._id || "" : "-1",
                categoria: document.getElementById("categoria").value ? document.getElementById("categoria").value : data.categoria[0] && data.categoria[0]._id,
                moodle: document.getElementById("moodle")?.value || (data.moodle.length > 0 ? data.moodle[0]._id : ""),
                nivel: document.getElementById("nivel").value ? document.getElementById("nivel").value : data.nivel,
                edicoes: document.getElementById("edicoes").value ? document.getElementById("edicoes").value : data.edicoes,
                duration: document.getElementById("duration").value ? document.getElementById("duration").value : data.duration,
                locality: document.getElementById("locality").value ? document.getElementById("locality").value : data.locality,
                address: document.getElementById("address").value ? document.getElementById("address").value : data.address,
                postalCode: document.getElementById("postalCode").value ? document.getElementById("postalCode").value : data.postalCode,
            }),
            method: 'PATCH',
        }).then((result) => {
            result.json().then((resp) => {
                toastSuccess('Alterações feitas com sucesso')
            })
            getPercurso();
        })
    }

    function submeterAlteracoes(id) {
        if (id) {
            alterarCoordenadores(id)
        }
        else alterarCoordenadores(null)
        changeDataInicio()
        changeDataFinal()
        alteracoesGeral()
        setAlterarGeral(false)
    }


    function alterarCoordenadores(id, solo) {
        fetch(`${process.env.REACT_APP_API_URL}/percurso/${params.id_curso}/${coordenador}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem('admin2')
            },
            body: JSON.stringify({
                idCoordenadorAntigo: id,
            }),
            method: 'PATCH',
        }).then((result) => {
            result.json().then((resp) => {
                if(solo)toastSuccess('Dados alterados com sucesso!')
            })
            getPercurso();
            setAlterar(false)
        })
    }

    function alterarSIGO(value) {

        fetch(`${process.env.REACT_APP_API_URL}/percurso/${params.id_curso}/SIGO`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem('admin2')
            },
            body: JSON.stringify({
                codeSIGO: value ? value : data.codeSIGO,
            }),
            method: 'PATCH',
        }).then((result) => {
            result.json().then((resp) => {
                toastSuccess('Código SIGO alterado com sucesso!')
            })
            getPercurso();
            setAlterar2(false)
        })
    }

    function alterarAreaCodigoFormacao(value) {

        fetch(`${process.env.REACT_APP_API_URL}/percurso/${params.id_curso}/AreaCodigoFormacao`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem('admin2')
            },
            body: JSON.stringify({
                areaCodigoFormacao: value ? value : data.areaCodigoFormacao,
            }),
            method: 'PATCH',
        }).then((result) => {
            result.json().then((resp) => {
                toastSuccess('Código SIGO alterado com sucesso!')
            })
            getPercurso();
            setAlterar3(false)
        })
    }

    //Obtem coordenadores
    function getCoordenadores() {
        fetch(`${process.env.REACT_APP_API_URL}/coordenadores/coordinators`, {
            headers: {
                'authorization': localStorage.getItem('admin2')
            },
            method: 'GET',
        })
            .then(response => response.json())
            .then(result => {
                setCoordenadorList(result)
            });
    }
    function getEntFormadoras() {
        fetch(`${process.env.REACT_APP_API_URL}/EntFormadora`, {
            headers: {
                'authorization': localStorage.getItem('admin2')
            },
            method: 'GET',
        })
            .then(response => response.json())
            .then(result => {
                setEntFormadorasList(result)
            });
    }
    function getEntFinanciadoras() {
        fetch(`${process.env.REACT_APP_API_URL}/EntFinanciadora`, {
            headers: {
                'authorization': localStorage.getItem('admin2')
            },
            method: 'GET',
        })
            .then(response => response.json())
            .then(result => {
                setEntFinanciadorasList(result)
            });
    }

    function getMoodles() {
        fetch(`${process.env.REACT_APP_API_URL}/moodle`, {
            headers: {
                'authorization': localStorage.getItem('admin2')
            },
            method: 'GET',
        })
            .then(response => response.json())
            .then(result => {
                setMoodlesList(result)
            });
    }


    function getOperacoes() {
        fetch(`${process.env.REACT_APP_API_URL}/Operacao`, {
            headers: {
                'authorization': localStorage.getItem('admin2')
            },
            method: 'GET',
        })
            .then(response => response.json())
            .then(result => {
                setOperacoesList(result)
            });
    }

    function sendMailSeguros() {
        fetch(`${process.env.REACT_APP_API_URL}/percurso/sendMailSeguros/${params.id_curso}`, {
            headers: {
                'authorization': localStorage.getItem('admin2')
            },
        })
            .then(response => response.json())
            .then(result => {
            });
    }


    function changeDataInicio(solo) {
        fetch(`${process.env.REACT_APP_API_URL}/percurso/${params.id_curso}/dateBegin`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem('admin2')
            },
            body: JSON.stringify({
                dateBegin: dateBegin,
            }),
            method: 'PATCH',
        }).then((result) => {
            result.json().then((resp) => {
                if(solo)toastSuccess('Data alterada com sucesso!')
            })
            getPercurso();
            setAlterarDataInicio(false)
        })
    }

    function changeDataFinal(solo) {
        fetch(`${process.env.REACT_APP_API_URL}/percurso/${params.id_curso}/dateEnd`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem('admin2')
            },
            body: JSON.stringify({
                dateEnd: dateEnd,
            }),
            method: 'PATCH',
        }).then((result) => {
            result.json().then((resp) => {
                if(solo)toastSuccess('Data alterada com sucesso!')
            })
            getPercurso();
            setAlterarDataFinal(false)
        })
    }
    function changeDataFinalCandidaturas(solo) {
        fetch(`${process.env.REACT_APP_API_URL}/percurso/${params.id_curso}/dateEndCandidacies`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem('admin2')
            },
            body: JSON.stringify({
                dateEndCandidacies: dateEndCandidacies,
            }),
            method: 'PATCH',
        }).then((result) => {
            result.json().then((resp) => {
                if(solo)toastSuccess('Data alterada com sucesso!')
            })
            getPercurso();
            setAlterarDataFinalCandidaturas(false)
        })
    }

    function fecharCurso() {
        if (data.fechoCoordenador) {
            fetch(`${process.env.REACT_APP_API_URL}/percurso/admin/fechoCurso/${params.id_curso}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'authorization': localStorage.getItem("admin2")
                },
                method: 'POST',
                body: JSON.stringify({
                    fechoAdmin: true
                })
            })
                .then(res => res.json())
                .then((result) => {
                    toastSuccess('Curso fechado com sucesso')
                    getPercurso()
                })
        }
        else toastError("Existem disciplinas por fechar")
    }

    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";


    return (
        <>
            <main className="min-h-screen">
                <div className="w-full">
                    <NavbarAdmin2 currentPage={"gerirCursos"} />
                </div>
                <Link to={'/MenuSuperAdmin/cursos'} className="back-button-div-noNavbar">
                    <img className='back-button-img' src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} alt="Back" />
                </Link>
                {data && <>
                    <div className="mt-8">
                        <h1 className="mt-3 sm:mx-16 sm:mt-0 title">{data.name}</h1>
                    </div>
                    <div className="flex flex-col mx-4">
                        <div className="grid lg:grid-cols-2 gap-x-32">
                            <div>
                                <div className="flex justify-center"  >
                                    <div className="element">Nome: </div>
                                    <div className="value"> {alterarGeral === false ? <>{data.name}</> : <input className="input-field" defaultValue={data.name} id="name" />}</div>
                                </div>
                                <div className="flex justify-center"  >
                                    <div className="element">Operação: </div>
                                    <div className="value">{alterarGeral === false ? <>{data.operacao[0] && <>{data.operacao[0].codigoOperacao}</>}</> : <select onWheel={(e) => e.target.blur()} className="selector2" type="text" id="operacao" name="operacao" defaultValue={data.operacao[0] ? data.operacao[0]._id : ""}  >
                                        <option value={""} disabled  > Selecionar a Operação</option>
                                        {operacoesList.map((value) => (
                                            <option key={value._id} value={value._id} >{value.codigoOperacao}</option>
                                        ))}

                                    </select>}</div>
                                </div>
                                <div className="flex justify-center" >
                                    <div className="element">Modalidade: </div>
                                    <div className="value">{alterarGeral === false ? <>{data.modalidade && <>{data.modalidade}</>}</> : <select onWheel={(e) => e.target.blur()} className="selector2" type="text" id="modalidade" name="modalidade" defaultValue={data.modalidade}  >
                                        <option value={""} disabled  > Selecionar a Modalidade</option>
                                        <option value="AEF à medida">AEF à medida</option>
                                        <option value="Cursos de Aprendizagem">Cursos de Aprendizagem</option>
                                        <option value="Cursos de Educação e Formação para Jovens">Cursos de Educação e Formação para Jovens</option>
                                        <option value="Cursos de Educação e Formação de Adultos">Cursos de Educação e Formação de Adultos</option>
                                        <option value="Curso de Especialização Tecnológica">Curso de Especialização Tecnológica</option>
                                        <option value="Cursos Profissionais">Cursos Profissionais</option>
                                        <option value="Formação Empresarial Conjunta e Formação Ação">Formação Empresarial Conjunta e Formação Ação</option>
                                        <option value="Formação Modular Certificada">Formação Modular Certificada</option>
                                        <option value="Formação Complementar">Formação Complementar</option>
                                        <option value="Português Língua de Acolhimento">Português Língua de Acolhimento</option>
                                        <option value="Outra Formação Profissional">Outra Formação Profissional</option>
                                    </select>}</div>
                                </div>
                                <div className="flex justify-center" >
                                    <div className="element">Area Código de Formação: </div>
                                    <div className="value">{(alterar3 === false && alterarGeral === false) ? <> {data.areaCodigoFormacao}
                                        <a className="tooltip" onClick={() => setAlterar3(true)} data-tip="Alterar Código SIGO">
                                            <BsFillPencilFill id="icon" className="m-2 cursor-pointer" />
                                        </a> </> : <div id="dropdown"> <input className="selector2" type="text" list="options" id="AreaCodigoFormacao" name="AreaCodigoFormacao" placeholder="Selecione uma Área de Formação" defaultValue={data.areaCodigoFormacao} />
                                        <datalist id="options">
                                            {options.map((option, index) => (
                                                <option key={index} value={option} />
                                            ))}
                                        </datalist>
                                        {alterar3 === true && <div className="flex justify-center mt-1" >
                                            <button className="p-1 button-cancelar" onClick={() => setAlterar3(false)}>Cancelar</button>
                                            <button className="p-1 ml-1 button-confirmar" onClick={() => alterarAreaCodigoFormacao(document.getElementById("AreaCodigoFormacao").value)}>Confirmar</button>
                                        </div>}
                                    </div>
                                    }
                                    </div>
                                </div>
                                <div className="flex justify-center" >
                                    <div className="element">Forma de organização: </div>
                                    <div className="value">{alterarGeral === false ? <>{data.type && <>{data.type}</>}</> : <select onWheel={(e) => e.target.blur()} className="selector2" type="text" id="type" name="type" defaultValue={data.type}  >
                                        <option value={""} disabled  > Selecionar uma Opção</option>
                                        <option value="Presencial">Presencial</option>
                                        <option value="Misto">Misto</option>
                                        <option value="Online">Online</option>
                                    </select>}</div>
                                </div>
                                <div className="flex justify-center" >
                                    <div className="element">Entidade formadora: </div>
                                    <div className="value">{alterarGeral === false ? <>{data?.entFormadora[0] && <>{data.entFormadora[0].name}</>}</> : <select onWheel={(e) => e.target.blur()} className="selector2" type="text" id="entFormadora" name="entFormadora" defaultValue={data.entFormadora[0] && data.entFormadora[0]._id}  >
                                        <option value={""} disabled  > Selecionar a Modalidade</option>
                                        {entFormadorasList.map((value) => (
                                            <option key={value._id} value={value._id} >{value.name}</option>
                                        ))}
                                    </select>}</div>

                                </div>
                                <div className="flex justify-center" >
                                    <div className="element">Número Código Balcão: </div>
                                    <div className="value">{data.cursoN}</div>
                                </div>
                                <div className="flex justify-center" >
                                    <div className="element">Código Interno: </div>
                                    <div className="value">{alterarGeral === false ? <>{data.codeInterno}</> : <input className="input-field" defaultValue={data.codeInterno} id="codeInterno" />}</div>
                                </div>

                                <div className="flex justify-center" >
                                    <div className="element">Código SIGO: </div>
                                    <div className="value">{(alterar2 === false && alterarGeral === false) ? <> {data.codeSIGO}
                                        <a className="tooltip" onClick={() => setAlterar2(true)} data-tip="Alterar Código SIGO">
                                            <BsFillPencilFill id="icon" className="m-2 cursor-pointer" />
                                        </a> </> : <div id="dropdown"><input className="input-field" defaultValue={data.codeSIGO} id="SIGO" />
                                        {alterar2 === true && <div className="flex justify-center mt-1" >
                                            <button className="p-1 button-cancelar" onClick={() => setAlterar2(false)}>Cancelar</button>
                                            <button className="p-1 ml-1 button-confirmar" onClick={() => alterarSIGO(document.getElementById("SIGO").value)}>Confirmar</button>
                                        </div>}
                                    </div>
                                    }
                                    </div>
                                </div>
                                <div className="flex justify-center" >
                                    <div className="element">Data-Fim das Candidaturas:</div>
                                    <div className="value">
                                        {(alterarDataFinalCandidaturas === false && alterarGeral === false) ? <> {data.dateEndCandidacies && <span id="dataInicio">{AlterarFormatoDataTempo(data.dateEndCandidacies)}</span>}
                                            <a className="tooltip" onClick={() => setAlterarDataFinalCandidaturas(true)} data-tip="Alterar Data">
                                                <BsFillPencilFill id="icon" className="m-2 cursor-pointer" />
                                            </a></> :
                                            <>
                                                <div id="dropdown">
                                                    <input className="input-field" type="datetime-local" name="dateEndCandidacies" id="dateEndCandidacies" defaultValue={data.dateEndCandidacies && data.dateEndCandidacies} onChange={(e) => setDateEndCandidacies(e.target.value)}></input>
                                                    {alterarDataFinalCandidaturas === true && <div className="flex justify-center mt-1" >
                                                        <button className="p-1 button-cancelar" onClick={() => setAlterarDataFinalCandidaturas(false)}>Cancelar</button>
                                                        <button className="p-1 ml-1 button-confirmar" onClick={() => changeDataFinalCandidaturas(true)}>Confirmar</button>
                                                    </div>}
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="flex justify-center" >
                                    <div className="element">Link Candidaturas:</div>
                                    {(data.dateEndCandidacies && new Date(data.dateEndCandidacies) > new Date()) || !data.dateEndCandidacies ?
                                        <div className="value">
                                            <a className="mr-2 link tooltip" data-tip="Abrir Link" href={`${process.env.PUBLIC_URL}/#/inscricao/candidatura/` + params.id_curso} target="_blank">Link </a>
                                            <div className="tooltip" data-tip="Copiar" onClick={() => copyText("https://magna.comenius.pt/#/inscricao/candidatura/" + params.id_curso)}><AiFillCopy /></div>
                                        </div> : <div className="value bg-red-400">Link indisponivel</div>}
                                </div>
                            </div>
                            <div className="table-individual">
                                <div className="flex justify-center" >
                                    <div className="element">Entidade financiadora: </div>
                                    <div className="value">{alterarGeral === false ? <>{data.entFinanciadora[0] ? <>{data.entFinanciadora[0].name}</> : <>Não Financiada</>}</> : <select onWheel={(e) => e.target.blur()} className="selector2" type="text" id="entFinanciadora" name="entFinanciadora" defaultValue={data.entFinanciadora[0] ? data.entFinanciadora[0]._id : ""}  >
                                        <option value={""} disabled  > Selecionar a Entidade Financiadora</option>
                                        {entFinanciadorasList.map((value) => (
                                            <option key={value._id} value={value._id} >{value.name}</option>
                                        ))}
                                        <option value={"-1"}>Não Financiada</option>
                                    </select>}</div>
                                </div>
                                <div className="flex justify-center" >
                                    <div className="element">Categoria: </div>
                                    <div className="value">{alterarGeral === false ? <>{data.categoria[0] ? <>{data.categoria[0].name}</> : <>Sem Categoria</>}</> : <select onWheel={(e) => e.target.blur()} className="selector2" type="text" id="categoria" name="categoria" defaultValue={data.categoria[0] ? data.categoria[0]._id : ""}  >
                                        <option value={""} disabled  > Selecionar a Categoria</option>
                                        {categoriasList.map((value) => (
                                            <option key={value._id} value={value._id} >{value.name}</option>
                                        ))}
                                    </select>}</div>
                                </div>
                                <div className="flex justify-center" >
                                    <div className="element">Data-Início:</div>
                                    <div className="value">
                                        {(alterarDataInicio === false && alterarGeral === false) ? <> {data.dateBegin && <span id="dataInicio">{AlterarFormato(data.dateBegin)}</span>}
                                            <a className="tooltip" onClick={() => setAlterarDataInicio(true)} data-tip="Alterar Data">
                                                <BsFillPencilFill id="icon" className="m-2 cursor-pointer" />
                                            </a></> :
                                            <>
                                                <div id="dropdown">
                                                    <input className="input-field" type="date" name="dateBegin" id="dateBegin" defaultValue={dateBegin} onChange={(e) => setDateBegin(e.target.value)}></input>
                                                    {alterarDataInicio === true && <div className="flex justify-center mt-1" >
                                                        <button className="p-1 button-cancelar" onClick={() => setAlterarDataInicio(false)}>Cancelar</button>
                                                        <button className="p-1 ml-1 button-confirmar" onClick={() => changeDataInicio(true)}>Confirmar</button>
                                                    </div>}
                                                </div>
                                            </>}
                                    </div>
                                </div>
                                <div className="flex justify-center" >
                                    <div className="element">Data-Fim:</div>
                                    <div className="value">
                                        {(alterarDataFinal === false && alterarGeral === false) ? <> {data.dateEnd && <span id="dataInicio">{AlterarFormato(data.dateEnd)}</span>}
                                            <a className="tooltip" onClick={() => setAlterarDataFinal(true)} data-tip="Alterar Data">
                                                <BsFillPencilFill id="icon" className="m-2 cursor-pointer" />
                                            </a></> :
                                            <>
                                                <div id="dropdown">
                                                    <input className="input-field" type="date" name="dateEnd" id="dateEnd" defaultValue={dateEnd} onChange={(e) => setDateEnd(e.target.value)}></input>
                                                    {alterarDataFinal === true && <div className="flex justify-center mt-1" >
                                                        <button className="p-1 button-cancelar" onClick={() => setAlterarDataFinal(false)}>Cancelar</button>
                                                        <button className="p-1 ml-1 button-confirmar" onClick={() => changeDataFinal(true)}>Confirmar</button>
                                                    </div>}
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="flex justify-center" >
                                    <div className="element">Nível: </div>
                                    <div className="value">{alterarGeral === false ? <>{data.nivel && <>{data.nivel}</>}</> : <select onWheel={(e) => e.target.blur()} className="selector2" type="text" id="nivel" name="nivel" defaultValue={data.nivel} required>
                                        <option value={""} disabled  > Selecionar o nível</option>
                                        <option value="2">2</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="N.A.">N.A.</option>
                                    </select>}</div>
                                </div>
                                <div className="flex justify-center" >
                                    <div className="element">Horário: </div>
                                    <div className="value">{data.horario && <>{data.horario}</>}</div>
                                </div>
                                <div className="flex justify-center" >
                                    <div className="element">Edição: </div>
                                    <div className="value">{alterarGeral === false ? <>{data.edicoes && <>{data.edicoes}</>}</> : <input className="input-field" defaultValue={data.edicoes} id="edicoes" />}</div>
                                </div>
                                <div className="flex justify-center" >
                                    <div className="element">Duração:</div>
                                    <div className="value">{alterarGeral === false ? <>{data.duration}</> : <input className="input-field" defaultValue={data.duration} id="duration" />}</div>
                                </div>
                                <div className="flex justify-center" >
                                    <div className="element">Morada:</div>
                                    <div className="value flex flex-wrap">{alterarGeral === false ? <>{data.address}{data.address && ", "} {data.postalCode}</> : <><input className="input-field" defaultValue={data.address} id="address" />,<input className="input-field" placeholder="Código Postal" defaultValue={data.postalCode} id="postalCode" /></>}</div>
                                </div>
                                <div className="flex justify-center" >
                                    <div className="element">Local: </div>
                                    <div className="value ">{alterarGeral === false ? <>{data.locality}</> : <><input className="input-field" placeholder="Morada" defaultValue={data.locality} id="locality" /></>}</div>
                                </div>
                                <div className="flex justify-center" >
                                    <div className="element">Coordenador: </div>
                                    <div className="value">
                                        {(alterar === false && alterarGeral === false) ? <> {name && <span id="coordenador">{name}</span>}
                                            <a className="tooltip" onClick={() => setAlterar(true)} data-tip="Alterar Coordenador">
                                                <BsFillPencilFill id="icon" className="m-2 cursor-pointer" />
                                            </a>
                                        </> :
                                            <>
                                                <div id="dropdown">
                                                    {coordenadorList.length > 0 && (
                                                        <select
                                                            onWheel={(e) => e.target.blur()}
                                                            defaultValue={data.Coordenador[0] && data.Coordenador[0]._id} // Use `value` for controlled components
                                                            className="selector"
                                                            onChange={(e) => setCoordenador(e.target.value)}
                                                        >
                                                            <option value={""}>
                                                                Selecione uma opção
                                                            </option>
                                                            {coordenadorList.map((value) => (
                                                                <option value={value._id} key={value._id}>
                                                                    {value.fullName}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    )}
                                                    {alterar === true && <div className="flex justify-center mt-1" >
                                                        <button className="p-1 button-cancelar" onClick={() => setAlterar(false)}>Cancelar</button>
                                                        <button className="p-1 ml-1 button-confirmar" onClick={() => alterarCoordenadores(data.Coordenador[0] && data.Coordenador[0]._id, true)}>Confirmar</button>
                                                    </div>}
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="flex justify-center" >
                                    <div className="element">Ação Curso Balcão: </div>
                                    <div className="value">{data.acaoN}</div>
                                </div>

                                <div className="flex justify-center" >
                                    <div className="element">Estado: </div>
                                    <div className="value">{data && (data.fechoAdmin === false || !data.fechoAdmin) ? "Ativo" : "Fechado"}</div>
                                </div>
                                {data.type !== "Presencial" && <div className="flex justify-center" >
                                    <div className="element">Moodle: </div>
                                    <div className="value">{alterarGeral === false ? <>{data.moodle[0] && <>{data.moodle[0].name}</>}</> : <select onWheel={(e) => e.target.blur()} className="selector2" type="text" id="moodle" name="moodle" defaultValue={data.moodle[0] ? data.moodle[0]._id : ""}  >
                                        <option value={""} disabled  > Selecionar o Moodle</option>
                                        {moodlesList.map((value) => (
                                            <option key={value._id} value={value._id} >{value.name}</option>
                                        ))}
                                    </select>}</div>
                                </div>}
                            </div>
                        </div>
                        <div className="mt-5 flex justify-center gap-6">
                            <button className={!alterarGeral ? "button-geral" : "button-cancelar"} onClick={() => setAlterarGeral(!alterarGeral)}>{alterarGeral === false ? "Alterar Dados" : "Cancelar"}</button>
                            {alterarGeral === true && <button className="button-confirmar" onClick={() => { submeterAlteracoes(data.Coordenador[0] && data.Coordenador[0]._id) }}>Submeter</button>}
                        </div>
                    </div>
                </>}

                <div className="grid w-3/5 grid-cols-1 mt-8 mb-32 2xl:grid-cols-2 justify-items-center sm:mb-8 sm:mr-4">
                    <div>
                        <h2 className="subtitle 2xl:-mb-3">Cronogramas</h2>
                        <div className="flex flex-col sm:flex-row 2xl:flex-col">
                            {data.cronogramaOriginal && <button className="button-paginas-individual 2xl:mt-8 group" onClick={() => downloadFile(data.cronogramaOriginal)}>
                                <AiOutlineCalendar size={24} className="text-main-color group-hover:text-white" />
                                <h3 className="mt-1 text-main-color group-hover:text-white">DOWNLOAD CRONOGRAMA GERAL ORIGINAL</h3>

                            </button>}

                            <button className="button-paginas-individual 2xl:mt-8 group" onClick={() => excelGeneratorClone(data)}>
                                <BiCalendarEdit size={24} className="text-main-color group-hover:text-white" />
                                <h3 className="mt-1 text-main-color group-hover:text-white">DOWNLOAD CRONOGRAMA GERAL ATUALIZADO</h3>
                            </button>

                            <button className="button-paginas-individual 2xl:mt-8 group" onClick={() => navigate("/MenuSuperAdmin/cursos/" + params.id_curso + "/Cronograma")}>
                                <BsCalendar3 size={24} className="text-main-color group-hover:text-white" />
                                <h3 className="mt-1 text-main-color group-hover:text-white">VISUALIZAR CRONOGRAMA DE SESSÕES DE FORMAÇÃO</h3>
                            </button>
                        </div>
                    </div>
                    <div className="mt-8 2xl:mt-0">
                        <h2 className="subtitle 2xl:-mb-3">Gestão do curso</h2>
                        <div className="grid grid-cols-1 lg:grid-cols-2">
                            <div className="flex flex-col sm:flex-row 2xl:flex-col ">
                                {data && data.indice && <button className="button-paginas-individual 2xl:mt-8 group" onClick={() => navigate("/MenuSuperAdmin/cursos/" + params.id_curso + "/verDTP")}>
                                    <LuFiles size={24} className="text-main-color group-hover:text-white" />
                                    <h3 className="text-main-color group-hover:text-white">VISUALIZAR DTP</h3>
                                </button>}

                                <button className="button-paginas-individual 2xl:mt-8 group" onClick={() => navigate("/MenuSuperAdmin/cursos/" + params.id_curso + "/gerirDTP")}>
                                    <LuFileCog size={24} className="text-main-color group-hover:text-white" />
                                    <h3 className="mt-1 text-main-color group-hover:text-white">GERIR DTP</h3>
                                </button>

                                <button className="button-paginas-individual 2xl:mt-8 group" onClick={() => navigate("/MenuSuperAdmin/cursos/" + params.id_curso + "/Ufcds")}>
                                    <Books size={24} className="text-main-color group-hover:text-white" />
                                    <h3 className="text-main-color group-hover:text-white">GERIR DISCIPLINAS</h3>
                                </button>
                                {(data.modalidade === "Formação Modular Certificada" || data.modalidade === "Cursos de Educação e Formação de Adultos") && <button className="button-paginas-individual 2xl:mt-8 group" onClick={() => sendMailSeguros()}>
                                    <Books size={24} className="text-main-color group-hover:text-white" />
                                    <h3 className="text-main-color group-hover:text-white">REENVIAR LEMBRETE DE ENTREGA DE SEGUROS</h3>
                                </button>}

                                {fechoCoordenador ? <>
                                    {data.fechoCoordenador === true && (!data.fechoAdmin) ?
                                        <button className="button-paginas-individual 2xl:mt-8 group" onClick={(e) => { fecharCurso(data.ufcds[0]._id) }}>
                                            <LuBookOpen size={24} className="text-main-color group-hover:text-white" />
                                            <h3 className="text-main-color group-hover:text-white">FECHAR CURSO</h3>
                                        </button> : <div className="button-paginas-individual-fechado 2xl:mt-8 group ">
                                            <LuBook size={24} className="text-white " />
                                            <h3 className="text-white ">CURSO FECHADO</h3>
                                        </div>}
                                </> : ""}
                            </div>
                            <div>
                                <div className="flex flex-col sm:flex-row 2xl:flex-col ">
                                    <button className="button-paginas-individual 2xl:mt-8 group" onClick={() => navigate("/MenuSuperAdmin/cursos/" + params.id_curso + "/formadores")}>
                                        <ChalkboardTeacher size={24} className="text-main-color group-hover:text-white" />
                                        <h3 className="text-main-color group-hover:text-white">VISUALIZAR FORMADORES</h3>
                                    </button>
                                    <button className="button-paginas-individual 2xl:mt-8 group" onClick={() => navigate("/MenuSuperAdmin/cursos/" + params.id_curso + "/turma")}>
                                        <Student size={24} className="text-main-color group-hover:text-white" />
                                        <h3 className="text-main-color group-hover:text-white">GERIR TURMA</h3>
                                    </button>
                                    <button className="button-paginas-individual 2xl:mt-8 group" onClick={() => ExcelFormandosSimples(data.formandos)}>
                                        <Student size={24} className="text-main-color group-hover:text-white" />
                                        <h3 className="text-main-color group-hover:text-white">EXPORTAR ALUNOS</h3>
                                    </button>
                                    <button className="button-paginas-individual 2xl:mt-8 group" onClick={() => ExcelUtilizadoresExternos(data.formadores, data.formandos, data.Coordenador)}>
                                        <Student size={24} className="text-main-color group-hover:text-white" />
                                        <h3 className="text-main-color group-hover:text-white">DOWNLOAD DOS DADOS UTILIZADORES EXTERNOS</h3>
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </main>
            <Footer />
        </>
    );
}

export default Candidaturas