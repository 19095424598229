import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom'
import Footer from '../../../components/Footer'
import { Link } from 'react-router-dom';
import NavbarAdmin1 from "../../../components/Navbars/NavbarAdmin1/NavbarAdmin1";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { FaEye } from "react-icons/fa";
import Cookies from "js-cookie";

function ShowCoordinators() {
    const params = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [dataToDisplay, setDataToDisplay] = useState([])
    const [source, setSource] = useState("down");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const savedSearchTerm = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Admin-Coordenadores-1") : "";
    const [searchTerm, setSearchTerm] = useState(savedSearchTerm ? savedSearchTerm : "");
    const savedPagina = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Admin-Coordenadores-Pag") : ""
    const [nPaginas, setNumPaginas] = useState(1);
    const [paginaAtual, setPaginaAtual] = useState(savedPagina ? parseInt(savedPagina) : 1);
    const savedItemsPag = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get(`Admin-Coordenadores-itm1`) : ""
    const [itemsPag, setItemsPag] = useState(savedItemsPag ? savedItemsPag : 10)

    useEffect(() => {
        Cookies.set("Admin-Coordenadores-1", searchTerm);
        Cookies.set("Admin-Coordenadores-Pag", paginaAtual)
        Cookies.set(`Admin-Coordenadores-itm1`, itemsPag)
        getCoordinators()
    }, [loading, paginaAtual, nPaginas, searchTerm, itemsPag]);


    //Atribuir numero de páginas
    function paginacaoTabelas(data) {
        if (data.length < itemsPag) {
            setNumPaginas(1)
        } else if (data.length % itemsPag === 0) {
            setNumPaginas(data.length / itemsPag)
        } else {
            setNumPaginas(Math.ceil(data.length / itemsPag))
        }
        if (paginaAtual > nPaginas) {
            setPaginaAtual(nPaginas);
        }
        updatePagination(data) // n é o state por causa das tabelas
    }


    
    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    const handleItemsPerPageChange = (newItemsPag) => {


        const newNumPaginas = Math.ceil(data.length / newItemsPag);


        const newPaginaAtual = paginaAtual > newNumPaginas ? newNumPaginas : paginaAtual;

        setItemsPag(newItemsPag);
        setNumPaginas(newNumPaginas);
        setPaginaAtual(newPaginaAtual);

        updatePagination(dataToDisplay);
    };

    //Define que dados mostrar consoante a página em que está
    function updatePagination(data) {
        let maxIndex = (paginaAtual * itemsPag)
        let minIndex
        if (paginaAtual === 1) {
            minIndex = 0
        } else {
            minIndex = (paginaAtual - 1) * itemsPag;
            maxIndex = (paginaAtual * itemsPag);
        }
        setDataToDisplay(data.slice(minIndex, maxIndex))
    }

    //aumentar página atual
    function increasePagination() {
        if (paginaAtual < nPaginas) {
            setPaginaAtual(prevIndex => (prevIndex + 1))
            updatePagination(data)
        }
    }

    //diminuir página atual
    function decreasePagination() {
        if (paginaAtual > 1) {
            setPaginaAtual(prevIndex => (prevIndex - 1))
            updatePagination(data)
        }
    }


    //filtra dados
    function filterData(data) {
        return data.filter((item) =>
            item.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.email.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }

    //Recebe todos os coordenadores
    function getCoordinators() {
        fetch(`${process.env.REACT_APP_API_URL}/coordenadores`, {
            headers: {
                'authorization': localStorage.getItem('admin1')
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    setData(result.sort((a, b) => {
                        return a.fullName.localeCompare(b.fullName);
                    }));
                    paginacaoTabelas(filterData(result.sort((a, b) => {
                        return a.fullName.localeCompare(b.fullName);
                    })));
                    setLoading(false);
                    result.map(id => {
                        let mediac = null
                        let cont = 0
                        let data2 = []
                        let filtro = []
                        if (id.percurso.length > 0 && id.percurso) {
                            if (id.percurso.dateEnd) {
                                id.percurso.sort((a, b) => b.dateEnd.localeCompare(a.dateEnd));
                            }
                            data2 = id.percurso
                            filtro = data2.filter(percurso => (percurso.evaluation || percurso.evaluation === 0));
                            if (filtro.length >= 5) {
                                filtro.length = 5
                            }
                            for (let i = 0; i < filtro.length; i++) {
                                if (filtro[i].evaluation === null) {
                                    cont--
                                }
                                else {
                                    mediac = mediac + parseInt(filtro[i].evaluation)
                                }
                            }
                            if (mediac || mediac === 0) {
                                id.media = Math.round(mediac / (filtro.length + (cont)))
                            }
                            else id.media = 101
                        }
                        return id.media
                    });
                    const numAscending = [...result].sort((a, b) => a.media - b.media);
                    setData(numAscending);
                    setLoading(false);
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                    setError(error);
                }
            );
    }
//Ordena coordenadores
    function getCoordenadoresOrder() {
        if (source === "down") {
            setSource("up")
        } else (
            setSource("down")
        )
        let dataReverse = data.reverse();
        setData(dataReverse);
    }

    //passa para a ultima página
    function ultimaPagina() {
        if (paginaAtual !== nPaginas) {
            setPaginaAtual(nPaginas);
            updatePagination(data);
        }
    }
    //passa para a primeira página
    function primeiraPagina() {
        if (paginaAtual !== 1) {
            setPaginaAtual(1);
            updatePagination(data);
        }
    }
    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";

    return (
        <>
            <main className="min-h-screen">
                <div className="w-full">
                    <NavbarAdmin1 currentPage={"manageCoordinator"} />
                </div>
                <Link to={`/menuadmin/` + params.id_candidatura} className="back-button-div-noNavbar">
                    <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                </Link>

                <div className="flex-grow mt-8">
                    <h1 className="mx-4 title">Bolsa de Coordenadores</h1>
                    {data && data.length > 0 ? <> <div className="search-div">
                        <label className="search-input-label">Pesquisar: </label>
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                                <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                </svg>
                            </div>
                            <input type="text" placeholder="ex: Nome, Email ..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} className="search-input" />
                        </div>
                    </div>
                        <div className="flex items-center justify-center mb-1">
                            <label className="text-text-main-color">Dados por página: </label>
                            <select onWheel={(e) => e.target.blur()} className="ml-1 selector w-14"
                                value={itemsPag}
                                onChange={(e) => handleItemsPerPageChange(parseInt(e.target.value))}>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                            </select>
                        </div>
                        <div className="table-container">
                            <table className="my-table">

                                <thead className="bg-main-color">
                                    <tr className="text-white h-14">
                                        <th className="p-2 min-w-[80px] border border-white">Nome Completo</th>
                                        <th className="p-2 min-w-[80px] border border-white">Email</th>
                                        <th className="p-2 min-w-[80px] border border-white">Tipo</th>
                                        <th className="p-2 min-w-[120px] border border-white">
                                            <div className="flex items-center justify-around ">
                                                Classificação {source === "down" ? <img alt="arrow" className="arrow" id="arrow" onClick={getCoordenadoresOrder} src={process.env.PUBLIC_URL + "/img/down.png"} /> : <img className="arrow" alt="arrow" id="arrow" onClick={getCoordenadoresOrder} src={process.env.PUBLIC_URL + "/img/up.png"} />}
                                            </div>
                                        </th>
                                        <th className="p-2 min-w-[80px] border border-white">Perfil</th>
                                        <th className="p-2 min-w-[80px] border border-white">Histórico</th>
                                        <th className="p-2 min-w-[80px] border border-white">Avaliação</th>
                                    </tr>
                                </thead>
                                {data && data.length > 0 ? (
                                    <tbody className="text-text-main-color">
                                        {filterData(dataToDisplay).map((item, index) => (
                                            <tr key={index}>
                                                <td className="border border-white coordinators">{item.fullName}</td>
                                                <td className="border border-white coordinators">{item.email}</td>
                                                <td className="border border-white coordinators">{item.tipo ? "Interno" : "Externo"}</td>
                                                <td className="border border-white coordinators">{item.media !== 101 ? <>{item.media || item.media === 0 ? <div>{item.media}%</div> : "Sem Percursos"}</> : "Em atividade"}</td>
                                                <td onClick={(e) => navigate("/menuadmin/" + params.id_candidatura + "/coordinators/coordinator/" + item._id)} className="border border-white cursor-pointer hover:bg-gray-300 group">
                                                    <div className="flex items-center justify-center group-hover:brightness-150">
                                                        <FaEye size={24} />
                                                    </div>
                                                </td>
                                                <td onClick={(e) => navigate("/menuadmin/" + params.id_candidatura + "/coordinators/atividade/" + item._id)} className="border border-white cursor-pointer hover:bg-gray-300 group">
                                                    <div className="flex items-center justify-center group-hover:brightness-150">
                                                        <FaEye size={24} />
                                                    </div>
                                                </td>
                                                <td onClick={(e) => navigate("/menuadmin/" + params.id_candidatura + "/coordinators/avaliacao/" + item._id)} className="border border-white cursor-pointer hover:bg-gray-300 group">
                                                    <div className="flex items-center justify-center group-hover:brightness-150">
                                                        <FaEye size={24} />
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                ) : (
                                    <tbody />
                                )}
                            </table>
                        </div>

                        <div className="flex items-center justify-center mt-4 mb-8">
                            <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => primeiraPagina()}>
                                <AiOutlineDoubleLeft />
                            </button>
                            <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => decreasePagination()}>
                                <AiOutlineLeft />
                            </button>
                            <p className="text-main-color">Página {paginaAtual} - {nPaginas}</p>
                            <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => increasePagination()}>
                                <AiOutlineRight />
                            </button>
                            <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => ultimaPagina()}>
                                <AiOutlineDoubleRight />
                            </button>
                        </div> </> : <p className="third-title">Não existem dados</p>}
                </div>
                <div className="w-full mt-3 sm:bottom-0 sm:mt-0">
                    <Footer />
                </div>
            </main>
        </>
    );
}


export default ShowCoordinators;