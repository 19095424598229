import React, { useState, useEffect } from "react";

function VerificaHorario(date) {
    let datas = ({
        primavera: "Verificando...",
        outono: "Verificando...",
    });
    let horario = 0
    const calcularPrimeiroDomingo = (ano, mes, dia) => {
        let data = new Date(ano, mes, dia);
        while (data.getDay() !== 0) { // 0 represents Sunday
            data.setDate(data.getDate() + 1);
        }
        return data;
    };

    const anoAtual = date.getFullYear();
    const inicioPrimavera = calcularPrimeiroDomingo(anoAtual, 2, 20); //20 March 
    const inicioOutono = calcularPrimeiroDomingo(anoAtual, 8, 22);    //22 September
    if (date > inicioPrimavera && date<inicioOutono) {
        horario =1
    }

    return horario;  // Return the actual date objects for primavera and outono
}

export default VerificaHorario;
