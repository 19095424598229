import React, { useState, useEffect } from "react";
import { ContratoFormador } from "../Modal/stylesContratos/styledFormador";
import SepararData from "../FormChecks/formatDates/splitDates";
import AlterarFormato from "../FormChecks/formatDates/foramatDates";
import toastError from "../Toast/toastError";
import toastSuccess from "../Toast/toastSuccess";
import refreshPage from "../Refresh";
import ConfirmToaster from "../Toast/toastConfirmChoice";

export default function ContratoFormadorFMC({
  data,
  token,
  formador,
  tipo,
  setOpen,
}) {
  // This const is for finding the "dadosContrato" object in the array of objects
  // Verify if the formador._id is equal to the id in the array of objects
  const index =
    formador && tipo === "formador"
      ? data.dadosContrato.findIndex((item) => item.id === formador._id)
      : 0;
  const contrato = data?.dadosContrato[index]?.dadosContrato;

  // const to handle the date
  const { dia, mes, ano } = SepararData(data.dateBegin);

  const [loadingEnvio, setLoadingEnvio] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [valores, setValores] = useState({
    valorHorasLecionadasFormador: contrato?.valorHorasLecionadasFormador ?? "",
    valorHoraFormador: contrato?.valorHoraFormador ?? "",
    valorDescritivo: contrato?.valorDescritivo ?? "",
    date: AlterarFormato(data?.dateBegin) ?? "",
    local: data?.percurso[0].locality ?? "",
  });

  async function fetchValorHoraFormador() {
    try {
      const queryParams = new URLSearchParams({
        entFormadoraId: data.percurso[0].entFormadora[0]._id,
        operacaoId: data.percurso[0].operacao[0]._id,
        percursoId: data.percurso[0]._id,
      }).toString();

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/valor-hora?${queryParams}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: localStorage.getItem(token), // Certifique-se de que 'token' está definido corretamente
          },
          method: "GET",
        }
      );
      
      if (!response.ok) {
        throw new Error("Erro ao obter valorHoraFormador");
      }
  
      const responseData = await response.json();
      setValores({...valores, valorHoraFormador: responseData.valorHora});
    } catch (error) {
      console.error('Error fetching valorHoraFormador:', error);
      throw error;
    }
  };

  // Function to send the contract by email
  async function enviarContratoFormador() {
    if (validateInputs() === false) {
      setErrorMessage("Preencha os campos em falta assinalados a vermelho!");
      return;
    }
    setLoadingEnvio(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/formadores/contrato`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: localStorage.getItem(token),
          },
          method: "POST",
          body: JSON.stringify({
            id: formador ? formador._id : data.formadores[0]._id,
            id_ufcd: data._id,
            valores: valores,
          }),
        }
      );

      if (!response.ok) {
        setOpen(false);
        setLoadingEnvio(false);
        toastError("Erro! O contrato não foi enviado");
        return;
      }

      setOpen(false);
      setLoadingEnvio(false);
      toastSuccess("Contrato enviado com sucesso");
      localStorage.setItem("contractUpdated", "true");

      setTimeout(() => {
        refreshPage();
      }, 1000);

      const result = await response.json();
    } catch (error) {
      console.error("Erro ao enviar contrato:", error);
      setOpen(false);
      toastError("Erro ao enviar contrato");
    }
  }
  // Auxiliary function to download the file
  function downloadFile(url) {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("target", "_blank"); // Opens the link in a new tab/window
    link.setAttribute("download", ""); // Optional, sets the download attribute to trigger download in some browsers
    link.click();
  }

  // Function to download the contract
  async function enviarContratoFormadorNoEmail() {
    if (validateInputs() === false) {
      setErrorMessage("Preencha os campos em falta assinalados a vermelho!");
      return;
    }
    setLoadingDownload(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/formadores/contratoSemEmail`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: localStorage.getItem(token),
          },
          method: "POST",
          body: JSON.stringify({
            id: formador ? formador._id : data.formadores[0]._id,
            id_ufcd: data._id,
            valores: valores,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Erro ao iniciar download do contrato");
      }

      const result = await response.json();
      downloadFile(result);
      setOpen(false);
      setLoadingDownload(false);
      refreshPage();
    } catch (error) {
      console.error("Erro ao iniciar download do contrato:", error);
      setOpen(false);
      setLoadingDownload(false);
      toastError("Erro ao iniciar download do contrato");
    }
  }

  // State to handle input values
  const [inputValues, setInputValues] = useState({
    inputValorHorasLecionadasFormador: valores.valorHorasLecionadasFormador,
    //inputValorHoraFormador: valores.valorHoraFormador,
    inputValorDescritivo: valores.valorDescritivo,
    inputDate: valores.date,
    inputLocal: valores.local,
  });

  // State to handle input errors
  const [inputErrors, setInputErrors] = useState({
    inputValorHorasLecionadasFormador: false,
    //inputValorHoraFormador: false,
    inputValorDescritivo: false,
    inputDate: false,
    inputLocal: false,
  });

  useEffect(() => {
    fetchValorHoraFormador();
  }, []);

  useEffect(() => {
    valores.dia = dia;
    valores.mes = mes;
    valores.ano = ano;
  }, [valores, dia, mes, ano]);

  // Handle input change and pass the value to the parent component by invoking the handleInputChange callback
  const handleInputChangeLocal = (e) => {
    const { name, value } = e.target;
    setInputValues({
      ...inputValues,
      [name]: value,
    });
    setInputErrors({
      ...inputErrors,
      [name]: false,
    });
  };
  // Validate all inputs and return true if all of them are valid
  const validateInputs = () => {
    const errors = {};
    let isValid = true;

    for (const key in inputValues) {

      if (
        key === "inputValorHorasLecionadasFormador" &&
        data?.formadores?.length < 2
      ) {
        continue; // Ignore this input validation if there is only one formador
      }
      
      if (inputValues[key].trim() === "") {
        errors[key] = true;
        isValid = false;
      }
    }
    setInputErrors(errors);
    return isValid;
  };

  // Function to adjust the width of the input field
  const adjustWidth = (e) => {
    e.target.style.width = `${e.target.value.length + 1}ch`;
  };

  // Style on inputs is for validation purposes, mark the input with red border if it's empty and black if it's not
  // On inputs, onChange event is used to handle the input change locally and in the parent component
  return (
    <>
      {loadingEnvio ? (
        <div className="text-center text-2xl">
          A enviar contrato, por favor aguarde...
        </div>
      ) : loadingDownload ? (
        <div className="text-center text-2xl">
          A fazer download do contrato, por favor aguarde...
        </div>
      ) : (
        <>
          <ContratoFormador>
            <div className="body">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <img
                    src={data.percurso[0].entFormadora[0].logotipo}
                    className="h-20"
                    alt="logo"
                    style={{ marginLeft: "20px" }}
                  />
                </div>
                <div>
                  <h1
                    style={{
                      fontSize: "16px",
                      paddingLeft: "15px",
                      marginTop: "-5px",
                    }}
                  >
                    Contrato de Prestação de Serviços de Formação
                  </h1>
                </div>
                <div>
                  {data.percurso[0].entFinanciadora &&
                    data.percurso[0].entFinanciadora.length > 0 &&
                    data.percurso[0].entFinanciadora[0].logotipo1 && (
                      <img
                        src={data.percurso[0].entFinanciadora[0].logotipo1}
                        className="h-20"
                        alt="logo"
                        style={{ marginRight: "20px" }}
                      />
                    )}
                </div>
              </div>
              <div className="page1"></div>
              <div>
                <div>
                  <p>
                    Entre, <b>{data.percurso[0].entFormadora[0].name}</b>, com
                    sede social em {data.percurso[0].entFormadora[0].morada},{" "}
                    {data.percurso[0].entFormadora[0].postalCode}{" "}
                    {data.percurso[0].entFormadora[0].concelho} com o
                    Contribuinte nº {data.percurso[0].entFormadora[0].nif},
                    nesse ato representado pelo/a{" "}
                    {data.percurso[0].entFormadora[0].representanteLegal},
                    adiante designada por <b>PRIMEIRO OUTORGANTE</b>, e
                  </p>
                  <table className="dados">
                    <tbody>
                      <tr>
                        <th>Nome Completo</th>
                        <td>
                          {formador
                            ? formador.fullName
                            : data.formadores[0].fullName}
                        </td>
                      </tr>
                      <tr>
                        <th>NIF</th>
                        <td>
                          {formador ? formador.nif : data.formadores[0].nif}
                        </td>
                      </tr>
                      <tr>
                        <th>Cartão de Cidadão/Passaporte</th>
                        <td>
                          {formador
                            ? formador.ccNumber
                            : data.formadores[0].ccNumber}
                        </td>
                      </tr>
                      <tr>
                        <th>Morada</th>
                        <td>
                          {formador
                            ? formador.address
                            : data.formadores[0].address}
                        </td>
                      </tr>
                      <tr>
                        <th>Código Postal</th>
                        <td>
                          {formador
                            ? formador.postalCode
                            : data.formadores[0].postalCode}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <p>
                    , adiante designada por <b>SEGUNDO OUTORGANTE</b>, é
                    celebrado o presente contrato de prestação de serviços, o
                    qual se rege pelo disposto nas seguintes cláusulas:
                  </p>
                </div>
                <div>
                  <h2>Cláusula 1ª</h2>
                  <p>
                    1. O <b>PRIMEIRO OUTORGANTE</b> contrata o{" "}
                    <b>SEGUNDO OUTORGANTE</b> para a prestação de serviços o
                    exercício da função de <b>FORMADOR/A</b> para o Curso{" "}
                    {data.percurso[0].name}, no âmbito do Programa{" "}
                    {data?.percurso[0]?.entFinanciadora[0]?.name}, tipologia de{" "}
                    {data?.percurso[0]?.operacao[0]?.tipologia} enquadrado pelas
                    seguintes especificações:
                  </p>
                  <table className="ufcds">
                    <tbody>
                      <tr>
                        <th style={{ width: "134px" }}>UFCD:</th>
                        <td style={{ width: "420px" }} colSpan={3}>
                          {data.codeUfcd} - {data.name}
                        </td>
                      </tr>
                      <tr>
                        <th style={{ width: "134px" }}>Nível da UFCD:</th>
                        <td style={{ width: "420px" }}>
                          {data.percurso[0].nivel}
                        </td>
                        <th style={{ width: "110px" }}>Carga Horária:</th>
                        <td style={{ width: "40px" }}>{data && data.formadores && data.formadores.length < 2 ? (data.cargaHoraria) :  (<input
                      className="input-field-contracts"
                      type="text"
                      name="inputValorHorasLecionadasFormador"
                      defaultValue={valores.valorHorasLecionadasFormador}
                      placeholder="5"
                      onChange={(e) => {
                        setValores({
                          ...valores,
                          valorHorasLecionadasFormador: e.target.value,
                        });
                        handleInputChangeLocal(e);
                        adjustWidth(e);
                      }}
                      style={{
                        width: `${
                          valores.valorHorasLecionadasFormador.length > 0
                            ? valores.valorHorasLecionadasFormador.length + 1
                            : 10
                        }ch`,
                        border: inputErrors.inputValorHorasLecionadasFormador
                          ? "3px solid red"
                          : "1px solid black",
                      }}
                    />)}</td>
                      </tr>
                      <tr>
                        <th>Nº do Projeto:</th>
                        <td colSpan={3}>
                          {data.percurso[0].operacao[0] &&
                            data.percurso[0].operacao[0].codigoOperacao}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <p>
                    2. Quando a formação decorre no formato presencial, o local
                    de realização da formação é:
                  </p>
                  <table className="realizacaocurso">
                    <tbody>
                      <tr>
                        <th>Local:</th>
                        <td>
                          {data.percurso[0].type !== "Online"
                            ? data.percurso[0].locality
                            : "(Online)"}
                        </td>
                      </tr>
                      <tr>
                        <th>Morada:</th>
                        <td>
                          {data.percurso[0].type !== "Online"
                            ? data.percurso[0].address
                            : "(Online)"}
                        </td>
                      </tr>
                      <tr>
                        <th>Código de Postal:</th>
                        <td>
                          {data.percurso[0].type !== "Online"
                            ? data.percurso[0].postalCode
                            : "(Online)"}
                        </td>
                      </tr>
                      <tr>
                        <th>Data de início prevista:</th>
                        <td>{AlterarFormato(data.dateBegin)}</td>
                      </tr>
                      <tr>
                        <th>Data de fim prevista:</th>
                        <td>{AlterarFormato(data.dateEnd)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <p>
                    3. Quando a formação decorre no formato a distância, a
                    formação é realizada através da seguinte plataforma:
                  </p>
                  <table className="plataforma">
                    <tbody>
                      <tr>
                        <th>Plataforma LMS:</th>
                        <td>
                          {/* VER POR QUE MOTIVO MOODLE NÂO È POPULADO */}
                          {data.formato === "Online" ? (
                            <a href={data?.percurso[0]?.moodle[0]?.name}>
                              {data?.percurso[0]?.moodle[0]?.name}
                            </a>
                          ) : (
                            "N/A"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div>
                <h2>Cláusula 2ª</h2>
                <ol>
                  <li>
                    A atividade prevista na prestação de serviços, mencionada na
                    cláusula 1ª, está enquadrada pela Portaria nº 66/2022, de 1
                    de fevereiro e contempla as seguintes condições da prestação
                    do serviço:
                  </li>
                  <ol className="tipoa">
                    <li>Respeitar a regulamentação da formação em vigor.</li>
                    <li>
                      Garantir a estrita confidencialidade no tratamento dos
                      dados aos quais terá acesso, garantindo que a informação
                      não será partilhada com terceiros e será utilizada apenas
                      para os fins diretamente relacionados com a formação,
                      sendo tratados de forma lícita de acordo com o Regulamento
                      Geral de Proteção de Dados e legislação aplicável.
                    </li>
                    <li>
                      Matéria a lecionar de acordo com os conteúdos
                      programáticos estabelecidos contratualmente entre o{" "}
                      <b>PRIMEIRO OUTORGANTE</b> e o <b>SEGUNDO OUTORGANTE</b>.
                    </li>
                    <li>
                      Adotar as estratégias pedagógicas estabelecidas
                      contratualmente com o <b>PRIMEIRO OUTORGANTE</b>.
                    </li>
                    <li>
                      Total e correto preenchimento do Dossier
                      Técnico-Pedagógico.
                    </li>
                    <li>
                      Entregar a documentação aplicável à ação de formação
                      (manual, planos de sessão, evidencias de avaliação,
                      corrigendas, grelhas e pautas de avaliação) de acordo com
                      as datas estipuladas pelo/a Gestor/a de Projeto e/ou
                      Coordenador/a Pedagógico/a.
                    </li>
                    <li>
                      Contactar em primeira instância o{" "}
                      <b>PRIMEIRO OUTORGANTE</b> sempre que surja algum
                      imprevisto em relação ao que estava acordado, preenchendo
                      simultaneamente a FICHA DE OCORRÊNCIAS, constante do
                      Dossier Técnico-Pedagógico.
                    </li>
                    <li>
                      Entregar/enviar para as instalações do{" "}
                      <b>PRIMEIRO OUTORGANTE</b> todo o material pedagógico que
                      seja adicionalmente entregue aos formandos/as.
                    </li>
                    <li>
                      Participar nas reuniões: (1) de preparação do
                      desenvolvimento/execução e (2) de acompanhamento do
                      desenvolvimento/execução.
                    </li>
                  </ol>
                  <br />
                  <li>
                    Ainda segundo a Portaria n.o 66/2022, de 1 de fevereiro, no
                    seu ponto 7 do Artigo 12o , o desenvolvimento de Formação
                    Modular Certificada, pode decorrer no formato à distância,
                    devendo nesses casos a execução da formação referida no
                    ponto 1 desta Cláusula 2ª ser orientada pelos preceitos
                    metodológicos e administrativos previstos pelo{" "}
                    <b>PRIMEIRO OUTORGANTE</b> e clarificados nos seguintes
                    documentos metodológicos do Sistema de Gestão baseado no
                    norma ISO 9001, que são dados a conhecer antes da celebração
                    do presente contrato:
                  </li>
                  <ol className="tipoa">
                    <li>
                      Procedimento 33: Conceber e executar formação à distância;
                    </li>
                    <li>METODOLOGIA do Modelo Pedagógico COM-TEC;</li>
                  </ol>
                  <li>
                    No acompanhamento destas tarefas o <b>SEGUNDO OUTORGANTE</b>{" "}
                    será sujeita a uma avaliação de desempenho com base na Ficha
                    de Avaliação (passível de consulta no seguinte LINK) a qual
                    representa uma qualificação dos serviços prestados e, em si
                    mesmo, uma orientação para a melhoria e contributo do
                    <b>SEGUNDO OUTORGANTE</b> para o projeto referido na
                    Cláusula 1ª deste contrato.
                  </li>
                  <li>
                    O <b>SEGUNDO OUTORGANTE</b> compromete-se a prestar apoio
                    pedagógico ao <b>PRIMEIRO OUTORGANTE</b>. Este apoio
                    consiste no fornecimento, discussão e esclarecimento de
                    todos os elementos pedagógicos acordados com o{" "}
                    <b>SEGUNDO OUTORGANTE</b>, nomeadamente: Objetivos
                    pedagógicos; Conteúdos programáticos; Referências
                    bibliográficas; Destinatários; Estratégia pedagógica, Datas,
                    horários e local de realização da intervenção, e Suportes
                    pedagógicos.
                  </li>
                </ol>
              </div>
              <div>
                <h2>Cláusula 3ª</h2>
                <ol>
                  <li>
                    O preço base da prestação de serviços corresponde ao
                    seguinte:
                  </li>
                  <table className="valor">
                    <tbody>
                      <tr>
                        <th>Valor hora (€)</th>
                        <td>
                          {data.percurso[0].entFormadora[0].name === "Astro Indomável Unipessoal Lda" ? (<input
                            className="w-full input-field"
                            name="inputValorHoraFormador"
                            type="text"
                            placeholder="10"
                            defaultValue={
                              valores.valorHoraFormador
                                ? valores.valorHoraFormador
                                : formador &&
                                  data.dadosContrato[index] &&
                                  data.dadosContrato[index].dadosContrato
                                    .valorHoraFormador
                            }
                            onChange={(e) => {
                              setValores({
                                ...valores,
                                valorHoraFormador: e.target.value,
                              });
                              handleInputChangeLocal(e);
                            }}
                            style={{
                              border: inputErrors.inputValorHoraFormador
                                ? "3px solid red"
                                : "1px solid black",
                            }}
                          /> ) : (valores.valorHoraFormador !== null ? valores.valorHoraFormador : "N/A")}                         
                        </td>
                      </tr>
                      <tr>
                        <th>Valor hora (descritivo)</th>
                        <td>
                          <input
                            className="w-full input-field"
                            name="inputValorDescritivo"
                            type="text"
                            placeholder="Dez euros"
                            defaultValue={
                              valores.valorDescritivo
                                ? valores.valorDescritivo
                                : formador &&
                                  data.dadosContrato[index] &&
                                  data.dadosContrato[index].dadosContrato
                                    .valorDescritivo
                            }
                            onChange={(e) => {
                              setValores({
                                ...valores,
                                valorDescritivo: e.target.value,
                              });
                              handleInputChangeLocal(e);
                            }}
                            style={{
                              border: inputErrors.inputValorDescritivo
                                ? "3px solid red"
                                : "1px solid black",
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>NOTA:</th>
                        <td>
                          Valores acrescidos de IVA à taxa legal em vigor, se de
                          direito.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <li>
                    Caso a formação seja realizada no formato a distância, a
                    remuneração da totalidade da “carga horária” mencionada na
                    Cláusula 1ª, ponto 1, só será efetivada se o somatório das
                    horas das sessões síncronas com o somatório das “horas de
                    dedicação” efetivamente registadas na plataforma LMS
                    (considerando o “tempo limite entre cliques de 60 minutos”),
                    corresponder a totalidade da carga horária da UFCD.
                  </li>
                </ol>
              </div>
              <div>
                <h2>Cláusula 4ª</h2>
                <ol>
                  <li>
                    O <b>SEGUNDO OUTORGANTE</b> deverá apresentar apresentar a
                    fatura no valor respeitante ao pagamento a efetuar, com o{" "}
                      descritivo:<b> “Ministrar {data && data.formadores && data.formadores.length < 2 ? (data.cargaHoraria) :  (valores.valorHorasLecionadasFormador)} horas de
                      formação na UFCD {data.codeUfcd} - {data.name} no Curso{" "}
                      {data.percurso[0].name}
                      {data.percurso[0].entFinanciadora &&
                        data.percurso[0].entFinanciadora.length > 0 && (
                          <>
                            {" "}
                            ao abrigo do Projeto Nº{" "}
                            {data.percurso[0].operacao[0].codigoOperacao}
                          </>
                        )}
                      ”
                    </b>{" "}
                    referidos no Ponto 1 da Cláusula 1ª, após a sua realização
                    parcial ou total, o qual satisfará as leis fiscais
                    aplicáveis aos rendimentos de trabalho independente.
                  </li>
                  <li>
                    O <b>SEGUNDO OUTORGANTE</b> deverá emitir a fatura-recibo
                    após a entrega de toda a documentação em conformidade e
                    exigida pela Coordenação Pedagógica.{" "}
                  </li>
                  <li>
                    O <b>PRIMEIRO OUTORGANTE</b> efetuará o pagamento das
                    respetivas importâncias até 60 dias após a emissão do
                    recibo, desde que o organismo financiador cumpra as
                    obrigações previstas na lei.
                  </li>
                </ol>
              </div>
              <div>
                <h2>Cláusula 5ª</h2>
                <ol>
                  <li style={{ listStyleType: "none" }}>
                    O <b>SEGUNDO OUTORGANTE</b> deverá enviar/manter atualizados
                    os dados relativos ao seu ficheiro pessoal, nomeadamente:
                  </li>
                  <ol className="tipoa" type="a">
                    <li>Curriculum Vitae;</li>
                    <li>
                      Informação correta dos dados constantes no Cartão de
                      Cidadão;
                    </li>
                    <li>
                      Informação correta sobre o Número de Identificação
                      Bancária;
                    </li>
                    <li>
                      Cópia dos Certificados comprovativos das Habilitações
                      Literárias;
                    </li>
                    <li>
                      Cópia do Certificado Competências Profissionais (CCP).
                    </li>
                  </ol>
                </ol>
              </div>
              <div>
                <h2>Cláusula 6ª</h2>
                <ol>
                  <li>
                    Os Outorgantes comprometem-se a respeitar o Regulamento
                    Geral de Proteção de Dados (RGPD) em vigor e demais
                    legislações nacionais aplicáveis aos dados pessoais.
                  </li>
                  <li>
                    O <b>PRIMEIRO OUTORGANTE</b> fará a recolha, conservação e
                    tratamento de dados do <b>SEGUNDO OUTORGANTE</b> por
                    imposição do cumprimento de obrigações legais, para
                    cumprimento do presente contrato e ainda porque o{" "}
                    <b>SEGUNDO OUTORGANTE</b> dá o seu consentimento.
                  </li>
                  <li>
                    O <b>PRIMEIRO OUTORGANTE</b> será o responsável pelo
                    tratamento dos dados do <b>SEGUNDO OUTORGANTE</b>
                    cujo processamento de dados será interno ficando o{" "}
                    <b>PRIMEIRO OUTORGANTE</b> expressamente autorizada a caso
                    assim o entenda efetuar esse processamento externamente.
                  </li>
                  <li>
                    O <b>PRIMEIRO OUTORGANTE</b> fará o tratamento de dados com
                    a finalidade de gestão económica e contabilística, gestão
                    fiscal, gestão administrativa, gestão de faturação, gestão
                    de clientes, gestão de cobranças e pagamentos, gestão de
                    fornecedores e histórico de relações comerciais.
                  </li>
                  <li>
                    Os dados pessoais incluídos no registo são o nome, morada,
                    contactos telefónicos e endereços eletrónicos, data de
                    nascimento, género, naturalidade, nacionalidade,
                    identificação civil, identificação e enquadramento fiscal e
                    perante a segurança social, autorização de residência,
                    passaporte, currículo profissional, profissão, habilitações
                    académicas, formação profissional e experiência anterior.
                  </li>
                  <li>
                    O <b>PRIMEIRO OUTORGANTE</b> vai comunicar ou transferir em
                    parte ou na sua totalidade os dados pessoais do
                    <b>SEGUNDO OUTORGANTE</b> a entidades públicas e ou privadas
                    sempre que tal decorra de obrigação legal e ou seja
                    necessário para cumprimento deste ou outros contratos
                    ficando para tal expressamente autorizada pelo
                    <b>SEGUNDO OUTORGANTE</b>.
                  </li>
                  <li>
                    O <b>PRIMEIRO OUTORGANTE</b> vai conservar os dados do{" "}
                    <b>SEGUNDO OUTORGANTE</b> pelos prazos necessários a dar
                    cumprimento a obrigações legais designadamente de 10 (dez)
                    anos para cumprimento à obrigação legal de arquivo de toda a
                    documentação de escrita comercial.
                  </li>
                  <li>
                    O <b>SEGUNDO OUTORGANTE</b> poderá solicitar ao{" "}
                    <b>PRIMEIRO OUTORGANTE</b> e esta salvo impedimento legal
                    vai salvaguardar os direitos do <b>SEGUNDO OUTORGANTE</b> de
                    acesso aos dados pessoais que lhe digam respeito, bem como a
                    sua retificação ou o seu apagamento, e a limitação do
                    tratamento, e o direito de se opor ao tratamento, bem como
                    do direito à portabilidade dos dados. E ainda o direito de
                    retirar consentimento em qualquer altura, sem comprometer a
                    licitude do tratamento efetuado com base no cumprimento de
                    obrigações legais ou com base no consentimento previamente
                    dado. E também o direito de reclamação sobre o tratamento de
                    dados junto da Comissão Nacional de Proteção de Dados.
                  </li>
                  <li>
                    Tendo em conta as técnicas mais avançadas, os custos de
                    aplicação e a natureza, o âmbito, o contexto e as
                    finalidades do tratamento, bem como os riscos, de
                    probabilidade e gravidade variável, para os direitos e
                    liberdades das pessoas singulares, o{" "}
                    <b>PRIMEIRO OUTORGANTE</b> aplica as medidas técnicas e
                    organizativas adequadas para assegurar um nível de segurança
                    adequado ao risco, incluindo, consoante o que for adequado.
                  </li>
                  <li>
                    Em caso de violação de dados pessoais o{" "}
                    <b>PRIMEIRO OUTORGANTE</b> notifica esse facto à Comissão
                    Nacional de Proteção de Dados nos termos e condições
                    previstos na lei. Se essa violação for suscetível de
                    implicar um elevado risco para os direitos e liberdades do
                    titular comunica-lhe esse facto, nos termos e condições
                    previstos na lei.
                  </li>
                  <li>
                    Especificamente nas componentes de formação a distância
                    síncrona, que se realizam no formato de videoconferência, o{" "}
                    <b>SEGUNDO OUTORGANTE</b> desde já aceita disponibilizar a
                    gravação dessas sessões na plataforma LMS, para partilha e
                    revisionamento posterior, por parte dos formandos ou
                    terceiros, apenas para fins de aprendizagem ou auditoria.
                  </li>
                </ol>
              </div>
              <div>
                <h2>Cláusula 7ª</h2>
                <ol>
                  <li>
                    O presente contrato não confere ao <b>SEGUNDO OUTORGANTE</b>{" "}
                    a qualidade de trabalhador/a do <b>PRIMEIRO OUTORGANTE</b>.
                  </li>
                  <li>
                    Em consequência do disposto no no 1, o{" "}
                    <b>SEGUNDO OUTORGANTE</b> não tem direito a férias, subsídio
                    de férias ou Natal, subsídio de refeição ou quaisquer outros
                    subsídios ou prestações complementares, sendo que é da sua
                    exclusiva responsabilidade a sua situação perante a
                    Administração Fiscal e Segurança Social.
                  </li>
                </ol>
              </div>

              <div>
                <h2>Cláusula 8ª</h2>
                <p>
                  O presente contrato pode cessar, sem necessidade de aviso
                  prévio quando os <b>OUTORGANTES</b> não cumpram as obrigações
                  nele assumidas.
                </p>
              </div>

              <div style={{ breakInside: "avoid" }}>
                <h2>Cláusula 9ª</h2>
                <p>
                  O presente contrato entra em vigor com a data das assinaturas
                  e vigorará durante o período estabelecido para a execução da
                  formação agora contratualizados e descritos na Cláusula 1ª.
                </p>
                <br />
                <p>
                  O presente contrato é feito em duplicado fincando cada uma das
                  partes na posse de um exemplar.
                </p>
                <table className="localData">
                  <tbody>
                    <tr>
                      <th>Local:</th>
                      <td>
                        <input
                          className="w-full input-field"
                          type="text"
                          name="inputLocal"
                          defaultValue={
                            valores.local
                          }
                          placeholder="ex: Porto"
                          onChange={(e) => {
                            setValores({
                              ...valores,
                              local: AlterarFormato(e.target.value),
                            });
                            handleInputChangeLocal(e);
                          }}
                          style={{
                            border: inputErrors.inputLocal
                              ? "3px solid red"
                              : "1px solid black",
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Data:</th>
                      <td>
                        <input
                          className="w-full input-field"
                          type="text"
                          name="inputDate"
                          defaultValue={
                            valores.date                          }
                            placeholder="12/12/2023"
                          onChange={(e) => {
                            setValores({
                              ...valores,
                              date: AlterarFormato(e.target.value),
                            });
                            handleInputChangeLocal(e);
                          }}
                          style={{
                            border: inputErrors.inputDate
                              ? "3px solid red"
                              : "1px solid black",
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    gap: "30px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      width: "50%",
                    }}
                  >
                    <h4 style={{ textAlign: "center" }}>
                      O PRIMEIRO OUTORGANTE
                    </h4>
                    <div
                      style={{
                        minHeight: "200px",
                        borderBottom: "1px solid black !important",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        alt="assinatura"
                        style={{ margin: "5px auto" }}
                        id="img"
                        className="img"
                        src={data.percurso[0].entFormadora[0].assinatura}
                        height="70"
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      width: "50%",
                    }}
                  >
                    <h4 style={{ textAlign: "center" }}>
                      O SEGUNDO OUTORGANTE
                    </h4>
                    <div
                      style={{
                        minHeight: "200px",
                        borderBottom: "1px solid black !important",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
              {data.percurso[0].entFinanciadora &&
                data.percurso[0].entFinanciadora.length > 0 &&
                data.percurso[0].entFinanciadora[0].logotipo2 && (
                  <div>
                    <img
                      src={data.percurso[0].entFinanciadora[0].logotipo2}
                      className="h-20"
                      alt="logo"
                      style={{ marginLeft: "20px" }}
                    />
                  </div>
                )}
            </div>
            <div className="flex justify-center w-full">
              <ConfirmToaster
                css={"mx-auto mt-4 button-geral"}
                icon={"Enviar Contrato"}
                title={"Enviar Contrato"}
                message={"Confirma o envio do contrato?"}
                confirm={enviarContratoFormador}
              />
              <ConfirmToaster
                css={"mx-auto mt-4 button-geral"}
                icon={"Download Contrato"}
                title={"Download Contrato"}
                message={"Confirma o download do contrato?"}
                confirm={enviarContratoFormadorNoEmail}
              />
            </div>
          </ContratoFormador>
          {errorMessage && (
            <div className="text-red-500 text-center mt-4">{errorMessage}</div>
          )}
        </>
      )}
    </>
  );
}
