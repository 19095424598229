import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom'
import NavbarAdmin1 from "../Navbars/NavbarAdmin1/NavbarAdmin1";
import NavbarAdmin2 from "../Navbars/NavbarAdmin2/NavbarAdmin2";
import Footer from "../Footer";
import toastSuccess from "../Toast/toastSuccess";
import ConfirmToaster from "../Toast/toastConfirmChoice";
import { IoMdRemoveCircle } from "react-icons/io";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import Cookies from "js-cookie";
import MultiSelectDropdown from "../FormChecks/MultipleSelectSearch/multipleSelectSearch";
import toastError from "../Toast/toastError";

function AllCategorias(token) {
    const [data, setData] = useState(null);
    const [dataToDisplay, setDataToDisplay] = useState([])
    const [adminType, setAdminType] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [entFormadora, setEntFormadora] = useState("");
    const [entFormadoraList, setEntFormadoraList] = useState(""); const savedSearchTerm = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Admin-categoria-1") : "";
    const [searchTerm, setSearchTerm] = useState(savedSearchTerm ? savedSearchTerm : "");
    const savedPagina = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Admin-categoria-Pag") : ""
    const [nPaginas, setNumPaginas] = useState(1);
    const [cont, setCont] = useState(0);
    const [paginaAtual, setPaginaAtual] = useState(savedPagina ? parseInt(savedPagina) : 1);
    const savedItemsPag = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get(`Admin-categoria-itm1`) : ""
    const [itemsPag, setItemsPag] = useState(savedItemsPag ? savedItemsPag : 10)
    const params = useParams();
    const navigate = useNavigate();
    //filtra dados
    function filterData(data) {
        return data.filter((item) =>
            item.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }

    useEffect(() => {
        Cookies.set("Admin-categoria-1", searchTerm);
        Cookies.set("Admin-categoria-Pag", paginaAtual);
        Cookies.set(`Admin-categoria-itm1`, itemsPag)
        getCategorias()
        if (entFormadoraList.length === 0) getEntidadesFormadoras()
    }, [loading, cont, paginaAtual, nPaginas, searchTerm, itemsPag]);

    //Atribuir numero de páginas
    function paginacaoTabelas(data) {
        if (data.length < itemsPag) {
            setNumPaginas(1)
        } else if (data.length % itemsPag === 0) {
            setNumPaginas(data.length / itemsPag)
        } else {
            setNumPaginas(Math.ceil(data.length / itemsPag))
        }
        if (paginaAtual > nPaginas) {
            setPaginaAtual(nPaginas);
        }
        updatePagination(data) // n é o state por causa das tabelas
    }

    //Define que dados mostrar consoante a página em que está
    function updatePagination(data) {
        let maxIndex = (paginaAtual * itemsPag)
        let minIndex
        if (paginaAtual === 1) {
            minIndex = 0
        } else {
            minIndex = (paginaAtual - 1) * itemsPag;
            maxIndex = (paginaAtual * itemsPag);
        }
        setDataToDisplay(data.slice(minIndex, maxIndex))
    }

    //aumentar página atual
    function increasePagination() {
        if (paginaAtual < nPaginas) {
            setPaginaAtual(prevIndex => (prevIndex + 1))
            updatePagination(data)
        }
    }

    //diminuir página atual
    function decreasePagination() {
        if (paginaAtual > 1) {
            setPaginaAtual(prevIndex => (prevIndex - 1))
            updatePagination(data)
        }
    }
    function handleChange(value) {
        setEntFormadora(value)
    }

    function getEntidadesFormadoras() {
        if (token !== "admin1") {
            fetch(`${process.env.REACT_APP_API_URL}/EntFormadora`, {
                headers: {
                    'authorization': localStorage.getItem(token)
                },
                method: 'GET',
            })
                .then(response => response.json())
                .then(result => {
                    setEntFormadoraList(result)
                });
        }
    }
    async function getCategorias() {

        try {
            const tokenValue = localStorage.getItem(token);
            const headers = { "authorization": tokenValue };
            let categorias = [];
            let filteredCategorias = [];
            if (token === "admin1") {
                // Fetch admin-specific data
                const adminResponse = await fetch(
                    `${process.env.REACT_APP_API_URL}/admins/${params.id_candidatura}`,
                    { headers }
                );
                if (!adminResponse.ok) {
                    throw new Error(`Admin fetch failed: ${adminResponse.statusText}`);
                }
                const adminResult = await adminResponse.json();
                setAdminType(adminResult.nomeRole)
                setEntFormadoraList(adminResult.entFormadora)

                // Fetch categories
                const categoriasResponse = await fetch(
                    `${process.env.REACT_APP_API_URL}/Categoria`,
                    { headers }
                );
                if (!categoriasResponse.ok) {
                    throw new Error(`Categorias fetch failed: ${categoriasResponse.statusText}`);
                }
                categorias = await categoriasResponse.json();
                // Filter categories based on admin data
                filteredCategorias = categorias.filter((item) =>
                    item.entFormadora[0] === adminResult.entFormadora[0]?._id
                );
            } else {
                // Fetch categories for non-admin
                const categoriasResponse = await fetch(
                    `${process.env.REACT_APP_API_URL}/Categoria`,
                    { headers }
                );
                if (!categoriasResponse.ok) {
                    throw new Error(`Categorias fetch failed: ${categoriasResponse.statusText}`);
                }
                categorias = await categoriasResponse.json();
                filteredCategorias = categorias; // No filtering for non-admin
            }

            // Update state with filtered data
            setData(filteredCategorias);
            paginacaoTabelas(filterData(filteredCategorias));
        } catch (error) {
            console.error("Error fetching data: ", error);
            setError(error);
        } finally {
            setLoading(false); // Stop loading indicator
        }
    }

    const handleClick = (event, item) => {
        // Check if the clicked element is a <td>
        if (event.target.tagName === 'TD') {
            // Check if the <td> has a 'clickable' class
            if (!event.target.classList.contains('categoria')) {
                // Navigate to the desired location
                // Replace 'destination' with your actual navigation logic
            }
            else navigate((token === "admin1" ? "/menuAdmin/" + params.id_candidatura : "/menuSuperAdmin") + "/categorias/" + item._id)
        }
    };
    function createCategorias() {
        const name = document.querySelector("#name").value.trim();

        // Ensure name is not empty before making the request
        if (!name) {
            console.error("Name is required.");
            toastError("Nome em falta")
            return;
        }
        if (!entFormadora) {
            console.error("Entidade Formadora is required.");
            toastError("Entidade Formadora em falta")
            return;
        }

        fetch(`${process.env.REACT_APP_API_URL}/Categoria`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem(token) // Wrap token name in quotes
            },
            body: JSON.stringify({ name, entFormadora }) // Use the captured name variable
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`HTTP error! Status: ${res.status}`);
                }
                return res.json();
            })
            .then((result) => {
                setCont((prevCont) => prevCont + 1); // Use callback form to update state
                setLoading(false); // Ensure loading state is updated
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setError(error); // Handle error state
                setLoading(false); // Ensure loading state is updated on error
            });
    }

    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    const handleItemsPerPageChange = (newItemsPag) => {
        const newNumPaginas = Math.ceil(data.length / newItemsPag);
        const newPaginaAtual = paginaAtual > newNumPaginas ? newNumPaginas : paginaAtual;
        setItemsPag(newItemsPag);
        setNumPaginas(newNumPaginas);
        setPaginaAtual(newPaginaAtual);
        updatePagination(dataToDisplay);
    };


    //Elimina uma ufcd pelo seu id
    function deleteCategoria(_id) {
        fetch(`${process.env.REACT_APP_API_URL}/Categoria/${_id}`, {
            method: 'DELETE',
            headers: {
                "authorization": localStorage.getItem(token)
            }
        }).then((result) => {
            result.json().then((resp) => {
                toastSuccess('UFCD removida com sucesso!')
                setCont(cont + 1)
            })
        })
    }

    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";
    //passa para a ultima página
    function ultimaPagina() {
        if (paginaAtual !== nPaginas) {
            setPaginaAtual(nPaginas);
            updatePagination(data);
        }
    }
    //passa para a primeira página
    function primeiraPagina() {
        if (paginaAtual !== 1) {
            setPaginaAtual(1);
            updatePagination(data);
        }
    }
    return (
        <>
            <main className="min-h-screen">
                {token === "admin2" ? <div className="w-full">
                    <NavbarAdmin2 currentPage={"manageCategoriasAdmin"} />
                </div> : <div className="w-full">
                    <NavbarAdmin1 currentPage={"manageCategoriasAdmin"} />
                </div>
                }
                <div className="flex-grow mt-8">
                    <Link to={'/menuadmin/' + params.id_candidatura} className="back-button-div-noNavbar">
                        <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                    </Link>
                    <h1 className="title">Lista de Categorias</h1>
                    {data && data.length > 0 ? <>
                        <div className="search-div">
                            <label className="search-input-label">Pesquisar: </label>
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                                    <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                    </svg>
                                </div>
                                <input type="text" className="search-input" placeholder="ex: Nome, Código..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                            </div>
                        </div>
                        <div className="flex justify-center items-center mb-1">
                            <label className="text-text-main-color">Dados por página: </label>
                            <select onWheel={(e) => e.target.blur()} className="selector w-14 ml-1"
                                value={itemsPag}
                                onChange={(e) => handleItemsPerPageChange(parseInt(e.target.value))}>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                            </select>
                        </div>
                        <div className="table-container">
                            <table className="my-table">
                                <thead className="bg-main-color">
                                    <tr className="text-white h-14">
                                        <th className="p-2 min-w-[150px] border border-white">Nome</th>
                                        {(token==="admin2"||( adminType === "Administrador - Diretor de Departamento" || adminType === "Gestor Financeiro"))&&<th className="p-2 min-w-[80px] border border-white">Ações</th>}
                                    </tr>
                                </thead>
                                {data.length > 0 ? (
                                    <tbody className="text-text-main-color">
                                        {filterData(dataToDisplay).map((item, index) => (
                                            <tr key={index} className="row-click" onClick={(e) => handleClick(e, item)} >
                                                <td className="categoria border border-white ufcd">{item.name}</td>
                                                <td className="border border-white">
                                                    <ConfirmToaster css={"button-geral mt-3 hover:brightness-75"} icon={<IoMdRemoveCircle size={24} />} title={"Remover"} message={"Tem a certeza que deseja eliminar a Categoria? (Se a eliminá-la perderá o acesso a esta.)"} confirm={deleteCategoria} id={item._id} />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                ) : (
                                    <tbody></tbody>
                                )}
                            </table>
                        </div>
                        <div className="flex items-center justify-center mt-4 mb-8">
                            <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => primeiraPagina()}>
                                <AiOutlineDoubleLeft />
                            </button>
                            <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => decreasePagination()}>
                                <AiOutlineLeft />
                            </button>
                            <p className="text-main-color">Página {paginaAtual} - {nPaginas}</p>
                            <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => increasePagination()}>
                                <AiOutlineRight />
                            </button>
                            <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => ultimaPagina()}>
                                <AiOutlineDoubleRight />
                            </button>
                        </div>
                    </> : <p className="third-title">Não existem dados</p>}
                    {(token==="admin2"||( adminType === "Administrador - Diretor de Departamento" || adminType === "Gestor Financeiro"))&&<div className="mb-8">
                        <h2 className="subtitle">Adicionar Categoria</h2>
                        <div className="flex flex-col mx-auto mt-4">
                            <label className="mt-3 label-input">Nome</label>
                            <input type="text" id="name" name="name" className="input-field" required />
                            <label className="mt-3 label-input">Entidade Formadora</label>
                            <MultiSelectDropdown data={entFormadoraList} changer={handleChange} width={"w-500"} />
                            <div>
                                <button onClick={createCategorias} className="mt-3 button-geral" value="+">Submeter</button>
                            </div>
                        </div>
                    </div>}
                </div>
                <div className="w-full mt-3 sm:bottom-0 sm:mt-0">
                    <Footer />
                </div>
            </main>
        </>
    );
}

export default AllCategorias;
