import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';
import DateTimeDifference from '../../FormChecks/DateTimeDifference/dateTimeDiff';

async function ExcelUFCDs(token, admin) {
    const alignment = {
        horizontal: 'center',
        vertical: 'middle',
        wrapText: true,
    };

    const workbook = new ExcelJS.Workbook();

    function getColumnLetterFromIndex(index) {
        const columnLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let columnName = '';

        while (index > 0) {
            const remainder = (index - 1) % 26;
            columnName = columnLetters[remainder] + columnName;
            index = Math.floor((index - 1) / 26);
        }

        return columnName;
    }

    function setMultipleColWidth(worksheet, startCol, endCol, width) {
        for (let col = startCol; col <= endCol; col++) {
            worksheet.getColumn(getColumnLetterFromIndex(col)).width = width;
        }
    }

    const convertDateFormat2 = (originalDate) => {
        const dateArray = originalDate.split('-');
        return `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;
    };

    function camelCaseConverter(name) {
        const sanitizedName = name.replace(/[*?:\\/[\]]/g, '');
        return sanitizedName.replace(/\s+(\w)/g, (_, letter) => letter.toUpperCase());
    }

    async function getPercursos() {
        const apiUrl = `${process.env.REACT_APP_API_URL}/percurso/getCursosEventos`;
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'authorization': localStorage.getItem(token)
        };

        const response = await fetch(apiUrl, { headers });
        const result = await response.json();
        let allresults = [];
        const entidadesFormadoras = {};
        const operacoes = {};

        if (token === "admin1" || token === "admin2") {

            if (admin) {
                allresults = result.filter(element =>
                    admin.entFormadora.some(entFormadora =>
                        element.entFormadora[0]?._id?.toString() === entFormadora._id?.toString()
                    )
                );
            } else {
                allresults = [...result];
            }


            allresults.forEach(element => {
                const entFormadora = element.entFormadora[0];
                const operacao = element.operacao[0];

                if (entFormadora && !entidadesFormadoras[entFormadora._id]) {
                    entidadesFormadoras[entFormadora._id] = {
                        entFormadora: entFormadora._id,
                        name: entFormadora.name,
                    };
                }

                if (operacao && !operacoes[operacao._id]) {
                    operacoes[operacao._id] = {
                        operacao: operacao._id,
                        codigoOperacao: operacao.codigoOperacao,
                    };
                }
            });

            dadosUfcds(allresults);
        }
    }


    function getFormandosCertificados(element, percurso) {
        let alunosCerti = 0;
        let volumeCerti = 0
        let volumeTotal = 0
        const formandos = element.formandos;
        const totalAulasSinc = element.eventos.reduce((total, event) => {
            return total + DateTimeDifference(event.start, event.end);
        }, 0);
        if (element.fechoCoordenador) {
            formandos.forEach((formando) => {
                if (formando.valid === "Validado" && formando.pauta && formando.pauta.length > 0) {
                    const index = formando.pauta.findIndex((item) => item.idUfcd === element._id.toString());
                    if (index !== -1) {
                        const pauta = formando.pauta[index];
                        const totalhoraspercent = (Math.floor((((pauta.assiduidade / 100) * (parseInt(element.cargaHoraria) - totalAulasSinc)) + pauta.sincrona) * 100) / parseInt(element.cargaHoraria))
                        if (element.formato === "Presencial") {
                            const percentSincrona = (100 * parseInt((pauta.sincrona))) / parseInt(element.cargaHoraria);
                            if (percurso.operacao[0].tipologia === "Formação Empresarial Conjunta e Formação Ação" && percentSincrona >= 80) {
                                alunosCerti++;
                                if (pauta.notaFinal && pauta.notaFinal >= 9.5) volumeCerti = volumeCerti + pauta.sincrona
                                volumeTotal = volumeTotal + pauta.sincrona
                            } else if (percentSincrona >= 90) {
                                alunosCerti++;
                                volumeCerti = volumeCerti + pauta.sincrona
                                volumeTotal = volumeTotal + pauta.sincrona
                            } else volumeTotal = volumeTotal + pauta.sincrona
                        } else if (
                            element.formato !== "Presencial"
                        ) {
                            if (percurso.operacao[0].tipologia === "Formação Empresarial Conjunta e Formação Ação" && totalhoraspercent >= 80) {
                                alunosCerti++;
                                if (pauta.media && pauta.media - (pauta.forumTotal - pauta.forum) >= 9.5) volumeCerti = volumeCerti + pauta.sincrona + pauta.assincrona
                                volumeTotal = volumeTotal + pauta.sincrona + pauta.assincrona
                            } else if (totalhoraspercent >= 90) {
                                alunosCerti++;
                                if (pauta.media &&totalhoraspercent >= 90 && pauta.media - (pauta.forumTotal - pauta.forum) >= 9.5)volumeCerti = volumeCerti + pauta.sincrona + pauta.assincrona
                                volumeTotal = volumeTotal + pauta.sincrona + pauta.assincrona
                            } else volumeTotal = volumeTotal + pauta.sincrona + pauta.assincrona
                        }
                    }
                }
            });
        }

        return { alunosCerti: alunosCerti, volumeCerti: volumeCerti, volumeTotal: volumeTotal };
    }

    function dadosUfcds(data) {
        const worksheet = workbook.addWorksheet(camelCaseConverter("Disciplinas"));
        setMultipleColWidth(worksheet, 1, 28, 20);

        const ufcdsTotal = [];
        data.forEach(percurso => {
            const ufcds = percurso.ufcds.filter(ufcd => ufcd.name !== "PRA");
            const jsonData = ufcds.map(element => ({
                NomeDisciplina: element.name,
                CodeDisciplina: element.codeUfcd,
                Horario: percurso.horario,
                DateInicio: element.dateBegin,
                DateFim: element.dateEnd,
                CargaHoraria: element.cargaHoraria,
                Formador: element.formadores,
                FormandosInscritos: element.formandos.filter(formando =>
                    (formando.valid === "Validado" || formando.valid === "Desistiu") &&
                    (formando.equivalencia.length === 0 || formando.equivalencia.every(equivalencia => equivalencia.idUfcd !== element._id)) &&
                    (formando.nInscricao.length === 0 || formando.nInscricao.every(nInscricao => nInscricao.idUfcd !== element._id))
                ).length,
                FormandosCertificados: getFormandosCertificados(element, percurso).alunosCerti,
                FormandosEncaminhados: element.formandos.filter(item => {
                    return (item.valid === "Validado" || item.valid === "Desistiu") &&
                        (item.desistencia.length === 0 || item.desistencia.every(desistencia => desistencia.idUfcd !== element._id)) &&
                        (item.equivalencia.length === 0 || item.equivalencia.every(equivalencia => equivalencia.idUfcd !== element._id)) &&
                        (item.nInscricao.length === 0 || item.nInscricao.every(nInscricao => nInscricao.idUfcd !== element._id)) &&
                        item.encCQ === "Validado"
                }).length,
                Volume: getFormandosCertificados(element, percurso).volumeTotal,
                VolumeCerti: getFormandosCertificados(element, percurso).volumeCerti,
                Estado: element.fechoCoordenador ? "Concluido" : "A decorrer",
                Operacao: percurso.operacao[0].codigoOperacao,
                OperacaoTipologia: percurso.operacao[0].tipologia && percurso.operacao[0].tipologia,
                EntFomadora: percurso.entFormadora[0]?.name || "",
                Coordenador: percurso.Coordenador[0]?.fullName || "",
                Nivel: percurso.nivel,
                CodeSIGO: percurso.codeSIGO,
                CodeInterno: percurso.codeInterno,
                NomeCurso: percurso.name,
            }));
            ufcdsTotal.push(...jsonData);
        });

        const headers = [
            'Operação', 'Tipologia', 'Entidade Formadora', 'Nome do Curso/Percurso', 'Código Interno', 'Código SIGO',
            'Código da Disciplina', 'Nome da Disciplina', 'Horário', 'Data de Início', 'Data de Fim', 'Carga Horária',
            'Nível', 'Formador/s', 'Coordenador', 'Número de Formandos Inscritos', 'Número de Formandos Encaminhados', 'Número de Formandos Certificados', 'Volume', 'Volume Certificados', 'Estado da Disciplina'
        ];

        headers.forEach((header, index) => {
            const cell = worksheet.getCell(`${getColumnLetterFromIndex(index + 1)}1`);
            cell.value = header;
            cell.alignment = alignment;
        });

        ufcdsTotal.forEach((element, i) => {
            const row = i + 2;
            worksheet.getCell(`A${row}`).value = element.Operacao;
            worksheet.getCell(`B${row}`).value = element.OperacaoTipologia;
            worksheet.getCell(`C${row}`).value = element.EntFomadora;
            worksheet.getCell(`D${row}`).value = element.NomeCurso;
            worksheet.getCell(`E${row}`).value = element.CodeInterno;
            worksheet.getCell(`F${row}`).value = element.CodeSIGO;
            worksheet.getCell(`G${row}`).value = element.CodeDisciplina;
            worksheet.getCell(`H${row}`).value = element.NomeDisciplina;
            worksheet.getCell(`I${row}`).value = element.Horario;
            worksheet.getCell(`J${row}`).value = convertDateFormat2(element.DateInicio);
            worksheet.getCell(`K${row}`).value = convertDateFormat2(element.DateFim);
            worksheet.getCell(`L${row}`).value = element.CargaHoraria;
            worksheet.getCell(`M${row}`).value = element.Nivel;
            worksheet.getCell(`N${row}`).value = element.Formador.map(f => f.fullName).join(', ');
            worksheet.getCell(`O${row}`).value = element.Coordenador;
            worksheet.getCell(`P${row}`).value = element.FormandosInscritos;
            worksheet.getCell(`Q${row}`).value = element.FormandosEncaminhados;
            worksheet.getCell(`R${row}`).value = element.FormandosCertificados;
            worksheet.getCell(`S${row}`).value = element.Volume;
            worksheet.getCell(`T${row}`).value = element.VolumeCerti;
            worksheet.getCell(`U${row}`).value = element.Estado;
        });
    }

    await getPercursos();

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    FileSaver.saveAs(blob, 'UFCDs.xlsx');
}

export default ExcelUFCDs;
