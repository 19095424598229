import NavbarAdmin1 from "../../Navbars/NavbarAdmin1/NavbarAdmin1";
import NavbarAdmin2 from "../../Navbars/NavbarAdmin2/NavbarAdmin2";
import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Footer from "../../Footer";
import DateTimeDifference from "../../FormChecks/DateTimeDifference/dateTimeDiff";
import MultiSelectDropdown from "../../FormChecks/MultipleSelectSearch/multipleSelectSearch";
import toastSuccess from "../../Toast/toastSuccess";
import ConfirmToaster from "../../Toast/toastConfirmChoice";
import { IoMdRemoveCircle } from "react-icons/io";
import BuildQuery from "../../FormChecks/buildQuery/buildQuery";

function CategoriaIndividual(token) {
    const [data, setData] = useState(null);

    const [adminType, setAdminType] = useState(null);

    const [entFormadora, setEntFormadora] = useState("");
    const [percursosSelected, setPercursosSelected] = useState("");
    const [entFormadoraList, setEntFormadoraList] = useState("");
    const [percursoList, setPercursoList] = useState("");

    const [formandosValidados, setFormandosValidados] = useState(null);
    const [formandosFinalizados, setFormandosFinalizados] = useState(null);

    const [cont, setCont] = useState(1);

    const [alterarDados, setAlterarDados] = useState(-1);

    const [formandosEncValidados, setEncValidados] = useState(null);
    const [formandosEncFinalizados, setEncFinalizados] = useState(null);

    const [contTotalFormandos, setContTotalFormandos] = useState(0);
    const [cont5e4Formandos, setCont5e4Formandos] = useState(0);

    const [contTotalFormadores, setContTotalFormadores] = useState(0);
    const [cont5e4Formadores, setCont5e4Formadores] = useState(0);

    const [contTotalCoordenadores, setContTotalCoordenadores] = useState(0);
    const [cont5e4Coordenadores, setCont5e4Coordenadores] = useState(0);

    const [formandosValidadosFiltrados, setFormandosValidadosFiltrados] = useState(null);
    const [formandosEncFiltrados, setFormandosEncFiltrados] = useState(null);

    const [volumeTotal, setVolumeTotal] = useState(null);
    const [taxaCertificacao, setTaxaCertificacao] = useState(null);

    const [volumeDates, setVolumeDates] = useState(null);
    const [taxaCertificacaoDates, setTaxaCertificacaoDates] = useState(null);

    const [formandosDesistentesDate, setFormandosDesistentesDate] = useState(null);

    const [desempenhoFormadores, setDesempenhoFormadores] = useState(null);
    const [desempenhoCoordenadores, setDesempenhoCoordenadores] = useState(null);

    const [percursos, setPercursos] = useState(null);
    const [dateBegin, setDateBegin] = useState(null);
    const [dateEnd, setDateEnd] = useState(null);
    const [loading, setLoading] = useState(true);

    const params = useParams();

    useEffect(() => {
        getCategoria()
        getAdmin()
        if (entFormadoraList.length === 0) getEntidadesFormadoras()
    }, [loading, cont, alterarDados, dateBegin, dateEnd]);
    //Obtem percurso
    // Helper to build query strings

    function handleChange(value) {
        setPercursosSelected(value)
    }

    function getAdmin() {
        if (token === "admin1") {
            fetch(`${process.env.REACT_APP_API_URL}/admins/${params.id_candidatura}`, {
                headers: {
                    "authorization": localStorage.getItem(token)
                }
            })
                .then((res) => res.json())
                .then(
                    (result) => {
                        setAdminType(result.nomeRole)
                    },
                    (error) => {
                    }
                );
        }
    }

    async function fetchData(url, options = {}) {
        try {
            const response = await fetch(url, options);
            if (!response.ok) throw new Error(`Error: ${response.statusText}`);
            return await response.json();
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async function getCategoria() {
        const idCategoria = params.id_categoria; // Replace with actual ID source

        if (!token || !idCategoria) {
            console.error("Token or Category ID is missing");
            return;
        }

        const headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: localStorage.getItem(token),
        };

        try {
            // Fetch main category data
            const query = BuildQuery({ populate: ["percursos", "entFormadora"], subPopulate: ["percursos.ufcds", "percursos.formandos", "percursos.formadores", "percursos.entFinanciadora"] });
            const query2 = BuildQuery({ populate: ["percurso"] });
            const categoryUrl = `${process.env.REACT_APP_API_URL}/Categoria/${idCategoria}?${query}`;
            const categoryData = await fetchData(categoryUrl, { headers });

            setData(categoryData);
            setLoading(false);

            const entFormadoraUrl = `${process.env.REACT_APP_API_URL}/EntFormadora/${categoryData.entFormadora[0]._id}?${query2}`;
            const entFormadoraData = await fetchData(entFormadoraUrl, { headers });

            // Obtenha os percursos existentes no categoryData
            const existingPercursos = categoryData.percursos || [];

            // Filtre os percursos de entFormadoraData para incluir apenas aqueles que não existem em existingPercursos
            const filteredPercursos = entFormadoraData.percurso.filter(
                (percurso) => !existingPercursos.some((existing) => existing._id === percurso._id)
            );
            // Atualize a lista com os percursos filtrados
            setPercursoList(filteredPercursos);

            // Process category data
            const percursos = categoryData.percursos || [];
            setPercursos(existingPercursos);

            if (filteredPercursos.length > 0) {
                processPercursos(percursos);
            }
        } catch (error) {
            console.error("Error fetching category data:", error);
        }
    }

    // Process percursos
    function processPercursos(result) {
        // Example date processing
        const firstCourseDate = result
            .map(p => new Date(p.dateBegin))
            .sort((a, b) => a - b)[0];

        const dataInicio = document.getElementById("dataInicio")?.value
            ? new Date(document.getElementById("dataInicio").value)
            : null;
        const dataFim = document.getElementById("dataFim")?.value
            ? new Date(document.getElementById("dataFim").value)
            : null;

        const percursosFiltred = result.filter(percurso => {
            const dateBegin = new Date(percurso.dateBegin);
            const dateEnd = new Date(percurso.dateEnd);
            return (!dataInicio || dateBegin >= dataInicio) && (!dataFim || dateEnd <= dataFim);
        });

        if (!dataInicio && firstCourseDate) setDateBegin(firstCourseDate);
        // Indicadores
        {
            //Formandos
            {  // Collect all formandos that are valid from each element
                const formandosValidados = result.map(element =>
                    element.formandos.filter((item) => item.valid === "Validado")
                ).flat(); // Flatten the array since filter returns arrays

                const formandosFinalizados = result
                    .filter(element => element.fechoAdmin === true) // or simply 'element.fechoAdmin' if it's a boolean
                    .map(element =>
                        element.formandos.filter((item) =>
                            item.valid === "Validado" && item.pauta?.notafinal >= 10
                        )
                    )
                    .flat();

                // Collect all formandos that are valid from each element
                const formandosEncValidados = result.map(element =>
                    element.formandos.filter((item) => item.valid === "Validado" && item.encCQ === "Validado")
                ).flat(); // Flatten the array since filter returns arrays


                const formandosEncFinalizados = result
                    .filter(element => element.fechoAdmin === true) // or simply 'element.fechoAdmin' if it's a boolean
                    .map(element =>
                        element.formandos.filter((item) =>
                            item.valid === "Validado" && item.encCQ === "Validado" && item.pauta?.notafinal >= 10
                        )
                    )
                    .flat();

                setEncValidados(formandosEncValidados)

                setEncFinalizados(formandosEncFinalizados)
                // Update the state with the valid formandos
                setFormandosValidados(formandosValidados);
                setFormandosFinalizados(formandosFinalizados)
            }
            //Taxa Certificação
            {
                let totalFormandos = 0; // Total formandos across all percursos
                let totalFormandosAprov = 0; // Total aprovados across all percursos

                result.forEach(percurso => {
                    let percursoFormandos = 0;
                    let totalAlunos = 0;
                    percurso.ufcds.forEach(disciplina => {
                        if (disciplina.fechoCoordenador) {
                            // Get data for this disciplina only once
                            const formandosData = getFormandosCertificados(disciplina, percurso);
                            percursoFormandos += formandosData.alunosCerti || 0;
                            totalAlunos += formandosData.totalAlunos.length || 0;
                        }
                    });

                    // Accumulate results for this percurso into global totals
                    totalFormandosAprov += percursoFormandos;
                    totalFormandos += totalAlunos;

                });

                // Calculate and set the certification rate
                const taxaCertificacao = totalFormandos > 0
                    ? Math.round((totalFormandosAprov * 100) / totalFormandos)
                    : 0;
                setTaxaCertificacao(taxaCertificacao);
            }

            //Volume
            {
                let totalVolume = 0; // Total volume across all percursos
                let totalFormandos = 0; // Total formandos across all percursos
                result.forEach(percurso => {
                    let percursoVolume = 0;
                    let percursoFormandos = 0
                    percurso.ufcds.forEach(disciplina => {
                        const certificados = getFormandosCertificados(disciplina, percurso);
                        percursoVolume += certificados.volumeTotal; // Acumular volume para o percurso
                    });
                    // Accumulate the results for this percurso into the global totals
                    totalVolume += percursoVolume;
                    totalFormandos += percursoFormandos;
                });
                setVolumeTotal({ volume: totalVolume, formandos: totalFormandos })
            }
        }
        // Análise
        {
            //Forms de Satisfação dos Formandos
            {
                const resultados5e4AvaSatisTotal = percursosFiltred.map(percurso =>
                    // Filter the "percursoFiltrado" based on the date range
                    percurso.ufcds.map(element =>
                        element.avaliacaoSatisfacaoFormandosResults.length * 8
                    )
                )
                    .flat(2) // Flatten nested arrays (double flattening because of nested maps)
                    .reduce((acc, curr) => acc + curr, 0); // Sum up the results
                setContTotalFormandos(resultados5e4AvaSatisTotal)

                const resultados5e4AvaSatisFormandos = percursosFiltred.map(percurso =>
                    // 
                    percurso.ufcds.map(percursoFiltrado =>
                        // Assuming 'avaliacaoSatisfacaoFormandosResults' is an array of objects
                        percursoFiltrado.avaliacaoSatisfacaoFormandosResults.map(criteriaObj => {
                            // Extract all the criterion values from the object
                            const criterios = Object.values(criteriaObj);
                            // Filter for valid values
                            return criterios.filter(criterio =>
                                ["5 - Muito Bom", "4 - Bom", 4, 5].includes(criterio)
                            ).length;
                        }).reduce((acc, curr) => acc + curr, 0) // Sum the filtered counts from each criteria object
                    )
                )
                    .flat() // Flatten the array to remove any nested arrays
                    .reduce((acc, curr) => acc + curr, 0); // Sum up the counts
                setCont5e4Formandos(resultados5e4AvaSatisFormandos)
            }

            //Forms de Satisfação dos Formadores
            {
                const resultados5e4AvaSatisTotal = percursosFiltred.map(percurso =>
                    // Filter the "percursoFiltrado" based on the date range
                    percurso.ufcds.map(element =>
                        element.avaliacaoSatisfacaoFormador.length * 8
                    )
                )
                    .flat(2) // Flatten nested arrays (double flattening because of nested maps)
                    .reduce((acc, curr) => acc + curr, 0); // Sum up the results
                setContTotalFormadores(resultados5e4AvaSatisTotal)

                const resultados5e4AvaSatisFormadores = percursosFiltred.map(percurso =>
                    // 
                    percurso.ufcds.map(percursoFiltrado =>
                        // Assuming 'avaliacaoSatisfacaoFormandosResults' is an array of objects
                        percursoFiltrado.avaliacaoSatisfacaoFormador.map(criteriaObj => {
                            // Extract all the criterion values from the object
                            const criterios = Object.values(criteriaObj);
                            // Filter for valid values
                            return criterios.filter(criterio =>
                                ["5 - Muito Bom", "4 - Bom", 4, 5].includes(criterio)
                            ).length;
                        }).reduce((acc, curr) => acc + curr, 0) // Sum the filtered counts from each criteria object
                    )
                )
                    .flat() // Flatten the array to remove any nested arrays
                    .reduce((acc, curr) => acc + curr, 0); // Sum up the counts
                setCont5e4Formadores(resultados5e4AvaSatisFormadores)
            }

            //Forms de Satisfação dos Coordenadores
            {
                const resultados5e4AvaSatisTotal = percursosFiltred.map(percurso =>
                    // Filter the "percursoFiltrado" based on the date range
                    percurso.ufcds.map(element =>
                        element.avaliacaoSatisfacaoCoordenador.length * 8
                    )
                )
                    .flat(2) // Flatten nested arrays (double flattening because of nested maps)
                    .reduce((acc, curr) => acc + curr, 0); // Sum up the results
                setContTotalCoordenadores(resultados5e4AvaSatisTotal)

                const resultados5e4AvaSatisCoordenadores = percursosFiltred.map(percurso =>
                    // 
                    percurso.ufcds.map(percursoFiltrado =>
                        // Assuming 'avaliacaoSatisfacaoFormandosResults' is an array of objects
                        percursoFiltrado.avaliacaoSatisfacaoCoordenador.map(criteriaObj => {
                            // Extract all the criterion values from the object
                            const criterios = Object.values(criteriaObj);
                            // Filter for valid values
                            return criterios.filter(criterio =>
                                ["5 - Muito Bom", "4 - Bom", 4, 5].includes(criterio)
                            ).length;
                        }).reduce((acc, curr) => acc + curr, 0) // Sum the filtered counts from each criteria object
                    )
                )
                    .flat() // Flatten the array to remove any nested arrays
                    .reduce((acc, curr) => acc + curr, 0); // Sum up the counts
                setCont5e4Coordenadores(resultados5e4AvaSatisCoordenadores)
            }

            //nºParticipantes
            {
                const formandosValidados = result
                    .map(element => element.formandos.filter(item => {
                        const inscricaoDate = item.dateInscricao ? new Date(item.dateInscricao) : new Date(element.dateBegin);
                        return item.valid === "Validado" &&
                            inscricaoDate >= new Date(dataInicio) &&
                            inscricaoDate <= new Date(dataFim);
                    }))
                    .flat(); // Flatten the array since filter returns arrays
                setFormandosValidadosFiltrados(formandosValidados);
            }

            // nºEncaminhados
            {
                const formandosEncValidados = result
                    .map(element => element.formandos.filter(item => {
                        const inscricaoDate = item.dateInscricao ? new Date(item.dateInscricao) : new Date(element.dateBegin);
                        return item.valid === "Validado" &&
                            item.encCQ === "Validado" &&
                            inscricaoDate >= new Date(dataInicio) &&
                            inscricaoDate <= new Date(dataFim);
                    }))
                    .flat(); // Flatten the array since filter returns arrays
                setFormandosEncFiltrados(formandosEncValidados);
            }

            //Taxa Certificação
            {
                let totalFormandos = 0; // Total formandos across all percursos
                let totalFormandosAprov = 0; // Total formandos across all percursos
                percursosFiltred.forEach(percurso => {
                    let percursoFormandos = 0;
                    let totalAlunos = 0;
                    percurso.ufcds.forEach(disciplina => {
                        if (disciplina.fechoCoordenador) {
                            // Get data for this disciplina only once
                            const formandosData = getFormandosCertificados(disciplina, percurso);
                            percursoFormandos += formandosData.alunosCerti || 0;
                            totalAlunos += formandosData.totalAlunos.length || 0;
                        }
                    });

                    // Accumulate results for this percurso into global totals
                    totalFormandosAprov += percursoFormandos;
                    totalFormandos += totalAlunos;
                });
                // Calculate and set the certification rate
                const taxaCertificacao = totalFormandos > 0
                    ? Math.round((totalFormandosAprov * 100) / totalFormandos)
                    : 0;
                setTaxaCertificacaoDates(taxaCertificacao)
            }

            //Volume
            {
                let totalVolume = 0; // Total volume across all percursos
                let totalVolumeCerti = 0; // Total formandos across all percursos
                percursosFiltred.forEach(percurso => {
                    let percursoVolume = 0;
                    let percursoVolumeCertificados = 0
                    percurso.ufcds.forEach(disciplina => {
                        const certificados = getFormandosCertificados(disciplina, percurso);
                        percursoVolume += certificados.volumeTotal; // Acumular volume para o percurso
                        percursoVolumeCertificados += certificados.volumeCerti; // Acumular formandos certificados
                    });
                    totalVolume = totalVolume + percursoVolume;
                    totalVolumeCerti = totalVolumeCerti + percursoVolumeCertificados
                });
                setVolumeDates({ volume: totalVolume, volumeCerti: totalVolumeCerti })
            }

            //nº Desistentes
            {
                const formandosDesis = percursosFiltred
                    .map(element => element.formandos.filter(item => item.valid === "Desistiu"))
                    .flat(); // Flatten the array since filter returns arrays

                const totalFormandos = percursosFiltred
                    .map(element => element.formandos.filter(item => item.valid !== "Por Validar"))
                    .flat(); // Flatten the array since filter returns arrays

                if (formandosDesis.length > 0) {
                    if (totalFormandos.length > 0) { // Avoid division by zero
                        const percentageDesistentes = (formandosDesis.length * 100) / totalFormandos.length;
                        setFormandosDesistentesDate(parseInt(percentageDesistentes));
                    }
                }
            }

            // Resultados avaliação dos Formadores
            {
                const resultadosAvaDesempenho = [];
                percursosFiltred.forEach(percurso => {
                    // Iterate over `ufcds` in each `percurso`
                    percurso.ufcds.forEach(element => {
                        // Ensure `evaluation` has entries
                        if (element.evaluation.length > 0) {
                            // Push `evaluationFinal` values into the array
                            element.evaluation.forEach(avaliacao => {
                                resultadosAvaDesempenho.push(avaliacao.evaluationFinal);
                            });
                        }
                    });
                });
                // Calculate the average if there are values in the array
                if (resultadosAvaDesempenho.length > 0) {
                    const total = resultadosAvaDesempenho.reduce((sum, value) => sum + value, 0);
                    const average = parseInt(total / resultadosAvaDesempenho.length);
                    setDesempenhoFormadores(average)
                }
            }

            // Resultados avaliação dos Coordenadores/Mediadores
            {
                const resultadosAvaDesempenho = [];
                percursosFiltred.forEach(percurso => {
                    // Iterate over `ufcds` in each `percurso`
                    if (percurso.evaluation) resultadosAvaDesempenho.push(percurso.evaluation)
                });
                // Calculate the average if there are values in the array
                if (resultadosAvaDesempenho.length > 0) {
                    const total = resultadosAvaDesempenho.reduce((sum, value) => sum + value, 0);
                    const average = parseInt(total / resultadosAvaDesempenho.length);
                    setDesempenhoCoordenadores(average)
                }
            }
        }
        // Process other metrics
        calculateIndicators(percursosFiltred);
    }

    // Calculate indicators
    function calculateIndicators(percursos) {
        // Validated Formandos
        const validatedFormandos = percursos
            .flatMap(p => p.formandos.filter(f => f.valid === "Validado"));
        setFormandosValidados(validatedFormandos);

        // Finalized Formandos
        const finalizedFormandos = percursos
            .filter(p => p.fechoAdmin)
            .flatMap(p => p.formandos.filter(f => f.valid === "Validado" && f.pauta?.notafinal >= 10));
        setFormandosFinalizados(finalizedFormandos);

        // Certification Rate
        let totalFormandos = 0;
        let totalApproved = 0;
        percursos.forEach(percurso => {
            percurso.ufcds.forEach(ufcd => {
                if (ufcd.fechoCoordenador) {
                    const { alunosCerti = 0, totalAlunos = [] } = getFormandosCertificados(ufcd, percurso);
                    totalApproved += alunosCerti;
                    totalFormandos += totalAlunos.length;
                }
            });
        });
        const certificationRate = totalFormandos > 0 ? Math.round((totalApproved * 100) / totalFormandos) : 0;
        setTaxaCertificacao(certificationRate);

        // Further processing (e.g., volumes, satisfaction, etc.)
    }

    function getEntidadesFormadoras() {
        if (token === "admin1") {
            fetch(`${process.env.REACT_APP_API_URL}/admins/${params.id_candidatura}`, {
                headers: {
                    "authorization": localStorage.getItem(token)
                }
            })
                .then((res) => res.json())
                .then(
                    (result) => {
                        if (token === "admin1") {
                            setEntFormadoraList(result.entFormadora)
                        }
                    },
                    (error) => {
                    }
                );
        } else {
            fetch(`${process.env.REACT_APP_API_URL}/EntFormadora`, {
                headers: {
                    'authorization': localStorage.getItem(token)
                },
                method: 'GET',
            })
                .then(response => response.json())
                .then(result => {
                    setEntFormadoraList(result)
                });
        }
    }

    function updateDadosCategoria() {
        const entidadeFormadora = document.getElementById("entFormadora") ? document.getElementById("entFormadora").value : null
        const name = document.getElementById("name") ? document.getElementById("name").value : null
        fetch(`${process.env.REACT_APP_API_URL}/Categoria/${params.id_categoria}`,
            {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json', "authorization": localStorage.getItem(token) },
                body: JSON.stringify({
                    name: name,
                    entFormadora: entidadeFormadora,
                })
            })
            .then((res) => res.json())
            .then(
                (result) => {
                    setAlterarDados(-1)
                },
                (error) => {
                }
            );
    }

    function updatePercursosCategoria() {
        fetch(`${process.env.REACT_APP_API_URL}/Categoria/${params.id_categoria}`,
            {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json', "authorization": localStorage.getItem(token) },
                body: JSON.stringify({
                    percursosIds: percursosSelected,
                })
            })
            .then((res) => res.json())
            .then(
                (result) => {
                    setCont(cont + 1)
                },
                (error) => {
                }
            );
    }

    function getFormandosCertificados(element, percurso) {
        let alunosCerti = 0;
        let volumeCerti = 0
        let volumeTotal = 0
        const formandos = element.formandos;
        const totalAulasSinc = element.eventos.reduce((total, event) => {
            return total + DateTimeDifference(event.start, event.end);
        }, 0);
        if (element.fechoCoordenador) {
            formandos.forEach((formando) => {
                if (formando.valid === "Validado" && formando.pauta && formando.pauta.length > 0) {
                    const index = formando.pauta.findIndex((item) => item.idUfcd === element._id.toString());
                    if (index !== -1) {
                        const pauta = formando.pauta[index];
                        const percentSincrona = (100 * parseInt(pauta.sincrona)) / parseInt(element.cargaHoraria);
                        const totalhoraspercent = (Math.floor((((pauta.assiduidade / 100) * (parseInt(element.cargaHoraria) - totalAulasSinc)) + pauta.sincrona) * 100) / parseInt(element.cargaHoraria))

                        if (element.formato === "Presencial") {
                            if (percurso.operacao[0].tipologia === "Formação Empresarial Conjunta e Formação Ação" && pauta.sincrona >= 80) {
                                alunosCerti++;
                                if (pauta.notaFinal && pauta.notaFinal >= 9.5) volumeCerti = volumeCerti + pauta.sincrona
                                volumeTotal = volumeTotal + pauta.sincrona
                            } else if (percentSincrona >= 90) {
                                alunosCerti++;
                                if (pauta.notaFinal && pauta.notaFinal >= 9.5) volumeCerti = volumeCerti + pauta.sincrona
                                volumeTotal = volumeTotal + pauta.sincrona
                            } else volumeTotal = volumeTotal + pauta.sincrona
                        } else if (
                            element.formato !== "Presencial"
                        ) {
                            if (percurso.operacao[0].tipologia === "Formação Empresarial Conjunta e Formação Ação" && totalhoraspercent >= 80) {
                                alunosCerti++;
                                if (pauta.media && pauta.media - (pauta.forumTotal - pauta.forum) >= 9.5) volumeCerti = volumeCerti + pauta.sincrona + pauta.assincrona
                                volumeTotal = volumeTotal + pauta.sincrona + pauta.assincrona
                            } else if (totalhoraspercent >= 90) {
                                alunosCerti++;
                                if (pauta.media && pauta.media - (pauta.forumTotal - pauta.forum) >= 9.5) volumeCerti = volumeCerti + pauta.sincrona + pauta.assincrona
                                volumeTotal = volumeTotal + pauta.sincrona + pauta.assincrona
                            } else volumeTotal = volumeTotal + pauta.sincrona + pauta.assincrona
                        }
                    }
                }
            });
        }
        return { alunosCerti: alunosCerti, totalAlunos: element.formandos.filter((item) => (item.valid === "Validado" || item.valid === "Desistiu")), volumeCerti: volumeCerti, volumeTotal: volumeTotal };
    }

    //Retira o percurso da categoria pelo seu id
    function removeOneOrMorePercursos(id) {
        fetch(`${process.env.REACT_APP_API_URL}/Categoria/removeOneOrMorePercursos/${params.id_categoria}`,
            {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json', "authorization": localStorage.getItem(token) },
                body: JSON.stringify({
                    percursosIds: [id],
                })
            }).then((result) => {
                result.json().then((resp) => {
                    toastSuccess('Percurso desassociado com sucesso')
                    setCont(cont + 1)
                })
            })
    }
    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );

    const route = token === "admin1" ? '/MenuAdmin/' + params.id_candidatura : "/MenuSuperAdmin"
    return (
        <>
            {data && <main className="min-h-screen">
                <div className="w-full">
                    {token === "admin1" && <NavbarAdmin1 currentPage={"manageCategoriasAdmin"} />}
                    {token === "admin2" && <NavbarAdmin2 currentPage={"manageCategoriasAdmin"} />}
                </div>
                <Link to={route + "/categorias"} className="back-button-div-noNavbar md:mt-5">
                    <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                </Link>
                {data && <div className="grid mt-20 gap-x-10 gap-y-5 sm:grid-cols-3">
                    <div className="text-center sm:text-left"><div className="text-xs uppercase text-text-second-color opacity-90">Nome: </div>
                        {alterarDados === -1 ? <div className="mt-2 text-lg text-text-main-color"> {data.name}</div> : <input className="input-field" type="text" defaultValue={data.name} id="name" />}</div>
                    <div className="text-center sm:text-left"><div className="text-xs uppercase text-text-second-color opacity-90">Entidade Formadora: </div >
                        {alterarDados === -1 ? <div className="mt-2 text-lg text-text-main-color">{data.entFormadora && data.entFormadora.length > 0 ? data.entFormadora.map((admin, index) => (
                            <span key={index}>{admin.name}{index < data.entFormadora.length - 1 ? ', ' : ''}</span>
                        )) : ' '}</div> : <select className="selector" id="entFormadora" defaultValue={entFormadora ? entFormadora : data.entFormadora.length > 0 ? data.entFormadora[0]._id : ""} onChange={(e) => setEntFormadora(e.target.value)}>
                            <option disabled value="">Selecione uma Entidade Formadora</option>
                            {entFormadoraList && entFormadoraList.length > 0 && entFormadoraList.map((adm, index) => (
                                <option key={index} value={adm._id}>{adm.name}</option>
                            ))}
                        </select>}
                    </div>

                </div>}
                {((token === "admin2") || (token === "admin1" && (adminType === "Administrador - Diretor de Departamento" || adminType === "Gestor Financeiro"))) && <div className="flex flex-row gap-3 mt-5">
                    <button className={alterarDados === -1 ? "button-geral" : "button-cancelar"} onClick={() => alterarDados === -1 ? setAlterarDados(1) : setAlterarDados(-1)}>{alterarDados === -1 ? "Alterar Dados" : "Cancelar"}</button>
                    {alterarDados !== -1 && <button className={"button-confirmar"} onClick={() => updateDadosCategoria()}>Confirmar</button>}
                </div>}
                {(token === "admin2" || (adminType === "Administrador - Diretor de Departamento" || adminType === "Gestor Financeiro")) && <div className="mt-10">
                    <h1 className="subtitle">Cursos/Percursos</h1>
                    <h1 className="text-lg text-text-main-color font-bold">Adicionar Cursos</h1>
                    <MultiSelectDropdown data={percursoList} multi={true} changer={handleChange} width={"w-[500px]"} />
                    <button className={"button-confirmar"} onClick={() => updatePercursosCategoria()}>Confirmar</button>
                </div>}
                <h1 className="mt-5 text-lg text-text-main-color font-bold">Lista de Cursos</h1>
                {percursos ? <div className="table-containermax-w-lg">
                    <table className="my-table ">
                        <thead className="bg-main-color">
                            <tr className="text-white h-14">
                                <th className="p-2 min-w-[150px] border border-white">Cursos/Percursos</th>
                                <th className="p-2 min-w-[150px] border border-white">Remover</th>
                            </tr>
                        </thead>
                        <tbody className="text-text-main-color">
                            {percursos.map((item, index) => (
                                <tr key={index}>
                                    <td className="border border-white">{item.name}</td>
                                    <td className="border border-white">
                                        <ConfirmToaster css={"button-geral mt-3 hover:brightness-75"} icon={<IoMdRemoveCircle size={24} />} title={"Remover"} message={"Tem a certeza que deseja retirar o Percurso da Categoria?"} confirm={removeOneOrMorePercursos} id={item._id} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div> : <div>Sem percursos associados</div>}

                <div className="table-containermax-w-lg mb-10">
                    <h1 className="mt-10 subtitle">Análise</h1>
                    <div className="flex col gap-3 justify-center">
                        {dateBegin && <div>
                            <h2 className="label text-text-main-color">Data Inicio:</h2>
                            <input id="dataInicio" type="date" className="input-field" defaultValue={new Date(dateBegin).toISOString().substring(0, 10)} onChange={(e) => setDateBegin((e.target.value))} />
                        </div>}
                        <div>
                            <h2 className="label text-text-main-color">Data Fim:</h2>
                            <input id="dataFim" type="date" className="input-field" defaultValue={dateEnd ? new Date(dateEnd).toISOString().substring(0, 10) : new Date().toISOString().substring(0, 10)} onChange={(e) => setDateEnd(new Date(e.target.value).toISOString().substring(0, 10))} />
                        </div>
                    </div>
                    <table className="my-table ">
                        <thead className="bg-main-color">
                            <tr className="text-white h-14">
                                <th className="p-2 min-w-[150px] border border-white">Objetivos Operacionais</th>
                                <th className="p-2 min-w-[150px] border border-white"></th>
                            </tr>
                        </thead>
                        <tbody className="text-text-main-color">
                            <tr>
                                <td className="border border-white">Avaliação Satisfação Formandos</td>
                                <td className="border border-white"> {contTotalFormandos > 0 ? Math.round((cont5e4Formandos / contTotalFormandos) * 100) : 0}%</td>
                            </tr>
                            <tr>
                                <td className="border border-white">Avaliação Satisfação Formadores</td>
                                <td className="border border-white"> {contTotalFormadores > 0 ? Math.round((cont5e4Formadores / contTotalFormadores) * 100) : 0}%</td>
                            </tr>
                            <tr>
                                <td className="border border-white">Avaliação Satisfação Coordenadores</td>
                                <td className="border border-white"> {contTotalCoordenadores > 0 ? Math.round((cont5e4Coordenadores / contTotalCoordenadores) * 100) : 0}%</td>
                            </tr>
                            <tr>
                                <td className="border border-white">Desempenho dos formadores</td>
                                <td className="border border-white">{desempenhoFormadores ? desempenhoFormadores + "%" : "Sem registos"}</td>
                            </tr>
                            <tr>
                                <td className="border border-white">Desempenho coordenadores e mediadores</td>
                                <td className="border border-white">{desempenhoCoordenadores ? desempenhoCoordenadores + "%" : "Sem registos"}</td>
                            </tr>
                            <tr>
                                <td className="border border-white">Taxa de Desistência</td>
                                <td className="border border-white">{formandosDesistentesDate ? formandosDesistentesDate : 0}%</td>
                            </tr>
                            <tr>
                                <td className="border border-white">Taxa de Assiduidade</td>
                                <td className="border border-white">{formandosDesistentesDate ? 100 - formandosDesistentesDate : 100}%</td>
                            </tr>
                            <tr>
                                <td className="border border-white">Avaliação de Impacto</td>
                                <td className="border border-white"></td>
                            </tr>
                            <tr>
                                <td className="border border-white">Nº Participantes</td>
                                <td className="border border-white">{formandosValidadosFiltrados ? formandosValidadosFiltrados.length : 0}</td>
                            </tr>
                            <tr>
                                <td className="border border-white">Nº Encaminhados</td>
                                <td className="border border-white">{formandosEncFiltrados ? formandosEncFiltrados.length : 0}</td>
                            </tr>
                            <tr>
                                <td className="border border-white">Taxa de Certificados</td>
                                <td className="border border-white">{taxaCertificacaoDates ? taxaCertificacaoDates : 0}%</td>
                            </tr>
                            <tr>
                                <td className="border border-white">Volume Total de Formação</td>
                                <td className="border border-white">{volumeDates ? volumeDates.volume + "h" : "Sem registos"}</td>
                            </tr>
                            <tr>
                                <td className="border border-white">Volume de Certificados de Formação</td>
                                <td className="border border-white">{volumeDates ? volumeDates.volumeCerti + "h" : "Sem registos"}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </main >}
            <Footer />
        </>
    );
}

export default CategoriaIndividual