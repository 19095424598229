import React, { useState } from "react";
import toastError from "../components/Toast/toastError";

const FileUploader = ({
  allowedTypes = [],
  inputClassName,
  inputAccept,
  inputMultiple,
  inputId,
  inputName,
  inputType,
  inputRequired,
  onChangeSpecial,
  inputStyle,
  hiddenFormatosPermitidos,
  inputDataTip,
  maxFileSize, // Tamanho máximo do arquivo em bytes (padrão: 4 MB)
}) => {
  const [error, setError] = useState("");
  const maxSize = maxFileSize?maxFileSize*1024*1024:4*1024*1024
  // Converte tipos MIME para extensões legíveis
  const getReadableExtensions = (mimeTypes) => {
    const mimeToExtension = {
      "image/jpeg": "JPEG",
      "image/png": "PNG",
      "image/jpg": "JPG",
      "application/pdf": "PDF",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "Excel (.xlsx)",
      "application/vnd.ms-excel": "Excel (.xls)",
      "text/csv": "CSV",
      "application/zip": "ZIP",
      "application/x-rar-compressed": "RAR",
    };
    return mimeTypes.map((mime) => mimeToExtension[mime] || mime);
  };

  // Verifica o conteúdo do arquivo usando "magic numbers"
  const validateMagicNumbers = async (file) => {
    const arrayBuffer = await file.arrayBuffer();
    const uint = new Uint8Array(arrayBuffer);

    // Magic numbers para tipos suportados
    const magicNumbers = {
      "image/png": [0x89, 0x50, 0x4e, 0x47],
      "image/jpeg": [0xff, 0xd8],
      "image/jpg": [0xff, 0xd8],
      "application/pdf": [0x25, 0x50, 0x44, 0x46],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [0x50, 0x4b, 0x03, 0x04],
      "application/vnd.ms-excel": [0xd0, 0xcf, 0x11, 0xe0],
      "text/csv": [0x74, 0x65, 0x78, 0x74], // Apenas exemplo, pode variar
      "application/zip": [0x50, 0x4b, 0x03, 0x04],
      "application/x-rar-compressed": [0x52, 0x61, 0x72, 0x21],
    };

    const fileMagic = magicNumbers[file.type];
    if (!fileMagic) return false; // Tipo MIME não suportado para validação

    // Verifica se os primeiros bytes correspondem aos magic numbers
    return fileMagic.every((byte, index) => uint[index] === byte);
  };

  // Valida o arquivo
  const validateFile = async (file) => {
    // Verifica se o tamanho do arquivo é permitido
    if (file.size > maxSize) {
      setError(`Tamanho do arquivo excede o limite permitido de ${maxSize/(1024*1024)} MB.`);
      return false;
    }

    // Verifica se o tipo MIME é permitido
    if (!allowedTypes.includes(file.type)) {
      const readableExtensions = getReadableExtensions(allowedTypes);
      setError(`Formato inválido! Apenas os seguintes formatos são permitidos: ${readableExtensions.join(", ")}`);
      return false;
    }

    // Verifica magic numbers para evitar manipulação
    const isMagicValid = await validateMagicNumbers(file);
    if (!isMagicValid) {
      setError("Conteúdo do arquivo inválido! Certifique-se de que o arquivo não foi manipulado.");
      return false;
    }

    return true;
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setError(""); 
      const isValid = await validateFile(file);
      if (isValid) {
        setError(""); // Remove erros anteriores
      } else {
        e.target.value = ""; // Reseta o input
      }
    }
  };

  const readableExtensions = getReadableExtensions(allowedTypes);

// *****Função handleFileChange****
//A função handleFileChange é chamada quando o evento onChange é disparado no input de arquivo.
//Ela valida o arquivo selecionado e, se o arquivo for válido, remove qualquer erro anterior. Caso contrário, reseta o input.
// *****Callback onChangeSpecial****
//Após a validação do arquivo, a função onChangeSpecial é chamada, se estiver definida, permitindo que você execute uma lógica adicional.

  return (
    <div style={styles.container}>
      <input className={inputClassName} accept={inputAccept} style={inputStyle} multiple={inputMultiple} type={inputType} id={inputId} name={inputName} required={inputRequired} onChange={(e) => {handleFileChange(e); if(onChangeSpecial)onChangeSpecial(e)}} data-tip={inputDataTip} />
      {!hiddenFormatosPermitidos && <p style={styles.info}>
        Formatos permitidos: <strong>{readableExtensions.join(", ")}</strong>
      </p>}
      {error && toastError(error)}
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "10px",
  },
  info: {
    margin: 0,
    fontSize: "14px",
    color: "#555",
  },
  toast: {
    marginTop: "10px",
    padding: "10px",
    backgroundColor: "red",
    color: "white",
    borderRadius: "5px",
    textAlign: "center",
    fontSize: "14px",
  },
};

export default FileUploader;

