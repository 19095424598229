import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Footer from "../../../../../components/Footer";
import Header from "../../../../../components/Header";
import 'react-toastify/dist/ReactToastify.css';
import excelGeneratorClone from "../../../../../components/Excel/cronogramaGeralClone";
import NavbarFormador from "../../../../../components/Navbars/NavbarFormador/navbarFormador";
import { AiOutlineCalendar } from "react-icons/ai";
import { BiCalendarEdit } from "react-icons/bi";
import { BsCalendar3 } from "react-icons/bs";
import { LuFileCog, LuFiles, LuFileText, LuBookOpen, LuBook } from "react-icons/lu";
import { Books, Student, ChalkboardTeacher } from "@phosphor-icons/react";
import AlterarFormato from "../../../../../components/FormChecks/formatDates/foramatDates";

function Candidaturas() {
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    const params = useParams();

    useEffect(() => {
        getPercurso()
    }, [loading]);


    //Download do ficheiro
    function downloadFile(url) {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('target', '_blank'); // Opens the link in a new tab/window
        link.setAttribute('download', ''); // Optional, sets the download attribute to trigger download in some browsers
        link.click();
    }
    //Obtem percurso
    function getPercurso() {
        fetch(`${process.env.REACT_APP_API_URL}/percurso/${params.id_curso}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem('user')
            },
            method: 'GET',
        })
            .then(res => res.json())
            .then((result) => {
                setData(result)
                setLoading(false)

            })
    }

    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";

    return (
        <>
            <main className="min-h-screen grid-cols-7 mx-auto md:grid xl:grid-cols-10">

                <div className='h-full sm:col-span-2 md:bg-secundary-color'>
                    <NavbarFormador currentPage={"cursos"} />
                </div>

                <div className="relative h-full col-span-5 mx-4 xl:col-span-8">
                    <Header sideMenu={"true"} />
                    <div className="back-button-noNavbar">
                        <Link to={"/menutrainers/" + params.id_candidatura + "/cursos"} className="flex w-8 h-8">
                            <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                        </Link>
                    </div>
                    <h1 className="mx-4 mt-8 title sm:mt-0">{data.name}</h1>
                    <div className="grid mx-4 xl:grid-cols-2 ">
                        <div>
                            <div className="flex justify-center" >
                                <div className="element">Nome: </div>
                                <div className="value">{data.name}</div>
                            </div>
                            <div className="flex justify-center" >
                                <div className="element">Operação: </div>
                                <div className="value">{data.operacao[0].codigoOperacao}</div>
                            </div>
                            <div className="flex justify-center">
                                <div className="element">Modalidade: </div>
                                {data.modalidade ? <div className="value">{data.modalidade}</div> : <div className="value" />}
                            </div>
                            <div className="flex justify-center">
                                <div className="element">Area Código de Formação: </div>
                                {data.areaCodigoFormacao ? <div className="value">{data.areaCodigoFormacao}</div> : <div className="value" />}
                            </div>
                            <div className="flex justify-center">
                                <div className="element">Forma de organização: </div>
                                <div className="value">{data.type}</div>
                            </div>
                            <div className="flex justify-center">
                                <div className="element">Entidade formadora: </div>
                                {data.entFormadora[0] ? <div className="value">{data.entFormadora[0].name}</div> : <div className="value" />}
                            </div>
                            <div className="flex justify-center">
                                <div className="element">Entidade financiadora: </div>
                                {data.entFinanciadora[0] ? <div className="value">{data.entFinanciadora[0].name}</div> : <div className="value" />}
                            </div>
                        </div>
                        <div>
                            <div className="flex justify-center">
                                <div className="element">Data-Inicio: </div>
                                <div className="value">{AlterarFormato(data.dateBegin)}</div>
                            </div>
                            <div className="flex justify-center">
                                <div className="element">Data-Fim:</div>
                                <div className="value">{AlterarFormato(data.dateEnd)}</div>
                            </div>
                            <div className="flex justify-center">
                                <div className="element">Nível: </div>
                                {data.nivel ? <div className="value">{data.nivel}</div> : <div className="value" />}
                            </div>
                            <div className="flex justify-center">
                                <div className="element">Horário: </div>
                                {data.horario ? <div className="value">{data.horario}</div> : <div className="value" />}
                            </div>
                            <div className="flex justify-center">
                                <div className="element">Edição: </div>
                                {data.edicoes ? <div className="value">{data.edicoes}</div> : <div className="value" />}
                            </div>
                            <div className="flex justify-center">
                                <div className="element">Duração:</div>
                                <div className="value">{data.duration}</div>
                            </div>
                            <div className="flex justify-center">
                                <div className="element">Local: </div>
                                <div className="value">{data.locality}</div>
                            </div>
                            <div className="flex justify-center" >
                                <div className="element">Estado: </div>
                                <div className="value">{data &&  (data.fechoAdmin === false || !data.fechoAdmin) ? "Ativo": "Fechado"}</div>
                            </div>
                        </div>
                    </div>

                    <div className="grid grid-cols-1 mt-8 mb-32 2xl:grid-cols-2 justify-items-center sm:mb-8 sm:mr-4">

                        <div>
                            <h2 className="subtitle 2xl:-mb-3">Cronogramas</h2>
                            <div className="grid grid-cols-1 2xl:grid-cols-2">
                                <div className="flex flex-col sm:flex-row 2xl:flex-col">
                                    {data.cronogramaOriginal && <button className="button-paginas-individual 2xl:mt-8 group" onClick={() => downloadFile(data.cronogramaOriginal)}>
                                        <AiOutlineCalendar size={24} className="text-main-color group-hover:text-white" />
                                        <h3 className="mt-1 text-main-color group-hover:text-white">DOWNLOAD CRONOGRAMA GERAL ORIGINAL</h3>
                                    </button>}
                                    <button className="button-paginas-individual 2xl:mt-8 group" onClick={() => excelGeneratorClone(data)}>
                                        <BiCalendarEdit size={24} className="text-main-color group-hover:text-white" />
                                        <h3 className="mt-1 text-main-color group-hover:text-white">GERAR CRONOGRAMA GERAL</h3>
                                    </button>
                                </div>
                                <div className="flex flex-col sm:flex-row 2xl:flex-col">
                                    <button className="button-paginas-individual 2xl:mt-8 group" onClick={() => navigate("/menutrainers/" + params.id_candidatura + "/cursos/" + params.id_curso + "/Cronograma")}>
                                        <BsCalendar3 size={24} className="text-main-color group-hover:text-white" />
                                        <h3 className="mt-1 text-main-color group-hover:text-white">VISUALIZAR/MARCAR CRONOGRAMA DE SESSÕES DE FORMAÇÃO</h3>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="mt-8 2xl:mt-0">
                            <h2 className="subtitle 2xl:-mb-3">Gestão do curso</h2>
                            <div className="grid grid-cols-1">
                                <div className="flex flex-col sm:flex-row 2xl:flex-col">
                                    {data && data.indice && <button className="button-paginas-individual 2xl:mt-8 group" onClick={() => navigate("/menutrainers/" + params.id_candidatura + "/cursos/" + params.id_curso + "/DTP")}>
                                        <LuFiles size={24} className="text-main-color group-hover:text-white" />
                                        <h3 className="text-main-color group-hover:text-white">VISUALIZAR DTP</h3>
                                    </button>}

                                    <button className="button-paginas-individual 2xl:mt-8 group" onClick={() => navigate("/menutrainers/" + params.id_candidatura + "/cursos/" + params.id_curso + "/Ufcds")}>
                                        <Books size={24} className="text-main-color group-hover:text-white" />
                                        <h3 className="text-main-color group-hover:text-white">GERIR DISCIPLINAS</h3>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
}

export default Candidaturas