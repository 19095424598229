import React, { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom'
import Footer from "../Footer";
import toastSuccess from "../Toast/toastSuccess";
import NavbarAdmin1 from "../Navbars/NavbarAdmin1/NavbarAdmin1";
import NavbarAdmin2 from "../Navbars/NavbarAdmin2/NavbarAdmin2";
import { AiOutlineCloseCircle, AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineLeft, AiOutlineRight} from "react-icons/ai";
import ConfirmToaster from "../Toast/toastConfirmChoice";
import Modal from 'react-modal';
import Cookies from "js-cookie";
import { FiDownload } from "react-icons/fi";
import onlyString from "../FormChecks/onlyString/onlyString";
import toastError from "../Toast/toastError";
import isNumber from "../isNumber/isNumber";
import containsPostalCode from "../FormChecks/containsPostalCode/containsPostalCode";
import check_nif_number from "../FormChecks/checkNif/checkNIf";
import FileUploader from "../../utils/validationFileUploader";
import AlterarFormato from "../../components/FormChecks/formatDates/foramatDates";
import { IoMdRemoveCircle } from "react-icons/io";

//Candidatura individual
export default function CoordenadorIndividual(token, type) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [dataToDisplay, setDataToDisplay] = useState([])
    const [error, setError] = useState(null);
    const [data2, setData2] = useState(0);
    const [modal, setModal] = useState(false);
    const [estadoAdmin, setEstadoAdmin] = useState(false);
    const [cont, setCont] = useState(0);
    const [numDoc, setNumDoc] = useState("");
    const [outrosDocs, setOutrosDocs] = useState([]);
    const [alterarFiles, setAlterarFiles] = useState(0);// Se 1 = Alterar , Se 2 = adicionar  , Se 0 = nada
    const [alterar, setAlterar] = useState(false);
    const [verIntegracaoSistema, setVerIntegracaoSistema] = useState(false);
    const [text, setText] = useState("");
    const savedPagina = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("SuperAdmin-coordinatorsCoordinators-Pag") : ""
    const [nPaginas, setNumPaginas] = useState(1);
    const [paginaAtual, setPaginaAtual] = useState(savedPagina ? parseInt(savedPagina) : 1);
    const savedItemsPag = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get(`Coordenadores-profile-itm1`) : ""
        const [itemsPag, setItemsPag] = useState(savedItemsPag ? savedItemsPag : 10)
    const [modalMailIsOpen, setModalMailIsOpen] = useState({
        open: false,
        email: "",      
    });
    
        document.data = data

    //Data atual
    const year = new Date().getFullYear()
    const month = new Date().getMonth()
    const day = new Date().getDate();

    document.data = data
    const dateToday = new Date(year, month, day)
    const params = useParams();

    useEffect(() => {
        Cookies.set("SuperAdmin-coordinatorsCoordinators-Pag", paginaAtual);
        Cookies.set(`SuperAdmin-coordinatorsCoordinators-itm1`, itemsPag);
        getCoordenadores()// eslint-disable-next-line
    }, [loading, paginaAtual, nPaginas, itemsPag]);

    //Atribuir numero de páginas
    function paginacaoTabelas(data) {
        if (data.length < itemsPag) {
            setNumPaginas(1)
        } else if (data.length % itemsPag === 0) {
            setNumPaginas(data.length / itemsPag)
        } else {
            setNumPaginas(Math.ceil(data.length / itemsPag))
        }
        updatePagination(data) // n é o state por causa das tabelas
    }

    function updatePagination(data) {
        let maxIndex = (paginaAtual * itemsPag)
        let minIndex
        if (paginaAtual === 1) {
            minIndex = 0
        } else {
            minIndex = (paginaAtual - 1) * itemsPag;
            maxIndex = (paginaAtual * itemsPag);
        }
        setDataToDisplay(data.slice(minIndex, maxIndex))
    }

    //aumentar página atual
    function increasePagination() {
        if (paginaAtual < nPaginas) {
            setPaginaAtual(prevIndex => (prevIndex + 1))
            updatePagination(data)
        }
    }

    //diminuir página atual
    function decreasePagination() {
        if (paginaAtual > 1) {
            setPaginaAtual(prevIndex => (prevIndex - 1))
            updatePagination(data)
        }
    }

    //passa para a ultima página
    //passa para a ultima página
    function ultimaPagina() {
        if (paginaAtual !== nPaginas) {
            setPaginaAtual(nPaginas);
            updatePagination(data);
        }
    }
    //passa para a primeira página
    function primeiraPagina() {
        if (paginaAtual !== 1) {
            setPaginaAtual(1);
            updatePagination(data);
        }
    }

    const handleFileChange = (e, index) => {
        const updatedOutrosDocs = [...outrosDocs];
        updatedOutrosDocs[index].file = e.target.files[0];
        setOutrosDocs(updatedOutrosDocs);
    };

    const handleNameChange = (e, index) => {
        const updatedOutrosDocs = [...outrosDocs];
        if (!updatedOutrosDocs[index]) {
            updatedOutrosDocs[index] = {
                name: '',
                file: null
            };
        }
        updatedOutrosDocs[index].name = e.target.value;
        setOutrosDocs(updatedOutrosDocs);
    };

    //Buscar o número de cursos que estão finalizados
    function getCoordenadores() {
        fetch(`${process.env.REACT_APP_API_URL}/coordenadores/${params.id_coordinator}`, {
            headers: {
                'authorization': localStorage.getItem(token)
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    setData(result);
                    paginacaoTabelas(result.percurso); // Dar update à paginação 
                    let contador = 0
                    result.percurso.forEach(cont => {
                        if (cont.fechoAdmin === false) {
                            contador++
                        }
                    });
                    setData2(contador);
                    setLoading(false);
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                    setError(error);
                }
            );
    };

    function alterarConfirmar() {
        setAlterar(true)
    }

    //Função para alterar os dados do perfil
    function alterarPerfil() {
        let cellNumber = document.getElementById('cellNumber')?.value;
        if (data.tipo === false) {
            if (isNumber(cellNumber) !== "" 
            && document.getElementById('email')?.value !== "" 
            && document.getElementById('address')?.value !== "" 
            && document.getElementById('locality')?.value !== "" 
            && document.getElementById('postalCode')?.value !== "" 
            && document.getElementById('county')?.value !== "" 
            && document.getElementById('iva')?.value !== "" 
            && document.getElementById('irs')?.value !== "" 
            && document.getElementById("name").value !== "" 
            && document.getElementById("nif").value !== "" 
            && document.getElementById("ccNumber").value !== "" 
            && document.getElementById("ccExpiration").value !== "" 
            && document.getElementById("nationality").value !== "" 
            && document.getElementById("tipo").value !== ""
            && document.getElementById("iban").value !== ""
        ) {
                if (!cellNumber.length > 13 || !cellNumber.length < 9 || !cellNumber < 0) {
                    let cont = 0
                    function verifyEmail() {
                        for (var i = 0; i < data2.length; i++) {
                            if (data2[i].email === document.getElementById('email')?.value) {
                                if (document.getElementById('email')?.defaultValue === document.getElementById('email')?.value);
                                else cont++;
                            }
                        }
                    }
                    verifyEmail()
                    // Verificar se o email tem "@" e o "."
                    let checkEmail = document.getElementById('email')?.value.includes("@" && ".")
                    if (checkEmail === true && cont === 0) {
                        if (document.getElementById('locality')?.value) {
                            if (containsPostalCode(document.getElementById('postalCode')?.value)) {
                                if (onlyString(document.getElementById('county')?.value)) {
                                    if (onlyString(document.getElementById('name')?.value)) {
                                        const dateCC = new Date(document.getElementById('ccExpiration')?.value)
                                        if (dateCC.getTime() > dateToday.getTime()) {
                                            if (check_nif_number(document.getElementById('nif')?.value)) {
                                                fetch(`${process.env.REACT_APP_API_URL}/coordenadores/${params.id_coordinator}/alterarPerfilCoordenador`,
                                                    {
                                                        body: JSON.stringify({
                                                            fullName: document.getElementById('name')?.value,
                                                            ccNumber: document.getElementById('ccNumber')?.value,
                                                            ccExpiration: document.getElementById('ccExpiration')?.value,
                                                            cellNumber: document.getElementById('cellNumber')?.value,
                                                            nationality: document.getElementById('nationality')?.value,
                                                            nif: document.getElementById('nif')?.value,
                                                            email: document.getElementById('email')?.value,
                                                            address: document.getElementById('address')?.value,
                                                            locality: document.getElementById('locality')?.value,
                                                            postalCode: document.getElementById('postalCode')?.value,
                                                            county: document.getElementById('county')?.value,
                                                            irs: document.getElementById('irs')?.value,
                                                            iva: document.getElementById('iva')?.value,
                                                            iban: document.getElementById('iban')?.value,
                                                            tipo: document.getElementById('tipo')?.value,
                                                        }),
                                                        method: "PATCH",
                                                        headers: {
                                                            'Accept': 'application/json',
                                                            'Content-Type': 'application/json',
                                                            'authorization': localStorage.getItem(token)
                                                        }
                                                    }).then((result) => {
                                                        result.json().then((resp) => {
                                                            if (resp === "Error Email") {
                                                                toastError("Email já existente.")
                                                                setAlterar(false);
                                                            } else {
                                                                setAlterar(false);
                                                                toastSuccess('Dados alterados com sucesso.')
                                                                getCoordenadores();
                                                            }
                                                        })
                                                    })
                                            } else toastError("Número de nif inválido.")
                                        } else toastError("Data do CC caducada.")
                                    } else toastError("Nome Inválido")
                                } else toastError("Concelho Inválido")
                            } else toastError("Código Postal Inválido")
                        } else toastError("Localidade inválida")
                    } else toastError("Email Inválido ou Existente")
                } else toastError("Número Inválido")
            } else toastError("Dados não inseridos")
        } else {
            if (isNumber(cellNumber) !== "" && document.getElementById('email')?.value !== "" && document.getElementById("name").value !== "" && document.getElementById("nif").value !== "") {
                if (!cellNumber.length > 13 || !cellNumber.length < 9 || !cellNumber < 0) {
                    let cont = 0
                    function verifyEmail() {
                        for (var i = 0; i < data2.length; i++) {
                            if (data2[i].email === document.getElementById('email')?.value) {
                                if (document.getElementById('email')?.defaultValue === document.getElementById('email')?.value);
                                else cont++;
                            }
                        }
                    }
                    verifyEmail()
                    // Verificar se o email tem "@" e o "."
                    let checkEmail = document.getElementById('email')?.value.includes("@" && ".")
                    if (checkEmail === true && cont === 0) {

                        if (onlyString(document.getElementById('name')?.value)) {
                            if (check_nif_number(document.getElementById('nif')?.value)) {
                                fetch(`${process.env.REACT_APP_API_URL}/coordenadores/${params.id_coordinator}/alterarPerfilCoordenador`,
                                    {
                                        body: JSON.stringify({
                                            fullName: document.getElementById('name')?.value,
                                            ccNumber: document.getElementById('ccNumber')?.value ,
                                            ccExpiration: document.getElementById('ccExpiration')?.value,
                                            cellNumber: document.getElementById('cellNumber')?.value,
                                            nationality: document.getElementById('nationality')?.value,
                                            nif: document.getElementById('nif')?.value,
                                            emailAntigo: data.email,
                                            email: document.getElementById('email')?.value,
                                            address: document.getElementById('address')?.value,
                                            locality: document.getElementById('locality')?.value,
                                            postalCode: document.getElementById('postalCode')?.value,
                                            county: document.getElementById('county')?.value,
                                            irs: document.getElementById('irs')?.value,
                                            iva: document.getElementById('iva')?.value,
                                            iban: document.getElementById('iban')?.value,
                                            tipo: document.getElementById('tipo')?.value,
                                        }),
                                        method: "PATCH",
                                        headers: {
                                            'Accept': 'application/json',
                                            'Content-Type': 'application/json',
                                            'authorization': localStorage.getItem(token)
                                        }
                                    }).then((result) => {
                                        result.json().then((resp) => {
                                            if (resp === "Error Email") {
                                                toastError("Email já existente.")
                                                setAlterar(false);
                                            } else {
                                                setAlterar(false);
                                                toastSuccess('Dados alterados com sucesso.')
                                                getCoordenadores();
                                            }
                                        })
                                    })
                            } else toastError("Número de nif inválido.")
                        } else toastError("Nome Inválido")
                    } else toastError("Email Inválido ou Existente")
                } else toastError("Número Inválido")
            } else toastError("Dados não inseridos")
        }
    }

    //Alterar foto de perfil
        const handleFileUpload = async (event) => {
            setLoading(true);
            const selectedFile = event.target.files[0];
            let ccForm = new FormData();
            ccForm.append('logo', selectedFile);
    
            const options = {
                method: "POST",
                headers: { "Accept": "application/json" },
                credentials: "same-origin",
                body: ccForm
            };
    
            try {
                const logoResponse = await fetch(`${process.env.REACT_APP_API_URL}/logo`, options);
                const logoResult = await logoResponse.json();
                await fetch(`${process.env.REACT_APP_API_URL}/coordenadores/${params.id_coordinator}/photo`,
                    {
                        body: JSON.stringify({
                            photo: logoResult,
                        }),
                        method: "PATCH",
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'authorization': localStorage.getItem(token)
                        }
                    })
    
                setAlterar(false);
                toastSuccess('Foto adicionada com sucesso!');
                setCont(cont + 1)
                window.location.reload();
            } catch (error) {
                console.error('Error uploading image:', error);
                setLoading(false);
            }
            setLoading(false); // Set loading back to false after the upload is complete
        };

        function removeDocs(cc, cv, ch, ccp, ibancomp) {
            fetch(`${process.env.REACT_APP_API_URL}/coordenadores/${data._id}/removerDocs`,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'authorization': localStorage.getItem(token)
                    },
                    method: 'PATCH',
                    body: JSON.stringify({
                        cc: cc && cc,
                        cv: cv && cv,
                        ch: ch && ch,
                        ccp: ccp && ccp,
                        ibancomp: ibancomp && ibancomp,
                    })
                }).then((result) => {
                    result.json().then((resp) => {
                        setCont(cont + 1)
                        window.location.reload();
                    })
                })
        }

        async function eliminarOutroDocumento(index) {
                setLoading(true)
                await fetch(`${process.env.REACT_APP_API_URL}/coordenadores/${params.id_coordinator}/deleteOtherDoc`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'authorization': localStorage.getItem(token)
                    },
                    method: 'PATCH',
                    body: JSON.stringify({
                        index: index
                    })
                })
                    .then(res => res.json())
                    .then((result) => {
                        setLoading(false)
                        toastSuccess("Ficheiro eliminado com sucesso")
                        setCont(cont + 1)
                        window.location.reload();
                    })
            }

    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    const handleItemsPerPageChange = (newItemsPag) => {
        const newNumPaginas = Math.ceil(data.length / newItemsPag);
        const newPaginaAtual = paginaAtual > newNumPaginas ? newNumPaginas : paginaAtual;

        setItemsPag(newItemsPag);
        setNumPaginas(newNumPaginas);
        setPaginaAtual(newPaginaAtual);

        updatePagination(dataToDisplay);
    };

     //Permite o coordenador inserir ficheiros
        //Submete ficheiros
        async function submeterFicheiros() {
            setLoading(true)
            let form = document.getElementById("form")
            let formDataInfo = new FormData()
    
            const cc_path = document.getElementById("cc") && document.getElementById("cc").value ? document.getElementById("cc") : ""
            const ch_path = document.getElementById("ch") && document.getElementById("ch").value ? document.getElementById("ch") : ""
            const ccp_path = document.getElementById("ccp") && document.getElementById("ccp").value ? document.getElementById("ccp") : ""
            const cv_path = document.getElementById("cv") && document.getElementById("cv").value ? document.getElementById("cv") : ""
            const ibancomp_path = document.getElementById("ibancomp") && document.getElementById("ibancomp").value ? document.getElementById("ibancomp") : ""
    
            function createOptions() {
                return {
                    method: "POST",
                    headers: { "Accept": "application/json" },
                    credentials: "same-origin"
                };
            }
    
            const promises = [];
    
            if (cc_path) {
                const cc = form.cc.files[0];
                let ccForm = new FormData();
                ccForm.append('cc', cc);
                const ccOptions = createOptions(); // Create separate options object
                ccOptions.body = ccForm;
                const ccResponse = await fetch(`${process.env.REACT_APP_API_URL}/mcc`, ccOptions);
                const ccResult = await ccResponse.json();
                formDataInfo.append('cc', ccResult);
                promises.push(ccResponse);
            }
    
            if (ch_path) {
                const ch = form.ch.files[0];
                let chForm = new FormData();
                chForm.append('ch', ch);
                const chOptions = createOptions(); // Create separate options object
                chOptions.body = chForm;
                const chResponse = await fetch(`${process.env.REACT_APP_API_URL}/mch`, chOptions);
                const chResult = await chResponse.json();
                formDataInfo.append('ch', chResult);
                promises.push(chResponse);
            }
    
            if (ccp_path) {
                const ccp = form.ccp.files[0]
                let ccpForm = new FormData()
                ccpForm.append('ccp', ccp)
                const ccpOptions = createOptions(); // Create separate options object
                ccpOptions.body = ccpForm;
                const ccpcompResponse = await fetch(`${process.env.REACT_APP_API_URL}/mccp`, ccpOptions)
                const ccpcompResult = await ccpcompResponse.json()
                formDataInfo.append('ccp', ccpcompResult)
                promises.push(ccpcompResponse);
            }
    
            if (ibancomp_path) {
                const ibancomp = form.ibancomp.files[0]
                let ibancompForm = new FormData()
                ibancompForm.append('ibancomp', ibancomp)
                const ibancompOptions = createOptions(); // Create separate options object
                ibancompOptions.body = ibancompForm;
                const ibancompResponse = await fetch(`${process.env.REACT_APP_API_URL}/mibancomp`, ibancompOptions)
                const ibancompResult = await ibancompResponse.json()
                formDataInfo.append('ibancomp', ibancompResult)
                promises.push(ibancompResponse);
            }

            //CV permite multiplos ficheiros
            if (cv_path) {
                const cvFiles = Array.from(cv_path.files);
                const cvResults = [];
                for (const cv of cvFiles) {
                    if (cv !== undefined) {
                        let cvForm = new FormData();
                        cvForm.append(`cv`, cv);
                        const cvOptions = createOptions(); // Create separate options object
                        cvOptions.body = cvForm;
                        const cvResponse = await fetch(`${process.env.REACT_APP_API_URL}/mcv`, cvOptions);
                        const cvResult = await cvResponse.json();
                        promises.push(cvResponse);
                        cvResults.push(cvResult);
                    }
                }
                formDataInfo.append(`cv`, cvResults);
            }

            let arrayObject = [];
            for (let i = 0; i < numDoc; i++) {
                const outro = outrosDocs[i].file;
                if (outro) {
                    let outroForm = new FormData();
                    outroForm.append('outro', outro);
                    const outroOptions = createOptions(); // Create separate options object
                    outroOptions.body = outroForm;
                    const outroResponse = await fetch(`${process.env.REACT_APP_API_URL}/outro`, outroOptions);
                    const outroResult = await outroResponse.json();
                    const object = {}
                    object.name = outrosDocs[i].name;
                    object.file = outroResult;
                    arrayObject[i] = object;
                }
            }
    
            if (arrayObject.length === 1) {
                formDataInfo.append("outros", JSON.stringify(arrayObject));
            } else formDataInfo.append("outros", JSON.stringify(arrayObject));
    
            try {
                const responses = await Promise.all(promises);
    
                // Check if all promises were successful
                if (responses.every(response => response.ok)) {
                    // All requests succeeded
                    // Process the responses and append data to formDataInfo
    
                    // Then send the PATCH request
                    const patchResponse = await fetch(`${process.env.REACT_APP_API_URL}/coordenadores/${data._id}`, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            authorization: localStorage.getItem(token)
                        },
                        method: 'PATCH',
                        body: formDataToJson(formDataInfo)
                    });
    
                    if (patchResponse.ok) {
                        toastSuccess("Ficheiros submetidos com sucesso!");
                        setAlterarFiles(0);
                        setLoading(false);
                        setAlterar(false);
                        setCont(cont + 1);                      
                        window.location.reload();
                    } else {
                        toastError("Erro ao submeter os ficheiros");
                        setLoading(false);
                    }
                } else {
                    // At least one request failed
                    toastError("Erro ao submeter os ficheiros");
                    setLoading(false);
                }
            } catch (error) {
                console.error("An error occurred:", error);
                toastError("Erro ao submeter os ficheiros");
                setLoading(false);
            }
        }

        async function eliminarCV(value) {
                setLoading(true)
                await fetch(`${process.env.REACT_APP_API_URL}/coordenadores/${params.id_coordinator}/deleteCV`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'authorization': localStorage.getItem(token)
                    },
                    method: 'PATCH',
                    body: JSON.stringify({
                        value: value
                    })
                })
                    .then(res => res.json())
                    .then((result) => {
                        setLoading(false)
                        toastSuccess("Ficheiro eliminado com sucesso")
                        setCont(cont + 1)
                    })
            }

         //transforma data em json
    function formDataToJson(formData) {
        var object = {};
        formData.forEach((value, key) => object[key] = value);
        return JSON.stringify(object);
    }

    //Altera se o coordenador está invalido para valido
    function validarCoordenador() {
        fetch(`${process.env.REACT_APP_API_URL}/admins/${params.id_coordinator}/validar/coordenador`, {
            method: "PATCH",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(token)
            },
        }).then((result) => {
            result.json().then((resp) => {
                getCoordenadores()
                toastSuccess('Estado do Coordenador alterado com sucesso!')
            })

        })
    }
    //Altera se o coordenador está valido para invalido
    function desvalidarCoordenador() {
        fetch(`${process.env.REACT_APP_API_URL}/admins/${params.id_coordinator}/desvalidar/coordenador`, {
            method: "PATCH",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(token)
            },
        }).then((result) => {
            result.json().then((resp) => {
                getCoordenadores()
                toastSuccess('Estado do Coordenador alterado com sucesso!')
            })

        })
    }
    //altera estado do coordenador
    function submeterEstado() {
        fetch(`${process.env.REACT_APP_API_URL}/admins/estadoPerfil/${data._id}/coordenador`, {
            method: "PATCH",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem(token)
            },
            body: JSON.stringify({
                profile: (!data.profileValid),
                text: text,
            })
        }).then((result) => {
            result.json().then((resp) => {
                getCoordenadores()
                setEstadoAdmin(false)
                toastSuccess('Estado do Perfil alterado com sucesso!')
            })

        })
    }
    //altera estado do admin
    function alterarEstadoPerfilAdmin() {
        setEstadoAdmin(true)
    }

    //Abre modal de enviar email
  const handleOpenMail = () => {
    setModalMailIsOpen(!modalMailIsOpen);
  };

  //Envia email personalizado
    function enviarMailPersonalizado() {
      let divErro = document.getElementById("erro");
  
      if (
        document.getElementById("assunto").value &&
        document.getElementById("corpo").value
      ) {
        let email = document.getElementById("email").value;
        let assunto = document.getElementById("assunto").value;
        let corpo = document.getElementById("corpo").value;
  
        setLoading(true);
        fetch(`${process.env.REACT_APP_API_URL}/mail/sendEmail`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: localStorage.getItem(token),
          },
          method: "POST",
          body: JSON.stringify({
            email: email,
            assunto: assunto,
            corpo: corpo,
          }),
        })
          .then((res) => res.json())
          .then((result) => {});
        setModalMailIsOpen(false);
        toastSuccess("E-Mail enviado com sucesso.");
        setLoading(false);
      } else {
        divErro.innerHTML = "Preencha todos os campos do e-mail.";
      }
    }

    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";

    return (
        <>
            <main className="min-h-screen">
                <div className="w-full">
                    {token === "admin1" && <NavbarAdmin1 currentPage={type} />}
                    {token === "admin2" && <NavbarAdmin2 currentPage={type} />}
                </div>
                {token === "admin1" &&<Link to={'/MenuAdmin/'+ params.id_candidatura +'/coordinators'} className="back-button-div-noNavbar">
                    <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                </Link>}
               {token === "admin2" &&  <Link to={'/MenuSuperAdmin/coordinators'} className="back-button-div-noNavbar">
                    <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                </Link>}
                <div className="flex-grow w-full mt-8">
                    <div className="mx-10">
                        <div className="items-center sm:flex sm:ml-24">
                            <div className="mx-auto">
                            <img alt="profile" className="object-contain mx-auto max-h-40 rounded-3xl sm:m-0" src={data.photo === undefined || data.photo === '' ? 'https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg' : data.photo} height="120px" />
                                
                            {alterar === true && 
                                <FileUploader
                                inputId={"fileInput"}
                                inputType={"file"}
                                inputAccept={".png, .jpeg, .jpg"}
                                onChangeSpecial={handleFileUpload}
                                allowedTypes={["image/png", "image/jpeg", "image/jpg"]}
                                />                            }</div>
                            <div className='mx-auto mt-2 sm:ml-16 '>
                                {alterar === true ?
                                    <input id="name" name="name" className="w-4/5 input-field" placeholder={data.fullName} defaultValue={data.fullName || ""} required /> :
                                    <h1 className="text-2xl font-bold text-text-main-color">{data.fullName}</h1>
                                }
                                <div className="justify-around mt-2 font-medium sm:flex text-text-second-color">
                                    <div className="px-1">
                                        <p>Percursos em atividade</p>
                                        {data2 && data2}
                                    </div>
                                    <div className="px-1">
                                        <p>Percursos finalizados</p>
                                        {data && data.percurso.length - data2}
                                    </div>
                                    <div className="px-1">
                                        <p>Percursos total</p>
                                        {data && data.percurso.length}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!data.tipo && data.profileValid === true ? <>
                            {estadoAdmin === false &&
                                <button className="mt-2 button-geral" onClick={() => alterarEstadoPerfilAdmin()}>Tornar Perfil Incompleto</button>
                            }</>
                            : <>
                                {estadoAdmin === false &&
                                    <button className="mt-2 button-geral" onClick={() => alterarEstadoPerfilAdmin()}>Tornar Completo</button>
                                }
                            </>}
                        {estadoAdmin === true && <div className="mt-2">
                            <p className="text-text-main-color">Caso deseja justificar a razão da alteração deixe um comentário:</p>
                            <p className="text-text-main-color">Este texto irá ser replicado no email gerado pelo sistema.</p>
                            <div>
                                <textarea className="max-w-lg input-textarea" onChange={(e) => setText(e.target.value)} />
                            </div>
                            <div>
                                <button className="inline w-24 mr-3 button-cancelar" onClick={() => setEstadoAdmin(false)}>Cancelar</button>
                                <button className="w-24 button-geral" onClick={() => submeterEstado()}>Submeter</button>
                            </div>
                        </div>}
                                          <button
                                            className="mt-2 ml-4 button-geral"
                                            data-tooltip-id="tooltip"
                                            data-tooltip-content={
                                              "Enviar E-Mail Personalizado"
                                            }
                                            data-tooltip-place="top"
                                            onClick={() => {
                                              setModalMailIsOpen({
                                                open: true,
                                                email: data.email,
                                              });
                                            }}
                                          >
                                            Enviar E-Mail Personalizado
                                          </button>
                        <div className="my-6">
                            <hr />
                        </div>

                        <div className="mb-5 lg:ml-24">
                            <h2 className="text-3xl font-bold text-center text-text-main-color sm:text-left">Dados Pessoais</h2>
                            <div className="my-1 mb-2 mt-4 text-center sm:text-left">
                                {data.fullName && data.cellNumber && data.email && data.nif && data.address && data.locality && ((data.cc_path && data.cv_path && data.ch_path && data.ccp_path && data.ibancomp_path && data.tipo === false) || data.tipo === true) && data.profileValid === true ? <div className="text-text-correct">Perfil preenchido </div> : <div className="text-text-error">Dados do perfil por preencher</div>}                           
                            <div className="grid mt-4 sm:grid-cols-2">
                                <div>
                                    {data.tipo === false && <>
                                <div className="mt-4 text-center sm:text-left">
                                        <div className="text-xs uppercase text-text-second-color opacity-90">Número do Documento de Identificação:</div>
                                    {alterar === true ? (
                                        <input
                                        type="text"
                                        id="ccNumber"
                                        name="ccNumber"
                                        className="w-4/5 input-field"
                                        placeholder={data.ccNumber ? data.ccNumber : "Insira seu ccNumber"}
                                        defaultValue={data.ccNumber || ""}
                                        />
                                    ) : (
                                        <>
                                        {data.ccNumber? (
                                            <div className="mt-1 text-lg text-text-main-color">
                                            {data.ccNumber}
                                            </div>
                                        ) : (
                                            <div className="input-error-profile" />
                                        )}
                                        </>
                                    )}
                                    </div>
                                    <div className="mt-4 text-center sm:text-left">
                                        <div className="text-xs uppercase text-text-second-color opacity-90">Data de validade do documento de identificação:</div>
                                    {alterar === true ? (
                                        <input
                                        type="date"
                                        id="ccExpiration"
                                        name="ccExpiration"
                                        className="w-4/5 input-field"
                                        placeholder={data.ccExpiration ? AlterarFormato(data.ccExpiration) : "Insira seu ccExpiration"}
                                        defaultValue={data.ccExpiration || ""}
                                        />
                                    ) : (
                                        <>
                                        {data.ccExpiration? (
                                            <div className="mt-1 text-lg text-text-main-color">
                                            {AlterarFormato(data.ccExpiration)}
                                            </div>
                                        ) : (
                                            <div className="input-error-profile" />
                                        )}
                                        </>
                                    )}
                                    </div>
                                    </>}
                                    <div className="mt-4 text-center sm:text-left">
                                        <div className="text-xs uppercase text-text-second-color opacity-90">NIF:</div>
                                    {alterar === true ? (
                                        <input
                                        type="text"
                                        id="nif"
                                        name="nif"
                                        className="w-4/5 input-field"
                                        placeholder={data.nif ? data.nif : "Insira seu nif"}
                                        defaultValue={data.nif || ""}
                                        />
                                    ) : (
                                        <>
                                        {data.nif? (
                                            <div className="mt-1 text-lg text-text-main-color">
                                            {data.nif}
                                            </div>
                                        ) : (
                                            <div className="input-error-profile" />
                                        )}
                                        </>
                                    )}
                                    </div>
                                    <div className="mt-4 text-center sm:text-left">
                                        <div className="text-xs uppercase text-text-second-color opacity-90">NºTelemóvel:</div>
                                    {alterar === true ? (
                                        <input
                                        type="text"
                                        id="cellNumber"
                                        name="cellNumber"
                                        className="w-4/5 input-field"
                                        placeholder={data.cellNumber ? data.cellNumber : "Insira o seu número de telemóvel"}
                                        defaultValue={data.cellNumber || ""}
                                        />
                                    ) : (
                                        <>
                                        {data.cellNumber? (
                                            <div className="mt-1 text-lg text-text-main-color">
                                            {data.cellNumber}
                                            </div>
                                        ) : (
                                            <div className="input-error-profile" />
                                        )}
                                        </>
                                    )}
                                    </div>
                                    <div className="mt-4 text-center sm:text-left">
                                        <div className="text-xs uppercase text-text-second-color opacity-90">E-mail:</div>
                                    {alterar === true ? (
                                        <input
                                        type="text"
                                        id="email"
                                        name="email"
                                        className="w-4/5 input-field"
                                        placeholder={data.email ? data.email : "Insira seu email"}
                                        defaultValue={data.email}
                                        />
                                    ) : (
                                        <>
                                        {data.email !== undefined && data.email !== null? (
                                            <div className="mt-1 text-lg text-text-main-color">
                                            {data.email}
                                            </div>
                                        ) : (
                                            <div className="input-error-profile" />
                                        )}
                                        </>
                                    )}
                                    </div>                                  
                                    {data.tipo === false && <>
                                        <div className="mt-4 text-center sm:text-left">
                                    <div className="text-xs uppercase text-text-second-color opacity-90">IVA:</div>
                                    {alterar === true ? (
                                        <select
                                        onWheel={(e) => e.target.blur()} className="w-4/5 selector" id="iva" name="iva" defaultValue={data.iva} required
                                        >
                                        <option value={1}>Sim</option>
                                        <option value={0}>Não</option>
                                        </select>
                                    ) : (
                                        <>
                                        {data.iva !== undefined && data.iva !== null? (
                                            <div className="mt-1 text-lg text-text-main-color">
                                            {data.iva === 1 ? "Sim" : "Não"}
                                            </div>
                                        ) : (
                                            <div className="input-error-profile" />
                                        )}
                                        </>
                                    )}
                                </div>     
                                <div className="mt-4 text-center sm:text-left">
                                    <div className="text-xs uppercase text-text-second-color opacity-90">IRS:</div>
                                    {alterar === true ? (
                                        <select
                                        onWheel={(e) => e.target.blur()} className="w-4/5 selector" id="irs" name="irs" defaultValue={data.irs} required
                                        >
                                        <option value={1}>Sim</option>
                                        <option value={0}>Não</option>
                                        </select>
                                    ) : (
                                        <>
                                        {data.irs !== undefined && data.irs !== null? (
                                            <div className="mt-1 text-lg text-text-main-color">
                                            {data.irs === 1 ? "Sim" : "Não"}
                                            </div>
                                        ) : (
                                            <div className="input-error-profile" />
                                        )}
                                        </>
                                    )}
                                </div>
                                </>}
                                </div>
                                <div>
                                    {data.tipo === false && <>
                                <div className="mt-4 text-center sm:text-left">
                                    <div className="text-xs uppercase text-text-second-color opacity-90">Morada:</div>
                                    {alterar === true ? (
                                        <input
                                        type="text"
                                        id="address"
                                        name="address"
                                        className="w-4/5 input-field"
                                        placeholder={data.address ? data.address : "Insira a sua morada"}
                                        defaultValue={data.address || ""}
                                        />
                                    ) : (
                                        <>
                                        {data.address? (
                                            <div className="mt-1 text-lg text-text-main-color">
                                            {data.address}
                                            </div>
                                        ) : (
                                            <div className="input-error-profile" />
                                        )}
                                        </>
                                    )}
                                </div>
                                <div className="mt-4 text-center sm:text-left">
                                        <div className="text-xs uppercase text-text-second-color opacity-90">Nacionalidade:</div>
                                    {alterar === true ? (
                                        <input
                                        type="text"
                                        id="nationality"
                                        name="nationality"
                                        className="w-4/5 input-field"
                                        placeholder={data.nationality ? data.nationality : "Insira a sua nacionalidade"}
                                        defaultValue={data.nationality || ""}
                                        />
                                    ) : (
                                        <>
                                        {data.nationality? (
                                            <div className="mt-1 text-lg text-text-main-color">
                                            {data.nationality}
                                            </div>
                                        ) : (
                                            <div className="input-error-profile" />
                                        )}
                                        </>
                                    )}
                                    </div>
                                <div className="mt-4 text-center sm:text-left">
                                        <div className="text-xs uppercase text-text-second-color opacity-90">Localidade:</div>
                                    {alterar === true ? (
                                        <input
                                        type="text"
                                        id="locality"
                                        name="locality"
                                        className="w-4/5 input-field"
                                        placeholder={data.locality ? data.locality : "Insira a sua localidade"}
                                        defaultValue={data.locality || ""}
                                        />
                                    ) : (
                                        <>
                                        {data.locality? (
                                            <div className="mt-1 text-lg text-text-main-color">
                                            {data.locality}
                                            </div>
                                        ) : (
                                            <div className="input-error-profile" />
                                        )}
                                        </>
                                    )}
                                    </div>
                                    <div className="mt-4 text-center sm:text-left">
                                        <div className="text-xs uppercase text-text-second-color opacity-90">Concelho:</div>
                                    {alterar === true ? (
                                        <input
                                        type="text"
                                        id="county"
                                        name="county"
                                        className="w-4/5 input-field"
                                        placeholder={data.county ? data.county : "Insira a sua localidade"}
                                        defaultValue={data.county || ""}
                                        />
                                    ) : (
                                        <>
                                        {data.county? (
                                            <div className="mt-1 text-lg text-text-main-color">
                                            {data.county}
                                            </div>
                                        ) : (
                                            <div className="input-error-profile" />
                                        )}
                                        </>
                                    )}
                                    </div>
                                    <div className="mt-4 text-center sm:text-left">
                                    <div className="text-xs uppercase text-text-second-color opacity-90">Código Postal:</div>
                                    {alterar === true ? (
                                        <input
                                        type="text"
                                        id="postalCode"
                                        name="postalCode"
                                        className="w-4/5 input-field"
                                        placeholder={data.postalCode ? data.postalCode : "Insira o seu código postal"}
                                        defaultValue={data.postalCode || ""}
                                        />
                                    ) : (
                                        <>
                                        {data.postalCode? (
                                            <div className="mt-1 text-lg text-text-main-color">
                                            {data.postalCode}
                                            </div>
                                        ) : (
                                            <div className="input-error-profile" />
                                        )}
                                        </>
                                    )}
                                </div>                                                                                   
                                <div className="mt-4 text-center sm:text-left">
                                    <div className="text-xs uppercase text-text-second-color opacity-90">IBAN:</div>
                                    {alterar === true ? (
                                        <input
                                        type="text"
                                        id="iban"
                                        name="iban"
                                        className="w-4/5 input-field"
                                        placeholder={data.iban ? data.iban : "Insira seu iban"}
                                        defaultValue={data.iban || ""}
                                        />
                                    ) : (
                                        <>
                                        {data.iban? (
                                            <div className="mt-1 text-lg text-text-main-color">
                                            {data.iban}
                                            </div>
                                        ) : (
                                            <div className="input-error-profile" />
                                        )}
                                        </>
                                    )}
                                </div>                                                            
                                </>}
                                    <div className="mt-4 text-center sm:text-left">
                                    <div className="text-xs uppercase text-text-second-color opacity-90">
                                        Tipo:
                                    </div>
                                    {token === "admin2" && alterar === true ? (
                                        <select
                                        id="tipo"
                                        name="tipo"
                                        className="w-4/5 input-field"
                                        defaultValue={data.tipo}
                                        >
                                        <option value={false}>Externo</option>
                                        <option value={true}>Interno</option>
                                        </select>
                                    ) : (
                                        <>
                                        {data.tipo !== undefined && data.tipo !== null ? (
                                            <div className="mt-1 text-lg text-text-main-color">
                                            {data.tipo ? "Interno" : "Externo"}
                                            </div>
                                        ) : (
                                            <div className="input-error-profile"/>
                                        )}
                                        </>
                                    )}
                                    </div>
                                </div>                                    
                                    <div className="mt-4 text-center sm:text-left">
                                        <div className="text-xs uppercase text-text-second-color opacity-90">Estado:</div>
                                    {data.valid === true ? (
                                        <div className="mt-1 text-lg text-text-main-color">
                                        Autorizado
                                        <button className="mx-auto mt-2 sm:mx-0 button-cancelar" onClick={() => desvalidarCoordenador()}>Excluir</button>
                                        </div>
                                    ) : (
                                        <div className="mt-1 text-lg text-text-main-color">
                                        Excluido
                                        <button className="mx-auto mt-2 sm:mx-0 button-confirmar" onClick={() => validarCoordenador()}>Autorizar</button>
                                        </div>
                                    )}
                                    </div>                        
                                <div className="flex flex-col">
                                    <div className="flex justify-center mt-3 sm:justify-normal">
                                    {alterar === true ? <button className="mr-5 button-cancelar" onClick={() => { setAlterar(false) }}>Cancelar</button> : <button className="button-geral" onClick={alterarConfirmar}>Alterar Dados</button>}
                                    {alterar === true && <ConfirmToaster css={"button-confirmar"} icon={"Confirmar"} title={"Alterar Dados"} message={"Deseja confirmar as alterações?"} confirm={alterarPerfil} tooltip={"Alterar Perfil"} />}
                                </div>
                                </div>
                            </div>
                            <h3 className="mt-8 text-3xl font-bold text-center text-text-main-color">Ficheiros</h3>
                                <div className="mt-3">
                                    <div className="text-center subtitle">Ficheiros de Identificação</div>
                                    <div className="grid w-1/2 grid-cols-1 mx-auto lg:grid-cols-2 2xl:grid-cols-4">
                                        {data.cc_path ?
                                            <div className="flex justify-center items-center flex-col">
                                                <button
                                                    className={data.aprovDocs && data.aprovDocs.length > 0 ?
                                                        (data.cc === "true" ? "button-files bg-green-800 hover:bg-green-700" :
                                                            (data.cc === "false" ? "button-files bg-red-800 hover:bg-red-700" : "button-files")) :
                                                        "button-files"
                                                    }
                                                    onClick={() => {
                                                        if (data.cc_path) {
                                                            window.open(data.cc_path, '_blank');
                                                        }
                                                    }}
                                                >
                                                    Documento de Identificação
                                                </button>
                                                {(token === "admin1" || token === "admin2") && <div>{
                                                    <div className="flex justify-center gap-2"> <ConfirmToaster
                                                        css="text-cancel-color hover:brightness-75 tooltip"
                                                        icon={<AiOutlineCloseCircle size={18} className="hover:text-text-error" />}
                                                        title="Remover Ficheiro"
                                                        message="Têm mesmo a certeza que deseja remover este ficheiro ? (Esta ação é irreversível.)"
                                                        confirm={() => removeDocs(true, false, false, false, false)}
                                                        type="remover"
                                                        tooltip="Eliminar Ficheiro"
                                                    /></div>}
                                                </div>}
                                            </div> :
                                            <div className="tooltip" data-tip="Sem Documento">
                                                <button className="button-files-empty">Documento de Identificação </button>
                                            </div>
                                        }
                                        {data.cv_path && data.cv_path.length > 0 ?
                                            <div className="flex justify-center items-center flex-col">
                                                <button
                                                    className={data.aprovDocs && data.aprovDocs.length > 0 ?
                                                        (data.cv === "true" ? "button-files bg-green-800 hover:bg-green-700" :
                                                            (data.cv === "false" ? "button-files bg-red-800 hover:bg-red-700" : "button-files")) :
                                                        "button-files"
                                                    }
                                                    onClick={() => {
                                                        setModal(true)
                                                    }}
                                                >
                                                    Curriculum Vitae
                                                </button>
                                                {(token === "admin1" || token === "admin2") && <div>{
                                                  <div className="flex justify-center gap-2"> <ConfirmToaster
                                                        css="text-cancel-color hover:brightness-75 tooltip"
                                                        icon={<AiOutlineCloseCircle size={18} className="hover:text-text-error" />}
                                                        title="Remover Ficheiro"
                                                        message="Têm mesmo a certeza que deseja remover este ficheiro ? (Esta ação é irreversível.)"
                                                        confirm={() => removeDocs(false, true, false, false, false)}
                                                        type="remover"
                                                        tooltip="Eliminar Ficheiro"
                                                    /></div>}
                                                </div>}
                                            </div>
                                            :
                                            <div className="flex justify-center tooltip" data-tip="Sem Documento">
                                                <button className="button-files-empty">Curriculum Vitae</button>
                                            </div>
                                        }
                                        {data.ch_path ?
                                            <div className="flex justify-center items-center flex-col">
                                                <button
                                                    className={data.aprovDocs && data.aprovDocs.length > 0 ?
                                                        (data.ch === "true" ? "button-files bg-green-800 hover:bg-green-700" :
                                                            (data.ch === "false" ? "button-files bg-red-800 hover:bg-red-700" : "button-files")) :
                                                        "button-files"
                                                    }
                                                    onClick={() => {
                                                        if (data.ch_path) {
                                                            window.open(data.ch_path, '_blank');
                                                        }
                                                    }}
                                                >
                                                    Certificado de Habilitações
                                                </button>
                                                {(token === "admin1" || token === "admin2") && <div>{
                                                   <div className="flex justify-center gap-2"> <ConfirmToaster
                                                        css="text-cancel-color hover:brightness-75 tooltip"
                                                        icon={<AiOutlineCloseCircle size={18} className="hover:text-text-error" />}
                                                        title="Remover Ficheiro"
                                                        message="Têm mesmo a certeza que deseja remover este ficheiro ? (Esta ação é irreversível.)"
                                                        confirm={() => removeDocs(false, false, true, false, false)}
                                                        type="remover"
                                                        tooltip="Eliminar Ficheiro"
                                                    /></div>}
                                                </div>}
                                            </div> :
                                            <div className="tooltip" data-tip="Sem Documento">
                                                <button className="button-files-empty"> Certificado de Habilitações</button>
                                            </div>}
                                        {data.ccp_path ?
                                            <div className="flex justify-center items-center flex-col">
                                                <button
                                                    className={data.aprovDocs && data.aprovDocs.length > 0 ?
                                                        (data.ccp === "true" ? "button-files bg-green-800 hover:bg-green-700" :
                                                            (data.ccp === "false" ? "button-files bg-red-800 hover:bg-red-700" : "button-files")) :
                                                        "button-files"
                                                    }
                                                    onClick={() => {
                                                        if (data.ccp_path) {
                                                            window.open(data.ccp_path, '_blank');
                                                        }
                                                    }}
                                                >
                                                    Certificação de Competências Pedagógicas
                                                </button>
                                                {(token === "admin1" || token === "admin2") && <div>{
                                                    <div className="flex justify-center gap-2"> <ConfirmToaster
                                                        css="text-cancel-color hover:brightness-75 tooltip"
                                                        icon={<AiOutlineCloseCircle size={18} className="hover:text-text-error" />}
                                                        title="Remover Ficheiro"
                                                        message="Têm mesmo a certeza que deseja remover este ficheiro ? (Esta ação é irreversível.)"
                                                        confirm={() => removeDocs(false, false, false, true, false)}
                                                        type="remover"
                                                        tooltip="Eliminar Ficheiro"
                                                    /></div>}</div>}
                                            </div> :
                                            <div className="tooltip" data-tip="Sem Documento">
                                                <button className="button-files-empty"> Certificação de Competências Pedagógicas</button>
                                            </div>}
                                            {data.tipo === false ? (
                                            data.ibancomp_path ? (
                                                <div className="flex justify-center items-center flex-col">
                                                    <button
                                                        className={
                                                            data.aprovDocs && data.aprovDocs.length > 0
                                                                ? data.iban === "true"
                                                                    ? "button-files bg-green-800 hover:bg-green-700"
                                                                    : data.iban === "false"
                                                                    ? "button-files bg-red-800 hover:bg-red-700"
                                                                    : "button-files"
                                                                : "button-files"
                                                        }
                                                        onClick={() => {
                                                            if (data.ibancomp_path) {
                                                                window.open(data.ibancomp_path, "_blank");
                                                            }
                                                        }}
                                                    >
                                                        IBAN
                                                    </button>
                                                    {(token === "admin1" || token === "admin2") && (
                                                        <div className="flex justify-center gap-2 mt-2">
                                                            <ConfirmToaster
                                                                css="text-cancel-color hover:brightness-75 tooltip"
                                                                icon={<AiOutlineCloseCircle size={18} className="hover:text-text-error" />}
                                                                title="Remover Ficheiro"
                                                                message="Tem mesmo a certeza que deseja remover este ficheiro? (Esta ação é irreversível.)"
                                                                confirm={() => removeDocs(false, false, false, false, true)}
                                                                type="remover"
                                                                tooltip="Eliminar Ficheiro"
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            ) : (
                                                <div className="tooltip" data-tip="Sem Documento">
                                                    <button className="button-files-empty">IBAN</button>
                                                </div>
                                            )
                                        ) : null}                                           
                                    </div>                                               
                                    {data.outros_path.length > 0 &&
                                        <>
                                            <div className="text-center subtitle">Outros Ficheiros</div>
                                            <div className="grid w-1/2 grid-cols-1 mx-auto lg:grid-cols-2 2xl:grid-cols-4">
                                                {data.outros_path.map((value, index) => (
                                                    <div key={index} className="relative flex justify-center">
                                                        <button className="relative flex items-center justify-between p-0 button-files">
                                                            <a href={value.file} target="_blank" rel="noreferrer" download className="flex-grow p-2 px-3 sm:py-4">
                                                                {value.name}
                                                            </a>
                                                            <ConfirmToaster
                                                                css="absolute top-0.5 right-0.5 text-cancel-color hover:brightness-75 tooltip"
                                                                icon={<AiOutlineCloseCircle size={18} className="hover:text-text-error" />}
                                                                title="Remover Ficheiro"
                                                                message="Têm mesmo a certeza que deseja remover este ficheiro ? (Esta ação é irreversível.)"
                                                                confirm={() => eliminarOutroDocumento(index)}
                                                                id={value.index}
                                                                type="remover"
                                                                tooltip="Eliminar Ficheiro"
                                                            />
                                                        </button>
                                                    </div>
                                                ))}
                                            </div>
                                        </>
                                    }
                                    {alterarFiles === 0 ?
                                        <div className="flex justify-center my-3">
                                            <button className="m-1 button-geral" onClick={() => setAlterarFiles(1)}>
                                                Alterar Ficheiros de Identificação
                                            </button>
                                            <button className="m-1 button-geral sm:ml-3" onClick={() => setAlterarFiles(2)}>
                                                Adicionar Outros Ficheiros
                                            </button>
                                            <button className="m-1 button-geral sm:ml-3" onClick={() => setVerIntegracaoSistema(!verIntegracaoSistema)}>
                                            Ver Integração com o Sistema
                                        </button>
                                        </div> : ""}
                                    {alterarFiles === 1 ?
                                        <div>
                                            <form id="form">
                                                <h1 className="mt-8 text-center title">Insira os seguintes ficheiros</h1>
                                                <div>
                                                    <div className="flex flex-col items-center">
                                                        <div className="text-center label-input">Documento de Identificação: </div>
                                                        <FileUploader
                                                        inputClassName={"input-file"}
                                                        inputType={"file"}
                                                        inputAccept={".pdf"}
                                                        inputId={"cc"}
                                                        inputName={"cc"}
                                                        allowedTypes={["application/pdf"]}
                                                        />
                                                    </div>
                                                    <div className="flex flex-col items-center mt-2">
                                                        <div className="text-center label-input" htmlFor="cv">Curriculum Vitae: </div>
                                                        <FileUploader
                                                        inputClassName={"input-file"}
                                                        inputType={"file"}
                                                        inputAccept={".pdf"}
                                                        inputMultiple={true}
                                                        inputId={"cv"}
                                                        inputName={"cv"}
                                                        allowedTypes={["application/pdf"]}
                                                        />
                                                    </div>
                                                    <div className="flex flex-col items-center mt-2">
                                                        <div className="text-center label-input" htmlFor="ch">Certificado de Habilitações: </div>
                                                        <FileUploader
                                                        inputClassName={"input-file"}
                                                        inputType={"file"}
                                                        inputAccept={".pdf"}
                                                        inputId={"ch"}
                                                        inputName={"ch"}
                                                        allowedTypes={["application/pdf"]}
                                                        />
                                                    </div>
                                                    <div className="flex flex-col items-center mt-2">
                                                        <div className="text-center label-input" htmlFor="ccp">Certificado de Competências Pedagógicas: </div>
                                                        <FileUploader
                                                        inputClassName={"input-file"}
                                                        inputType={"file"}
                                                        inputAccept={".pdf"}
                                                        inputId={"ccp"}
                                                        inputName={"ccp"}
                                                        allowedTypes={["application/pdf"]}
                                                        />
                                                    </div>
                                                    {data.tipo === false && <div className="flex flex-col items-center mt-2">
                                                        <div className="text-center label-input">Comprovativo de IBAN: </div>
                                                        <FileUploader
                                                        inputClassName={"input-file"}
                                                        inputType={"file"}
                                                        inputAccept={".pdf"}
                                                        inputId={"ibancomp"}
                                                        inputName={"ibancomp"}
                                                        allowedTypes={["application/pdf"]}
                                                        />
                                                    </div>}
                                                </div>
                                            </form>                                            
                                            <div className="flex justify-center mt-3">
                                                <button className="mr-5 button-cancelar" onClick={() => { setAlterarFiles(0) }}>Cancelar</button>
                                                <ConfirmToaster css={"button-confirmar"} icon={"Confirmar"} title={"Confirmar"} message={"Deseja confirmar as alterações?"} confirm={submeterFicheiros} tooltip={"Submeter Ficheiros"}></ConfirmToaster>
                                            </div>
                                        </div>
                                        : ""}
                                    {alterarFiles === 2 ?
                                        <div>
                                            <h1 className="mt-8 text-center title">Adicionar Ficheiros</h1>
                                            <div className="flex flex-col justify-center mb-2">
                                                <label className="text-center text-text-main-color">Quantos outros documentos deseja inserir:</label>
                                                <input className="w-1/3 mx-auto input-field" type="number" value={numDoc} onChange={(e) => { setNumDoc(e.target.value) }}></input>
                                            </div>
                                            <div className="flex flex-col items-center w-full pt-4 overflow-y-auto rounded-lg max-h-156 bg-gray-50">
                                                {Array.from({ length: numDoc }).map((_, index) => (
                                                    <div key={index} className="w-4/5 my-2">
                                                        <h4 className="mb-1 text-left uppercase text-main-color">Documento {index + 1}</h4>
                                                        <div className="flex w-full text-left">
                                                            <label className="w-2/5 text-text-main-color" htmlFor={`fullName${index}`}>Nome do documento:</label>
                                                            <input className="w-3/5 input-field" type="text" id={`fullName${index}`} name={`fullName${index}`} value={outrosDocs[index]?.name} onChange={(e) => handleNameChange(e, index)} />
                                                        </div>
                                                        <div className="flex w-full mt-2 mb-3 text-left">
                                                            <label className="w-2/5 text-text-main-color" htmlFor={`outro${index}`}>Ficheiro:</label>
                                                            <FileUploader
                                                            inputClassName={"w-3/5 input-file"}
                                                            inputType={"file"}
                                                            inputAccept={".pdf"}
                                                            inputId={`outro${index}`}
                                                            inputName={`outro${index}`}
                                                            onChangeSpecial={(e) => handleFileChange(e, index)}
                                                            allowedTypes={["application/pdf"]}
                                                            />
                                                        </div>
                                                        <hr />
                                                    </div>
                                                ))}
                                            </div>                                            
                                            <div className="flex justify-center mt-3">
                                                <button className="mr-5 button-cancelar" onClick={() => { setAlterarFiles(0) }}>Cancelar</button>
                                                <ConfirmToaster css={"button-confirmar"} icon={"Confirmar"} title={"Confirmar"} message={"Deseja confirmar as alterações?"} confirm={submeterFicheiros} tooltip={"Submeter Ficheiros"}></ConfirmToaster>
                                            </div>
                                        </div>
                                        : ""}                            
                                </div>                   
                            </div>
                        {verIntegracaoSistema === true ?
                            <div>
                            <h1 className="mt-8 subtitle">Interação com o Sistema</h1>
                            {data && data.percurso.length > 0 ? <>
                                <div className="flex justify-center items-center mb-1">
                                    <label className="text-text-main-color">Dados por página: </label>
                                    <select onWheel={(e) => e.target.blur()} className="selector w-14 ml-1"
                                        value={itemsPag}
                                        onChange={(e) => handleItemsPerPageChange(parseInt(e.target.value))}>
                                        <option value={5}>5</option>
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                        <option value={50}>50</option>
                                    </select>
                                </div>
                                <div className="table-container">
                                    <table className="my-table">

                                        <thead className="bg-main-color">
                                            <tr className="text-white h-14">
                                                <th className="p-2 min-w-[80px] border border-white">Curso</th>
                                                <th className="p-2 min-w-[80px] border border-white">Estado</th>
                                            </tr>
                                        </thead>
                                        {data.percurso.length > 0 && (
                                            <tbody className="text-text-main-color">
                                                {dataToDisplay.map((item, index) => (
                                                    <tr key={index}>
                                                        <td className="border border-white">{item.name}</td>
                                                        <td className="border border-white">{!item.fechoCoordenador === true ? "Ativo" : "Inativo"}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        )}
                                    </table>
                                </div>
                                <div className="flex items-center justify-center mt-4 mb-8">
                                    <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => primeiraPagina()}>
                                        <AiOutlineDoubleLeft />
                                    </button>
                                    <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => decreasePagination()}>
                                        <AiOutlineLeft />
                                    </button>
                                    <p className="text-main-color">Página {paginaAtual} - {nPaginas}</p>
                                    <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => increasePagination()}>
                                        <AiOutlineRight />
                                    </button>
                                    <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => ultimaPagina()}>
                                        <AiOutlineDoubleRight />
                                    </button>
                                </div> </> : <p className="third-title">Não existem dados</p>}
                        </div> : ""}
                            </div>

                        <Modal isOpen={modal} className="fixed inset-0 flex items-center justify-center">
                            <div className="absolute w-full h-full" onClick={() => setModal(false)}></div>
                            <div className="relative flex flex-col items-center px-10 shadow-2xl md:px-20 bg-secundary-color py-7 rounded-2xl">
                                <div>
                                    <h1 className="subtitle">Currículos</h1>
                                </div>
                                <div className="modal-content">
                                    {data.cv_path && <>{data.cv_path.map((value, index) => (
                                        <div key={value}>
                                            <div className="flex items-center m-1.5">
                                                <span className="mr-2 text-text-main-color">CV - {index + 1}:</span>
                                                <a className="mr-2 button-geral" href={value} target="_blank" rel="noreferrer" download>
                                                    <FiDownload />
                                                </a>
                                                 <button className="button-cancelar w-fit" onClick={() => { eliminarCV(value) }}>
                                                <IoMdRemoveCircle />
                                            </button>
                                            </div>
                                        </div>
                                    ))}</>}
                                </div>
                                <button className="absolute top-1.5 right-1.5" onClick={() => setModal(false)}>
                                    <AiOutlineCloseCircle size={24} className="hover:text-text-error" />
                                </button>
                            </div>
                        </Modal>
                    </div>
                </div>
                <div className="w-full mt-3 sm:bottom-0 sm:mt-0">
                    <Footer />
                </div>
            </main >

            <Modal
                    isOpen={modalMailIsOpen.open}
                    className="flex items-center justify-center min-h-screen"
                  >
                    <div className="absolute w-full h-full" onClick={handleOpenMail}></div>
                    <div className="flex flex-col items-center px-10 overflow-auto shadow-2xl bg-secundary-color py-7 rounded-2xl min-w-[70%] relative">
                      <h1 className="title">Enviar E-Mail</h1>
            
                      <div className="flex flex-col w-full px-10">
                        <input
                          className="w-full input-field-disabled"
                          id="email"
                          type="text"
                          disabled
                          value={modalMailIsOpen.email}
                        />
                        <input
                          className="w-full mt-3 input-field"
                          type="text"
                          id="assunto"
                          placeholder="Assunto"
                        />
                        <textarea
                          className="w-full mt-3 input-field min-h-8"
                          id="corpo"
                          type="text"
                          rows={10}
                          placeholder="Corpo do E-Mail"
                        />
                      </div>
                      <div id="erro" className="text-center text-text-error"></div>
                      <button
                        className="mt-4 button-confirmar"
                        onClick={enviarMailPersonalizado}
                      >
                        Enviar
                      </button>
                      <button
                        className="absolute top-1.5 right-1.5"
                        onClick={() => setModalMailIsOpen({ open: false, email: "" })}
                      >
                        <AiOutlineCloseCircle size={24} className="hover:text-text-error" />
                      </button>
                    </div>
                  </Modal>
        </>

        
    );
}