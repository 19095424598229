import React, { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom'
import NavbarAdmin1 from "../../../components/Navbars/NavbarAdmin1/NavbarAdmin1";
import Footer from "../../../components/Footer";
import toastSuccess from "../../../components/Toast/toastSuccess";
import toastError from "../../../components/Toast/toastError";
import ConfirmToaster from "../../../components/Toast/toastConfirmChoice";
import { read, utils } from 'xlsx';
import csvtojson from 'csvtojson';
import { IoMdRemoveCircle } from "react-icons/io";
import { PiMicrosoftExcelLogoFill } from "react-icons/pi";
import { FaFileUpload } from "react-icons/fa";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import Cookies from "js-cookie";
import isNumber from "../../../components/isNumber/isNumber";
import FileUploader from "../../../utils/validationFileUploader";
function ShowUfcd() {
    const [data, setData] = useState(null);
    const [dataToDisplay, setDataToDisplay] = useState([])
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [logsUfcdsExcel, setLogsUfcdsExcel] = useState([]);
    const savedSearchTerm = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Admin-ufcdsGlob-1") : "";
    const [searchTerm, setSearchTerm] = useState(savedSearchTerm ? savedSearchTerm : "");
    const [jsonData, setJsonData] = useState(null);
    const [file, setFile] = useState(null);
    const [escolha, setEscolha] = useState("");
    const [cont, setCont] = useState(0)
    const savedPagina = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get("Admin-ufcdsGlob-Pag") : ""
    const [nPaginas, setNumPaginas] = useState(1);
    const [paginaAtual, setPaginaAtual] = useState(savedPagina ? parseInt(savedPagina) : 1);
    const savedItemsPag = localStorage.getItem("cookiesAccepted") === "true" ? Cookies.get(`Admin-ufcdsGlob-itm1`) : ""
    const [itemsPag, setItemsPag] = useState(savedItemsPag ? savedItemsPag : 10)
    let id = 0
    const params = useParams();
    //filtra dados
    function filterData(data) {
        return data.filter((item) =>
            item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.codeUfcd.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }

    useEffect(() => {
        Cookies.set("Admin-ufcdsGlob-1", searchTerm);
        Cookies.set("Admin-ufcdsGlob-Pag", paginaAtual);
        Cookies.set(`Admin-ufcdsGlob-itm1`, itemsPag)
        getUfcds()
    }, [loading, cont, paginaAtual, nPaginas, data, searchTerm, itemsPag]);

    //Atribuir numero de páginas
    function paginacaoTabelas(data) {
        if (data.length < itemsPag) {
            setNumPaginas(1)
        } else if (data.length % itemsPag === 0) {
            setNumPaginas(data.length / itemsPag)
        } else {
            setNumPaginas(Math.ceil(data.length / itemsPag))
        }
        if (paginaAtual > nPaginas) {
            setPaginaAtual(nPaginas);
        }
        updatePagination(data) // n é o state por causa das tabelas
    }

    //Define que dados mostrar consoante a página em que está
    function updatePagination(data) {
        let maxIndex = (paginaAtual * itemsPag)
        let minIndex
        if (paginaAtual === 1) {
            minIndex = 0
        } else {
            minIndex = (paginaAtual - 1) * itemsPag;
            maxIndex = (paginaAtual * itemsPag);
        }
        setDataToDisplay(data.slice(minIndex, maxIndex))
    }

    //aumentar página atual
    function increasePagination() {
        if (paginaAtual < nPaginas) {
            setPaginaAtual(prevIndex => (prevIndex + 1))
            updatePagination(data)
        }
    }

    //diminuir página atual
    function decreasePagination() {
        if (paginaAtual > 1) {
            setPaginaAtual(prevIndex => (prevIndex - 1))
            updatePagination(data)
        }
    }

    function getUfcds() {
        fetch(`${process.env.REACT_APP_API_URL}/ufcdsGlobal`, {
            headers: {
                "authorization": localStorage.getItem('admin1')
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    setData(result);
                    paginacaoTabelas(filterData(result));
                    setLoading(false);
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                    setError(error);
                    setLoading(false);
                }
            );
    }

    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    //define o numero de items por página e caso após alterar o valor de dados por pagina esteja numa pagina que já não existe muda para a 1a pagina
    const handleItemsPerPageChange = (newItemsPag) => {


        const newNumPaginas = Math.ceil(data.length / newItemsPag);


        const newPaginaAtual = paginaAtual > newNumPaginas ? newNumPaginas : paginaAtual;

        setItemsPag(newItemsPag);
        setNumPaginas(newNumPaginas);
        setPaginaAtual(newPaginaAtual);

        updatePagination(dataToDisplay);
    };

    function submeter() {

        let ufcd = {
            name: document.querySelector("#name").value.trim(),
            codeUfcd: document.querySelector("#codeUfcd").value.trim(),
            cargaHoraria: parseInt(document.querySelector("#cargaHoraria").value.trim()),
        }

        if (!ufcd.codeUfcd || !ufcd.name || (!ufcd.cargaHoraria && isNumber(ufcd.cargaHoraria))) {
            toastError("Alguns dos campos não foram inseridos ou mal inseridos")
            return;
        }

        if (data.find((disciplina)=>(disciplina.codeUfcd === ufcd.codeUfcd))) {
            toastError("Disciplina já existente")
            return;
        }
        fetch(`${process.env.REACT_APP_API_URL}/ufcdsGlobal`,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', "authorization": localStorage.getItem('admin1') },
                body: JSON.stringify(ufcd)
            })
            .then(res => res.json())
            .then(
                (res) => {
                    toastSuccess('UFCD inserida com sucesso!')
                    document.querySelector("#name").value = "";
                    document.querySelector("#codeUfcd").value = "";
                    document.querySelector("#cargaHoraria").value = "";
                    document.querySelector("#typeSubmit").value = "";
                    setEscolha("")
                    setCont(cont + 1)
                },
                (error) => {
                    console.error(error)
                    return
                }
            )
    }

    function submeterExcel() {
        if (file) {
            convertExcelToJson(file)
                .then((data) => {
                    let template = {
                        ufcdsGlobais: data,
                    }
                    fetch(`${process.env.REACT_APP_API_URL}/ufcdsGlobal/excel`,
                        {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json', "authorization": localStorage.getItem('admin1') },
                            body: JSON.stringify(template)
                        })
                        .then(res => res.json())
                        .then(
                            (res) => {
                                if (res.logs) {
                                    setLogsUfcdsExcel(res.logs)
                                }
                            })
                })
                .catch((error) => {
                    console.error(error)
                    return
                })
        }
    }

    //Elimina uma ufcd pelo seu id
    function deleteUfcd(_id) {
        fetch(`${process.env.REACT_APP_API_URL}/ufcdsGlobal/${_id}`, {
            method: 'DELETE',
            headers: {
                "authorization": localStorage.getItem('admin1')
            }
        }).then((result) => {
            result.json().then((resp) => {
                toastSuccess('UFCD removida com sucesso!')
                getUfcds()
            })
        })
    }

    //Download do ficheiro
    function downloadFile() {
        const fileUrl = process.env.PUBLIC_URL + '/Disciplinas.xlsx'; // Update the file path accordingly

        // Create a temporary link element
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = 'ModeloCriarCurso.xlsx'; // Specify the desired filename for the download

        // Programmatically trigger the download
        link.click();
    }

    function convertExcelToJson(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = read(data, { type: 'array' });
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const csvData = utils.sheet_to_csv(worksheet);

                csvtojson()
                    .fromString(csvData)
                    .then((jsonData) => {
                        // Filter rows with values in columns A, B, C, or D
                        const filteredData = jsonData.filter(row =>
                            row.Codigo || row.Nome || row.Carga || row.Formato
                        );
                        resolve(filteredData);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            };
            reader.onerror = (err) => {
                reject(err);
            };
            reader.readAsArrayBuffer(file);
        });
    }
    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
        }
    };

    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";
    //passa para a ultima página
    function ultimaPagina() {
        if (paginaAtual !== nPaginas) {
            setPaginaAtual(nPaginas);
            updatePagination(data);
        }
    }
    //passa para a primeira página
    function primeiraPagina() {
        if (paginaAtual !== 1) {
            setPaginaAtual(1);
            updatePagination(data);
        }
    }
    return (
        <>
            <main className="min-h-screen">
                <div className="w-full">
                    <NavbarAdmin1 currentPage={"criarCursosDisciplinasModelo"} />
                </div>
                <div className="flex-grow mt-8">
                    <Link to={'/menuadmin/' + params.id_candidatura} className="back-button-div-noNavbar">
                        <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                    </Link>
                    <h1 className="title">Lista de Disciplinas Modelo</h1>
                    {data && data.length > 0 ? <>
                        <div className="search-div">
                            <label className="search-input-label">Pesquisar: </label>
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                                    <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                    </svg>
                                </div>
                                <input type="text" className="search-input" placeholder="ex: Nome, Código..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                            </div>
                        </div>
                        <div className="flex justify-center items-center mb-1">
                            <label className="text-text-main-color">Dados por página: </label>
                            <select onWheel={(e) => e.target.blur()} className="selector w-14 ml-1"
                                value={itemsPag}
                                onChange={(e) => handleItemsPerPageChange(parseInt(e.target.value))}>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                            </select>
                        </div>
                        <div className="table-container">
                            <table className="my-table">

                                <thead className="bg-main-color">
                                    <tr className="text-white h-14">
                                        <th className="p-2 min-w-[150px] border border-white">Nome</th>
                                        <th className="p-2 min-w-[80px] border border-white">Código disciplina</th>
                                        <th className="p-2 min-w-[80px] border border-white">Carga Horária</th>
                                        <th className="p-2 min-w-[80px] border border-white">Ações</th>
                                    </tr>
                                </thead>
                                {data.length > 0 ? (
                                    <tbody className="text-text-main-color">
                                        {filterData(dataToDisplay).map((item, index) => (
                                            <tr key={index}>
                                                <td className="border border-white ufcd">{item.name}</td>
                                                <td className="border border-white ufcd">{item.codeUfcd}</td>
                                                <td className="border border-white ufcd">{item.cargaHoraria}</td>
                                                <td className="border border-white">
                                                    <ConfirmToaster css={"button-geral mt-3 hover:brightness-75"} icon={<IoMdRemoveCircle size={24} />} title={"Remover"} message={"Tem a certeza que deseja eliminar a disciplina template ? (Se a eliminá-la perderá o acesso a esta.)"} confirm={deleteUfcd} id={item._id} />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                ) : (
                                    <tbody></tbody>
                                )}
                            </table>
                        </div>
                        <div className="flex items-center justify-center mt-4 mb-8">
                            <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => primeiraPagina()}>
                                <AiOutlineDoubleLeft />
                            </button>
                            <button className={paginaAtual > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => decreasePagination()}>
                                <AiOutlineLeft />
                            </button>
                            <p className="text-main-color">Página {paginaAtual} - {nPaginas}</p>
                            <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => increasePagination()}>
                                <AiOutlineRight />
                            </button>
                            <button className={paginaAtual < nPaginas ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => ultimaPagina()}>
                                <AiOutlineDoubleRight />
                            </button>
                        </div>
                    </> : <p className="third-title">Não existem dados</p>}
                    <div className="mb-8">
                        <h2 className="subtitle">Adicionar disciplina Modelo</h2>
                        <div className="search-div">
                            <h3 className="mr-1 text-text-main-color">Download de Modelo excel:</h3>
                            <button className="p-1 text-2xl button-geral" onClick={() => downloadFile()}><PiMicrosoftExcelLogoFill /></button>
                        </div>

                        <label className="label-input">Método de criação</label>
                        <select onWheel={(e) => e.target.blur()} name="typeSubmit" id="typeSubmit" className="w-1/2 mx-auto selector" defaultValue={escolha} onChange={(e) => setEscolha(e.target.value)}>
                            <option value="" disabled>Selecione como pretente criar as Disciplinas Modelo</option>
                            <option value="1">Excel</option>
                            <option value="2">Manualmente</option>
                        </select>

                        {escolha === "1" ? <div>
                            <div className="flex items-center justify-center mt-3">
                                <h3 className="mr-1 text-text-main-color">Submeta o ficheiro aqui:</h3>
                                <button className={`p-1 text-2xl button-geral ${(file?.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file?.type === "application/vnd.ms-excel") && "bg-text-correct"}`} id="downloadButton" onClick={() => { document.getElementById('fileInput').click(); }}>
                                    <FaFileUpload />
                                    <FileUploader
                                        inputType={"file"}
                                        inputId={"fileInput"}
                                        inputClassName={"hidden"}
                                        inputAccept={".xls,.xlsx"}
                                        onChangeSpecial={handleFileChange}
                                        hiddenFormatosPermitidos={true}
                                        allowedTypes={["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"]}
                                    />
                                </button>
                            </div>

                            <div className="mt-3">
                                {file ?
                                    <button className="button-geral" onClick={() => submeterExcel()}>Submeter</button> :
                                    <button className="button-disabled">Submeter</button>
                                }
                            </div>
                            {logsUfcdsExcel.length > 0 && (
                                <div className="mt-3">
                                    {logsUfcdsExcel.some((log) => log.status !== "Sucesso") && (
                                    <div>
                                        <h3 className="text-red-600">Erros:</h3>
                                        <ul>
                                        {logsUfcdsExcel
                                            .filter((log) => log.status !== "Sucesso")
                                            .map((log, index) => (
                                            <li key={index}>
                                                <span className="text-main-color" >{log.ufcd}</span> {log.message}
                                            </li>
                                            ))}
                                        </ul>
                                    </div>
                                    )}
                                    {logsUfcdsExcel.some((log) => log.status === "Sucesso") && (
                                    <div className="mt-3">
                                        <h3 className="text-green-600">Ufcds criadas com Sucesso:</h3>
                                        <ul>
                                        {logsUfcdsExcel
                                            .filter((log) => log.status === "Sucesso")
                                            .map((log, index) => (
                                            <li key={index}>
                                                <span className="text-main-color">{log.ufcd}</span>
                                            </li>
                                            ))}
                                        </ul>
                                    </div>
                                    )}
                                </div>
                                )}
                            {jsonData && <pre>{JSON.stringify(jsonData, null, 2)}</pre>}
                        </div> : <>
                            {escolha === "2" && <div className="flex flex-col w-1/2 mx-auto mt-4">

                                <label className="mt-3 label-input">Nome</label>
                                <input type="text" id="name" name="name" className="input-field" required />
                                <label className="mt-3 label-input">Código da disciplina</label>
                                <input type="text" id="codeUfcd" className="input-field " name="codeUfcd" />
                                <label className="mt-3 label-input">Carga Horária</label>
                                <input type="number" onWheel={(e) => e.target.blur()} min={0} id="cargaHoraria" className="input-field" name="cargaHoraria" />
                                <div>
                                    <button onClick={submeter} className="mt-4 button-geral" value="+">Submeter</button>
                                </div>
                            </div>
                            }
                        </>
                        }
                    </div>
                </div>
                <div className="w-full mt-3 sm:bottom-0 sm:mt-0">
                    <Footer />
                </div>
            </main>
        </>
    );
}

export default ShowUfcd;
