import React from "react";
import toastSuccess from "../Toast/toastSuccess";
import toastError from "../Toast/toastError";
import refreshPage from "../Refresh";
import { ContratoEFA } from "../Modal/stylesContratos/styledEFA";
import AlterarFormato from "../FormChecks/formatDates/foramatDates";

export default function ContratoCoordenadorEFA({ data, token, setOpen }) {

  const [errorMessage, setErrorMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  // Fields to be filled in the contract
  const [valores, setValores] = React.useState({
    valorBase: data?.dadosContrato?.valorBase ?? "",
    valorDescritivo: data?.dadosContrato?.valorDescritivo ?? "",
    valorMensal: data?.dadosContrato?.valorMensal ?? "",
    valorDescritivoMensal: data?.dadosContrato?.valorDescritivoMensal ?? "",
    dataInicio: data?.dateBegin ? AlterarFormato(data.dateBegin) : "",
    dataTermo: data?.dateEnd ? AlterarFormato(data.dateEnd) : "",
    duracao: data?.dadosContrato?.duracao ?? "",
    local: data?.locality ?? "",
    date: data?.dateBegin ? AlterarFormato(data.dateBegin) : "",
  });

  // Function to send the contract to the coordinator
  async function enviarContratoCoordenadorEFA() {
    if (validateInputs() === false) {
      setErrorMessage("Preencha os campos em falta assinalados a vermelho!");
      return;
    }
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/coordenadores/contrato`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: localStorage.getItem(token),
          },
          method: "POST",
          body: JSON.stringify({
            id_percurso: data._id,
            id: data.Coordenador[0]._id,
            valores: valores,
          }),
        }
      );

      if (!response.ok) {
        setOpen(false);
        setLoading(false);
        toastError("Erro! O contrato não foi enviado");
        return;
      }

      setOpen(false);
      setLoading(false);
      toastSuccess("Contrato enviado com sucesso");
      localStorage.setItem("contractUpdated", "true");

      setTimeout(() => {
        refreshPage();
      }, 1000);

      const result = await response.json();
    } catch (error) {
      console.error("Erro ao enviar contrato:", error);
      setOpen(false);
      toastError("Erro ao enviar contrato");
    }
  }

  // State to handle input values
  const [inputValues, setInputValues] = React.useState({
    inputDate: data?.dateBegin ? AlterarFormato(data.dateBegin) : "",
    inputLocal: data?.locality ?? "",
    inputValorBase: data?.dadosContrato?.valorBase ?? "",
    inputValorDescritivo: data?.dadosContrato?.valorDescritivo ?? "",
    inputValorMensal: data?.dadosContrato?.valorMensal ?? "",
    inputValorDescritivoMensal:
      data?.dadosContrato?.valorDescritivoMensal ?? "",
    inputDataInicio: data?.dateBegin ? AlterarFormato(data.dateBegin) : "",
    inputDataTermo: data?.dateEnd ? AlterarFormato(data.dateEnd) : "",
    inputDuracao: data?.dadosContrato?.duracao ?? "",
  });

  // State to handle input errors
  const [inputErrors, setInputErrors] = React.useState({
    inputDate: false,
    inputLocal: false,
    inputValorBase: false,
    inputValorDescritivo: false,
    inputValorMensal: false,
    inputValorDescritivoMensal: false,
    inputDataInicio: false,
    inputDataTermo: false,
    inputDuracao: false,
  });

  // Handle input change and pass the value to the parent component by invoking the handleInputChange callback
  const handleInputChangeLocal = (e) => {
    const { name, value } = e.target;
    setInputValues({
      ...inputValues,
      [name]: value,
    });
    setInputErrors({
      ...inputErrors,
      [name]: false,
    });
  };

  // Validate all inputs and return true if all of them are valid
  const validateInputs = () => {
    const errors = {};
    let isValid = true;

    for (const key in inputValues) {
      if (inputValues[key].trim() === "") {
        errors[key] = true;
        isValid = false;
      }
    }
    setInputErrors(errors);
    return isValid;
  };

  // Style on inputs is for validation purposes, mark the input with red border if it's empty and black if it's not
  // On inputs, onChange event is used to handle the input change locally and in the parent component
  return (
    <>
      {loading ? (
        <div className="text-center text-2xl">
          A enviar contrato por favor aguarde...
        </div>
      ) : (
        <>
          {" "}
          <ContratoEFA>
            <div className="body">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <img
                    src={data.entFormadora[0] && data.entFormadora[0].logotipo}
                    className="h-20"
                    alt="logo"
                    style={{ marginLeft: "20px" }}
                  />
                </div>
                <div>
                  <h1
                    style={{
                      fontSize: "16px",
                      paddingLeft: "15px",
                      marginTop: "-5px",
                    }}
                  >
                    Contrato de prestação de serviços de Mediação de Cursos EFA
                  </h1>
                </div>
                <div>
                  {data.entFinanciadora.length > 0 &&
                    data.entFinanciadora[0].logotipo1 && (
                      <img
                        src={data.entFinanciadora[0].logotipo1}
                        className="h-20"
                        alt="logo"
                        style={{ marginLeft: "20px" }}
                      />
                    )}
                </div>
              </div>
              <div>
                <div>
                  <p>
                    Entre:
                    <br />
                    <b> {data.entFormadora[0].name} </b>, pessoa coletiva nº{" "}
                    {data.entFormadora[0].nif} com instalações na{" "}
                    {data.entFormadora[0].morada},{" "}
                    {data.entFormadora[0].postalCode}{" "}
                    {data.entFormadora[0].concelho}, representado por{" "}
                    {data.entFormadora[0].representanteLegal}, adiante designada
                    por <b>Primeiro Outorgante</b>, e <br />
                  </p>
                  <table className="dados">
                    <tr>
                      <th>
                        <b>Nome Completo</b>
                      </th>
                      <td> {data.Coordenador[0].fullName} </td>
                    </tr>
                    <tr>
                      <th>NIF</th>
                      <td> {data.Coordenador[0].nif} </td>
                    </tr>
                    <tr>
                      <th>Cartão de Cidadão/Passaporte</th>
                      <td> {data.Coordenador[0].ccNumber}</td>
                    </tr>
                    <tr>
                      <th>Morada</th>
                      <td> {data.Coordenador[0].address} </td>
                    </tr>
                    <tr>
                      <th>Código Postal</th>
                      <td> {data.Coordenador[0].postalCode} </td>
                    </tr>
                  </table>
                  <p>
                    adiante designado por <b>Segundo Outorgante</b>, é celebrado
                    o presente contrato de prestação de serviços, o qual se rege
                    pelo disposto nas seguintes cláusulas:{" "}
                  </p>
                </div>
                <div>
                  <h2>Cláusula 1ª</h2>
                  <p>
                    A 1ª Outorgante contrata a 2ª Outorgante para a prestação de
                    serviços enquanto Mediador(a) Pessoal e Social de{" "}
                    {data.name} enquadrado na candidatura identificada com o
                    seguinte código universal:
                  </p>
                  <table className="dados">
                    <tr>
                      <th>Nº do Projeto:</th>
                      <td colSpan="3">
                        {" "}
                        {data.operacao[0] &&
                          data.operacao[0].codigoOperacao}{" "}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div>
                <div>
                  <h2>Cláusula 2ª</h2>
                  <ol>
                    <li>
                      {" "}
                      A atividade prevista na prestação de serviços, mencionada
                      na cláusula 1ª, está enquadrada pela Portaria n.º 86/2022,
                      de 4 de fevereiro e contempla as seguintes tarefas:
                    </li>
                    <ol className="tipoa">
                      <li>
                        {" "}
                        Assegurar a constituição dos grupos de formação através
                        de processos de recrutamento e seleção de formandos;
                      </li>
                      <li>
                        Assegurar a orientação e desenvolvimento do diagnóstico
                        dos formandos, em articulação com os formadores da
                        equipa técnico-pedagógica;
                      </li>
                      <li>
                        Garantir o acompanhamento e orientação pessoal, social e
                        pedagógica dos formandos;
                      </li>
                      <li>
                        Dinamizar a equipa técnico-pedagógica no âmbito do
                        processo formativo, salvaguardando o cumprimento dos
                        percursos individuais e do percurso do grupo de
                        formação;
                      </li>
                      <li>
                        Assegurar a articulação entre a equipa
                        técnico-pedagógica e o grupo de formação, assim como
                        entre estes e a 1ª Outorgante;
                      </li>
                      <li>
                        Gestão de todos os aspetos técnicos, pedagógicos e
                        administrativos dos projetos de formação/outros;
                      </li>
                      <li>Conceção de programas de formação;</li>
                      <li>Divulgação de programas de formação;</li>
                      <li>Recrutamento e Seleção de formadores;</li>
                      <li>
                        Organização do dossier técnico-pedagógicos do curso em
                        que esteja envolvida;
                      </li>
                      <li>
                        Elaboração de relatórios de avaliação/outros requeridos
                        pelas entidades financiadoras;
                      </li>
                      <li>
                        Estabelecimento de mecanismos de comunicação com as
                        entidades financiadoras a fim destes serem executados
                        segundo as suas orientações técnicas;
                      </li>
                      <li>
                        Atendimento telefónico/ presencial bem como outras
                        tarefas de natureza administrativa, comercial e/ou
                        outras, inerentes à prestação dos serviços;
                      </li>
                    </ol>
                    <br />
                    <li>
                      Ainda segundo a Portaria n.º 86/2022, de 4 de fevereiro,
                      no seu ponto 9 do Artigo 8º, o desenvolvimento dos cursos
                      de {data.name}, podem ser desenvolvidos no formato à
                      distância, devendo nesses casos a mediação referida no
                      ponto 1 desta Cláusula 2ª ser orientada pelos preceitos
                      metodológicos e administrativos previstos pela 1ª
                      Outorgante e clarificados nos seguintes documentos
                      metodológicos do Sistema de Gestão baseado no norma ISO
                      9001, que são dados a conhecer antes da celebração do
                      presente contrato:
                    </li>
                    <ol className="tipoa" type="a">
                      <li>
                        Procedimento 33: Conceber e executar formação à
                        distância;
                      </li>
                      <li>METODOLOGIA do Modelo Pedagógico COM-TEC;</li>
                      <li>
                        Procedimento 36: Realizar a mediação de Cursos EFA;
                      </li>
                    </ol>
                    <li>
                      No acompanhamento destas tarefas a 2ª Outorgante será
                      sujeita a uma avaliação de desempenho com base na Ficha de
                      Avaliação (passível de consulta no seguinte{" "}
                      <a href="LINK">LINK</a>) a qual representa uma
                      qualificação dos serviços prestados e, em si mesmo, uma
                      orientação para a melhoria e contributo da 2ª Outorgante
                      para o projeto referido na Cláusula 1ª deste contrato.
                    </li>
                  </ol>
                </div>
              </div>
              <div>
                <h2>Cláusula 3ª</h2>
                <ol>
                  <li>
                    O preço base da prestação de serviços corresponde ao
                    seguinte:
                  </li>
                  <table className="valor">
                    <tr>
                      <th>Valor base(€)</th>
                      <td>
                        <input
                          className="w-full input-field"
                          type="text"
                          name="inputValorBase"
                          defaultValue={valores.valorBase}
                          placeholder="ex: 5€"
                          onChange={(e) => {
                            setValores({
                              ...valores,
                              valorBase: e.target.value,
                            });
                            handleInputChangeLocal(e);
                          }}
                          style={{
                            border: inputErrors.inputValorBase
                              ? "3px solid red"
                              : "1px solid black",
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Valor base (descritivo)</th>
                      <td>
                        <input
                          className="w-full input-field"
                          type="text"
                          name="inputValorDescritivo"
                          defaultValue={valores.valorDescritivo}
                          placeholder="ex: cinco euros"
                          onChange={(e) => {
                            setValores({
                              ...valores,
                              valorDescritivo: e.target.value,
                            });
                            handleInputChangeLocal(e);
                          }}
                          style={{
                            border: inputErrors.inputValorDescritivo
                              ? "3px solid red"
                              : "1px solid black",
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Valor mensal</th>
                      <td>
                        <input
                          className="w-full input-field"
                          type="text"
                          name="inputValorMensal"
                          defaultValue={valores.valorMensal}
                          placeholder="ex: 1000€"
                          onChange={(e) => {
                            setValores({
                              ...valores,
                              valorMensal: e.target.value,
                            });
                            handleInputChangeLocal(e);
                          }}
                          style={{
                            border: inputErrors.inputValorMensal
                              ? "3px solid red"
                              : "1px solid black",
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Valor mensal (descritivo)</th>
                      <td>
                        <input
                          className="w-full input-field"
                          type="text"
                          name="inputValorDescritivoMensal"
                          defaultValue={valores.valorDescritivoMensal}
                          placeholder="ex: mil euros"
                          onChange={(e) => {
                            setValores({
                              ...valores,
                              valorDescritivoMensal: e.target.value,
                            });
                            handleInputChangeLocal(e);
                          }}
                          style={{
                            border: inputErrors.inputValorDescritivoMensal
                              ? "3px solid red"
                              : "1px solid black",
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>NOTA:</th>
                      <td>
                        Valores acrescidos de IVA à taxa legal em vigor, se de
                        direito.
                      </td>
                    </tr>
                  </table>
                  <li>
                    O <b>SEGUNDO OUTORGANTE</b> deverá apresentar faturas-recibo
                    nos valores respeitantes aos pagamentos a efetuar
                    mensalmente, com o descritivo correspondente ao objeto
                    previsto neste contrato.
                  </li>
                </ol>
              </div>
              <div>
                <h2>Cláusula 4ª</h2>
                <ol>
                  <li>
                    O presente contrato de prestação de serviços tem o seguinte
                    período de validade:
                  </li>
                  <table className="valor">
                    <tr>
                      <th>Data de inicio</th>
                      <td>
                        <input
                          className="w-full input-field"
                          type="text"
                          name="inputDataInicio"
                          defaultValue={valores.dataInicio}
                          placeholder="ex: 25/12/2022"
                          onChange={(e) => {
                            setValores({
                              ...valores,
                              dataInicio: AlterarFormato(e.target.value),
                            });
                            handleInputChangeLocal(e);
                          }}
                          style={{
                            border: inputErrors.inputDataInicio
                              ? "3px solid red"
                              : "1px solid black",
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Data de termo</th>
                      <td>
                        <input
                          className="w-full input-field"
                          type="text"
                          name="inputDataTermo"
                          defaultValue={valores.dataTermo}
                          placeholder="ex: 25/12/2022"
                          onChange={(e) => {
                            setValores({
                              ...valores,
                              dataTermo: AlterarFormato(e.target.value),
                            });
                            handleInputChangeLocal(e);
                          }}
                          style={{
                            border: inputErrors.inputDataTermo
                              ? "3px solid red"
                              : "1px solid black",
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Duração (meses)</th>
                      <td>
                        <input
                          className="w-full input-field"
                          type="text"
                          name="inputDuracao"
                          defaultValue={valores.duracao}
                          placeholder="ex: 12"
                          onChange={(e) => {
                            setValores({
                              ...valores,
                              duracao: e.target.value,
                            });
                            handleInputChangeLocal(e);
                          }}
                          style={{
                            border: inputErrors.inputDuracao
                              ? "3px solid red"
                              : "1px solid black",
                          }}
                        />
                      </td>
                    </tr>
                  </table>
                </ol>
              </div>
              <div>
                <h2>Cláusula 5ª</h2>
                <p>
                  A 2ª Outorgante obriga-se a manter confidencialidade e a
                  guardar sigilo relativamente a toda e qualquer informação cujo
                  conhecimento lhe advenha, direta ou indiretamente, do
                  cumprimento do presente contrato.
                </p>
              </div>
              <div>
                <h2>Cláusula 6ª</h2>
                <ol>
                  <li>
                    A 1ª Outorgante fará a recolha, conservação e tratamento de
                    dados da 2ª Outorgante por imposição do cumprimento de
                    obrigações legais, para cumprimento do presente contrato e
                    ainda porque a 2ª Outorgante dá o seu consentimento.
                  </li>
                  <li>
                    A 1ª Outorgante será o responsável pelo tratamento dos dados
                    da 2ª Outorgante cujo processamento de dados será interno
                    ficando a 1ª Outorgante expressamente autorizada a caso
                    assim o entenda efetuar esse processamento externamente.
                  </li>
                  <li>
                    A 1ª Outorgante fará o tratamento de dados com a finalidade
                    de gestão económica e contabilística, gestão fiscal, gestão
                    administrativa, gestão de faturação, gestão de clientes,
                    gestão de cobranças e pagamentos, gestão de fornecedores e
                    histórico de relações comerciais.
                  </li>
                  <li>
                    Os dados pessoais incluídos no registo são o nome, morada,
                    contactos telefónicos e endereços eletrónicos, data de
                    nascimento, género, naturalidade, nacionalidade,
                    identificação civil, identificação e enquadramento fiscal e
                    perante a segurança social, autorização de residência,
                    passaporte, currículo profissional, profissão, habilitações
                    académicas, formação profissional e experiência anterior.
                  </li>
                  <li>
                    A 1ª Outorgante vai comunicar ou transferir em parte ou na
                    sua totalidade os dados pessoais da 2ª Outorgante a
                    entidades públicas e ou privadas sempre que tal decorra de
                    obrigação legal e ou seja necessário para cumprimento deste
                    ou outros contratos ficando para tal expressamente
                    autorizada pela 2ª Outorgante.
                  </li>
                  <li>
                    A 1ª Outorgante vai conservar os dados da 2ª Outorgante
                    pelos prazos necessários a dar cumprimento a obrigações
                    legais designadamente de 10 (dez) anos para cumprimento à
                    obrigação legal de arquivo de toda a documentação de escrita
                    comercial.
                  </li>
                  <li>
                    A 2ª Outorgante poderá solicitar à 1ª Outorgante e esta,
                    salvo impedimento legal, vai salvaguardar os direitos da 2ª
                    Outorgante de acesso aos dados pessoais que lhe digam
                    respeito, bem como a sua retificação ou o seu apagamento, e
                    a limitação do tratamento, e o direito de se opor ao
                    tratamento, bem como do direito à portabilidade dos dados. E
                    ainda o direito de retirar consentimento em qualquer altura,
                    sem comprometer a licitude do tratamento efetuado com base
                    no cumprimento de obrigações legais ou com base no
                    consentimento previamente dado. E também o direito de
                    reclamação sobre o tratamento de dados junto da Comissão
                    Nacional de Proteção de Dados.
                  </li>
                  <li>
                    Tendo em conta as técnicas mais avançadas, os custos de
                    aplicação e a natureza, o âmbito, o contexto e as
                    finalidades do tratamento, bem como os riscos, de
                    probabilidade e gravidade variável, para os direitos e
                    liberdades das pessoas singulares, a 1ª Outorgante aplica as
                    medidas técnicas e organizativas adequadas para assegurar um
                    nível de segurança adequado ao risco, incluindo, consoante o
                    que for adequado.
                  </li>
                  <li>
                    Em caso de violação de dados pessoais a 1ª Outorgante
                    notifica esse facto à Comissão Nacional de Proteção de Dados
                    nos termos e condições previstos na lei. Se essa violação
                    for suscetível de implicar um elevado risco para os direitos
                    e liberdades do titular comunica-lhe esse facto, nos termos
                    e condições previstos na lei.
                  </li>
                </ol>
                <div style={{ breakInside: "avoid" }}>
                  <p>
                    O presente contrato é feito em duplicado fincando cada uma
                    das partes na posse de um exemplar.
                  </p>
                  <table className="localData">
                    <tr>
                      <th>Local:</th>
                      <td>
                        <input
                          className="w-full input-field"
                          type="text"
                          name="inputLocal"
                          defaultValue={valores.local}
                          placeholder="ex: Porto"
                          onChange={(e) => {
                            setValores({ ...valores, local: e.target.value });
                            handleInputChangeLocal(e);
                          }}
                          style={{
                            border: inputErrors.inputLocal
                              ? "3px solid red"
                              : "1px solid black",
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Data:</th>
                      <td>
                        <input
                          className="w-full input-field"
                          type="text"
                          name="inputDate"
                          defaultValue={valores.date}
                          placeholder="ex: 25/12/2022"
                          onChange={(e) => {
                            setValores({
                              ...valores,
                              date: AlterarFormato(e.target.value),
                            });
                            handleInputChangeLocal(e);
                          }}
                          style={{
                            border: inputErrors.inputDate
                              ? "3px solid red"
                              : "1px solid green",
                          }}
                        />
                      </td>
                    </tr>
                  </table>
                  <br />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      gap: "30px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        width: "50%",
                      }}
                    >
                      <h4 style={{ textAlign: "center" }}>
                        O PRIMEIRO OUTORGANTE
                      </h4>
                      <div
                        style={{
                          minHeight: "200px",
                          borderBottom: "1px solid black !important",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          alt="assinatura"
                          style={{ margin: "5px auto" }}
                          id="img"
                          className="img"
                          src={data.entFormadora[0].assinatura}
                          height="70"
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        width: "50%",
                      }}
                    >
                      <h4 style={{ textAlign: "center" }}>
                        O SEGUNDO OUTORGANTE
                      </h4>
                      <div
                        style={{
                          minHeight: "200px",
                          borderBottom: "1px solid black !important",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              {data.entFinanciadora &&
                data.entFinanciadora.length > 0 &&
                data.entFinanciadora[0].logotipo2 && (
                  <div>
                    <img
                      src={data.entFinanciadora[0].logotipo2}
                      className="h-20"
                      alt="logo"
                      style={{ marginLeft: "20px" }}
                    />
                  </div>
                )}
            </div>
          </ContratoEFA>
          <div className="flex justify-center mt-2">
            <button
              onClick={enviarContratoCoordenadorEFA}
              className="button-geral"
              variant="gradient"
            >
              Enviar Contrato
            </button>
          </div>
          {errorMessage && (
            <div className="text-center text-red-500">{errorMessage}</div>
          )}
        </>
      )}
    </>
  );
}
