import React from "react";
import './App.css';
import { Routes, Route } from 'react-router-dom'
import CookiesDisclaimer from "./components/cookieDisclaimer/CookiesDisclaimer";

//Route Guard
import PrivateRoutesAdmin1 from "./components/PrivateRoutes/Admin1";
import PrivateRoutesAdmin2 from "./components/PrivateRoutes/Admin2";
import PrivateRoutesUser from "./components/PrivateRoutes/User";
import PrivateRoutesCoordenadores from "./components/PrivateRoutes/Coordinator";

//Importar fonts
import "./fonts/Lato-Regular.ttf"

//Importar páginas
import Home from './pages/Home/Home';

//Page 404
import Page404 from './pages/Page404/Page404';

//Form de Avaliação de Impacto dos formandos
import FormAvaliacaoImpacto from './pages/FormAvaliacaoImpacto/formAvaliacaoImpacto.jsx';
import FormAvaliacaoSatisfacao from './pages/FormAvaliacaoSatFormandos/formAvaliacaoSatFormandos.jsx';
//Alterar Perfil
import EditarProfileFormadorSemAcesso from './pages/EditProfileFormador/editProfileFormador.jsx';
//Logins
import MenuFormadores from './pages/MenuFormadores/MenuTrainers/menuTrainers';
import PortalFormandos from './pages/PortalFormandos/portalFormandos';
import Login from './pages/Logins/LoginAdmins/login';
import LoginCoordenador from './pages/Logins/LoginCoordenador/loginCoordenador';
import LoginFormador from './pages/Logins/LoginFormador/login';

//Reset Password Admin
import ForgotPassword from "./pages/Logins/LoginAdmins/ForgotPassword/forgotPassword";
import ResetPassword from "./pages/Logins/LoginAdmins/ResetPassword/resetPassword";

//Reset Password Coordenador
import ForgotPasswordCoordenador from "./pages/Logins/LoginCoordenador/ForgotPassword/forgotPassword";
import ResetPasswordCordenador from "./pages/Logins/LoginCoordenador/ResetPassword/resetPassword";

//Reset Password Formador
import ForgotPasswordFormador from "./pages/Logins/LoginFormador/ForgotPassword/forgotPassword";
import ResetPasswordFormador from "./pages/Logins/LoginFormador/ResetPassword/resetPassword";

// Super Admin
import Menu from "./pages/MenuSuperAdmin/menu";
import MenuProfile from "./pages/MenuSuperAdmin/Profile/profile";
import ChangePassword from "./pages/MenuSuperAdmin/Profile/ChangePassword/changePassword";
import Administradores from "./pages/MenuSuperAdmin/Administradores/administradores";
import AddAdministradores from "./pages/MenuSuperAdmin/Administradores/addAdministradores";
import Administrador from "./pages/MenuSuperAdmin/Administradores/Administrador/administrador";
import Coordinators from "./pages/MenuSuperAdmin/Coordinators/coordinators";
import MenuAtividadeCoordenador from "./pages/MenuSuperAdmin/Coordinators/Coordinator/Atividade/atividade";
import MenuAvaliarCoordenador from "./pages/MenuSuperAdmin/Coordinators/Coordinator/Atividade/Evaluation/evaluation";
import MenuAvaliacaoCoordenador from "./pages/MenuSuperAdmin/Coordinators/Coordinator/Avaliacao/avaliacao";
import AddCoordinators from "./pages/MenuSuperAdmin/Coordinators/addCoordinators"
import AddExternCoordinators from "./pages/MenuSuperAdmin/Coordinators/addExternCoordinators"
import Coordinator from "./pages/MenuSuperAdmin/Coordinators/Coordinator/coordinator";
import AllCandidacies from "./pages/MenuSuperAdmin/AllCandidacies/allCandidacies";
import Individual from "./pages/MenuSuperAdmin/AllCandidacies/Individual/individual";
import AllFormandos from "./pages/MenuSuperAdmin/AllFormandos/allFormandos";
import Categoria from "./pages/MenuSuperAdmin/Categorias/categorias";
import CategoriaIndividual from "./pages/MenuSuperAdmin/Categorias/CategoriaIndividual/categoriaIndividual.jsx"; 
import Operacoes from "./pages/MenuSuperAdmin/Operacoes/operacoes";
import OperacoesIndividual from "./pages/MenuSuperAdmin/Operacoes/OperacoesInvidual/operacoesIndividual.jsx";
import Formando from "./pages/MenuSuperAdmin/AllFormandos/Individual/individual";
import List from "./pages/MenuSuperAdmin/ListTrainers/listTrainers";
import Trainer from "./pages/MenuSuperAdmin/ListTrainers/Trainer/trainer";
import UFCDsFullListAdmin from "./pages/MenuSuperAdmin/ListTrainers/Trainer/UFCDsHistórico/ufcdsHistórico";
import UFCDsIndividual from "./pages/MenuSuperAdmin/ListTrainers/Trainer/UFCDsIndividual/ufcdsIndividual";
import UFCDsEvaluation from "./pages/MenuSuperAdmin/ListTrainers/Trainer/UFCDsIndividual/Evaluation/evaluation";
import UFCDsFullListEvaluation from "./pages/MenuSuperAdmin/ListTrainers/Trainer/UFCDsHistórico/Evaluation/evaluation";
import UFCDs from "./pages/MenuSuperAdmin/UFCDs/ufcds";
import UFCDsToCode from "./pages/MenuSuperAdmin/UFCDs/code-ufcds/code-ufcds";
import UFCDsGlobais from "./pages/MenuSuperAdmin/UFCDsGlobais/ufcdsGlobais";
import Templates from "./pages/MenuSuperAdmin/Templates/templates";
import TemplatesIndividual from "./pages/MenuSuperAdmin/Templates/TemplateIndividual/templateIndividual";
import Moodle from "./pages/MenuSuperAdmin/Moodle/GerirMoodle/gerirMoodle";
import CriarMoodle from "./pages/MenuSuperAdmin/Moodle/CriarMoodle/criarMoodle";
import Turmas from "./pages/MenuSuperAdmin/Turmas/turmas";
import Turma from "./pages/MenuSuperAdmin/Turmas/Turma/turma";
import FormandoTurma from "./pages/MenuSuperAdmin/Turmas/Turma/Individual/individual";
import Course from "./pages/MenuSuperAdmin/Courses/Courses";
import AvaliacaoCoordenador from "./pages/MenuSuperAdmin/Courses/Evaluation/evaluation";
import AddCourse from "./pages/MenuSuperAdmin/Courses/AddCourses/AddCourses";
import CourseCreated from "./pages/MenuSuperAdmin/Courses/AddCourses/CourseCreated/courseCreated";
import CourseIndividualGerirDTP from "./pages/MenuSuperAdmin/Courses/CoursesIndividual/GerirDTP/gerirDtp";
import CourseIndividualVerDTP from "./pages/MenuSuperAdmin/Courses/CoursesIndividual/VerDTP/DTP";
import CourseIndividual from "./pages/MenuSuperAdmin/Courses/CoursesIndividual/CoursesIndividual";
import PercursoTurma from "./pages/MenuSuperAdmin/Courses/CoursesIndividual/TurmaPercurso/turmaPercurso";
import FormandoTurmaPercurso from "./pages/MenuSuperAdmin/Courses/CoursesIndividual/TurmaPercurso/Individual/individual"
import FormandoCandidaturasTurmaPercurso from "./pages/MenuSuperAdmin/Turmas/Turma/Candidaturas/candidaturas.jsx"
import PercursoFormadores from "./pages/MenuSuperAdmin/Courses/CoursesIndividual/FormadoresPercurso/formadoresPercurso";
import PercursoFormadoresIndividual from "./pages/MenuSuperAdmin/Courses/CoursesIndividual/FormadoresPercurso/FormadorIndividual/formadorIndividual";
import PercursoUfcdsAssiduidade from "./pages/MenuSuperAdmin/Courses/CoursesIndividual/UfcdsPercurso/Assiduidade/assiduidade";
import PercursoUfcds from "./pages/MenuSuperAdmin/Courses/CoursesIndividual/UfcdsPercurso/ufcdsPercurso";
import PercursoUfcdsIndividualModulos from "./pages/MenuSuperAdmin/Courses/CoursesIndividual/UfcdsPercurso/Modulos/modulos.jsx";
import PercursoUfcdsFormAvaliacaoFormador from "./pages/MenuSuperAdmin/Courses/CoursesIndividual/UfcdsPercurso/FormAvaliacao/formAvaliacaoFormador.jsx";
import PercursoUfcdsFormAvaliacaoCoordenador from "./pages/MenuSuperAdmin/Courses/CoursesIndividual/UfcdsPercurso/FormAvaliacao/formAvaliacaoCoordenador.jsx";
import PercursoUfcdsAvaliacao from "./pages/MenuSuperAdmin/Courses/CoursesIndividual/UfcdsPercurso/Evaluation/evaluation";
import PercursoUfcdsIndividualPauta from "./pages/MenuSuperAdmin/Courses/CoursesIndividual/UfcdsPercurso/Pauta/pauta";
import PercursoUfcdsIndividualTurma from "./pages/MenuSuperAdmin/Courses/CoursesIndividual/UfcdsPercurso/turmaUfcd/turma";
import PercursoCronograma from "./pages/MenuSuperAdmin/Courses/CoursesIndividual/CronogramaPercurso/CronogramaPercurso";
import EntFinanciadora from "./pages/MenuSuperAdmin/FundingEntity/fundingEntity";
import EntFormadora from "./pages/MenuSuperAdmin/TrainingEntity/trainingEntity";
import EntFinanciadoraIndividual from "./pages/MenuSuperAdmin/FundingEntity/Individual/individual";
import EntFormadoraIndividual from "./pages/MenuSuperAdmin/TrainingEntity/Individual/individual";
import CriarEntFormadora from "./pages/MenuSuperAdmin/TrainingEntity/createTrainingEntity";
import CriarEntFinanciadora from "./pages/MenuSuperAdmin/FundingEntity/createFundingEntity";

// Administrador Regular
import MenuAdmin from "./pages/MenuAdmin/menuAdmin"
import MenuAdminAllCandidacies from "./pages/MenuAdmin/AllCandidacies/allCandidacies";
import MenuAdminCandidacie from "./pages/MenuAdmin/AllCandidacies/Individual/individual";
import MenuAdminAddExternCoordinators from "./pages/MenuAdmin/Coordinators/addCoordinators";
import MenuAdminCoordinators from "./pages/MenuAdmin/Coordinators/coordinators";
import MenuAdminCategoria from "./pages/MenuAdmin/Categorias/categoria.jsx";
import MenuAdminCategoriaIndividual from "./pages/MenuAdmin/Categorias/CategoriaIndividual/categoriaIndividual.jsx";
import MenuAdminOperacoes from "./pages/MenuAdmin/Operacoes/operacoes";
import MenuAdminOperacoesIndividual from "./pages/MenuAdmin/Operacoes/OperacoesInvidual/operacoesIndividual.jsx";
import MenuAdminCoordinator from "./pages/MenuAdmin/Coordinators/Coordinator/coordinator";
import MenuAdminAllFormandos from "./pages/MenuAdmin/AllFormandos/allFormandos";
import MenuAdminFormando from "./pages/MenuAdmin/AllFormandos/Individual/individual";
import MenuAdminList from "./pages/MenuAdmin/ListTrainers/listTrainers.jsx";
import MenuAdminTrainer from "./pages/MenuAdmin/ListTrainers/Trainer/trainer";
import MenuAdminUFCDs from "./pages/MenuAdmin/UFCDs/ufcds";
import MenuAdminTemplates from "./pages/MenuAdmin/Templates/templates";
import MenuAdminTemplatesIndividual from "./pages/MenuAdmin/Templates/TemplateIndividual/templateIndividual";
import MenuAdminUFCDsGlobais from "./pages/MenuAdmin/UFCDsGlobais/ufcdsGlobais";
import MenuAdminUFCDsFullList from "./pages/MenuAdmin/ListTrainers/Trainer/UFCDsHistórico/ufcdsHistórico";
import MenuAdminUFCDsIndividual from "./pages/MenuAdmin/ListTrainers/Trainer/UFCDsIndividual/ufcdsindividual";
import MenuAdminUFCDsEvaluation from "./pages/MenuAdmin/ListTrainers/Trainer/UFCDsIndividual/Evaluation/evaluation";
import MenuAdminFullListUFCDsEvaluation from "./pages/MenuAdmin/ListTrainers/Trainer/UFCDsHistórico/Evaluation/evaluation";
import MenuAdminUFCDsToCode from "./pages/MenuAdmin/UFCDs/code-ufcds/code-ufcds";
import MenuAdminProfile from "./pages/MenuAdmin/Profile/profile";
import MenuAdminChangePassword from "./pages/MenuAdmin/Profile/ChangePassword/changePassword";
import MenuAdminCourse from "./pages/MenuAdmin/Courses/Courses";
import MenuAdminAvaliacaoCoordenador from "./pages/MenuAdmin/Courses/Evaluation/evaluation";
import MenuAdminTurmas from "./pages/MenuAdmin/Turmas/turmas";
import MenuAdminTurma from "./pages/MenuAdmin/Turmas/Turma/turma";
import MenuAdminFormandoTurma from "./pages/MenuAdmin/Turmas/Turma/Individual/individual";
import MenuAdminFormandoCandidaturasTurma from "./pages/MenuAdmin/Turmas/Turma/Candidaturas/candidaturas.jsx";
import MenuAdminAddCourse from "./pages/MenuAdmin/Courses/AddCourses/AddCourses";
import MenuAdminFormandoTurmaCourse from "./pages/MenuAdmin/Courses/CoursesIndividual/TurmaPercurso/Individual/individual";
import MenuAdminCourseIndividual from "./pages/MenuAdmin/Courses/CoursesIndividual/CoursesIndividual";
import MenuAdminCourseIndividualGerirDTP from "./pages/MenuAdmin/Courses/CoursesIndividual/GerirDTP/gerirDtp";
import MenuAdminCourseIndividualVerDTP from "./pages/MenuAdmin/Courses/CoursesIndividual/VerDTP/DTP";
import MenuAdminCourseCreated from "./pages/MenuAdmin/Courses/AddCourses/CourseCreated/courseCreated";
import MenuAdminPercursoTurma from "./pages/MenuAdmin/Courses/CoursesIndividual/TurmaPercurso/turmaPercurso";
import MenuAdminPercursoFormadores from "./pages/MenuAdmin/Courses/CoursesIndividual/FormadoresPercurso/formadoresPercurso";
import MenuAdminPercursoFormadoresIndividual from "./pages/MenuAdmin/Courses/CoursesIndividual/FormadoresPercurso/FormadorIndividual/formadorIndividual";
import MenuAdminPercursoUfcdsAssiduidade from "./pages/MenuAdmin/Courses/CoursesIndividual/UfcdsPercurso/Assiduidade/assiduidade";
import MenuAdminPercursoUfcds from "./pages/MenuAdmin/Courses/CoursesIndividual/UfcdsPercurso/ufcdsPercurso";
import MenuAdminPercursoUfcdsIndividualModulos from "./pages/MenuAdmin/Courses/CoursesIndividual/UfcdsPercurso/Modulos/modulos.jsx";
import MenuAdminPercursoUfcdsFormAvaliacaoFormador from "./pages/MenuAdmin/Courses/CoursesIndividual/UfcdsPercurso/FormAvaliacao/formAvaliacaoFormador.jsx";
import MenuAdminPercursoUfcdsFormAvaliacaoCoordenador from "./pages/MenuAdmin/Courses/CoursesIndividual/UfcdsPercurso/FormAvaliacao/formAvaliacaoCoordenador.jsx";
import MenuAdminPercursoUfcdsAvaliacao from "./pages/MenuAdmin/Courses/CoursesIndividual/UfcdsPercurso/Evaluation/evaluation";
import MenuAdminPercursoUfcdsIndividualPauta from "./pages/MenuAdmin/Courses/CoursesIndividual/UfcdsPercurso/Pauta/pauta";
import MenuAdminPercursoUfcdsIndividualTurma from "./pages/MenuAdmin/Courses/CoursesIndividual/UfcdsPercurso/turmaUfcd/turma";
import MenuAdminPercursoCronograma from "./pages/MenuAdmin/Courses/CoursesIndividual/CronogramaPercurso/CronogramaPercurso";
import MenuAdminEntFormadora from "./pages/MenuAdmin/TrainingEntity/trainingEntity";
import MenuAdminCriarEntFormadora from "./pages/MenuAdmin/TrainingEntity/createTrainingEntity";
import MenuAdminEntFormadoraIndividual from "./pages/MenuAdmin/TrainingEntity/Individual/individual.jsx";
import MenuAdminEntFinanciadora from "./pages/MenuAdmin/FundingEntity/fundingEntity";
import MenuAdminCriarEntFinanciadora from "./pages/MenuAdmin/FundingEntity/createFundingEntity";
import MenuAdminEntFinanciadoraIndividual from "./pages/MenuAdmin/FundingEntity/Individual/individual.jsx";
import MenuAdminAddAdministradores from "./pages/MenuAdmin/Administradores/addAdministradores";
import MenuAdminListAdmins from "./pages/MenuAdmin/AllAdmins/allAdmins";
import MenuAdminListAdminsIndividual from "./pages/MenuAdmin/AllAdmins/Individual/individual";
import MenuAdminAtividade from "./pages/MenuAdmin/Coordinators/Coordinator/Atividade/atividade"
import MenuAdminAtividadeAvaliar from "./pages/MenuAdmin/Coordinators/Coordinator/Atividade/Evaluation/evaluation"
import MenuAdminAvaliar from "./pages/MenuAdmin/Coordinators/Coordinator/Avaliacao/avaliacao"

// Coordenador
import MenuCoordenador from "./pages/MenuCoordenador/menuCoordenador"
import MenuCoordenadorProfile from "./pages/MenuCoordenador/Profile/profile";
import MenuCoordenadorChangePassword from "./pages/MenuCoordenador/Profile/ChangePassword/changePassword";
import MenuCoordenadorCourse from "./pages/MenuCoordenador/Courses/Courses";
import MenuCoordenadorTurmas from "./pages/MenuCoordenador/Turmas/turmas";
import MenuCoordenadorTurma from "./pages/MenuCoordenador/Turmas/Turma/turma";
import MenuCoordenadorTurmaFormando from "./pages/MenuCoordenador/Turmas/Turma/AlunoIndividual/alunoIndividual";
import MenuCoordenadorList from "./pages/MenuCoordenador/ListTrainers/listTrainers";
import MenuCoordenadorTrainer from "./pages/MenuCoordenador/ListTrainers/Trainer/trainer";
import MenuCoordenadorUFCDsFullListAdmin from "./pages/MenuCoordenador/ListTrainers/Trainer/UFCDsHistórico/ufcdsHistórico";
import MenuCoordenadorUFCDsIndividual from "./pages/MenuCoordenador/ListTrainers/Trainer/UFCDsIndividual/ufcdsindividual.jsx";
import MenuCoordenadorUFCDsEvaluation from "./pages/MenuCoordenador/ListTrainers/Trainer/UFCDsIndividual/Evaluation/evaluation";
import MenuCoordenadorUFCDsFullListEvaluation from "./pages/MenuCoordenador/ListTrainers/Trainer/UFCDsHistórico/Evaluation/evaluation";
import MenuCoordenadorDisciplinas from "./pages/MenuCoordenador/Disciplinas/disciplinas";
import MenuCoordenadorDisciplina from "./pages/MenuCoordenador/Disciplinas/disciplina/disciplina";
import MenuCoordenadorDisciplinaModulos from "./pages/MenuCoordenador/Disciplinas/disciplina/Modulos/modulos.jsx";
import MenuCoordenadorDisciplinaPauta from "./pages/MenuCoordenador/Disciplinas/disciplina/pauta/pauta";
import MenuCoordenadorDisciplinaTurma from "./pages/MenuCoordenador/Disciplinas/disciplina/turmaUfcd/turma.jsx";
import MenuCoordenadorCourseIndividual from "./pages/MenuCoordenador/Courses/CoursesIndividual/CoursesIndividual";
import MenuCoordenadorCourseIndividualPra from "./pages/MenuCoordenador/Courses/CoursesIndividual/Pra/pra";
import MenuCoordenadorCourseIndividualGerirDTP from "./pages/MenuCoordenador/Courses/CoursesIndividual/GerirDTP/gerirDtp";
import MenuCoordenadorCourseIndividualVerDTP from "./pages/MenuCoordenador/Courses/CoursesIndividual/VerDTP/DTP";
import MenuCoordenadorPercursoTurma from "./pages/MenuCoordenador/Courses/CoursesIndividual/TurmaPercurso/turmaPercurso";
import MenuCoordenadorPercursoTurmaIndividual from "./pages/MenuCoordenador/Courses/CoursesIndividual/TurmaPercurso/AlunoIndividual/alunoIndividual";
import MenuCoordenadorPercursoFormadores from "./pages/MenuCoordenador/Courses/CoursesIndividual/FormadoresPercurso/formadoresPercurso";
import MenuCoordenadorPercursoFormadorIndividual from "./pages/MenuCoordenador/Courses/CoursesIndividual/FormadoresPercurso/FormadorIndividual/formadorIndividual";
import MenuCoordenadorPercursoFormadorIndividualHistorico from "./pages/MenuCoordenador/Courses/CoursesIndividual/FormadoresPercurso/FormadorHistorico/formadorHistorico.jsx";
import MenuCoordenadorPercursoUfcds from "./pages/MenuCoordenador/Courses/CoursesIndividual/UfcdsPercurso/ufcdsPercurso";
import MenuCoordenadorPercursoUfcdsAvaliacao from "./pages/MenuCoordenador/Courses/CoursesIndividual/UfcdsPercurso/Evaluation/evaluation";
import MenuCoordenadorPercursoUfcdsIndividual from "./pages/MenuCoordenador/Courses/CoursesIndividual/UfcdsPercurso/UfcdsPercursoIndividual/ufcdsPercursoIndividual";
import MenuCoordenadorPercursoUfcdsIndividualModulos from "./pages/MenuCoordenador/Courses/CoursesIndividual/UfcdsPercurso/Modulos/modulos.jsx";
import MenuCoordenadorPercursoUfcdsFormAvaliacaoFormador from "./pages/MenuCoordenador/Courses/CoursesIndividual/UfcdsPercurso/FormAvaliacao/formAvaliacaoFormador.jsx";
import MenuCoordenadorPercursoUfcdsFormAvaliacaoCoordenador from "./pages/MenuCoordenador/Courses/CoursesIndividual/UfcdsPercurso/FormAvaliacao/formAvaliacaoCoordenador.jsx";
import MenuCoordenadorPercursoUfcdsIndividualCronograma from "./pages/MenuCoordenador/Courses/CoursesIndividual/UfcdsPercurso/UfcdsPercursoIndividual/CronogramaUfcdPercurso/cronogramaUfcdPercurso";
import MenuCoordenadorPercursoUfcdsIndividualTurma from "./pages/MenuCoordenador/Courses/CoursesIndividual/UfcdsPercurso/turmaUfcd/turma";
import MenuCoordenadorPercursoCronograma from "./pages/MenuCoordenador/Courses/CoursesIndividual/CronogramaPercurso/CronogramaPercurso";
import MenuCoordenadorIndividual from "./pages/MenuCoordenador/Formandos/Individual/individual";

// Formador
import Candidacy from "./pages/Candidacies/CandidacyFormador/candidacy";
import MenuTrainers from "./pages/MenuFormadores/MenuTrainers/menuTrainers"
import Profile from "./pages/MenuFormadores/MenuTrainers/Profile/profile";
import UFCDsAtivas from "./pages/MenuFormadores/MenuTrainers/UFCDsListed/UFCDsAtivas/ufcdsListed";
import MenuTrainersModulosAtivas from "./pages/MenuFormadores/MenuTrainers/UFCDsListed/UFCDsAtivas/Modulos/modulos"
import MenuTrainersPercursoUfcdsFormAvaliacaoFormador from "./pages/MenuFormadores/MenuTrainers/UFCDsListed/UFCDsAtivas/FormAvaliacao/formAvaliacaoFormador.jsx";
import UFCDsHistorico from "./pages/MenuFormadores/MenuTrainers/UFCDsListed/UFCDsFullRegist/ufcdsFullRegist";
import MenuTrainersModulosHistorico from "./pages/MenuFormadores/MenuTrainers/UFCDsListed/UFCDsFullRegist/Modulos/modulos"
import MenuTrainerHorario from "./pages/MenuFormadores/MenuTrainers/Horário/Horário";
import Presencas from "./pages/MenuFormadores/MenuTrainers/Presencas/presencas";
import MenuTrainersPercursos from "./pages/MenuFormadores/MenuTrainers/Courses/Courses";
import MenuTrainersPercursosIndividual from "./pages/MenuFormadores/MenuTrainers/Courses/CoursesIndividual/CoursesIndividual";
import MenuTrainersPercursosIndividualDTP from "./pages/MenuFormadores/MenuTrainers/Courses/CoursesIndividual/DTP/DTP";
import MenuTrainersPercursosIndividualCronograma from "./pages/MenuFormadores/MenuTrainers/Courses/CoursesIndividual/CronogramaPercurso/CronogramaPercurso"
import MenuTrainersPercursosIndividualUfcds from "./pages/MenuFormadores/MenuTrainers/Courses/CoursesIndividual/CoursesIndividualUfcds/CoursesIndividualUfcds"
import MenuTrainersPercursoPedagogico from "./pages/MenuFormadores/MenuTrainers/Courses/CoursesIndividual/CoursesIndividualUfcds/Modulos/modulos";

import MenuTrainersPercursoEventos from "./pages/MenuFormadores/MenuTrainers/Courses/CoursesIndividual/CoursesIndividualUfcds/Eventos/Eventos";
import MenuTrainersPercursoEventoPlanoSessao from "./pages/MenuFormadores/MenuTrainers/Courses/CoursesIndividual/CoursesIndividualUfcds/Eventos/PlanodeSessao/planodesessao";
import MenuTrainersPercursosIndividualUfcdsPauta from "./pages/MenuFormadores/MenuTrainers/Courses/CoursesIndividual/CoursesIndividualUfcds/Pauta/pauta"
import MenuTrainersPercursosIndividualUfcdsTurma from "./pages/MenuFormadores/MenuTrainers/Courses/CoursesIndividual/CoursesIndividualUfcds/turmaUfcd/turmaUfcd"

// Formando
import LoginFormando from './pages/PortalFormandos/LoginFormando/login';
import CandidacyFormandos from "./pages/Candidacies/CandidacyFormando/candidacyFormandos";
import ConfirmacaoFormandos from "./pages/Candidacies/CandidacyFormando/Recandidatura/recandidaturaFormando";
import AddFiles from "./pages/Candidacies/CandidacyFormando/AddFiles/addFiles";
import MenuFormando from "./pages/PortalFormandos/MenuFormando/menuFormando";
import MenuFormandoAvaliacao from "./pages/PortalFormandos/MenuFormando/Avaliacao/avaliacao";
import MenuFormandoPerfil from "./pages/PortalFormandos/MenuFormando/Profile/profile";
import MenuFormandoHorario from "./pages/PortalFormandos/MenuFormando/Horário/Horário";
import ForgotPasswordFormando from "./pages/PortalFormandos/LoginFormando/ForgotPassword/forgotPassword";
import ResetPasswordFormando from "./pages/PortalFormandos/LoginFormando/ResetPassword/resetPassword";


import { ToastContainer } from "react-toastify";
import PrivateRoutesFormando from "./components/PrivateRoutes/Formando";
import MoodleIndividual from "./pages/MenuSuperAdmin/Moodle/GerirMoodle/Individual/individual";
import PrivateRoutesCoordinatorFormador from "./components/PrivateRoutes/anyUser.jsx";

function App() {

    /* const[isAuthenticated, setisAuthenticated] = useState(false) */

    return (
        <div className="App">
            <CookiesDisclaimer />
            <ToastContainer />
            <Routes>
                <Route path='/' exact element={<Home />} />
                <Route path='/MenuFormadores' element={<MenuFormadores />} />
                <Route path='/portalformandos' element={<PortalFormandos />} />
                <Route path='/login' element={<Login />} />
                <Route path='/logincoordenador' element={<LoginCoordenador />} />
                <Route path='/loginformando' element={<LoginFormando />} />
                <Route path='/loginformador' element={<LoginFormador />} />
                <Route path='/candidacy' element={<Candidacy />} />
                <Route path='/inscricao/candidatura/:id_curso' element={<CandidacyFormandos />} />
                <Route path='/inscricao/:id_curso' element={<ConfirmacaoFormandos />} />
                <Route path='/ficheiros/:id' element={<AddFiles />} />
                <Route path="/addExternCoordinators" element={<AddExternCoordinators />} />
                <Route path="/forgotpassword" element={<ForgotPassword />} />
                <Route path="/resetpassword/:token" element={<ResetPassword />} />
                <Route path="/forgotpasswordCordinator" element={<ForgotPasswordCoordenador />} />
                <Route path="/resetpasswordCoordenador/:token" element={<ResetPasswordCordenador />} />
                <Route path='/alterarPerfil/:id' element={<EditarProfileFormadorSemAcesso />} /> 
                <Route path='/FormAvaliacaoSatisfacao/:id_ufcd' element={<FormAvaliacaoSatisfacao />} />
                <Route path='/FormAvaliacaoImpacto/:id_percurso/:id_formando' element={<FormAvaliacaoImpacto />} />
               
                {/*Presenças*/}
                <Route path="/forgotpassword/formando" element={<ForgotPasswordFormando />} />
                <Route path="/resetpassword/formando/:token" element={<ResetPasswordFormando />} />

                <Route path="/forgotpassword/formador" element={<ForgotPasswordFormador />} />
                <Route path="/resetpassword/formador/:token" element={<ResetPasswordFormador />} />

                {/* Menu Admin2 */}
                <Route element={<PrivateRoutesAdmin2 />}>
                    <Route path="/MenuSuperAdmin" element={<Menu />} />
                    <Route path="/MenuSuperAdmin/Profile/:id_candidatura" element={<MenuProfile />} />
                    <Route path="/MenuSuperAdmin/Profile/:id_candidatura/changepassword" element={<ChangePassword />} />
                    <Route path="/MenuSuperAdmin/administradores" element={<Administradores />} />
                    <Route path="/MenuSuperAdmin/addAdministradores" element={<AddAdministradores />} />
                    <Route path="/MenuSuperAdmin/administradores/administrador/:id_admin" element={<Administrador />} />
                    <Route path="/MenuSuperAdmin/coordinators" element={<Coordinators />} />

                    {/* Categoria */}
                    <Route path="/MenuSuperAdmin/categorias" element={<Categoria />} />
                    <Route path="/MenuSuperAdmin/categorias/:id_categoria" element={<CategoriaIndividual />} />

                    <Route path="/MenuSuperAdmin/operacoes" element={<Operacoes />} />
                    <Route path="/MenuSuperAdmin/operacoes/:id_operacao" element={<OperacoesIndividual />} />

                    <Route path="/MenuSuperAdmin/addCoordinators" element={<AddCoordinators />} />
                    <Route path="/MenuSuperAdmin/candidacies" element={<AllCandidacies />} />
                    <Route path="/MenuSuperAdmin/formandos" element={<AllFormandos />} />
                    <Route path="/MenuSuperAdmin/formando/individual/:id_formando" element={<Formando />} />
                    <Route path="/MenuSuperAdmin/list" element={<List />} />
                    <Route path="/MenuSuperAdmin/list/:id_candidato" element={<Trainer />} />
                    <Route path="/MenuSuperAdmin/list/ufcds/:id_candidatura" element={<UFCDsIndividual />} />
                    <Route path="/MenuSuperAdmin/list/Full-ufcds/:id_candidatura" element={<UFCDsFullListAdmin />} />
                    <Route path="/MenuSuperAdmin/list/ufcds/:id_candidatura/:id_ufcd" element={<UFCDsEvaluation />} />
                    <Route path="/MenuSuperAdmin/list/Full-ufcds-evaluation/:id_candidatura/:id_ufcd" element={<UFCDsFullListEvaluation />} />
                    <Route path="/MenuSuperAdmin/individual/:id_candidato" element={<Individual />} />
                    <Route path="/MenuSuperAdmin/UFCDs" element={<UFCDs />} />
                    <Route path="/MenuSuperAdmin/UFCDs/code-ufcds/:codeUfcd" element={<UFCDsToCode />} />
                    {/* Coordenadores */}
                    <Route path="/MenuSuperAdmin/coordinators/coordinator/:id_coordinator" element={<Coordinator />} />
                    <Route path="/MenuSuperAdmin/coordinators/atividade/:id" element={<MenuAtividadeCoordenador />} />
                    <Route path="/MenuSuperAdmin/coordinators/atividade/:id/avaliar/:id_percurso" element={<MenuAvaliarCoordenador />} />
                    <Route path="/MenuSuperAdmin/coordinators/avaliacao/:id" element={<MenuAvaliacaoCoordenador />} />
                    {/*Entidade Financiadora*/}
                    <Route path="/MenuSuperAdmin/EntFinanciadora" element={<EntFinanciadora />} />
                    <Route path="/MenuSuperAdmin/CriarEntFinanciadora" element={<CriarEntFinanciadora />} />
                    <Route path="/MenuSuperAdmin/EntFinanciadora/:id_item" element={<EntFinanciadoraIndividual />} />
                    {/*Entidade Formadora*/}
                    <Route path="/MenuSuperAdmin/EntFormadora" element={<EntFormadora />} />
                    <Route path="/MenuSuperAdmin/CriarEntFormadora" element={<CriarEntFormadora />} />
                    <Route path="/MenuSuperAdmin/EntFormadora/:id_item" element={<EntFormadoraIndividual />} />
                    {/*Turmas*/}
                    <Route path="/MenuSuperAdmin/turmas" element={<Turmas />} />
                    <Route path="/MenuSuperAdmin/turmas/turma/:id_curso" element={<Turma />} />
                    <Route path="/MenuSuperAdmin/turmas/turma/:id_curso/:id_formando" element={<FormandoTurma />} />
                    <Route path="/MenuSuperAdmin/turmas/turma/:id_curso/:id_formando/candidaturas" element={<FormandoCandidaturasTurmaPercurso />} />
                    {/*Cursos*/}
                    <Route path="/MenuSuperAdmin/cursos" element={<Course />} />
                    <Route path="/MenuSuperAdmin/cursos/addcursos" element={<AddCourse />} />
                    <Route path="/MenuSuperAdmin/cursos/:id_curso" element={<CourseIndividual />} />
                    <Route path="/MenuSuperAdmin/cursos/avaliacao/:id_curso" element={<AvaliacaoCoordenador />} />
                    <Route path="/MenuSuperAdmin/cursos/:id_curso/gerirDTP" element={<CourseIndividualGerirDTP />} />
                    <Route path="/MenuSuperAdmin/cursos/:id_curso/verDTP" element={<CourseIndividualVerDTP />} />
                    <Route path='/MenuSuperAdmin/cursos/:id_curso/turma' element={<PercursoTurma />} />
                    <Route path="/MenuSuperAdmin/cursos/:id_curso/individual/:id_formando" element={<FormandoTurmaPercurso />} />
                    <Route path='/MenuSuperAdmin/cursos/:id_curso/formadores' element={<PercursoFormadores />} />
                    <Route path='/MenuSuperAdmin/cursos/:id_curso/formadores/:id_candidato' element={<PercursoFormadoresIndividual />} />
                    <Route path='/MenuSuperAdmin/cursos/:id_curso/Ufcds' element={<PercursoUfcds />} />
                    <Route path='/MenuSuperAdmin/cursos/:id_curso/Ufcds/:id_ufcd/Modulos' element={<PercursoUfcdsIndividualModulos />} />
                    <Route path='/MenuSuperAdmin/cursos/:id_curso/Ufcds/:id_ufcd/FormAvaliacao/formador/:id_avaliador' element={<PercursoUfcdsFormAvaliacaoFormador />} />
                    <Route path='/MenuSuperAdmin/cursos/:id_curso/Ufcds/:id_ufcd/FormAvaliacao/coordenador/:id_avaliador' element={<PercursoUfcdsFormAvaliacaoCoordenador />} />
                    <Route path='/MenuSuperAdmin/cursos/:id_curso/Ufcds/:id_ufcd/pauta' element={<PercursoUfcdsIndividualPauta />} />
                    <Route path='/MenuSuperAdmin/cursos/:id_curso/Ufcds/:id_ufcd/turma' element={<PercursoUfcdsIndividualTurma />} />
                    <Route path='/MenuSuperAdmin/cursos/:id_curso/Ufcds/:id_ufcd/assiduidade' element={<PercursoUfcdsAssiduidade />} />
                    <Route path='/MenuSuperAdmin/cursos/:id_curso/Ufcds/:id_ufcd/evaluation/:id_formador' element={<PercursoUfcdsAvaliacao />} />
                    <Route path='/MenuSuperAdmin/cursos/:id_curso/Cronograma' element={<PercursoCronograma />} />
                    <Route path='/MenuSuperAdmin/coursecreated' element={<CourseCreated />} />

                    {/*Templates*/}
                    <Route path="/MenuSuperAdmin/templates" element={<Templates />} />
                    <Route path="/MenuSuperAdmin/templates/:id_template" element={<TemplatesIndividual />} />

                    {/*Moodle*/}
                    <Route path="/MenuSuperAdmin/Moodle" element={<Moodle />} />
                    <Route path="/MenuSuperAdmin/Moodle/:id" element={<MoodleIndividual />} />
                    <Route path="/MenuSuperAdmin/criarMoodle" element={<CriarMoodle />} />

                    {/*UFCDs Modelo*/}
                    <Route path="/MenuSuperAdmin/UFCDsGlobais" element={<UFCDsGlobais />} />
                </Route>
                {/* Menu Formando */}
                <Route element={<PrivateRoutesFormando />}>
                    <Route path="/menuformando/:id_formando" element={<MenuFormando />} />
                    <Route path="/menuformando/:id_formando/avaliacao" element={<MenuFormandoAvaliacao />} />
                    <Route path="/menuformando/:id_formando/profile" element={<MenuFormandoPerfil />} />
                    <Route path="/menuformando/:id_formando/horario" element={<MenuFormandoHorario />} />
                </Route>
                {/* Menu trainers */}
                <Route element={<PrivateRoutesUser />}>
                    <Route path="/menutrainers/:id_candidatura" element={<MenuTrainers />} />
                    <Route path="/menutrainers/:id_candidatura/UFCDsListed/ativas" element={<UFCDsAtivas />} />
                    <Route path="/menutrainers/:id_candidatura/UFCDsListed/ativas/modulos/:id_ufcd" element={<MenuTrainersModulosAtivas />} />
                    <Route path="/menutrainers/:id_candidatura/UFCDsListed/historico" element={<UFCDsHistorico />} />
                    <Route path="/menutrainers/:id_candidatura/UFCDsListed/historico/modulos/:id_ufcd" element={<MenuTrainersModulosHistorico />} />
                    <Route path='/menutrainers/:id_candidatura/cursos/:id_curso/Ufcds/:id_ufcd/FormAvaliacao/formador/:id_avaliador' element={<MenuTrainersPercursoUfcdsFormAvaliacaoFormador />} />
                    <Route path="/menutrainers/:id_candidatura/profile" element={<Profile />} />
                    <Route path="/menutrainers/:id_candidatura/horario" element={<MenuTrainerHorario />} />
                    <Route path="/menutrainers/:id_candidatura/cursos" element={<MenuTrainersPercursos />} />
                    <Route path="/menutrainers/:id_candidatura/cursos/:id_curso" element={<MenuTrainersPercursosIndividual />} />
                    <Route path="/menutrainers/:id_candidatura/cursos/:id_curso/DTP" element={<MenuTrainersPercursosIndividualDTP />} />
                    <Route path="/menutrainers/:id_candidatura/cursos/:id_curso/Cronograma" element={<MenuTrainersPercursosIndividualCronograma />} />
                    <Route path="/menutrainers/:id_candidatura/cursos/:id_curso/Ufcds" element={<MenuTrainersPercursosIndividualUfcds />} />
                    <Route path="/menutrainers/:id_candidatura/cursos/:id_curso/Ufcds/:id_ufcds/eventos" element={<MenuTrainersPercursoEventos />} />
                    <Route path="/menutrainers/:id_candidatura/cursos/:id_curso/Ufcds/:id_ufcds/eventos/:id_evento" element={<MenuTrainersPercursoEventoPlanoSessao />} />
                    <Route path="/menutrainers/:id_candidatura/cursos/:id_curso/Ufcds/:id_ufcd/percursopedagogico" element={<MenuTrainersPercursoPedagogico />} />
                    <Route path="/menutrainers/:id_candidatura/cursos/:id_curso/Ufcds/:id_ufcd/pauta" element={<MenuTrainersPercursosIndividualUfcdsPauta />} />
                    <Route path="/menutrainers/:id_candidatura/cursos/:id_curso/Ufcds/:id_ufcds/turma" element={<MenuTrainersPercursosIndividualUfcdsTurma />} />
                    <Route path='/menutrainers/:id_candidatura/cursos/:id_curso/Ufcds/:id_ufcd/FormAvaliacao/formador/:id_avaliador' element={<MenuCoordenadorPercursoUfcdsFormAvaliacaoFormador />} />

                </Route>
                {/* Menu Admin1 */}
                <Route element={<PrivateRoutesAdmin1 />}>
                    <Route path="/MenuAdmin/:id_candidatura" element={<MenuAdmin />} />
                    {/*Coordenadores*/}
                    <Route path="/MenuAdmin/:id_candidatura/coordinators" element={<MenuAdminCoordinators />} />
                    <Route path="/MenuAdmin/:id_candidatura/coordinators/coordinator/:id_coordinator" element={<MenuAdminCoordinator />} />
                    <Route path="/MenuAdmin/:id_candidatura/addCoordinators" element={<MenuAdminAddExternCoordinators />} />
                    <Route path="/MenuAdmin/:id_candidatura/coordinators/atividade/:id" element={<MenuAdminAtividade />} />
                    <Route path="/MenuAdmin/:id_candidatura/coordinators/atividade/:id/avaliar/:id_percurso" element={<MenuAdminAtividadeAvaliar />} />
                    <Route path="/MenuAdmin/:id_candidatura/coordinators/avaliacao/:id" element={<MenuAdminAvaliar />} />
                    {/*Administração*/}
                    <Route path="/MenuAdmin/addAdministradores/:id_candidatura" element={<MenuAdminAddAdministradores />} />
                    <Route path="/MenuAdmin/:id_candidatura/operacoes" element={<MenuAdminOperacoes />} />
                    <Route path="/MenuAdmin/:id_candidatura/operacoes/:id_operacao" element={<MenuAdminOperacoesIndividual />} />
                    <Route path="/MenuAdmin/Profile/:id_candidatura" element={<MenuAdminProfile />} />
                    <Route path="/MenuAdmin/:id_candidatura/listAdmins" element={<MenuAdminListAdmins />} />
                    <Route path="/MenuAdmin/:id_candidatura/listAdmins/Admin/:id_candidato" element={<MenuAdminListAdminsIndividual />} />
                    <Route path="/MenuAdmin/Profile/:id_candidatura/changepassword" element={<MenuAdminChangePassword />} />
                    {/*Formandos*/}
                    <Route path="/MenuAdmin/:id_candidatura/formandos" element={<MenuAdminAllFormandos />} />
                    <Route path="/MenuAdmin/:id_candidatura/individual/formando/:id_formando" element={<MenuAdminFormando />} />
                    {/* Categoria */}
                    <Route path="/MenuAdmin/:id_candidatura/categorias" element={<MenuAdminCategoria />} />
                    <Route path="/MenuAdmin/:id_candidatura/categorias/:id_categoria" element={<MenuAdminCategoriaIndividual />} />
                    {/*Turmas*/}
                    <Route path="/menuAdmin/:id_candidatura/turmas" element={<MenuAdminTurmas />} />
                    <Route path="/menuAdmin/:id_candidatura/turmas/turma/:id_curso" element={<MenuAdminTurma />} />
                    <Route path="/menuAdmin/:id_candidatura/turmas/turma/:id_curso/:id_formando" element={<MenuAdminFormandoTurma />} />
                    <Route path="/menuAdmin/:id_candidatura/turmas/turma/:id_curso/:id_formando/candidaturas" element={<MenuAdminFormandoCandidaturasTurma />} />
                    {/*Formadores*/}
                    <Route path="/MenuAdmin/:id_candidatura/candidacies" element={<MenuAdminAllCandidacies />} />
                    <Route path="/MenuAdmin/:id_candidatura/candidacies/:id_candidato" element={<MenuAdminCandidacie />} />
                    <Route path="/MenuAdmin/:id_candidatura/list" element={<MenuAdminList />} />
                    <Route path="/MenuAdmin/:id_candidatura/list/:id_candidato" element={<MenuAdminTrainer />} />
                    <Route path="/MenuAdmin/:id_candidatura/list/ufcds/:id_candidato" element={<MenuAdminUFCDsIndividual />} />
                    <Route path="/MenuAdmin/list/ufcds/:id_candidatura/:id_ufcd" element={<MenuAdminUFCDsEvaluation />} />
                    <Route path="/MenuAdmin/:id_candidatura/list/Full-ufcds/:id_candidato" element={<MenuAdminUFCDsFullList />} />
                    <Route path="/MenuAdmin/:id_candidatura/list/Full-ufcds-evaluation/:id_candidato/:id_ufcd" element={<MenuAdminFullListUFCDsEvaluation />} />
                    {/*UFCDs*/}
                    <Route path="/MenuAdmin/:id_candidatura/UFCDs" element={<MenuAdminUFCDs />} />
                    <Route path="/MenuAdmin/:id_candidatura/UFCDs/code-ufcds/:codeUfcd" element={<MenuAdminUFCDsToCode />} />
                    {/*Cursos*/}
                    <Route path="/MenuAdmin/:id_candidatura/cursos" element={<MenuAdminCourse />} />
                    <Route path="/menuAdmin/:id_candidatura/cursos/avaliacao/:id_curso" element={<MenuAdminAvaliacaoCoordenador />} />
                    <Route path="/MenuAdmin/:id_candidatura/cursos/addcursos" element={<MenuAdminAddCourse />} />
                    <Route path="/MenuAdmin/:id_candidatura/cursos/:id_curso" element={<MenuAdminCourseIndividual />} />
                    <Route path="/MenuAdmin/:id_candidatura/cursos/:id_curso/gerirDTP" element={<MenuAdminCourseIndividualGerirDTP />} />
                    <Route path="/MenuAdmin/:id_candidatura/cursos/:id_curso/verDTP" element={<MenuAdminCourseIndividualVerDTP />} />
                    <Route path="/MenuAdmin/:id_candidatura/cursos/:id_curso/:id_formando" element={<MenuAdminFormandoTurmaCourse />} />
                    <Route path='/MenuAdmin/:id_candidatura/cursos/:id_curso/formadores' element={<MenuAdminPercursoFormadores />} />
                    <Route path='/MenuAdmin/:id_candidatura/cursos/:id_curso/formadores/:id_candidato' element={<MenuAdminPercursoFormadoresIndividual />} />
                    <Route path='/MenuAdmin/:id_candidatura/cursos/:id_curso/Ufcds/:id_ufcd/assiduidade' element={<MenuAdminPercursoUfcdsAssiduidade />} />
                    <Route path='/MenuAdmin/:id_candidatura/cursos/:id_curso/Ufcds' element={<MenuAdminPercursoUfcds />} />
                    <Route path='/MenuAdmin/:id_candidatura/cursos/:id_curso/Ufcds/:id_ufcd/Modulos' element={<MenuAdminPercursoUfcdsIndividualModulos />} />
                    <Route path='/MenuAdmin/:id_candidatura/cursos/:id_curso/Ufcds/:id_ufcd/FormAvaliacao/formador/:id_avaliador' element={<MenuAdminPercursoUfcdsFormAvaliacaoFormador />} />
                    <Route path='/MenuAdmin/:id_candidatura/cursos/:id_curso/Ufcds/:id_ufcd/FormAvaliacao/coordenador/:id_avaliador' element={<MenuAdminPercursoUfcdsFormAvaliacaoCoordenador />} />
                    <Route path='/MenuAdmin/:id_candidatura/cursos/:id_curso/Ufcds/:id_ufcd/evaluation/:id_formador' element={<MenuAdminPercursoUfcdsAvaliacao />} />
                    <Route path='/MenuAdmin/:id_candidatura/cursos/:id_curso/Ufcds/:id_ufcd/pauta' element={<MenuAdminPercursoUfcdsIndividualPauta />} />
                    <Route path='/MenuAdmin/:id_candidatura/cursos/:id_curso/Cronograma' element={<MenuAdminPercursoCronograma />} />
                    <Route path='/MenuAdmin/:id_candidatura/coursecreated' element={<MenuAdminCourseCreated />} />
                    <Route path='/MenuAdmin/:id_candidatura/cursos/:id_curso/turma' element={<MenuAdminPercursoTurma />} />
                    <Route path='/MenuAdmin/:id_candidatura/cursos/:id_curso/Ufcds/:id_ufcd/turma' element={<MenuAdminPercursoUfcdsIndividualTurma />} />
                    {/*Entidade Formadora*/}
                    <Route path="/MenuAdmin/:id_candidatura/entFormadoras" element={<MenuAdminEntFormadora />} />
                    <Route path="/MenuAdmin/:id_candidatura/CriarEntFormadora" element={<MenuAdminCriarEntFormadora />} />
                    <Route path="/MenuAdmin/:id_candidatura/entFormadoras/:id_item" element={<MenuAdminEntFormadoraIndividual />} />
                    {/*Entidade Financiadora*/}
                    <Route path="/MenuAdmin/:id_candidatura/entFinanciadoras" element={<MenuAdminEntFinanciadora />} />
                    <Route path="/MenuAdmin/:id_candidatura/CriarEntFinanciadora" element={<MenuAdminCriarEntFinanciadora />} />
                    <Route path="/MenuAdmin/:id_candidatura/entFinanciadoras/:id_item" element={<MenuAdminEntFinanciadoraIndividual />} />
                    {/*Templates*/}
                    <Route path="/MenuAdmin/:id_candidatura/templates" element={<MenuAdminTemplates />} />
                    <Route path="/MenuAdmin/:id_candidatura/templates/:id_template" element={<MenuAdminTemplatesIndividual />} />
                    {/*UFCDs Modelo*/}
                    <Route path="/MenuAdmin/:id_candidatura/UFCDsGlobais" element={<MenuAdminUFCDsGlobais />} />
                </Route>
                {/* Menu Coornedador */}
                <Route element={<PrivateRoutesCoordenadores />}>
                    <Route path="/MenuCoordenador/:id_candidatura" element={<MenuCoordenador />} />
                    {/*Perfil*/}
                    <Route path="/MenuCoordenador/Profile/:id_candidatura" element={<MenuCoordenadorProfile />} />
                    <Route path="/MenuCoordenador/Profile/:id_candidatura/changepassword" element={<MenuCoordenadorChangePassword />} />
                    {/*Turmas*/}
                    <Route path="/MenuCoordenador/:id_candidatura/turmas" element={<MenuCoordenadorTurmas />} />
                    <Route path="/MenuCoordenador/:id_candidatura/turmas/turma/:id_curso" element={<MenuCoordenadorTurma />} />
                    <Route path="/MenuCoordenador/:id_candidatura/turmas/turma/:id_curso/:id_formando" element={<MenuCoordenadorTurmaFormando />} />
                    {/* Formadores */}
                    <Route path="/MenuCoordenador/:id_candidatura/list" element={<MenuCoordenadorList />} />
                    <Route path="/MenuCoordenador/:id_candidatura/list/:id_candidato" element={<MenuCoordenadorTrainer />} />
                    <Route path="/MenuCoordenador/:id_candidatura/list/ufcds/:id_candidato" element={<MenuCoordenadorUFCDsIndividual />} />
                    <Route path="/MenuCoordenador/:id_candidatura/list/Full-ufcds/:id_candidato" element={<MenuCoordenadorUFCDsFullListAdmin />} />
                    <Route path="/MenuCoordenador/:id_candidatura/list/ufcds/:id_candidatura/:id_ufcd" element={<MenuCoordenadorUFCDsEvaluation />} />
                    <Route path="/MenuCoordenador/:id_candidatura/list/Full-ufcds-evaluation/:id_candidatura/:id_ufcd" element={<MenuCoordenadorUFCDsFullListEvaluation />} />
                    {/*Disciplinas*/}
                    <Route path="/MenuCoordenador/:id_candidatura/disciplinas" element={<MenuCoordenadorDisciplinas />} />
                    <Route path="/MenuCoordenador/:id_candidatura/disciplinas/disciplina/:id_curso" element={<MenuCoordenadorDisciplina />} />
                    <Route path="/MenuCoordenador/:id_candidatura/disciplinas/disciplina/:id_curso/Modulos/:id_ufcd" element={<MenuCoordenadorDisciplinaModulos />} />
                    <Route path="/MenuCoordenador/:id_candidatura/disciplinas/:id_curso/pauta/:id_ufcd" element={<MenuCoordenadorDisciplinaPauta />} />
                    <Route path='/MenuCoordenador/:id_candidatura/disciplinas/:id_curso/turma/:id_ufcd' element={<MenuCoordenadorDisciplinaTurma />} />
                    {/*Cursos*/}
                    <Route path="/MenuCoordenador/:id_candidatura/cursos" element={<MenuCoordenadorCourse />} />
                    <Route path="/MenuCoordenador/:id_candidatura/cursos/:id_curso" element={<MenuCoordenadorCourseIndividual />} />
                    <Route path="/MenuCoordenador/:id_candidatura/cursos/:id_curso/Pra/:id_ufcd" element={<MenuCoordenadorCourseIndividualPra />} />
                    <Route path="/MenuCoordenador/:id_candidatura/cursos/:id_curso/gerirDTP" element={<MenuCoordenadorCourseIndividualGerirDTP />} />
                    <Route path="/MenuCoordenador/:id_candidatura/cursos/:id_curso/verDTP" element={<MenuCoordenadorCourseIndividualVerDTP />} />
                    <Route path='/MenuCoordenador/:id_candidatura/cursos/:id_curso/turma' element={<MenuCoordenadorPercursoTurma />} />
                    <Route path='/MenuCoordenador/:id_candidatura/cursos/:id_curso/formadores' element={<MenuCoordenadorPercursoFormadores />} />
                    <Route path='/MenuCoordenador/:id_candidatura/cursos/:id_curso/formadores/:id_candidato' element={<MenuCoordenadorPercursoFormadorIndividual />} />
                    <Route path='/MenuCoordenador/:id_candidatura/cursos/:id_curso/formadoresHistorico/:id_candidato' element={<MenuCoordenadorPercursoFormadorIndividualHistorico />} />
                    <Route path='/MenuCoordenador/:id_candidatura/cursos/:id_curso/turma/:id_formando' element={<MenuCoordenadorPercursoTurmaIndividual />} />
                    <Route path='/MenuCoordenador/:id_candidatura/cursos/:id_curso/Ufcds' element={<MenuCoordenadorPercursoUfcds />} />
                    <Route path='/MenuCoordenador/:id_candidatura/cursos/:id_curso/Ufcds/:id_ufcd/evaluation/:id_formador' element={<MenuCoordenadorPercursoUfcdsAvaliacao />} />
                    <Route path='/MenuCoordenador/:id_candidatura/cursos/:id_curso/Ufcds/:id_ufcd' element={<MenuCoordenadorPercursoUfcdsIndividual />} />
                    <Route path='/MenuCoordenador/:id_candidatura/cursos/:id_curso/Ufcds/:id_ufcd/Modulos' element={<MenuCoordenadorPercursoUfcdsIndividualModulos />} />
                    <Route path='/MenuCoordenador/:id_candidatura/cursos/:id_curso/Ufcds/:id_ufcd/FormAvaliacao/formador/:id_avaliador' element={<MenuCoordenadorPercursoUfcdsFormAvaliacaoFormador />} />
                    <Route path='/MenuCoordenador/:id_candidatura/cursos/:id_curso/Ufcds/:id_ufcd/FormAvaliacao/coordenador/:id_avaliador' element={<MenuCoordenadorPercursoUfcdsFormAvaliacaoCoordenador />} />
                    <Route path='/MenuCoordenador/:id_candidatura/cursos/:id_curso/Ufcds/:id_ufcd/turma' element={<MenuCoordenadorPercursoUfcdsIndividualTurma />} />
                    <Route path='/MenuCoordenador/:id_candidatura/cursos/:id_curso/Ufcds/:id_ufcd/Cronograma' element={<MenuCoordenadorPercursoUfcdsIndividualCronograma />} />
                    <Route path='/MenuCoordenador/:id_candidatura/cursos/:id_curso/Cronograma' element={<MenuCoordenadorPercursoCronograma />} />
                    <Route path="/MenuCoordenador/:id_candidatura/Turma/:id_curso/formando/individual/:id_formando" element={<MenuCoordenadorIndividual />} />
                </Route>
                <Route element={<PrivateRoutesCoordinatorFormador />}>
                    <Route path="/Presencas/:id_ufcds/:id_evento" element={<Presencas />} />
                </Route>
                <Route path="*" element={<Page404 />} />

            </Routes>
        </div>
    )
}

export default App