import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../../../components/Footer";
import NavbarAdmin1 from "../../../components/Navbars/NavbarAdmin1/NavbarAdmin1";
import 'react-toastify/dist/ReactToastify.css';
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { IoMdRemoveCircle } from "react-icons/io";
import Cookies from "js-cookie";
import toastSuccess from "../../../components/Toast/toastSuccess";
import ConfirmToaster from "../../../components/Toast/toastConfirmChoice";
import CheckTypeAdmin from "../../../components/CheckTypeAdmin/CheckTypeAdmin";

function Formandos() {
    const [data, setData] = useState([]);
    const [dataToDisplay, setDataToDisplay] = useState([])
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [totalPages, setTotalPages] = useState();
    const [savedSearchTerm, setSavedSearchTerm] = useState(
        Cookies.get("searchTerm")
      );
    const [searchTerm, setSearchTerm] = useState(savedSearchTerm ? savedSearchTerm : "");
    const [isCheckedSaved, setIscheckedSaved] = useState(Cookies.get("isChecked"))
    const [isChecked, setIsChecked] = useState(isCheckedSaved ? isCheckedSaved : "");
    const [savedCurrentPage, setSavedCurrentPage] = useState(Number(Cookies.get("currentPage")) || 1);
    const [currentPage, setCurrentPage] = useState(savedCurrentPage ? savedCurrentPage : 1);
    const [savedItemsPag, setSavedItemsPag] = useState(
        Cookies.get("itemsPerPage")
      );
    const [itemsPag, setItemsPag] = useState(savedItemsPag ? savedItemsPag : 5)
      const params = useParams();
      const navigate = useNavigate();

    useEffect(() => {
        Cookies.set("currentPage", currentPage);
        Cookies.set("searchTerm", searchTerm);
        Cookies.set("isChecked", isChecked);
        Cookies.set(`itemsPag`, itemsPag);
       getFormandos();
    }, [loading, isChecked, currentPage, searchTerm, itemsPag, totalPages]);

    function deleteFormando(_id) {
        fetch(`${process.env.REACT_APP_API_URL}/formandos/${_id}`, {
            method: "DELETE",
            headers: {
                'authorization': localStorage.getItem('admin2')
            }
        }).then((result) => {
            result.json().then((resp) => {
                toastSuccess('Candidatura removida com sucesso!')
                getFormandos()
            })
        })
    }
     // Go to the last page
  function goToLastPage() {
    if (currentPage !== totalPages) {
      setCurrentPage(totalPages);
    }
  }
   // Go to the first page
  function goToFirstPage() {
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  }

   // Increase the current page
  function increasePagination() {
    setCurrentPage((prevCurrentPage) => {
      if (prevCurrentPage < totalPages) {
        return prevCurrentPage + 1;
      }
      return prevCurrentPage;
    });
  }

    // Decrease the current page
  function decreasePagination() {
    setCurrentPage((prevCurrentPage) => {
      if (prevCurrentPage > 1) {
        return prevCurrentPage - 1;
      }
      return prevCurrentPage;
    });
  }

    const handleClick = (event, item) => {
        // Check if the clicked element is a <td>
        if (event.target.tagName === 'TD') {
            // Check if the <td> has a 'clickable' class
            if (!event.target.classList.contains('candidate')) {
                // Navigate to the desired location
                // Replace 'destination' with your actual navigation logic
            }
            else navigate("/MenuSuperAdmin/formando/individual/" + item._id)
        }
    };

    


    // getAll formandos of Admin
    async function getFormandos(){
           
        try{ 
            
            const response = await fetch(`${process.env.REACT_APP_API_URL}/formandos/getAllFormandos`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'authorization': localStorage.getItem('admin2')
            },
            body: JSON.stringify({
                filters: {valid: isChecked},
                searchTerm: searchTerm,
                searchFields: ["fullName",
                    "email",
                    "locality", "nif",
                ],
                populate: ["percurso"],
                subPopulate: ["percurso.entFormadora", "percurso.entFinanciadora"],
                andOrCondition: "OR",
                orderBy: "fullName",
                orderByDirection: "asc",
                page: currentPage,
                pageSize: itemsPag
                
            })
        });

        const result = await response.json();
        const totalItems = result.totalItems;
        const totalPages = Math.ceil(totalItems / result.pageSize)
        
        if (currentPage > totalPages) {
                        setCurrentPage(totalPages > 0 ? totalPages : 1);
                      } else {
                        setTotalPages(totalPages);
                      }
                      setData(result.data)
                    setLoading(false);
        } catch (error){
            console.error("Error fetching data: ", error);
                    setError(error);
        }
    }

    if (loading) return (
        <div className="flex items-center justify-center w-screen h-screen bg-secundary-color">
            <div className="loading"></div>
        </div>
    );
    if (error) return "Error!";

    return (
        <>
            <main className="min-h-screen">
                <div className="w-full">
                    <NavbarAdmin1 currentPage={"verFormandos"} />
                </div>
                <div className="flex-grow mt-8">
                    <Link to={'/menuadmin/' + params.id_candidatura} className="back-button-div-noNavbar">
                        <img className='back-button-img' alt="Back" src={process.env.PUBLIC_URL + "/img/back_arrow.svg"} />
                    </Link>
                    <h1 className="mx-4 mt-4 title sm:mt-0">Lista de Formandos</h1>
                    <div className="search-div">
                        <label className="search-input-label">Pesquisar: </label>
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                                <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                </svg>
                            </div>
                            <input type="text" className="search-input" placeholder="ex: Nome, Email ..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                        </div>
                    </div>
                    <div className="mt-4 mb-2">
                        <label className="text-text-main-color" >Formandos: </label>
                        <select onWheel={(e) => e.target.blur()} className="mr-3 search-selector" name="sel" id="sel" value={isChecked} onChange={(e) => setIsChecked(e.target.value)}>
                            <option value="">Todos</option>
                            <option value="Validado">Validados</option>
                            <option value="Por Validar">Não Validados</option>
                            <option value="Excluido">Excluídos</option>
                        </select>
                    </div>
                    {data && data.length > 0 ? <>
                        <div className="flex justify-center items-center mb-1">
                            <label className="text-text-main-color">Dados por página: </label>
                            <select onWheel={(e) => e.target.blur()} className="selector w-14 ml-1"
                                value={itemsPag}
                                onChange={(e) => setItemsPag(parseInt(e.target.value))}>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                            </select>
                        </div>
                        <div className="table-container">
                            <table className="my-table">

                                <thead className="bg-main-color">
                                    <tr className="text-white h-14">
                                        <th className="p-2 min-w-[80px] border border-white">Nome Completo</th>
                                        <th className="p-2 min-w-[80px] border border-white">Género</th>
                                        <th className="p-2 min-w-[80px] border border-white">Data nascimento</th>
                                        <th className="p-2 min-w-[80px] border border-white">Número de telefone</th>
                                        <th className="p-2 min-w-[80px] border border-white">E-mail</th>
                                        <th className="p-2 min-w-[80px] border border-white">Habilitações Académicas</th>
                                        <th className="p-2 min-w-[80px] border border-white">Localidade</th>
                                        <th className="p-2 min-w-[80px] border border-white">Nif</th>
                                        <th className="p-2 min-w-[80px] border border-white">Estado</th>
                                        <th className="p-2 min-w-[80px] border border-white">Curso</th>
                                        <th className="p-2 min-w-[80px] border border-white">Entidade Formadora</th>
                                        <th className="p-2 min-w-[80px] border border-white">Entidade Financiadora</th>
                                        {CheckTypeAdmin() ===true  &&<th className="p-2 min-w-[80px] border border-white">Remover Formando</th>}
                                    </tr>
                                </thead>
                                {data.length > 0 ? (
                                    <tbody className="text-text-main-color">
                                        {data.map((item, index) => (
                                            <tr key={index} className="row-click" onClick={(e) => handleClick(e, item)}>
                                                <td className="border border-white candidate">{item.fullName}</td>
                                                <td className="border border-white candidate">{item.gender}</td>
                                                <td className="border border-white candidate">{item.birthDate}</td>
                                                <td className="border border-white candidate">{item.cellNumber}</td>
                                                <td className="border border-white candidate">{item.email}</td>
                                                <td className="border border-white candidate">{item.academicDegree}</td>
                                                <td className="border border-white candidate">{item.locality}</td>
                                                <td className="border border-white candidate">{item.nif}</td>
                                                {item.valid === "Validado" ? <td className="border border-white candidate">Validado</td> : <td className="border border-white">Por Validar</td>}
                                                {item.percurso.length > 0 && item.percurso[0].name ? <td className="border border-white candidate">{item.percurso[0].name}</td> : <td className="border border-white">Sem Curso</td>}
                                                {item.percurso.length > 0 && item.percurso[0].entFormadora.length > 0 ? <td className="border border-white candidate">{item.percurso[0].entFormadora[0].name}</td> : <td className="border border-white">Sem Curso</td>}
                                                {item.percurso.length > 0 && item.percurso[0].entFinanciadora.length > 0 ? <td className="border border-white candidate">{item.percurso[0].entFinanciadora[0].name}</td> : <td className="border border-white">Sem Curso</td>}
                                                {CheckTypeAdmin() ===true  &&<td className="border border-white">
                                                    <ConfirmToaster css={"button-geral hover:brightness-75"} icon={<IoMdRemoveCircle size={24} />} title={"Eliminar"} message={"Deseja eliminar a candidatura ? (Se a eliminá-la perderá o acesso à esta.)"} confirm={deleteFormando} id={item._id}></ConfirmToaster>
                                                </td>}
                                            </tr>
                                        ))}
                                    </tbody>
                                ) : (
                                    <tbody />
                                )}
                            </table>
                        </div>
                        <div className="flex items-center justify-center mt-4 mb-8">
                            <button className={currentPage > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => goToFirstPage()}>
                                <AiOutlineDoubleLeft />
                            </button>
                            <button className={currentPage > 1 ? "button-geral mr-1" : "button-disabled mr-1"} onClick={() => decreasePagination()}>
                                <AiOutlineLeft />
                            </button>
                            <p className="text-main-color">Página {currentPage} - {totalPages}</p>
                            <button className={currentPage < totalPages ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => increasePagination()}>
                                <AiOutlineRight />
                            </button>
                            <button className={currentPage < totalPages ? "button-geral ml-1" : "button-disabled ml-1"} onClick={() => goToLastPage()}>
                                <AiOutlineDoubleRight />
                            </button>
                        </div> </> : <p className="third-title">Não existem dados</p>}
                </div>
                <div className="w-full mt-3 sm:bottom-0 sm:mt-0">
                    <Footer />
                </div>
            </main >
        </>
    );
}

export default Formandos