import React, { useState } from 'react';
import Select from 'react-select';



const MultiSelectDropdown = ({ data, width, multi ,changer }) => {
    const [selectedOptions, setSelectedOptions] = useState([]);
    // Transformando os dados em um formato apropriado para o react-select
    const filteredData = Array.isArray(data)
        ? data.map((element) => ({ value: element._id, label: element.name }))
        : [];

    const handleChange = (selected) => {
        setSelectedOptions(selected);
        // changer(selected); 
    };
    const handleFinalChange = () => {
        Array.isArray(selectedOptions)
        ? changer(selectedOptions.map((item)=> item.value))
        : changer(selectedOptions.value)
    };
    const customStyles = {
        control: (base, state) => ({
            ...base,
            borderColor: state.isFocused ? '#3555bd' : '#172554', // Cor da borda (focado e normal)
            boxShadow: state.isFocused ? '0 0 4px #3555bd' : 'none', // Sombra quando focado
            '&:hover': {
                borderColor: '#3555bd', // Cor da borda ao passar o mouse
            },
            backgroundColor: '#E6F0F9',
            borderRadius: '8px',
            padding: '5px',
            transition: 'all 0.3s ease',
            margin: '10px'
        }),
        dropdownIndicator: (base, state) => ({
            ...base,
            color: state.isFocused ? '#172554' : '#172554', // Cor da seta (focado e normal)
            ':hover': {
                color: '#3555bd', // Cor ao passar o mouse
            },
        }),
        indicatorSeparator: (base) => ({
            ...base,
            backgroundColor: '#172554', // Cor da barra
            width: '1px', // Largura da barra
        }),
        option: (base, state) => ({
            ...base,
            backgroundColor: state.isFocused ? '#172554' : '#E6F0F9',
            color: state.isFocused ? '#E6F0F9' : '#172554',
            padding: '10px 15px',
            cursor: 'pointer',
            ':active': {
                backgroundColor: '#172554',
                color: '#E6F0F9',
            },
        }),
        multiValue: (base) => ({
            ...base,
            backgroundColor: '#172554',
            color: '#E6F0F9',
            borderRadius: '4px',
            padding: '2px 5px',
            ':hover': {
                backgroundColor: '#3555bd',
                color: '#E6F0F9',
            },
        }),
        multiValueLabel: (base) => ({
            ...base,
            color: '#E6F0F9',
        }),
        multiValueRemove: (base) => ({
            ...base,
            color: '#E6F0F9',
            cursor: 'pointer',
            ':hover': {
                backgroundColor: '#172554',
                color: '#E6F0F9',
            },
        }),
        clearIndicator: (base, state) => ({
            ...base,
            color: state.isFocused ? '#172554' : '#172554', // Cor do botão "Remover Todos"
            ':hover': {
                color: '#3555bd', // Cor ao passar o mouse
            },
        }),
        menu: (base) => ({
            ...base,
            borderRadius: '8px',
            overflow: 'hidden',
        }),
        placeholder: (base) => ({
            ...base,
            color: '#2f3b7791',
            fontStyle: 'italic',
        }),
    };

    return (
        <div className={`selector mb-6 ${width?width:"w-72"}`}>
            <h3 className='mt-1 font-bold'>{multi?"Escolha as opções pretendidas":"Escolha a opção pretendida" }</h3>
            <Select
                options={filteredData}
                styles={customStyles}
                isMulti={multi?true:false}
                value={selectedOptions}
                onChange={handleChange}
                onBlur={handleFinalChange}
                placeholder="Selecione..."
                noOptionsMessage={() => "Nenhuma opção disponível"}
            />     
        </div>
    );
};

export default MultiSelectDropdown;