import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from 'react-router-dom'
import { AiFillHome } from "react-icons/ai";
import { BsBuildingFillAdd, BsBuildingsFill, BsFillBuildingFill, BsPersonFillAdd } from "react-icons/bs";
import { FaBookOpen, FaCube, FaUserAlt, FaUserEdit, FaUserGraduate, FaUserTag, FaUserTie, FaUsers } from "react-icons/fa";
import { PiCubeFocusLight, PiUserListBold, PiUserListFill } from "react-icons/pi";
import { MdLibraryAdd, MdLibraryBooks } from "react-icons/md";
import { ChalkboardTeacher } from "@phosphor-icons/react";
import Logout from "../../logout/logout";
import CheckTypeAdmin from "../../CheckTypeAdmin/CheckTypeAdmin";
import CheckTypeFinanceiro from "../../CheckTypeAdmin/CheckTypeFinanceiro";

//Componente navbar
function NavbarAdmin1({ currentPage }) {
    const navigate = useNavigate()
    const [dropdown, setDropdown] = useState(0);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [name, setName] = useState(null);
    const [mobileMenu, setMobileMenu] = useState(false);
    //Separa nome e aplido

    function nomeApelido(fullname) {
        let names = fullname.split(" ");
        if (names.length === 1) {
            setName(names[0])
        } else {
            let firstName = names[0];
            let lastName = names.pop();
            setName(firstName + " " + lastName)
        }
    }
    //Dropdown da navbar
    //Dropdown da navbar
    function toggleDrop(number) {
        if (dropdown === number) return 0;
        else return number;
    }

    useEffect(() => {
        const id = localStorage.getItem('id');
        getAdminPhoto(id)
    }, []);

    //Recebe a foto do administrador pelo seu id
    function getAdminPhoto(id) {
        fetch(`${process.env.REACT_APP_API_URL}/admins/${id}/photo`, {
            headers: {
                'authorization': localStorage.getItem('admin1')
            }
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    setData(result);
                    nomeApelido(result.fullName)
                },
                (error) => {
                    console.error("Error fetching data: ", error);
                    setError(error);
                }
            );
    };

    const params = useParams();

    return (
        <>
            {/* Desktop menu */}
            <nav className="flex justify-center p-2 bg-secundary-color 2xl:justify-around">
                <a href="#" className="flex items-center justify-center mt-1">
                    <img src={process.env.PUBLIC_URL + "/img/logotipo/Logo.svg"} alt="Magna Logo" />
                </a>
                <button type="button" className="inline-flex 2xl:hidden absolute left-0 top-1.5  items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200" onClick={() => setMobileMenu(!mobileMenu)}>
                    <span className="sr-only">Open main menu</span>
                    <svg className="top-0 w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                    </svg>
                </button>
                <div className="flex p-4">
                    <div className="hidden w-full 2xl:block 2xl:w-auto" id="navbar-dropdown">
                        <ul className="flex flex-col items-center justify-center md:flex-row">
                            <li className="m-2">
                                <Link to={"/menuAdmin/"+params.id_candidatura} className={"navbar-item " + (currentPage === "home" && "navbar-dropdown-item-selected")} aria-current="page">Início</Link>
                            </li>
                           {CheckTypeAdmin() ===true  && <li className="m-2">
                                <button id="dropdownNavbarLink" className={"navbar-item " + (currentPage.includes('Admin') ? "navbar-dropdown-item-selected" : (dropdown === 1 && "bg-text-third-color text-white"))} onClick={() => setDropdown(toggleDrop(1))}>Administradores
                                    <svg className="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                                    </svg>
                                </button>
                                {dropdown === 1 && <div id="dropdownNavbar" className={"z-10 absolute font-normal bg-navbar-secundary-color text-main-color  rounded-lg shadow "}>
                                    <ul className="py-2 text-sm text-main-color " aria-labelledby="dropdownLargeButton">
                                        <li>
                                            <Link to={"/menuAdmin/addAdministradores/" + params.id_candidatura} className={"navbar-dropdown-item " + (currentPage === "addAdmin" && "navbar-dropdown-item-selected")}>Criar Administradores</Link>
                                        </li>
                                        <li>
                                            <Link to={"/menuadmin/" + params.id_candidatura + "/listAdmins"} className={"navbar-dropdown-item " + (currentPage === "manageAdmin" && "navbar-dropdown-item-selected")}>Gerir Administradores</Link>
                                        </li>
                                        <li>
                                            <Link to={"/menuadmin/" + params.id_candidatura + "/operacoes"} className={"navbar-dropdown-item " + (currentPage === "manageOperacoesAdmin" && "navbar-dropdown-item-selected")}>Gerir Operações</Link>
                                        </li>
                                    </ul>
                                </div>}
                            </li>}
                           { CheckTypeAdmin() ===true  &&<li className="m-2">
                                <button id="dropdownNavbarLink" className={"navbar-item " + (currentPage.includes("Coord") ? "navbar-dropdown-item-selected" : (dropdown === 2 && "bg-text-third-color text-white"))} onClick={() => setDropdown(toggleDrop(2))}>Coordenadores
                                    <svg className="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                                    </svg>
                                </button>
                                {dropdown === 2 && <div id="dropdownNavbar" className={"z-10 absolute font-normal bg-navbar-secundary-color text-main-color  rounded-lg shadow "}>
                                    <ul className="py-2 text-sm text-main-color " aria-labelledby="dropdownLargeButton">
                                        <li>
                                            <Link to={"/menuAdmin/" + params.id_candidatura + "/addCoordinators"} className={"navbar-dropdown-item " + (currentPage === "addCoordinator" && "navbar-dropdown-item-selected")}>Criar Coordenadores</Link>
                                        </li>
                                        <li>
                                            <Link to={"/menuadmin/" + params.id_candidatura + "/coordinators"} className={"navbar-dropdown-item " + (currentPage === "manageCoordinator" && "navbar-dropdown-item-selected")}>Bolsa de Coordenadores</Link>
                                        </li>
                                    </ul>
                                </div>}
                            </li>}

                            {localStorage.getItem("TipoAdmin") !== "TORVC" &&<li className="m-2">
                                <button id="dropdownNavbarLink" className={"navbar-item " + (currentPage.includes('Formador') ? "navbar-dropdown-item-selected" : (dropdown === 3 && "bg-text-third-color text-white"))} onClick={() => setDropdown(toggleDrop(3))}>Formadores
                                    <svg className="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                                    </svg>
                                </button>
                                {dropdown === 3 && <div id="dropdownNavbar" className={"z-10 absolute font-normal bg-navbar-secundary-color text-main-color  rounded-lg shadow "}>
                                    <ul className="py-2 text-sm text-main-color " aria-labelledby="dropdownLargeButton">
                                        <li>
                                            <Link to={"/menuadmin/" + params.id_candidatura + "/candidacies"} className={"navbar-dropdown-item " + (currentPage === "candidaturasFormador" && "navbar-dropdown-item-selected")}>Ver Candidaturas</Link>
                                        </li>
                                        <li>
                                            <Link to={"/menuadmin/" + params.id_candidatura + "/list"} className={"navbar-dropdown-item " + (currentPage === "ListaFormador" && "navbar-dropdown-item-selected")}>Bolsa de Formadores</Link>
                                        </li>
                                    </ul>
                                </div>}
                            </li>}
                           {CheckTypeAdmin() ===true  && <li className="m-2">
                                <button id="dropdownNavbarLink" className={"navbar-item " + (currentPage.includes('Entidade') ? "navbar-dropdown-item-selected" : (dropdown === 4 && "bg-text-third-color text-white"))} onClick={() => setDropdown(toggleDrop(4))}>Entidades
                                    <svg className="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                                    </svg>
                                </button>
                                {dropdown === 4 && <div id="dropdownNavbar" className={"z-10 absolute font-normal bg-navbar-secundary-color text-main-color  rounded-lg shadow"}>
                                    <ul className="py-2 text-sm text-main-color " aria-labelledby="dropdownLargeButton">
                                        <li>
                                            <Link to={"/menuadmin/" + params.id_candidatura + "/CriarEntFormadora"} className={"navbar-dropdown-item " + (currentPage === "criarEntidadeTraining" && "navbar-dropdown-item-selected")}>Criar Entidade Formadora</Link>
                                        </li>
                                        <li>
                                            <Link to={"/menuadmin/" + params.id_candidatura + "/entFormadoras"} className={"navbar-dropdown-item " + (currentPage === "gerirEntidadeTraining" && "navbar-dropdown-item-selected")}>Gerir Entidades Formadoras</Link>
                                        </li>
                                        <li>
                                            <Link to={"/menuadmin/" + params.id_candidatura + "/CriarEntFinanciadora"} className={"navbar-dropdown-item " + (currentPage === "criarEntidadeFinanciadora" && "navbar-dropdown-item-selected")}>Criar Entidade Financiadora</Link>
                                        </li>
                                        <li>
                                            <Link to={"/menuadmin/" + params.id_candidatura + "/entFinanciadoras"} className={"navbar-dropdown-item " + (currentPage === "gerirEntidadeFinanciadora" && "navbar-dropdown-item-selected")}>Gerir Entidades Financiadoras</Link>
                                        </li>
                                    </ul>
                                </div>}
                            </li>}

                            <li className="m-2">
                                <button id="dropdownNavbarLink" className={"navbar-item " + (currentPage.includes('Cursos') ? "navbar-dropdown-item-selected" : (dropdown === 5 && "bg-text-third-color text-white"))} onClick={() => setDropdown(toggleDrop(5))}>Cursos/Percursos
                                    <svg className="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                                    </svg>
                                </button>
                                {dropdown === 5 && <div id="dropdownNavbar" className={"z-10 absolute font-normal bg-navbar-secundary-color text-main-color rounded-lg shadow"}>
                                    <ul className="py-2 text-sm text-main-color" aria-labelledby="dropdownLargeButton">
                                       {CheckTypeAdmin() ===true  && <li>
                                            <Link to={"/menuadmin/" + params.id_candidatura + "/cursos/addcursos"} className={"navbar-dropdown-item " + (currentPage === "criarCursos" && "navbar-dropdown-item-selected")}>Criar Cursos/Percursos</Link>
                                        </li>}
                                        <li>
                                            <Link to={"/menuadmin/" + params.id_candidatura + "/cursos"} className={"navbar-dropdown-item " + (currentPage === "gerirCursos" && "navbar-dropdown-item-selected")}>Gerir Cursos/Percursos</Link>
                                        </li>
                                       {CheckTypeAdmin() ===true  && <li>
                                            <Link to={"/menuadmin/" + params.id_candidatura + "/templates"} className={"navbar-dropdown-item " + (currentPage === "criarCursosModelo" && "navbar-dropdown-item-selected")}>Criar Cursos/Percursos Modelo</Link>
                                        </li>}
                                       {CheckTypeAdmin() ===true  && <li>
                                            <Link to={"/menuadmin/" + params.id_candidatura + "/UFCDsGlobais"} className={"navbar-dropdown-item " + (currentPage === "criarCursosDisciplinasModelo" && "navbar-dropdown-item-selected")}>Criar Disciplinas Modelo</Link>
                                        </li>}
                                    </ul>
                                </div>}
                            </li>

                            <li className="m-2">
                                <button id="dropdownNavbarLink" className={"navbar-item " + (currentPage.includes("Formandos") ? "navbar-dropdown-item-selected" : (dropdown === 6 && "bg-text-third-color text-white"))} onClick={() => setDropdown(toggleDrop(6))}>Formandos
                                    <svg className="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                                    </svg>
                                </button>
                                {dropdown === 6 && <div id="dropdownNavbar" className={"z-10 absolute font-normal bg-navbar-secundary-color text-main-color rounded-lg shadow "}>
                                    <ul className="py-2 text-sm text-main-color " aria-labelledby="dropdownLargeButton">
                                        <li>
                                            <Link to={"/menuadmin/" + params.id_candidatura + "/formandos"} className={"navbar-dropdown-item " + (currentPage === "verFormandos" && "navbar-dropdown-item-selected")}>Ver Formandos</Link>
                                        </li>
                                        <li>
                                            <Link to={"/menuadmin/" + params.id_candidatura + "/turmas"} className={"navbar-dropdown-item " + (currentPage === "gerirTurmasFormandos" && "navbar-dropdown-item-selected")}>Gerir Turmas Formados</Link>
                                        </li>
                                    </ul>
                                </div>}
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="relative items-center justify-center hidden mr-6 2xl:flex">
                    <img className="object-cover w-16 h-16 border rounded-full cursor-pointer border-main-color" src={data && data.photo ? data.photo : 'https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg'} onClick={() => setDropdown(toggleDrop(7))} />
                    {dropdown === 7 && <div id="dropdownNavbar" className={"z-10 absolute top-16 -left-20 font-normal bg-navbar-secundary-color text-main-color rounded-lg shadow w-40"}>
                        <p className="pt-2 text-center">{name && name}</p>
                        <ul className="pb-2 text-sm text-main-color ">
                            <li className="m-2">
                                <Link to={"/menuadmin/Profile/" + params.id_candidatura} className={"navbar-dropdown-item " + (currentPage === "myProfile" && "navbar-dropdown-item-selected")}>
                                    <p>O meu perfil</p>
                                </Link>
                            </li>
                            <li className="mx-2">
                                <button className='w-full logoutButton' onClick={() => { Logout("admin1"); navigate("/") }} >
                                    <img className='h-6' src={process.env.PUBLIC_URL + "/img/logout.png"} alt="Logout" /><p className="ml-1">Logout</p>
                                </button>
                            </li>
                        </ul>
                    </div>}
                </div>
            </nav>

            {/* Mobile menu */}
            {mobileMenu &&
                <aside id="sidebar" className="fixed top-0 left-0 z-40 w-1/2 h-full transition-transform 2xl:hidden md:w-1/3 lg:w-1/4 sm:translate-x-0" aria-label="Sidebar">
                    <button onClick={() => setMobileMenu(!mobileMenu)} type="button" className="inline-flex 2xl:hidden absolute left-0 top-1.5  items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200">
                        <span className="sr-only">Open sidebar</span>
                        <svg className="top-0 w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                        </svg>
                    </button>
                    <div id="sidebarContent" className="h-full px-1 py-4 overflow-y-auto md:px-3 bg-secundary-color bg-opacity-80">
                        <ul className="mt-12 space-y-2 font-medium md:mt-0">
                            <li className="flex flex-col items-center">
                                <Link to={"/menuadmin/Profile/" + params.id_candidatura}>
                                    <img className={"rounded-full cursor-pointer object-cover h-16 w-16 mx-auto border-main-color " + (currentPage === "perfil" ? "border-[3px]" : "border")} src={data && data.photo ? data.photo : 'https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg'} />
                                    <p className="pt-2 text-center text-text-main-color">{name && name}</p>
                                </Link>
                            </li>
                            <li>
                                <Link to={"/MenuSuperAdmin"} className={"navbar-item " + (currentPage === "home" && "navbar-dropdown-item-selected")} aria-current="page">
                                    <AiFillHome className={"flex-shrink-0 w-3 h-3 sm:w-5 sm:h-5 text-text-main-color " + (currentPage === "home" && "text-white")} />
                                    <span className={"flex-1 ml-3 text-left text-xs sm:text-base text-text-main-color " + (currentPage === "home" && "text-white")}>Início</span>
                                </Link>
                            </li>
                            <li onClick={() => setDropdown(toggleDrop(1))}>
                                <div className={"flex items-center h-12 p-2 rounded-lg group " + (currentPage.includes('Admin') ? "navbarItemSelected" : "hover:bg-white")}>
                                    <FaUserTie className={"flex-shrink-0 w-3 h-3 sm:w-5 sm:h-5 text-text-main-color " + (currentPage.includes('Admin') && "text-white")} />
                                    <span className={"flex-1 ml-3 text-left text-xs sm:text-base text-text-main-color " + (currentPage.includes('Admin') && "text-white")}>Administradores</span>
                                    <svg className="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                                    </svg>
                                </div>
                            </li>
                            {dropdown === 1 && <div id="dropdownNavbar" className={"bg-navbar-secundary-color text-main-color rounded-lg shadow"}>
                                <ul className="py-2 text-sm text-main-color" aria-labelledby="dropdownLargeButton">
                                    <li>
                                        <Link to={"/MenuAdmin/addAdministradores/" + params.id_candidatura} className={"flex items-center h-12 p-2 rounded-lg group " + (currentPage === "addAdmin" ? "navbarItemSelected" : "hover:bg-white")}>
                                            <BsPersonFillAdd className={"flex-shrink-0 w-3 h-3 sm:w-5 sm:h-5 text-text-main-color " + (currentPage === "addAdmin" && "text-white")} />
                                            <span className={"flex-1 ml-3 text-left text-xs sm:text-base text-text-main-color " + (currentPage === "addAdmin" && "text-white")}>Criar Administradores</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={"/menuadmin/" + params.id_candidatura + "/listAdmins"} className={"flex items-center h-12 p-2 rounded-lg group " + (currentPage === "manageAdmin" ? "navbarItemSelected" : "hover:bg-white")}>
                                            <FaUserEdit className={"flex-shrink-0 w-3 h-3 sm:w-5 sm:h-5 text-text-main-color " + (currentPage === "manageAdmin" && "text-white")} />
                                            <span className={"flex-1 ml-3 text-left text-xs sm:text-base text-text-main-color " + (currentPage === "manageAdmin" && "text-white")}>Gerir Administradores</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>}
                            <li onClick={() => setDropdown(toggleDrop(2))}>
                                <div className={"flex items-center h-12 p-2 rounded-lg group " + (currentPage.includes("Coord") ? "navbarItemSelected" : "hover:bg-white")}>
                                    <FaUsers className={"flex-shrink-0 w-3 h-3 sm:w-5 sm:h-5 text-text-main-color " + (currentPage.includes("Coord") && "text-white")} />
                                    <span className={"flex-1 ml-3 text-left text-xs sm:text-base text-text-main-color " + (currentPage.includes("Coord") && "text-white")}>Coordenadores</span>
                                    <svg className="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                                    </svg>
                                </div>
                            </li>
                            {dropdown === 2 && <div id="dropdownNavbar" className={"bg-navbar-secundary-color text-main-color rounded-lg shadow"}>
                                <ul className="py-2 text-sm text-main-color" aria-labelledby="dropdownLargeButton">
                                    <li>
                                        <Link to={"/menuAdmin/" + params.id_candidatura + "/addCoordinators"} className={"flex items-center h-12 p-2 rounded-lg group " + (currentPage === "addCoordinator" ? "navbarItemSelected" : "hover:bg-white")}>
                                            <BsPersonFillAdd className={"flex-shrink-0 w-3 h-3 sm:w-5 sm:h-5 text-text-main-color " + (currentPage === "addCoordinator" && "text-white")} />
                                            <span className={"flex-1 ml-3 text-left text-xs sm:text-base text-text-main-color " + (currentPage === "addCoordinator" && "text-white")}>Criar Coordenadores</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={"/menuadmin/" + params.id_candidatura + "/coordinators"} className={"flex items-center h-12 p-2 rounded-lg group " + (currentPage === "manageCoordinator" ? "navbarItemSelected" : "hover:bg-white")}>
                                            <FaUserEdit className={"flex-shrink-0 w-3 h-3 sm:w-5 sm:h-5 text-text-main-color " + (currentPage === "manageCoordinator" && "text-white")} />
                                            <span className={"flex-1 ml-3 text-left text-xs sm:text-base text-text-main-color " + (currentPage === "manageCoordinator" && "text-white")}>Bolsa de Coordenadores</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>}
                            <li onClick={() => setDropdown(toggleDrop(3))}>
                                <div className={"flex items-center h-12 p-2 rounded-lg group " + (currentPage.includes('Formador') ? "navbarItemSelected" : "hover:bg-white")}>
                                    <FaUserAlt className={"flex-shrink-0 w-3 h-3 sm:w-5 sm:h-5 text-text-main-color " + (currentPage.includes('Formador') && "text-white")} />
                                    <span className={"flex-1 ml-3 text-left text-xs sm:text-base text-text-main-color " + (currentPage.includes('Formador') && "text-white")}>Formadores</span>
                                    <svg className="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                                    </svg>
                                </div>
                            </li>
                            {dropdown === 3 && <div id="dropdownNavbar" className={"bg-navbar-secundary-color text-main-color rounded-lg shadow"}>
                                <ul className="py-2 text-sm text-main-color" aria-labelledby="dropdownLargeButton">
                                    <li>
                                        <Link to={"/menuadmin/" + params.id_candidatura + "/candidacies"} className={"flex items-center h-12 p-2 rounded-lg group " + (currentPage === "candidaturasFormador" ? "navbarItemSelected" : "hover:bg-white")}>
                                            <PiUserListBold className={"flex-shrink-0 w-3 h-3 sm:w-5 sm:h-5 text-text-main-color " + (currentPage === "candidaturasFormador" && "text-white")} />
                                            <span className={"flex-1 ml-3 text-left text-xs sm:text-base text-text-main-color " + (currentPage === "candidaturasFormador" && "text-white")}>Ver Candidaturas</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={"/menuadmin/" + params.id_candidatura + "/list"} className={"flex items-center h-12 p-2 rounded-lg group " + (currentPage === "ListaFormador" ? "navbarItemSelected" : "hover:bg-white")}>
                                            <PiUserListFill className={"flex-shrink-0 w-3 h-3 sm:w-5 sm:h-5 text-text-main-color " + (currentPage === "ListaFormador" && "text-white")} />
                                            <span className={"flex-1 ml-3 text-left text-xs sm:text-base text-text-main-color " + (currentPage === "ListaFormador" && "text-white")}>Bolsa de Formadores</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>}
                            <li onClick={() => setDropdown(toggleDrop(4))}>
                                <div className={"flex items-center h-12 p-2 rounded-lg group " + (currentPage.includes('Entidade') ? "navbarItemSelected" : "hover:bg-white")}>
                                    <BsBuildingsFill className={"flex-shrink-0 w-3 h-3 sm:w-5 sm:h-5 text-text-main-color " + (currentPage.includes('Entidade') && "text-white")} />
                                    <span className={"flex-1 ml-3 text-left text-xs sm:text-base text-text-main-color " + (currentPage.includes('Entidade') && "text-white")}>Entidades</span>
                                    <svg className="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                                    </svg>
                                </div>
                            </li>
                            {dropdown === 4 && <div id="dropdownNavbar" className={"bg-navbar-secundary-color text-main-color rounded-lg shadow"}>
                                <ul className="py-2 text-sm text-main-color" aria-labelledby="dropdownLargeButton">
                                    <li>
                                        <Link to={"/menuadmin/" + params.id_candidatura + "/CriarEntFormadora"} className={"flex items-center h-12 p-2 rounded-lg group " + (currentPage === "criarEntidadeTraining" ? "navbarItemSelected" : "hover:bg-white")}>
                                            <img src="/img/school-circle-check-solid.png" className={"object-contain w-3 h-4 sm:w-5 sm:h-5 text-text-main-color " + (currentPage === "criarEntidadeTraining" && "invert brightness-0")} />
                                            <span className={"flex-1 ml-3 text-left text-xs sm:text-base text-text-main-color " + (currentPage === "criarEntidadeTraining" && "text-white")}>Criar Entidade Formadora</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={"/menuadmin/" + params.id_candidatura + "/entFormadoras"} className={"flex items-center h-12 p-2 rounded-lg group " + (currentPage === "gerirEntidadeTraining" ? "navbarItemSelected" : "hover:bg-white")}>
                                            <img src="/img/school.svg" className={"flex-shrink-0 w-3 h-3 sm:w-5 sm:h-5 text-text-main-color " + (currentPage === "gerirEntidadeTraining" && "text-white")} />
                                            <span className={"flex-1 ml-3 text-left text-xs sm:text-base text-text-main-color " + (currentPage === "gerirEntidadeTraining" && "text-white")}>Gerir Entidades Formadoras</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={"/menuadmin/" + params.id_candidatura + "/CriarEntFinanciadora"} className={"flex items-center h-12 p-2 rounded-lg group " + (currentPage === "criarEntidadeFinanciadora" ? "navbarItemSelected" : "hover:bg-white")}>
                                            <BsBuildingFillAdd className={"flex-shrink-0 w-3 h-3 sm:w-5 sm:h-5 text-text-main-color " + (currentPage === "criarEntidadeFinanciadora" && "text-white")} />
                                            <span className={"flex-1 ml-3 text-left text-xs sm:text-base text-text-main-color " + (currentPage === "criarEntidadeFinanciadora" && "text-white")}>Criar Entidade Financiadora</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={"/menuadmin/" + params.id_candidatura + "/entFinanciadoras"} className={"flex items-center h-12 p-2 rounded-lg group " + (currentPage === "criarCursosModelo" ? "navbarItemSelected" : "hover:bg-white")}>
                                            <BsFillBuildingFill className={"flex-shrink-0 w-3 h-3 sm:w-5 sm:h-5 text-text-main-color " + (currentPage === "criarCursosModelo" && "text-white")} />
                                            <span className={"flex-1 ml-3 text-left text-xs sm:text-base text-text-main-color " + (currentPage === "criarCursosModelo" && "text-white")}>Criar Cursos/Percursos Modelo</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>}
                            <li onClick={() => setDropdown(toggleDrop(5))}>
                                <div className={"flex items-center h-12 p-2 rounded-lg group " + (currentPage.includes('Cursos') ? "navbarItemSelected" : "hover:bg-white")}>
                                    <FaBookOpen className={"flex-shrink-0 w-3 h-3 sm:w-5 sm:h-5 text-text-main-color " + (currentPage.includes('Cursos') && "text-white")} />
                                    <span className={"flex-1 ml-3 text-left text-xs sm:text-base text-text-main-color " + (currentPage.includes('Cursos') && "text-white")}>Cursos/Percursos</span>
                                    <svg className="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                                    </svg>
                                </div>
                            </li>

                            {dropdown === 5 && <div id="dropdownNavbar" className={"bg-navbar-secundary-color text-main-color rounded-lg shadow"}>
                                <ul className="py-2 text-sm text-main-color" aria-labelledby="dropdownLargeButton">
                                    <li>
                                        <Link to={"/menuadmin/" + params.id_candidatura + "/cursos/addcursos"} className={"flex items-center h-12 p-2 rounded-lg group " + (currentPage === "criarCursos" ? "navbarItemSelected" : "hover:bg-white")}>
                                            <MdLibraryAdd className={"object-contain w-3 h-4 sm:w-5 sm:h-5 text-text-main-color " + (currentPage === "criarCursos" && "invert brightness-0")} />
                                            <span className={"flex-1 ml-3 text-left text-xs sm:text-base text-text-main-color " + (currentPage === "criarCursos" && "text-white")}>Criar Cursos/Percursos</span>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to={"/menuadmin/" + params.id_candidatura + "/cursos"} className={"flex items-center h-12 p-2 rounded-lg group " + (currentPage === "gerirCursos" ? "navbarItemSelected" : "hover:bg-white")}>
                                            <MdLibraryBooks className={"flex-shrink-0 w-3 h-3 sm:w-5 sm:h-5 text-text-main-color " + (currentPage === "gerirCursos" && "text-white")} />
                                            <span className={"flex-1 ml-3 text-left text-xs sm:text-base text-text-main-color " + (currentPage === "gerirCursos" && "text-white")}>Gerir Cursos/Percursos</span>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to={"/menuadmin/" + params.id_candidatura + "/templates"} className={"flex items-center h-12 p-2 rounded-lg group " + (currentPage === "criarCursosModelo" ? "navbarItemSelected" : "hover:bg-white")}>
                                            <FaCube className={"flex-shrink-0 w-3 h-3 sm:w-5 sm:h-5 text-text-main-color " + (currentPage === "criarCursosModelo" && "text-white")} />
                                            <span className={"flex-1 ml-3 text-left text-xs sm:text-base text-text-main-color " + (currentPage === "criarCursosModelo" && "text-white")}>Criar Cursos/Percursos Modelo</span>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to={"/menuadmin/" + params.id_candidatura + "/UFCDsGlobais"} className={"flex items-center h-12 p-2 rounded-lg group " + (currentPage === "criarCursosDisciplinasModelo" ? "navbarItemSelected" : "hover:bg-white")}>
                                            <PiCubeFocusLight className={"flex-shrink-0 w-3 h-3 sm:w-5 sm:h-5 text-text-main-color " + (currentPage === "criarCursosDisciplinasModelo" && "text-white")} />
                                            <span className={"flex-1 ml-3 text-left text-xs sm:text-base text-text-main-color " + (currentPage === "criarCursosDisciplinasModelo" && "text-white")}>Criar Disciplinas Modelo</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>}

                            <li onClick={() => setDropdown(toggleDrop(6))}>
                                <div className={"flex items-center h-12 p-2 rounded-lg group " + (currentPage.includes('Formandos') ? "navbarItemSelected" : "hover:bg-white")}>
                                    <FaUserGraduate className={"flex-shrink-0 w-3 h-3 sm:w-5 sm:h-5 text-text-main-color " + (currentPage.includes('Formandos') && "text-white")} />
                                    <span className={"flex-1 ml-3 text-left text-xs sm:text-base text-text-main-color " + (currentPage.includes('Formandos') && "text-white")}>Formandos</span>
                                    <svg className="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                                    </svg>
                                </div>
                            </li>

                            {dropdown === 6 && <div id="dropdownNavbar" className={"bg-navbar-secundary-color text-main-color rounded-lg shadow"}>
                                <ul className="py-2 text-sm text-main-color" aria-labelledby="dropdownLargeButton">
                                    <li>
                                        <Link to={"/menuadmin/" + params.id_candidatura + "/formandos"} className={"flex items-center h-12 p-2 rounded-lg group " + (currentPage === "verFormandos" ? "navbarItemSelected" : "hover:bg-white")}>
                                            <FaUserGraduate className={"flex-shrink-0 w-3 h-3 sm:w-5 sm:h-5 text-text-main-color " + (currentPage === "verFormandos" && "text-white")} />
                                            <span className={"flex-1 ml-3 text-left text-xs sm:text-base text-text-main-color " + (currentPage === "verFormandos" && "text-white")}>Ver Formandos</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={"/menuadmin/" + params.id_candidatura + "/turmas"} className={"flex items-center h-12 p-2 rounded-lg group " + (currentPage === "ListaFormador" ? "navbarItemSelected" : "hover:bg-white")}>
                                            <ChalkboardTeacher weight="fill" className={"flex-shrink-0 w-3 h-3 sm:w-5 sm:h-5 text-text-main-color " + (currentPage === "ListaFormador" && "text-white")} />
                                            <span className={"flex-1 ml-3 text-left text-xs sm:text-base text-text-main-color " + (currentPage === "ListaFormador" && "text-white")}>Bolsa de Formadores</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>}

                            <ul className="pt-4 mt-4 space-y-2 font-medium border-t border-gray-200">
                                <li>
                                    <button className='w-full logoutButton' onClick={() => { Logout("admin1"); navigate("/") }}>
                                        <img className='h-4 sm:h-5' src={process.env.PUBLIC_URL + "/img/logout.png"} alt="Logout" /><p className="ml-2 sm:ml-3">Logout</p>
                                    </button>
                                </li>
                            </ul>
                        </ul>
                    </div >
                </aside >
            }
        </>
    )
}

export default NavbarAdmin1